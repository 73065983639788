import {NavMenuItem} from "app/modules/common/menu/menu";
import {Paths} from "app/modules/company/stores/paths";
import React from "react";

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AdjustIcon from '@mui/icons-material/Adjust';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import BallotIcon from '@mui/icons-material/Ballot';
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import ContactsIcon from '@mui/icons-material/Contacts';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import MediationIcon from '@mui/icons-material/Mediation';
import PaidIcon from '@mui/icons-material/Paid';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MapIcon from '@mui/icons-material/Map';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import SyncIcon from '@mui/icons-material/Sync';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

export const Orders = new NavMenuItem({
    title: "Orders",
    icon: <AssignmentIcon fontSize={"small"}/>,
    expanded: false,
    selected: false,
    subItems: []
});

export const OrdersAll = new NavMenuItem({
    path: Paths.Orders.nav,
    title: "All",
    icon: <AssignmentIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const OrdersProgress = new NavMenuItem({
    path: Paths.OrdersInProgress.nav,
    title: "In Progress",
    icon: <SyncIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const OrdersReady = new NavMenuItem({
    path: Paths.OrdersReady.nav,
    title: "Ready",
    icon: <AlarmOnIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const OrdersHold = new NavMenuItem({
    path: Paths.OrdersHold.nav,
    title: "Hold",
    icon: <HourglassEmptyIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const OrderTasks = new NavMenuItem({
    path: Paths.OrdersTasks.nav,
    title: "Tasks",
    icon: <TaskAltIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Quotes = new NavMenuItem({
    path: Paths.Quotes.nav,
    title: "Quotes",
    icon: <AutoAwesomeMotionIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Bids = new NavMenuItem({
    path: Paths.OrderBids.nav,
    title: "Bids",
    icon: <LibraryBooksIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const LoadBoard = new NavMenuItem({
    title: "LoadBoard",
    icon: <DashboardIcon fontSize={"small"}/>,
    expanded: false,
    selected: false,
    subItems: []
});

export const Radar = new NavMenuItem({
    path: Paths.Radar.nav,
    title: "Radar",
    icon: <AdjustIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const ExternalOrders = new NavMenuItem({
    path: Paths.ExternalOrders.nav,
    title: "All Posted",
    icon: <InventoryIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const FleetView = new NavMenuItem({
    path: Paths.FleetView.nav,
    title: "Fleet View",
    icon: <MapIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Timeline = new NavMenuItem({
    path: Paths.Timeline.nav,
    title: "Timeline",
    icon: <AvTimerIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const FleetDrivers = new NavMenuItem({
    path: Paths.Drivers.nav,
    title: "Drivers",
    icon: <BadgeIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const FleetCarriers = new NavMenuItem({
    path: Paths.Carriers.nav,
    title: "Carriers",
    icon: <PeopleAltIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const FleetVehicles = new NavMenuItem({
    path: Paths.Vehicles.nav,
    title: "Vehicles",
    icon: <LocalShippingIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Fleet = new NavMenuItem({
    title: "Fleet",
    icon: <PeopleAltIcon fontSize={"small"}/>,
    expanded: false,
    selected: false,
    subItems: []
});

export const Contacts = new NavMenuItem({
    title: "Contacts",
    icon: <PeopleAltIcon fontSize={"small"}/>,
    expanded: false,
    selected: false,
    subItems: [
        new NavMenuItem({
            path: Paths.Customers.nav,
            title: "Customers",
            icon: <BusinessIcon fontSize={"small"}/>,
            expanded: false,
            selected: false
        }),
        new NavMenuItem({
            path: Paths.Contacts.nav,
            title: "Contacts",
            icon: <ContactsIcon fontSize={"small"}/>,
            expanded: false,
            selected: false
        })
    ]
});

export const Accounting = new NavMenuItem({
    title: "Accounting",
    icon: <AccountBalanceIcon fontSize={"small"}/>,
    expanded: false,
    selected: false,
    subItems: []
});

export const AccountingInvoiceDashboard = new NavMenuItem({
    path: Paths.AccountingInvoiceDashboard.nav,
    title: "Dashboard",
    icon: <DashboardIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const AccountingOpenOrders = new NavMenuItem({
    path: Paths.AccountingOpen.nav,
    title: "Open Orders",
    icon: <BallotIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const AccountingNetTerms = new NavMenuItem({
    path: Paths.AccountingNetTerms.nav,
    title: "Net Terms",
    icon: <CalendarMonthIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const AccountingInvoices = new NavMenuItem({
    path: Paths.AccountingInvoices.nav,
    title: "Open Invoices",
    icon: <HourglassBottomIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})


export const AccountingInvoicesClosed = new NavMenuItem({
    path: Paths.AccountingInvoicesClosed.nav,
    title: "Closed Invoices",
    icon: <HourglassBottomIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const AccountingPayments = new NavMenuItem({
    path: Paths.AccountingPayments.nav,
    title: "Payments",
    icon: <AttachMoneyIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const temp = [
    new NavMenuItem({
        path: Paths.Empty.nav,
        title: "Dashboard",
        icon: <DashboardIcon fontSize={"small"}/>,
        expanded: false,
        selected: false
    }),
    new NavMenuItem({
        path: Paths.Empty.nav,
        title: "AP",
        icon: <PaidIcon fontSize={"small"}/>,
        expanded: false,
        selected: false
    }),

]

export const Integrations = new NavMenuItem({
    path: Paths.Integrations.nav,
    title: "ELD Integrations",
    icon: <MediationIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const CompanyIntegrations = new NavMenuItem({
    path: Paths.CompanyIntegrations.nav,
    title: "Integrations",
    icon: <MediationIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const TrackingDevices = new NavMenuItem({
    path: Paths.TrackingDevices.nav,
    title: "Tracking Devices",
    icon: <ShareLocationIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const MobileLogs = new NavMenuItem({
    path: Paths.MobileLogs.nav,
    title: "SMS Log",
    icon: <ShareLocationIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const OwnerAppAccounts = new NavMenuItem({
    path: Paths.OwnerAppAccounts.nav,
    title: "Owner Accounts",
    icon: <PeopleAltIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const Groups = new NavMenuItem({
    path: Paths.Empty.nav,
    title: "Teams",
    icon: <GroupsIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const GeneralSettings = new NavMenuItem({
    path: Paths.Settings.nav,
    title: "General",
    icon: <ListAltIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const EmailSettings = new NavMenuItem({
    path: Paths.EmailSettings.nav,
    title: "Email",
    icon: <EmailIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
})

export const Settings = new NavMenuItem({
    title: "Settings",
    icon: <SettingsIcon fontSize={"small"}/>,
    expanded: false,
    selected: false,
    subItems: []
});

export const TelegramLinks = new NavMenuItem({
    path: Paths.TelegramChannelLinks.nav,
    title: "Telegram Links",
    icon: <ChatIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const TelegramChannels = new NavMenuItem({
    path: Paths.TelegramChannels.nav,
    title: "Telegram",
    icon: <ChatIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});
