import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    CarrierShortListModel,
    CompanyIntegrationType,
    CompanyIntegrationUserListModel,
    RadarData,
    RadarModel,
    TenantClaims,
    UpdateVehicleAvailabilityModel,
    VehicleAvailabilityModel,
    VehicleAvailabilityStatus,
    VehicleRadarAvailabilityStatus,
} from "app/modules/company/api/clients.api";
import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {LocalStorageService} from "app/modules/common/stores/LocalStorageService";
import {MainStore} from "app/modules/company/stores/MainStore";
import {Paths} from "app/modules/company/stores/paths";
import queryString from "query-string";
import * as React from "react";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import {CacheService} from "app/modules/company/services/CacheService";
import Moment from "moment";
import Events from "app/modules/common/static/EventNames";
import PubSub from 'pubsub-js';
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogIntegrationUserSettingEdit} from "app/modules/company/components/load-boards/radar/dialog.integration-user-settings.edit";

export class RadarStore extends BaseListStore<RadarModel> {
    @inject companyApiClients: ApiClients;
    @inject localStorageService : LocalStorageService;
    @inject mainStore: MainStore;
    @inject cacheService: CacheService;
    @inject dialogStore: DialogStore;

    savedParamsName: string = "SelectedRadarVehiclesNew";

    filters = new FormState({
        search: new FieldState(''),
        carrierId: new FieldState(-1)
    })

    vehicles: VehicleAvailabilityModel[] = [];
    filteredItems: VehicleAvailabilityModel[] = null;
    carrier: CarrierShortListModel[] = [];
    selectedVehicles: number[] = [];
    radarData: RadarData = null;
    expandedRows: number[] = [];
    gridApi: React.MutableRefObject<GridApiPro> = null;
    integrationUserSettings: CompanyIntegrationUserListModel[] = [];

    isLeftPanelHidden: boolean = false;
    selectedVehicleNumber: number = null;

    openEditVehicleSettings: boolean = false;

    vehicleSettings = new FormState({
        availableAt: new FieldState(null),
        partial: new FieldState(false),
        location: new FieldState(''),
        maxMiles: new FieldState(null),
        pickUpRadius: new FieldState(0),
        note: new FieldState(''),
        states: new FieldState([]),
        status: new FieldState(VehicleAvailabilityStatus.Available),
        vehicleId: new FieldState(0),
        liveTruckLocation: new FieldState(false),
        hasIntegration: new FieldState(false),
    });

    vehicleAvailabilityStatuses = [
        { id : VehicleAvailabilityStatus.Available, name: "Available" },
        { id : VehicleAvailabilityStatus.Broken, name: "Broken" },
        { id : VehicleAvailabilityStatus.Unavailable, name: "Unavailable" }
    ];

    states: { id, name }[] = [];

    constructor() {
        super();

        makeObservable(this, {
            filteredItems: observable,
            selectedVehicles: observable,
            expandedRows: observable,
            openEditVehicleSettings: observable,
            isLeftPanelHidden: observable,
            integrationUserSettings: observable,

            init: action,
            applyVehicleFilters: action,
            setSelected: action,
            openEditVehicleSettingsModal: action,
            closeEditVehicleSettingsModal: action,
            saveVehicleSettings: action,
            bet: action,
            switchLeftPanel: action,
            clearFilters: action,
            removeOrder: action,
            updateVehicleAvailability: action,
            orderItems: action
        });
    }

    clearFilters = async () => {
        this.filters.$.search.onChange("");
        this.filters.$.carrierId.onChange(-1);

        await this.applyVehicleFilters();
    }

    applyVehicleFilters = async () => {
        this.selectedVehicles.length = 0;

        if(this.filters.$.search.value?.length > 0) {
            this.filteredItems = this.vehicles.filter(x => this.filters.$.search.value == x.number.toString());
        } else {
            switch (this.filters.$.carrierId.value) {
                case -1:
                    this.filteredItems = this.vehicles;
                    break;
                case -2:
                    this.filteredItems = this.vehicles.filter(x => x.status == VehicleRadarAvailabilityStatus.Available && x.availability.availableAt < Moment());
                    break;
                case -3:
                    this.filteredItems = this.vehicles.filter(x => x.status == VehicleRadarAvailabilityStatus.Available);
                    break;
                case -4:
                    this.filteredItems = this.vehicles.filter(x => x.status != VehicleRadarAvailabilityStatus.Available);
                    break;
                default:
                    this.filteredItems = this.vehicles.filter(x => x.carrierId == (this.filters.$.carrierId.value || null));
                    break;
            }
        }
    }

    setSelected = (id: number) => {
        let find = this.selectedVehicles.find(x => x == id);

        if(find) {
            this.selectedVehicles = this.selectedVehicles.filter(x => x != id);
        } else {
            this.selectedVehicles.push(id);
        }
    }

    applySpecificFilters() {}

    setItems = async (fleet: VehicleAvailabilityModel[]) => {
        this.vehicles = fleet;
        this.filteredItems = this.vehicles;

        this.orderItems();
    }

    orderItems = () => {
        this.vehicles = this.vehicles.sort(this.orderSort);
        this.filteredItems = this.filteredItems.sort(this.orderSort);
    }

    private orderSort = (a: VehicleAvailabilityModel, b: VehicleAvailabilityModel) => {
        let aStatus= a.status;
        let bStatus= b.status;
        let aNumber = a.number;
        let bNumber = b.number;
        a.status - b.status


        if(aStatus == bStatus)
        {
            return (aNumber < bNumber) ? -1 : (aNumber > bNumber) ? 1 : 0;
        }
        else
        {
            return (aStatus < bStatus) ? -1 : 1;
        }
    }

    updateVehicleAvailability = (data: VehicleAvailabilityModel) => {
        let isNotified = false;
        const handleChanges = (oldObj: VehicleAvailabilityModel, newObj: VehicleAvailabilityModel) => {
            if(isNotified) {
                return;
            }

            if(oldObj.status == newObj.status) {
                PubSub.publish(Events.Notification, `Settings for #${newObj.number} has been update.`);
            }

            isNotified = true;
        }

        for(let i = 0; i < this.vehicles.length; i++) {
            if(this.vehicles[i].id == data.id) {
                handleChanges(this.vehicles[i], data);
                this.vehicles[i] = data;
                break;
            }
        }

        for(let i = 0; i < this.filteredItems.length; i++) {
            if(this.filteredItems[i].id == data.id) {
                handleChanges(this.filteredItems[i], data);
                this.filteredItems[i] = data;
                break;
            }
        }

        this.orderItems();
    }

    async loadData() {
        this.localStorageService.setItem(this.savedParamsName, this.selectedVehicles);

        await this.wrapLoadingApiCall(async () => {
            const [orders] = [
                await this.companyApiClients.radarClient.getRadarInfo(this.selectedVehicles),
            ]

            this.radarData = orders;
            this.items = this.radarData.items;
            this.expandedRows = this.radarData.items.filter(x => x.note?.length > 0).map(x => x.id);
        });
    }

    removeOrder = (orderId) => {
        this.items = this.items.filter(x => x.id != orderId);
        this.radarData.items = this.items;
        this.expandedRows = this.radarData.items.filter(x => x.note?.length > 0).map(x => x.id);

        this.gridApi.current.updateRows([{id: orderId, _action: "delete"}]);
    }

    switchLeftPanel = () => {
        this.isLeftPanelHidden = !this.isLeftPanelHidden;
    }

    openEditVehicleSettingsModal = (vehicleId: number) => {
        let vehicle = this.vehicles.find(x => x.id == vehicleId);

        this.selectedVehicleNumber = vehicle.number;
        this.vehicleSettings.$.vehicleId.value = vehicleId;
        this.vehicleSettings.$.hasIntegration.value = vehicle.hasIntegration;

        this.vehicleSettings.$.status.value = vehicle.availability.status;
        this.vehicleSettings.$.states.value = vehicle.availability.states;
        this.vehicleSettings.$.note.value = vehicle.availability.note;
        this.vehicleSettings.$.pickUpRadius.value = vehicle.availability.pickUpRadius;
        this.vehicleSettings.$.location.value = vehicle.availability.location;
        this.vehicleSettings.$.maxMiles.value = vehicle.availability.maxMiles;
        this.vehicleSettings.$.partial.value = vehicle.availability.partial;
        this.vehicleSettings.$.availableAt.value = vehicle.availability.availableAt;
        this.vehicleSettings.$.liveTruckLocation.value = !(vehicle.location?.length > 0);

        this.openEditVehicleSettings = true;
    }

    closeEditVehicleSettingsModal = () => this.openEditVehicleSettings = false;

    saveVehicleSettings = async () => {
        this.isLoading = true;

        await this.wrapLoadingApiCall(async () => {
            if(this.vehicleSettings.$.hasIntegration.value) {
                this.vehicleSettings.$.location.value = null;
            }

            await this.companyApiClients.vehicleClient.updateVehicleAvailability(new UpdateVehicleAvailabilityModel({
                location: this.vehicleSettings.$.location.value,
                partial: this.vehicleSettings.$.partial.value,
                states: this.vehicleSettings.$.states.value,
                note: this.vehicleSettings.$.note.value,
                status: this.vehicleSettings.$.status.value,
                maxMiles: this.vehicleSettings.$.maxMiles.value || null,
                vehicleId: this.vehicleSettings.$.vehicleId.value,
                pickUpRadius: this.vehicleSettings.$.pickUpRadius.value,
                availableAt: this.vehicleSettings.$.availableAt.value
            }));

            this.closeEditVehicleSettingsModal();
        });
    }

    bet = async (orderId: number, vehicleNumbers: number[]) => {
        window.open(Paths.OrderNewBidDetails.nav(orderId) + "?" + queryString.stringify({ vehicleNumbers : vehicleNumbers }), "_blank");
    }

    hideBid = async (orderId: number) => {
        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.bidsClient.hideBid(orderId);

            this.removeOrder(orderId);
        })
    }

    openIntegrationUserSettings = (settings: CompanyIntegrationUserListModel) => {
        const key = "integration_company_settings_" + (settings.id);
        this.dialogStore.addDialog({
            key: key,
            component: <div><DialogIntegrationUserSettingEdit id={settings.id} type={settings.type} settingsJSON={settings.settingsJSON} closeModal={() => this.dialogStore.closeDialog(key)} updated={async () => {
                this.dialogStore.closeDialog(key);
                await this.loadData();
            }} /></div>
        });
    }

    runIntegration = async (settings: CompanyIntegrationUserListModel) => {
        await this.companyApiClients.companyIntegrationClient.refreshIntegrationDataForCurrentUser(settings.id);
    }

    init = async (gridApi: React.MutableRefObject<GridApiPro>) => {
        this.states = this.mainStore.tenantData.states.map(x => { return {id: x.code, name: x.name}; });
        this.gridApi = gridApi;

        await this.wrapInitLoadingApiCall(async () => {
            const [fleet, carriers, userSettings] = [
                await this.companyApiClients.vehicleClient.getVehicleAvailabilityModels(),
                await this.cacheService.getCarriers(),
                await (this.mainStore.checkClaim(TenantClaims.IntegrationUserSettingsView) ? this.companyApiClients.companyIntegrationClient.getUserSettings(CompanyIntegrationType.DAT) : Promise.resolve<CompanyIntegrationUserListModel[]>([]))
            ];

            this.integrationUserSettings = userSettings;

            await this.setItems(fleet);

            this.carrier = [
                new CarrierShortListModel({ id: -1, displayName: "All" }),
                new CarrierShortListModel({ id: -2, displayName: "Available Now" }),
                new CarrierShortListModel({ id: -3, displayName: "Available All" }),
                new CarrierShortListModel({ id: -4, displayName: "Unavailable" }),
                new CarrierShortListModel({ id: null, displayName: "Own" })]
                .concat(carriers);

            this.items = [];

            this.selectedVehicles = this.localStorageService.getItem<number[]>(this.savedParamsName) || [];

            if(this.selectedVehicles.length > 0) {
                await this.loadData();
            }
        });
    }
}
