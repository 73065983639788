import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {NavigationStore} from "app/modules/admin/stores/NavigationStore";
import {UserInfo} from "app/modules/admin/api/clients.api";
import {InternalClaims} from "app/modules/admin/api/clients.api";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";
import {AdminApiClients} from "app/modules/admin/services/AxiosBaseClient";

export class MainStore extends BaseLoadingStore{
    @inject navigationStore: NavigationStore;
    @inject adminApiClients: AdminApiClients;

    userData: UserInfo = null;

    isAuthenticated: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            userData: observable,
            isAuthenticated: observable,

            init: action
        });
    }

    checkClaim = (claim: InternalClaims) => (this.userData.adminClaims.find(x => x == claim) || this.userData.adminClaims.find(x => x == InternalClaims.FullAccess)) != undefined;

    async init() {
        try {
            this.userData = await this.adminApiClients.dataProviderClient.getUserData();
            await this.navigationStore.init(this.checkClaim);

            runInAction(() => {
                this.isAuthenticated = true;
            });
        } catch(ex) {
            runInAction(() => {
                this.isAuthenticated = false;
            });

            ApiErrorHandler(ex);
        }
        finally {
            runInAction(() => {
                this.isInitLoading = false;
            });
        }
    }
}
