import React, {Component} from "react";
import {Button, Grid, InputBase} from "@mui/material";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {Link} from "react-router-dom";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {TenantsStore} from "app/modules/organization/components/tenants/TenantsStore";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {NavigationStore} from "app/modules/organization/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {Paths} from "app/modules/organization/stores/paths";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {TenantListModel} from "app/modules/organization/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {TenantStatusNameMap} from "app/modules/company/helper/TenantStatusNameMap";

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 5,
        renderCell: (params: GridRenderCellParams<TenantListModel>) => (
            <Link to={Paths.EditTenant.nav(params.row.id)}>{params.row.name}</Link>
        ),
    },
    {
        field: 'uSDOT',
        headerName: 'USDOT',
        minWidth: 150,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TenantListModel>) => (
            params.row.uSDOT
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TenantListModel>) => (
            TenantStatusNameMap.get(params.row.status)
        ),
    }
];

@provider(TenantsStore)
@observer
export default class Index extends Component<any, any> {
    @inject tenantsStore: TenantsStore;
    @inject navigationStore: NavigationStore;

    render() {
        if(this.tenantsStore.isInitLoading) {
            return <Loading />;
        }

        if(this.tenantsStore.totalRows == 0) {
            return <NavigateHook path={Paths.NewTenant.nav}/>;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.tenantsStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewTenant.nav} Text="New Company"/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.tenantsStore.filters.$.search.value}
                            onChange={(e)=>this.tenantsStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.tenantsStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.tenantsStore.items}
                    columns={columns}
                    pageSize={this.tenantsStore.pageSize}
                    rowsPerPageOptions={this.tenantsStore.pageSizeOptions}
                    rowCount={this.tenantsStore.totalRows}
                    page={this.tenantsStore.skipRows/this.tenantsStore.pageSize}
                    loading={this.tenantsStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.tenantsStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.tenantsStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    async componentDidMount() {
        await this.tenantsStore.init();
    }
}

