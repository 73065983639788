export const Paths = {
    Drivers: { nav: "/drivers", router: "/drivers" },
    NewDriver: { nav: "/drivers/new", router: "/drivers/new" },
    EditDriver: { nav: (id, page = '') => `/drivers/${id}/${page}`, router: "/drivers/:id/*" },

    Carriers: { nav: "/carriers", router: "/carriers" },
    NewCarrier: { nav: "/carriers/new", router: "/carriers/new" },
    EditCarrier: { nav: (id, page = '') => `/carriers/${id}/${page}`, router: "/carriers/:id/*" },

    Vehicles: { nav: "/vehicles", router: "/vehicles" },
    NewVehicle: { nav: "/vehicles/new", router: "/vehicles/new" },
    EditVehicle: { nav: (id, page = '') => `/vehicles/${id}/${page}`, router: "/vehicles/:id/*" },

    OwnerAppAccounts: { nav: "/owner-app-accounts", router: "/owner-app-accounts" },
    NewOwnerAppAccount: { nav: "/owner-app-accounts/new", router: "/owner-app-accounts/new" },
    EditOwnerAppAccount: { nav: (id, page = '') => `/owner-app-accounts/${id}/${page}`, router: "/owner-app-accounts/:id/*" },

    Integrations: { nav: "/integrations", router: "/integrations" },
    NewIntegration: { nav: "/integrations/new", router: "/integrations/new" },
    EditIntegration: { nav: (id, page = '') => `/integrations/${id}/${page}`, router: "/integrations/:id/*" },

    CompanyIntegrations: { nav: "/company-integrations", router: "/company-integrations" },
    NewCompanyIntegration: { nav: "/company-integrations/new", router: "/company-integrations/new" },
    EditCompanyIntegration: { nav: (id, page = '') => `/company-integrations/${id}/${page}`, router: "/company-integrations/:id/*" },

    Settings: { nav: "/settings", router: "/settings" },
    EmailSettings: { nav: "/email-settings", router: "/email-settings" },

    FleetView: { nav: "/fleet", router: "/fleet" },
    Radar: { nav: "/radar", router: "/radar" },
    ExternalOrders: { nav: "/external-orders", router: "/external-orders" },
    Dashboard: { nav: "/", router: "/" },

    OrderBids: { nav: "/orders/bids", router: "/orders/bids" },
    OrderBidDetails: { nav: (id, page = '') => `/orders/bids/${id}`, router: "/orders/bids/:id" },
    OrderNewBidDetails: { nav: (id, page = '') => `/orders/external/${id}/bid`, router: "/orders/external/:id/bid" },

    Quotes: { nav: "/quotes", router: "/quotes" },
    NewQuote: { nav: "/quotes/new", router: "/quotes/new" },
    EditQuote: { nav: (id: any = '') => `/quotes/${id}`, router: "/quotes/:id/*" },

    Orders: { nav: "/orders", router: "/orders" },
    OrdersInProgress: { nav: "/orders/progress", router: "/orders/progress" },
    OrdersReady: { nav: "/orders/ready", router: "/orders/ready" },
    OrdersHold: { nav: "/orders/hold", router: "/orders/hold" },
    OrdersTasks: { nav: "/orders/tasks", router: "/orders/tasks" },
    NewOrder: { nav: "/orders/new", router: "/orders/new" },
    EditOrder: { nav: (id: any = '') => `/orders/${id}`, router: "/orders/:id/*" },

    Empty: { nav: "/empty", router: "/empty" },

    Customers: { nav: "/customers", router: "/customers" },
    NewCustomer: { nav: "/customers/new", router: "/customers/new" },
    EditCustomer: { nav: (id, page = '') => `/customers/${id}/${page}`, router: "/customers/:id/*" },

    Contacts: { nav: "/contacts", router: "/contacts" },
    NewContact: { nav: "/contacts/new", router: "/contacts/new" },
    EditContact: { nav: (id, page = '') => `/contacts/${id}/${page}`, router: "/contacts/:id/*" },

    TelegramChannelLinks: { nav: "/telegramChannelLinks", router: "/telegramChannelLinks" },
    TelegramChannels: { nav: "/telegramChannels", router: "/telegramChannels" },
    Timeline: { nav: "/timeline", router: "/timeline" },

    AccountingInvoiceDashboard: { nav: "/accounting/dashboard/invoices", router: "/accounting/dashboard/invoices" },
    AccountingOpen: { nav: "/accounting/open", router: "/accounting/open" },
    AccountingNetTerms: { nav: "/accounting/net-terms", router: "/accounting/net-terms" },
    AccountingInvoices: { nav: "/accounting/invoices", router: "/accounting/invoices" },
    AccountingInvoicesClosed: { nav: "/accounting/invoices/closed", router: "/accounting/invoices/closed" },
    AccountingPayments: { nav: "/accounting/payments", router: "/accounting/payments" },
    NewAccountingPayment: { nav: "/accounting/payments/new", router: "/accounting/payments/new" },
    EditAccountingPayment: { nav: (id: any = '') => `/accounting/payments/${id}`, router: "/accounting/payments/:id/*" },

    TrackingDevices: { nav: "/settings/tracking", router: "/settings/tracking" },
    MobileLogs: { nav: "/settings/mobile-logs", router: "/settings/mobile-logs" },
}
