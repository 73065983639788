import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress, List, ListItem, ListItemText,
    Stack
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    ContactListFilters,
    ContactListModel,
    DataListGetParamsOfContactListFilters,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {requiredMinLength} from "app/modules/common/form/validators";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogCustomerContactNewSearch} from "app/modules/company/components/contact/dialog.customerContact.newSearch";
import {MainStore} from "app/modules/company/stores/MainStore";

export interface DialogSelectCustomerContactProps {
    closeModal: () => void;
    setContact: (customerId: number) => void;
    customerId: number;
    contactId?: number;
    title: string;
}

export const DialogSelectCustomerContact = observer((props: DialogSelectCustomerContactProps) => {
    const [formState, _] = useState(new FormState({
        contactId: new FieldState(null),
        search: new FieldState("").validators(requiredMinLength(3))
    }));

    const [items, setItems] = useState<ContactListModel[]>();
    const [isLoading, setLoading] = useState(true);

    const store = useInstance(ApiClients);
    const dialogStore = useInstance(DialogStore);
    const mainStore = useInstance(MainStore);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await store.contactClient.getContactList(new DataListGetParamsOfContactListFilters({
                offset: 0,
                pageSize: 100,
                filter: new ContactListFilters({ customerId: props.customerId })
            }));
            setItems(data.items);
            setLoading(false);
        };

        fetchData();
    }, []);

    const createSearchContact = () => {
        const key = "new_customer";
        props.closeModal();
        dialogStore.addDialog({
            key: key,
            component: <DialogCustomerContactNewSearch closeModal={() => dialogStore.closeDialog(key)} setContact={async (contactId) => {
                props.setContact(contactId);
                dialogStore.closeDialog(key);
            }} title="Create Customer"/>
        });
    }

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                <Stack spacing={2} direction={"column"}>
                    {isLoading ? <LinearProgress/> : <List dense={true} sx={{
                        overflow: 'auto',
                        maxHeight: 300
                    }}>
                        <Box sx={{ width: "100%", textAlign: "center" }}><Button onClick={createSearchContact}>{mainStore.checkClaim(TenantClaims.ContactEdit) ? "Create/Search Contact" : "Search Contact"}</Button></Box>
                        {items?.map(x => <ListItem sx={{cursor: "pointer"}} key={x.id} selected={x.id == formState.$.contactId.value} onClick={() => formState.$.contactId.onChange(x.id)}>
                            <ListItemText
                                primary={x.fullName}
                                secondary={[(x.phone && "Phone:") + x.phone, (x.email && "Email:") + x.email].filter(x => !!x).join(", ")}
                            />
                        </ListItem>)}
                    </List>}
                </Stack>
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} disabled={!formState.$.contactId.value} variant="outlined" onClick={() => { props.setContact(formState.$.contactId.value); }} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
