import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {int, required} from "app/modules/common/form/validators";
import {SectionTextField} from "app/modules/common/details/text-field";
import {
    PaymentNetTermEditModel
} from "app/modules/company/api/clients.api";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogNetTermEditProps {
    closeModal: () => void;
    updated: () => void;
    netTermId?: number;
}

export const DialogNetTermEdit = observer((props: DialogNetTermEditProps) => {
    const [formState, _] = useState(new FormState({
        id: new FieldState(null),
        name: new FieldState("").validators(required),
        netTerm: new FieldState(0).validators(required, int),
        commission: new FieldState(0).validators(int)
    }));

    const [isLoading, setLoading] = useState(true);

    const store = useInstance(ApiClients);

    useEffect(() => {
        if(props.netTermId > 0) {
            const fetchData = async () => {
                const details = await store.paymentClient.getPaymentNetTermById(props.netTermId);

                formState.$.id.value = details.id;
                formState.$.name.value = details.name;
                formState.$.netTerm.value = details.netTerm;
                formState.$.commission.value = details.commission;

                setLoading(false);
            };

            fetchData();
        } else {
            setLoading(false);
        }
    }, [])

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);
            await store.paymentClient.upsertPaymentNetTerm(new PaymentNetTermEditModel({
                id: formState.$.id.value,
                name: formState.$.name.value,
                netTerm: formState.$.netTerm.value,
                commission: formState.$.commission.value
            }));

            props.updated();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {formState.$.id.value > 0 ? "Update" : "Create"} Net Term
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                <Stack spacing={2} direction={"column"}>
                    <SectionTextField
                        label={"Name"}
                        field={formState.$.name}
                        fullWidth
                    />
                    <SectionTextField
                        label={"Term (days)"}
                        field={formState.$.netTerm}
                        fullWidth
                        type={"number"}
                    />
                    <SectionTextField
                        label={"Commission (factoring company, or quick pay options)"}
                        field={formState.$.commission}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
