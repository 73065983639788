export const Paths = {
    Tenants: { nav: "/admin", router: "/" },
    NewTenant: { nav: "/admin/tenants/new", router: "/tenants/new" },
    EditTenant: { nav: (id, page = '') => `/admin/tenants/${id}/${page}`, router: "/tenants/:id/*" },
    Users: { nav: "/admin/users", router: "/users" },
    HangfireDashboard: { nav: "/admin/jobs", router: null },
    EditUser: { nav: (id, page = '') => `/admin/users/${id}/${page}`, router: "/users/:id/*" },
    Organizations: { nav: "/admin/organizations", router: "/organizations" },
    NewOrganization: { nav: "/admin/organizations/new", router: "/organizations/new" },
    EditOrganization: { nav: (id, page = '') => `/admin/organizations/${id}/${page}`, router: "/organizations/:id/*" },
}
