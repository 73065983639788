import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    ContactListModel, DataListGetParamsOfContactListFilters,
    DriverListFilters
} from "app/modules/company/api/clients.api";

export class ContactStore extends BaseListStore<ContactListModel>{
    @inject companyApiClients: ApiClients;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new DriverListFilters({search: this.filters.$.search.value});
            const [result] = await Promise.all([
                this.companyApiClients.contactClient.getContactList(new DataListGetParamsOfContactListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
