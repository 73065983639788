import {observer} from "mobx-react";
import {Component} from "react";
import {inject, provider} from "react-ioc";
import {ResetPasswordStore} from "app/modules/auth/components/reset-password/ResetPasswordStore";
import {Alert, Avatar, Box, Container, Grid, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from "react";
import {LoadingButton} from "@mui/lab";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {centerBoxStyle} from "app/modules/auth";

@provider(ResetPasswordStore)
@observer
export default class Index extends Component<any, any> {
    @inject resetPasswordStore: ResetPasswordStore;

    render() {

        if(this.resetPasswordStore.isSuccess) {
            return <Box sx={centerBoxStyle}>
                <Alert sx={{ mt: 2, mb: 2 }} severity="success">If an account with this email address exists, you'll receive an email with the reset link.</Alert>
                <Box component="form">
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/login" Text="Login" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>;
        }

        return <Container component="main" maxWidth="xs">
            <Box sx={centerBoxStyle}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>

                {this.resetPasswordStore.errorMessage && <Alert sx={{ width: "100%", mt: 2, mb: 2 }} severity="error">{this.resetPasswordStore.errorMessage}</Alert>}

                <Box component="form" onSubmit={() => {}} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={this.resetPasswordStore.formState.$.email.value}
                        error={this.resetPasswordStore.formState.$.email.hasError}
                        helperText={this.resetPasswordStore.formState.$.email.error}
                        onChange={(e) => this.resetPasswordStore.formState.$.email.onChange(e.target.value)}
                    />
                    <LoadingButton
                        fullWidth
                        loading={this.resetPasswordStore.isLoading}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={this.resetPasswordStore.submit}
                    >
                        Send Reset Link
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/login" Text="Login" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>;
    }
}
