import {Box, LinearProgress, Stack, Typography} from "@mui/material";
import React from "react";
import {BoxCenter} from "app/modules/common/layout/components/containers";

export const Loading = (props: { height?: number }) => {
    return <Box sx={{ height: `calc(${props.height || 80}vh - 64px)`, marginTop: `20vh` }} alignItems={"center"} textAlign={"center"}>
        <BoxCenter>
            <Stack direction={"column" } sx={{ width: "40%" }}>
                <LinearProgress/>
                <Typography sx={{ fontWeight: 'bold' }} variant="body1">Loading...</Typography>
            </Stack>
        </BoxCenter>
    </Box>;
}
