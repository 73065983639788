import {observer} from "mobx-react";
import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import moment from "moment";
import {DateTimePicker} from "@mui/x-date-pickers-pro";
import {FieldState, FormState} from "formstate";
import {requiredDate} from "app/modules/common/form/validators";

export interface DialogSetDateProps {
    closeModal: () => void;
    setDate: (date: moment.Moment) => void;
    date: moment.Moment;
}

export const DialogSetDate = observer((props: DialogSetDateProps) => {
    const [formState, _] = useState(new FormState({
        date: new FieldState<moment.Moment>(moment(props.date || new Date())).validators(requiredDate),
    }));

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Set Date
        </DialogTitle>
        <DialogContent id="alert-dialog-description">
            <DateTimePicker
                label="Date"
                renderInput={(params) => <TextField variant="standard" {...params} />}
                value={formState.$.date.value}
                onChange={formState.$.date.onChange}
                minutesStep={5}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeModal}>Close</Button>
            <Button variant="outlined" onClick={() => { props.setDate(formState.$.date.value); }} color="primary" autoFocus>
                Save
            </Button>
        </DialogActions>
    </Dialog>;
})
