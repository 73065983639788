import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfOwnerAccountListFilters,
    OwnerAccountListModel,
    TrackingDeviceListFilters
} from "app/modules/company/api/clients.api";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogTrackingDevice} from "app/modules/company/components/settings/tracking/dialog.tracking";

export class OwnerAppAccountStore extends BaseListStore<OwnerAccountListModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    update = async (deviceId: number) => {
        const key = "update_device";
        this.dialogStore.addDialog({
            key: key,
            component: <DialogTrackingDevice closeModal={() => this.dialogStore.closeDialog(key)} deviceId={deviceId} onSave={() => {
                this.dialogStore.closeDialog(key);
                this.loadData();
            }}/>
        });
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new TrackingDeviceListFilters({search: this.filters.$.search.value});
            const [result] = await Promise.all([
                this.companyApiClients.ownerAccountClient.getList(new DataListGetParamsOfOwnerAccountListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
