import Moment from "moment";

export const DateTimeHelper = {
    formatDiff(dateStart: Moment.Moment, dateEnd: Moment.Moment) {
        let end = dateEnd || Moment(new Date());
        let duration = Moment.duration(end.diff(dateStart))

        let hours = parseInt(duration.asHours().toString());
        let minutes = parseInt((duration.asMinutes() % 60).toString());

        return [ hours, minutes ];
    },
    formatMomentDateTime(date: Moment.Moment) {
        return date ? Moment(date).format("MM/DD/YYYY LT") : 'N/A'
    },
    formatMomentDateTimeUtcToLocal(date: Moment.Moment) {
        return date ? Moment(date).local().format("MM/DD/YYYY LT") : 'N/A'
    },
    formatMomentDate(date: Moment.Moment) {
        return date ? date.format("MM/DD/YYYY") : 'N/A'
    },
    formatMomentDateTimeShort: (date: Moment.Moment) => {
        return date ? date.format("MM/DD LT") : 'N/A';
    },
    formatMomentDateTimeShortUtcToLocal: (date: Moment.Moment) => {
        return date ? Moment.utc(date).local().format("MM/DD LT") : 'N/A';
    },
    formatMomentDateShort: (date: Moment.Moment) => {
        return date ? date.format("MM/DD") : 'N/A';
    },
    formatMomentTimeShort: (date: Moment.Moment) => {
        return date ? date.format("LT") : 'N/A';
    },
    formatMomentTimeShortUtcToLocal: (date: Moment.Moment) => {
        return date ? Moment.utc(date).local().format("LT") : 'N/A';
    },
    formatDateTime: (date: Date) => {
        return date ? Moment.utc(date).local().format("llll Z") : 'N/A';
    },
    formatDateShort: (date: Date) => {
        return date ? Moment.utc(date).local().format("MM/DD") : 'N/A';
    },
    formatDateFull: (date: Date) => {
        return date ? Moment.utc(date).local().format("MM/DD/YYYY") : 'N/A';
    },
    formatTimeShort: (date: Date) => {
        return date ? Moment.utc(date).local().format("LT") : 'N/A';
    },
    formatDateTimeShort: (date: Date) => {
        return date ? Moment.utc(date).local().format("MM/DD LT") : 'N/A';
    },
    formatTimeline: (date: Date) => {
        return date ? Moment.utc(date).local().format("LT") : 'N/A';
    },
    parseDateTimeString: (date: string) => {
        return date ? Moment.utc(date).local().date() : null;
    },
    parseDateTime: (date: Date) => {
        return date ? Moment.utc(date).local().toDate() : null;
    },
    addDays: (date, days)  => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
}
