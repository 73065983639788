import {inject} from "react-ioc";
import {TenantDetailsStore} from "app/modules/admin/components/tenants/details/TenantDetailsStore";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Button, Stack, TextField} from "@mui/material";
import {ContainerDetails} from "app/modules/common/layout/components/containers";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";

@observer
export default class Details extends Component<any, any> {
    @inject tenantDetailsStore: TenantDetailsStore;
    render() {
        // if(this.tenantDetailsStore.isLoading) {
        //     return <Loading height={60}/>;
        // }

        return <ContainerDetails>
            <SectionName title="Basic Info" description="Tenant Basic Info" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <div>
                        <TextField
                            fullWidth
                            label="Name"
                            value="First Easy Delivery, Inc"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                        />
                    </div>
                    <Stack spacing={2} direction={"row"}>
                        <TextField
                            sx={{ width: "50%" }}
                            label="Owner Name"
                            value="Sergii Popov"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                        />
                        <TextField
                            sx={{ width: "50%" }}
                            label="Owner Email"
                            value="sergii@firsteasydelivery.com"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <TextField
                            sx={{ width: "50%" }}
                            label="Active"
                            value="Y"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                        />
                        <TextField
                            sx={{ width: "50%" }}
                            label="Status"
                            value="Live"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                        />
                    </Stack>
                </Stack>
            </Section>
            <SectionSeparator/>
            <SectionName title="RS-Logistics" description="test" />
            <Section>
                <Stack spacing={2} direction={"row"}>
                    <TextField
                        sx={{ width: "25%" }}
                        label="Trucks"
                        value="20"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    <TextField
                        sx={{ width: "25%" }}
                        label="Drivers"
                        value="30"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    <TextField
                        sx={{ width: "25%" }}
                        label="Contractors"
                        value="5"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    <TextField
                        sx={{ width: "25%" }}
                        label="Loads"
                        value="345"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                </Stack>
            </Section>
            <SectionSeparator/>
            <SectionName title="First Easy Delivery, Inc" description="test" />
            <Section>
                <Stack spacing={2} direction={"row"}>
                    <TextField
                        sx={{ width: "25%" }}
                        label="Trucks"
                        value="20"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    <TextField
                        sx={{ width: "25%" }}
                        label="Drivers"
                        value="30"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    <TextField
                        sx={{ width: "25%" }}
                        label="Contractors"
                        value="5"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    <TextField
                        sx={{ width: "25%" }}
                        label="Loads"
                        value="345"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                </Stack>
            </Section>
            <SectionSeparator/>
            <SectionName title="Actions" description="Actions to Activate/Deactivate/Delete" />
            <Section>
                <Stack spacing={2} direction={"row"}>
                    <LoadingButton loading={this.tenantDetailsStore.isLoading} variant="outlined" color="primary" onClick={this.tenantDetailsStore.resetJobs} >
                        Reset Jobs
                    </LoadingButton>
                    <LoadingButton loading={this.tenantDetailsStore.isLoading} variant="outlined" color="primary" onClick={this.tenantDetailsStore.sylectusSync} >
                        Sylectus Sync
                    </LoadingButton>
                    <LoadingButton loading={this.tenantDetailsStore.isLoading} variant="outlined" color="primary" onClick={this.tenantDetailsStore.upgradeTenant} >
                        Upgrade
                    </LoadingButton>
                </Stack>
                <Stack spacing={2} direction={"row"}>
                    <Button variant="outlined" color="primary">
                        Activate
                    </Button>
                    <Button variant="outlined" color="error">
                        Deactivate
                    </Button>
                    <Button variant="contained" color="error">
                        Delete
                    </Button>
                </Stack>
            </Section>
        </ContainerDetails>;
    }
}
