import {NavMenuItem} from "app/modules/common/menu/menu";
import {Paths} from "app/modules/organization/stores/paths";
import React from "react";

import BusinessIcon from '@mui/icons-material/Business';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SecurityIcon from '@mui/icons-material/Security';

export const Dashboard = new NavMenuItem({
    path: Paths.Dashboard.nav,
    title: "Dashboard",
    icon: <DashboardIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Tenants = new NavMenuItem({
    path: Paths.Tenants.nav,
    title: "Companies",
    icon: <BusinessIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Users = new NavMenuItem({
    path: Paths.Users.nav,
    title: "Users",
    icon: <PeopleAltIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Roles = new NavMenuItem({
    path: Paths.Roles.nav,
    title: "Roles",
    icon: <SecurityIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});
