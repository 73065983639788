import {NavMenuItem} from "app/modules/common/menu/menu";
import {Tenants, Users, Organizations, HangfireDashboard} from "app/modules/admin/stores/menu-items";
import {action, makeObservable, observable} from "mobx";
import {InternalClaims} from "app/modules/admin/api/clients.api";

export class NavigationStore {
    title: string = "Main";
    menuItems: NavMenuItem[] = observable.array([]);

    constructor() {
        makeObservable(this, {
            title: observable,
            menuItemOnClick: action,
            init: action,
            changeLocation: action
        })
    }

    menuItemOnClick = (item: NavMenuItem) => {
        for(let i = 0; i < this.menuItems.length; i++) {
            if(this.menuItems[i] != item && this.menuItems[i].expanded != false) {
                this.menuItems[i].expanded = false;
            }
        }

        item.expanded = !item.expanded;
    }

    changeLocation = (path: string, search: string) => {
        for(let i = 0; i < this.menuItems.length; i++) {
            this.menuItems[i].selected = this.menuItems[i].path == path;

            if(this.menuItems[i].selected) this.title = this.menuItems[i].title;

            if(this.menuItems[i].subItems?.length > 0) {
                for(let j = 0; j < this.menuItems[i].subItems.length; j++) {
                    this.menuItems[i].subItems[j].selected = this.menuItems[i].subItems[j].path == path;

                    if(this.menuItems[i].subItems[j].selected) this.title = this.menuItems[i].subItems[j].title;
                }
            }
        }
    }

    init = (check: (claim: InternalClaims) => boolean) => {
        this.menuItems.push(Tenants);
        this.menuItems.push(Organizations);
        this.menuItems.push(Users);
        check(InternalClaims.HangfireDashboard) && this.menuItems.push(HangfireDashboard);
    }
}

