import axios from "axios";

import {
    IAuthClient,
    AuthClient
} from "../api/clients.api";

const hostName = window.location.hostname;
export const apiBaseUrl = hostName == 'localhost' ? 'http://localhost:5000' :'https://app.onlogixs.com';

export class ApiClients {
    authClient: IAuthClient = null;

    constructor() {
        let axiosInstance = axios.create({
            baseURL: apiBaseUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            validateStatus: (status) => {
                return status >= 200 && status < 500
            }
        });

        axiosInstance.defaults.withCredentials = true;

        this.authClient = new AuthClient(apiBaseUrl, axiosInstance);

        axiosInstance.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(error.response?.status == 401) {
                window.location.href = "/login";
            }
        });
    }
}


