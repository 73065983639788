import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {CarrierDetailsStore} from "app/modules/company/components/carriers/details/CarrierDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Grid, MenuItem, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {FileInput} from "app/modules/company/components/file/file-uploader";
import {FileItemType, FileType} from "app/modules/company/api/clients.api";
import {ActivateDeactivateSection} from "app/modules/common/details/activate-section";
import {SectionDatePickerField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";

@provider(CarrierDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject carrierDetailsStore: CarrierDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.carrierDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.carrierDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Carriers.nav}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.carrierDetailsStore.title} Size={!this.carrierDetailsStore.isReadOnlyMode ? 8 : 12}/>
                {!this.carrierDetailsStore.isReadOnlyMode && <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.carrierDetailsStore.isLoading} disabled={this.carrierDetailsStore.formState.hasError} variant="contained" onClick={this.carrierDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>}
                <ContainerDetails>
                    <SectionName title="Info" description="Carrier info" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <SectionTextField
                                label={"Display Name"}
                                isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                field={this.carrierDetailsStore.formState.$.displayName}
                                fullWidth={true}
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Name"}
                                    isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                    field={this.carrierDetailsStore.formState.$.name}
                                    width={"50%"}
                                />
                                <SectionTextField
                                    label={"DBA Name"}
                                    isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                    field={this.carrierDetailsStore.formState.$.dbaName}
                                    width={"50%"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"USDOT"}
                                    isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                    field={this.carrierDetailsStore.formState.$.uSDOT}
                                    width={"50%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"EIN"}
                                    isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                    field={this.carrierDetailsStore.formState.$.eIN}
                                    width={"50%"}
                                    type={"number"}
                                />
                            </Stack>
                            <SectionDatePickerField
                                isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                field={this.carrierDetailsStore.formState.$.insuranceExpirationDate}
                                label="Insurance Expiration Date"
                                width={"100%"}
                                minDate={new Date()}
                            />
                            <SectionTextField
                                label={"Address"}
                                isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                field={this.carrierDetailsStore.formState.$.address}
                                fullWidth
                            />
                            <SectionTextField
                                label={"Address2"}
                                isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                field={this.carrierDetailsStore.formState.$.address2}
                                fullWidth
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"City"}
                                    isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                    field={this.carrierDetailsStore.formState.$.city}
                                    width={"33%"}
                                />
                                <SectionSelectField
                                    field={this.carrierDetailsStore.formState.$.state}
                                    isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                    width={"33%"}
                                    label={"State"}
                                    items={() => this.mainStore.tenantData.states.map((x) => <MenuItem value={x.code} key={x.code}>{x.name}</MenuItem>)}
                                />
                                <SectionTextField
                                    label={"ZIP"}
                                    isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                    field={this.carrierDetailsStore.formState.$.zip}
                                    width={"33%"}
                                />
                            </Stack>
                            <SectionTextField
                                label={"Phone"}
                                isReadOnlyMode={this.carrierDetailsStore.isReadOnlyMode}
                                field={this.carrierDetailsStore.formState.$.phone}
                                fullWidth
                            />
                            {this.carrierDetailsStore.carrierId > 0 && <Stack spacing={2} direction={"row"}>
                                <FileInput fileType={FileType.Agreement} fileItemType={FileItemType.Carrier} objectId={this.carrierDetailsStore.carrierId}/>
                                <FileInput fileType={FileType.W9} fileItemType={FileItemType.Carrier} objectId={this.carrierDetailsStore.carrierId}/>
                                <FileInput fileType={FileType.Authority} fileItemType={FileItemType.Carrier} objectId={this.carrierDetailsStore.carrierId}/>
                            </Stack>}
                            {this.carrierDetailsStore.carrierId > 0 && <Stack spacing={2} direction={"row"}>
                                <FileInput fileType={FileType.Insurance} fileItemType={FileItemType.Carrier} objectId={this.carrierDetailsStore.carrierId}/>
                                <FileInput fileType={FileType.VoidCheck} fileItemType={FileItemType.Carrier} objectId={this.carrierDetailsStore.carrierId}/>
                            </Stack>}
                        </Stack>
                    </Section>
                    {!this.carrierDetailsStore.isReadOnlyMode && this.carrierDetailsStore.carrierId > 0 && <ActivateDeactivateSection isActive={this.carrierDetailsStore.isActive} isLoading={this.carrierDetailsStore.isLoading} activate={this.carrierDetailsStore.activate} deactivate={this.carrierDetailsStore.deactivate} />}
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.carrierDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
