import {Chip, Grid, Typography} from "@mui/material";
import React from "react";

export const PageTitleChip = (props) => {
    return <Typography variant="h3" component="div">
        {props.Title} {props.TotalRows != undefined && <Chip label={props.TotalRows} color="primary" size="small" />}
    </Typography>;
}

export const PageTitle = (props) => {
    return <Typography variant="h3" component="div" textAlign={props.textAlign || "left"}>
        {props.Title}
    </Typography>;
}

export const PageTitleDetails = (props) => {
    return <Grid item xs={props.Size} sx={{ pb: 4 }}>
        <PageTitle Title={props.Title} textAlign={props.textAlign || "left"}/>
    </Grid>;
}
