//import {provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/organization/stores/MainStore";
import {inject, provider} from "react-ioc";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {MainView} from "app/modules/common/layout/main.view";
import {RouteChangeHandlerComponent} from "app/modules/common/layout/hooks/route-change-handler";
import {Menu} from "app/modules/common/menu/menu";
import {Route, Routes} from "react-router-dom";
import {Paths} from "app/modules/organization/stores/paths";
import {NavigationStore} from "app/modules/organization/stores/NavigationStore";
import {ApiClients} from "app/modules/organization/services/AxiosBaseClient";
import UserIndex from "app/modules/organization/components/users/index";
import UserDetailsIndex from "app/modules/organization/components/users/details/index";
import RoleIndex from "app/modules/organization/components/roles/index";
import RoleDetailsIndex from "app/modules/organization/components/roles/details/index";
import TenantsIndex from "app/modules/organization/components/tenants/index";
import TenantNewIndex from "app/modules/organization/components/tenants/new/index";
import {TenantClaims} from "app/modules/organization/api/clients.api";
import {DialogContainer} from "app/modules/common/dialog/dialog.container";
import {DialogStore} from "app/modules/common/dialog/DialogStore";

@provider(MainStore, NavigationStore, ApiClients, DialogStore)
@observer
export class IndexOrganization extends Component<any, any> {
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.mainStore.isInitLoading) {
            return <Loading/>;
        }

        if(!this.mainStore.isInitLoading && !this.mainStore.isAuthenticated) {
            return <NavigateHook path={"/auth/accessDenied"} />;
        }

        return <React.Fragment>
            <MainView
                Menu={<Menu menu={this.navigationStore.menuItems} onClick={this.navigationStore.menuItemOnClick} />}
                Title={<div>{this.mainStore.orgInfo.name}</div>}
                Content={<Routes>
                    <Route path={Paths.Dashboard.router} element={<div/>}/>

                    {this.mainStore.checkClaim(TenantClaims.TenantListView) && <Route path={Paths.Tenants.router} element={<TenantsIndex/>}/>}
                    {this.mainStore.checkClaim(TenantClaims.TenantNew) && <Route path={Paths.NewTenant.router} element={<TenantNewIndex/>}/>}
                    <Route path={Paths.EditTenant.router} element={<div/>}/>

                    {this.mainStore.checkClaim(TenantClaims.UsersListView) && <Route path={Paths.Users.router} element={<UserIndex/>}/>}
                    {this.mainStore.checkClaim(TenantClaims.UsersListView) && <Route path={Paths.EditUsers.router} element={<UserDetailsIndex/>}/>}

                    {this.mainStore.checkClaim(TenantClaims.RoleListView) && <Route path={Paths.Roles.router} element={<RoleIndex/>}/>}
                    {this.mainStore.checkClaim(TenantClaims.RoleListView) && <Route path={Paths.EditRoles.router} element={<RoleDetailsIndex/>}/>}
                </Routes>}/>
            <RouteChangeHandlerComponent handler={this.navigationStore.changeLocation}/>
            <DialogContainer/>
        </React.Fragment>;
    }

    componentDidMount() {
        this.mainStore.init();
    }
}
