import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/organization/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfTenantListFilters,
    TenantListFilters,
    TenantListModel
} from "app/modules/organization/api/clients.api";

export class TenantsStore extends BaseListStore<TenantListModel> {
    @inject apiClients: ApiClients;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        await this.wrapLoadingApiCall(async () => {
            let filters = new TenantListFilters({search: this.filters.$.search.value});
            const [result] = await Promise.all([
                this.apiClients.tenantClient.getTenants(new DataListGetParamsOfTenantListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;

        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
