import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem, Stack} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {SectionSelectChipsField, SectionSelectField, SectionToggleField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    DriverShortListModel, NamedValueLong,
    TelegramChannelSettingsModel, TelegramChannelType,
    VehicleShortListModel
} from "app/modules/company/api/clients.api";
import {required} from "app/modules/common/form/validators";
import {TelegramChannelTypeMap} from "app/modules/company/components/settings/telegram/types/index";
import {CacheService} from "app/modules/company/services/CacheService";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogChannelProps {
    closeModal: () => void;
    onSave: () => void;
    channelId?: number;
}

export const DialogChannel = observer((props: DialogChannelProps) => {
    const [formState, _] = useState(new FormState({
        driverIds: new FieldState([]),
        vehicleIds: new FieldState([]),
        active: new FieldState(true),
        type: new FieldState(TelegramChannelType.OrderInfo).validators(required),
        telegramChannelId: new FieldState(null).validators(required),
    }));

    const [drivers, setDrivers] = useState<DriverShortListModel[]>();
    const [vehicles, setVehicles] = useState<VehicleShortListModel[]>();
    const [links, setLinks] = useState<NamedValueLong[]>();
    const [isLoading, setLoading] = useState(true);

    const types: NamedValueLong[] = [
        new NamedValueLong({ id: TelegramChannelType.OrderInfo, name: TelegramChannelTypeMap.get(TelegramChannelType.OrderInfo) }),
        new NamedValueLong({ id: TelegramChannelType.AwardedOrders, name: TelegramChannelTypeMap.get(TelegramChannelType.AwardedOrders) }),
        new NamedValueLong({ id: TelegramChannelType.OrderFeed, name: TelegramChannelTypeMap.get(TelegramChannelType.OrderFeed) }),
        new NamedValueLong({ id: TelegramChannelType.ContractorFeed, name: TelegramChannelTypeMap.get(TelegramChannelType.ContractorFeed) })
    ];

    const store = useInstance(ApiClients);
    const cache = useInstance(CacheService);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const [dr, vh, details, links] = [
                await cache.getDrivers(),
                await cache.getVehicles(),
                props.channelId ? await store.telegramClient.getSettingsDetails(props.channelId) : null,
                !props.channelId ? await store.telegramClient.getTelegramChannelLinks() : null
            ];

            if(props.channelId) {
                formState.$.type.value = details.type;
                formState.$.telegramChannelId.value = details.telegramChannelId;
                formState.$.active.value = details.active;
                formState.$.driverIds.value = details.driverIds;
                formState.$.vehicleIds.value = details.vehicleIds;
            } else {
                setLinks(links);
            }

            setDrivers(dr);
            setVehicles(vh);
            setLoading(false);
        };

        fetchData();
    }, []);

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);

            await store.telegramClient.upsertSettings(new TelegramChannelSettingsModel({
                id: props.channelId,
                type: formState.$.type.value,
                telegramChannelId: formState.$.telegramChannelId.value,
                active: formState.$.active.value,
                driverIds: formState.$.driverIds.value,
                vehicleIds: formState.$.vehicleIds.value,
            }));

            props.onSave();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    }

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Update Telegram Channel
        </DialogTitle>
        <DialogContent>
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                {!isLoading ? <Stack direction={"column"} spacing={2}>
                    {!props.channelId && <SectionSelectField
                        field={formState.$.telegramChannelId}
                        width={"100%"}
                        label={"Telegram Link"}
                        items={() => links.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />}
                    {!props.channelId && <SectionSelectField
                        field={formState.$.type}
                        width={"100%"}
                        label={"Type"}
                        items={() => types.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />}
                    {formState.$.type.value != TelegramChannelType.ContractorFeed && <SectionSelectChipsField
                        field={formState.$.driverIds}
                        width={"100%"}
                        label={"Drivers"}
                        none={true}
                        items={drivers.map((x) => { return { id: x.id, name: x.fullName + ( x.carrierId ?  " - " + x.carrier : "") }; })}
                    />}
                    {formState.$.type.value != TelegramChannelType.ContractorFeed && <SectionSelectChipsField
                        field={formState.$.vehicleIds}
                        width={"100%"}
                        label={"Vehicles"}
                        none={true}
                        items={vehicles.map((x) => { return { id: x.id, name: '#' + x.number + ( x.carrierId ?  " - " + x.carrierName : "") }; })}
                    />}
                    {formState.$.type.value == TelegramChannelType.ContractorFeed && <SectionSelectField
                        array={true}
                        field={formState.$.vehicleIds}
                        width={"100%"}
                        label={"Vehicle"}
                        items={() => vehicles.map((x) => <MenuItem value={x.id} key={x.id}>{'#' + x.number + ( x.carrierId ?  " - " + x.carrierName : "")}</MenuItem>)}
                    />}
                    {props.channelId && <SectionToggleField
                        field={formState.$.active}
                        label="Active/Inactive"
                    />}
                </Stack> : <LinearProgress/> }
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
