import { FormState, FieldState } from 'formstate';
import {required} from "app/modules/common/form/validators";
import {action, makeObservable, observable, runInAction} from "mobx";
import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/organization/services/AxiosBaseClient";
import {IUSDOTInfo} from "app/modules/organization/api/clients.api";

export class TenantNewStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;

    usdotSearchForm = new FormState({
        usdot: new FieldState(null).validators(required),
    });

    usdotDetails: IUSDOTInfo = null;

    constructor() {
        super();

        this.isLoading = false;

        makeObservable(this, {
            usdotDetails: observable,

            save: action,
            getUSDOTDetails: action
        });
    }

    save = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.tenantClient.createTenant(this.usdotSearchForm.$.usdot.value);

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }

    getUSDOTDetails = async () => {
        await this.usdotSearchForm.validate();

        if(this.usdotSearchForm.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            const result = await this.apiClients.tenantClient.getUSDOTInfo(this.usdotSearchForm.$.usdot.value);

            runInAction(() => {
                this.usdotDetails = result;

                if(!result) {
                    this.usdotSearchForm.$.usdot.setError("USDOT is not found.")
                }
            })
        });
    }
}
