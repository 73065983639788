import {useEffect, useState} from "react";
import PubSub from 'pubsub-js';
import Events from "app/modules/common/static/EventNames";
import {Alert, AlertTitle} from "@mui/material";
import React from "react";

export const ErrorHandler = () => {
    const [message, setMessage] = useState(null);

    useEffect( () => {
        let timeOutToken = null;

        let hideMessage = () => {
            if(timeOutToken != null) {
                clearTimeout(timeOutToken);
            }

            setMessage(null);
        }

        let tokenOrderApiMessage = PubSub.subscribe(Events.APIError, (eventName: string, message: string) => {
            setMessage(message);
            timeOutToken = setTimeout(() => hideMessage(), 1000 * 10) as any;
        });

        let tokenOrderApiMessageHide = PubSub.subscribe(Events.APIErrorHide, () => {
            hideMessage();
        });

        return () => {
            PubSub.unsubscribe(tokenOrderApiMessage);
            PubSub.unsubscribe(tokenOrderApiMessageHide);
        };
    }, [])

    return message && <Alert severity="error" style={{ position: "absolute", right: "50px", top: "50px", zIndex: 9999, borderColor: "#c72828", borderWidth: "1px", borderStyle: "solid" }} onClick={() => PubSub.publish(Events.APIErrorHide)}>
            <AlertTitle>{"Error"}</AlertTitle>
            {message || "Unexpected Error"}
        </Alert> || <React.Fragment/>;
}
