import {Box, Card, Divider, Grid, Typography} from "@mui/material";
import React from "react";

export const SectionName = (props: {title?: string, description?: string}) => <Grid item xs={3} sx={{ paddingRight: "25px" }} textAlign={"right"}>
    <Typography variant="h5" component="div">
        {props.title}
    </Typography>
    {props.title && <Divider light />}
    {props.description && <Typography variant="caption" component="div">
        {props.description}
    </Typography>}
</Grid>

export const Section = (props) => <Grid item xs={props.size || 9}>
        <Card sx={{ padding: "25px 15px 25px 15px" }}>{props.children}</Card>
</Grid>

export const SectionBlank = (props) => <Grid item xs={props.size || 9}>
    <Box sx={{ padding: "25px 15px 25px 15px" }}>{props.children}</Box>
</Grid>

export const SectionSeparator = (props) => <Grid item xs={12} sx={{ p: 1 }}/>
