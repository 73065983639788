import React from "react";
import {action, makeObservable, observable} from "mobx";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export class DialogItem {
    component: React.ReactNode;
    key: any;
}

export class DialogParams {
    title: string;
    description: string;
    okAction: () => void;
    okText: string = "Ok";
    okColor: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' = 'primary';
    cancelText: string = "Close";
}

export class DialogStore {
    dialogs: DialogItem[] = [];

    closeDialog = (key: any) => this.dialogs = this.dialogs.filter(x => x.key != key);
    addDialog = (item: DialogItem) => this.dialogs.push(item);

    constructor() {
        makeObservable(this, {
            dialogs: observable,

            closeDialog: action,
            addDialog: action,
            addConfirmDialog: action
        });
    }

    addConfirmDialog = (params: DialogParams) => {
        const key = new Date().valueOf();
        const item: DialogItem = {
            key: key,
            component: <Dialog
                open={true}
                onClose={() => this.closeDialog(key)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {params.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {params.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.closeDialog(key)}>{params.cancelText}</Button>
                    <Button variant="outlined" onClick={() => { params.okAction(); this.closeDialog(key); }} color={params.okColor} autoFocus>
                        {params.okText}
                    </Button>
                </DialogActions>
            </Dialog>
        }

        this.dialogs.push(item);
    }
}
