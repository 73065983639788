import React from "react";
import {Grid, Typography} from "@mui/material";

import icon from '../assets/icon.png';

const BreakElement = () => <div>
    <br/>
    <br/>
</div>

export default () => {
    return <Grid container sx={{ width: "100%", paddingTop: "20px" }}>
        <Grid item xs={12} textAlign={"center"} sx={{ pb: 8 }}>
            <img src={icon} style={{ maxWidth: "200px", width: "100%", borderRadius: "20px" }}/>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h3" component="div" align={"center"} justifyContent={"center"} gutterBottom>Support</Typography>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} sx={{paddingLeft: "10px", paddingRight: "10px"}}>
            <Typography variant="subtitle1" component="div" align={"left"} justifyContent={"center"}>
                <BreakElement/>
                If you have any questions or issues, please <a href="mailto:sergii@onlogixs.com">contact us</a>.
                <BreakElement/>
            </Typography>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} textAlign={"center"}>
            Ⓒ {new Date().getFullYear()} OnLogixs
            <BreakElement/>
        </Grid>
    </Grid>;
}
