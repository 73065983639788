import {observer} from "mobx-react";
import React from "react";
import {Component} from "react";
import {
    Alert,
    Avatar,
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {LoginStore} from "app/modules/auth/components/login/LoginStore";
import {inject, provider} from "react-ioc";
import {LoadingButton} from "@mui/lab";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {centerBoxStyle} from "app/modules/auth";

@provider(LoginStore)
@observer
export default class Index extends Component<any, any> {
    @inject loginStore: LoginStore;

    render() {
        if(!this.loginStore.isLoading && this.loginStore.isSuccessLogin) {
            return <NavigateHook path={"/"}/>;
        }

        return <Container component="main" maxWidth="xs">
            <Box sx={centerBoxStyle}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                {this.loginStore.errorMessage && <Alert sx={{ width: "100%", mt: 2, mb: 2 }} severity="error">{this.loginStore.errorMessage}</Alert>}

                <Box component="form" onSubmit={() => {}} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={this.loginStore.formState.$.email.value}
                        error={this.loginStore.formState.$.email.hasError}
                        helperText={this.loginStore.formState.$.email.error}
                        onChange={(e) => this.loginStore.formState.$.email.onChange(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={this.loginStore.formState.$.password.value}
                        error={this.loginStore.formState.$.password.hasError}
                        helperText={this.loginStore.formState.$.password.error}
                        onChange={(e) => this.loginStore.formState.$.password.onChange(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={this.loginStore.formState.$.rememberMe.value} onChange={(e) => this.loginStore.formState.$.rememberMe.onChange(e.target.checked)} />}
                        label="Remember me"
                    />
                    <LoadingButton
                        fullWidth
                        loading={this.loginStore.isLoading}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={this.loginStore.login}
                    >
                        Sign In
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/auth/reset-password" Text="Forgot password?" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>;
    }
}
