import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Box, Button, Grid, InputBase, Stack} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {CustomerListModel, CustomerNoteType, TenantClaims} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {Paths} from "app/modules/company/stores/paths";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {MainStore} from "app/modules/company/stores/MainStore";
import {CustomerStore} from "app/modules/company/components/contact/customers/CustomerStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";

export const customerNoteTypeColorMap: Map<CustomerNoteType, string> = new Map<CustomerNoteType, string>([
    [CustomerNoteType.None, "none"],
    [CustomerNoteType.Warning, "#eaba02"],
    [CustomerNoteType.Avoid, "#ff1744"],
    [CustomerNoteType.Preferable, "#4caf50"]
]);

const columns = [
    {
        field: 'status',
        headerName: '',
        maxWidth: 30,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CustomerListModel>) => (
            <Box sx={{ width: "5px", height: "100%", backgroundColor: customerNoteTypeColorMap.get(params.row.noteType) }}/>
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 4,
        renderCell: (params: GridRenderCellParams<CustomerListModel>) => (
            <Stack direction={"column"}>
                <Link to={Paths.EditCustomer.nav(params.row.id)}>
                    {params.row.companyName}
                </Link>
                {params.row.name}
            </Stack>
        ),
    },
    {
        field: 'state',
        headerName: 'State',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<CustomerListModel>) => (
            StringHelper.formatNull(params.row.state)
        )
    },
    {
        field: 'uSDOT',
        headerName: 'USDOT',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CustomerListModel>) => (
            StringHelper.formatNull(params.row.uSDOT)
        )
    },
    {
        field: 'mcNumber',
        headerName: 'McNumber',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CustomerListModel>) => (
            StringHelper.formatNull(params.row.mcNumber)
        )
    },
    {
        field: 'phone',
        headerName: 'Phone',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CustomerListModel>) => (
            StringHelper.formatNull(params.row.phone)
        )
    }
];

@provider(CustomerStore)
@observer
export default class Index extends Component<any, any> {
    @inject customerStore: CustomerStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.customerStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.CustomerEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.customerStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.CustomerEdit) && <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewCustomer.nav} Text="New Customer"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.customerStore.filters.$.search.value}
                            onChange={(e)=>this.customerStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.customerStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.customerStore.items}
                    columns={columns}
                    pageSize={this.customerStore.pageSize}
                    rowsPerPageOptions={this.customerStore.pageSizeOptions}
                    rowCount={this.customerStore.totalRows}
                    page={this.customerStore.skipRows/this.customerStore.pageSize}
                    loading={this.customerStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.customerStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.customerStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.customerStore.init();
    }
}

