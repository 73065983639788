import {observer} from "mobx-react";
import React, {Component, ReactNode} from "react";
import {inject, provider} from "react-ioc";
import {DashboardStore} from "app/modules/company/components/accounting/dashboard/DashboardStore";
import {MainStore} from "app/modules/company/stores/MainStore";
import {BoxCenter, Container} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {Box, Chip, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {Loading} from "app/modules/common/layout/components/loading";
import {SectionDatePickerField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import IncomeChart from './ProfitChart';
import ArChart from "app/modules/company/components/accounting/dashboard/ARChart";

const CardItem = (params: {title: string, amount: number, count: number, description: string, additional?: ReactNode}) => {
    return <Grid item xs={4}><BoxCenter>
        <Paper sx={{ textAlign: "center", p: 3, width: "100%", m: 1 }}>
            <Stack>
                <Typography color={"rgb(140, 140, 140)"} variant={"subtitle2"} gutterBottom>{params.title}</Typography>
                <Typography variant={"h5"} gutterBottom>{NumberHelper.numberFormat(params.count || 0)} <Chip label={NumberHelper.formatUSDollar(params.amount || 0)} color="primary" /> </Typography>
                <Divider sx={{ pt: 1, mb: 1 }}/>
                <Box sx={{ height: "30px" }}>
                    <Typography color={"rgb(140, 140, 140)"} variant={"caption"} gutterBottom>{params.description}. {params.additional}</Typography>
                </Box>
            </Stack>
        </Paper>
    </BoxCenter></Grid>;
}

@provider(DashboardStore)
@observer
export default class Index extends Component<any, any> {
    @inject dashboardStore: DashboardStore;
    @inject mainStore: MainStore;

    render() {
        if(this.dashboardStore.isInitLoading) {
            return <Loading />;
        }

        return <Container>
            <PageTitleDetails Title="Accounting" Size={12}/>
            <Grid xs={3} item sx={{ pt: 3, p: 1 }}>
                <Paper sx={{ width: "100%" }}>
                    <Typography sx={{ p: 2 }} variant={"h5"} gutterBottom>
                        <Box sx={{ pr: 1 }} component={"span"}>Receivable</Box>
                        <Chip label={NumberHelper.formatUSDollar(this.dashboardStore.aRmetrics.openTotal)} color="primary" />
                    </Typography>
                    <Box sx={{ width: "100%", height: "658px" }}>
                        <ArChart
                            open={this.dashboardStore.aRmetrics.open}
                            openTotal={this.dashboardStore.aRmetrics.openTotal}
                            due={this.dashboardStore.aRmetrics.overdue}
                            dueTotal={this.dashboardStore.aRmetrics.overdueTotal} />
                    </Box>
                </Paper>
            </Grid>
            <Grid xs={9} item>
                <Grid container>
                    <Grid xs={12} item sx={{ pt: 3, p: 1 }}>
                        <Paper sx={{ width: "100%" }}>
                            <Typography sx={{ p: 2 }} variant={"h5"} gutterBottom>Profit [PLACEHOLDER]</Typography>
                            <Box sx={{ width: "100%", height: "400px" }}>
                                <IncomeChart />
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid xs={12} item>
                        <BoxCenter>
                            <Stack direction={"row"} spacing={2} sx={{ width: "auto", p: 2 }}>
                                <SectionDatePickerField
                                    field={this.dashboardStore.filters.$.from}
                                    label="From"
                                    width={"250px"}
                                />
                                <SectionDatePickerField
                                    field={this.dashboardStore.filters.$.to}
                                    label="From"
                                    width={"250px"}
                                />
                                <LoadingButton loading={this.dashboardStore.isLoading} variant={"contained"} onClick={this.dashboardStore.loadData}>Filter</LoadingButton>
                            </Stack>
                        </BoxCenter>
                    </Grid>
                    <CardItem title="Total"
                              count={this.dashboardStore.metrics.numberTotal}
                              amount={this.dashboardStore.metrics.total}
                              description={"Total invoices"}
                              additional={<Typography color={"rgb(140, 140, 140)"} variant={"caption"} gutterBottom>Profit {<Chip label={NumberHelper.formatUSDollar(this.dashboardStore.metrics.closedTotal - this.dashboardStore.metrics.closedPaidTotal)} color="success" />}</Typography>}
                    />
                    <CardItem title="Closed" count={this.dashboardStore.metrics.closed} amount={this.dashboardStore.metrics.closedTotal} description={"Total closed invoices"} />
                    <CardItem title="Closed Paid"
                              count={this.dashboardStore.metrics.closed}
                              amount={this.dashboardStore.metrics.closedPaidTotal}
                              description={"Total actual paid amount for closed invoices"}
                              additional={<Typography color={"rgb(140, 140, 140)"} variant={"caption"} gutterBottom>Paid fee {<Chip label={NumberHelper.formatUSDollar(this.dashboardStore.metrics.closedTotal - this.dashboardStore.metrics.closedPaidTotal)} color="error" />}</Typography>}
                    />
                </Grid>
            </Grid>
        </Container>
    }

    componentDidMount() {
        this.dashboardStore.init();
    }
}
