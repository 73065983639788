import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {required} from "app/modules/common/form/validators";
import {SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {MainStore} from "app/modules/company/stores/MainStore";
import {CustomerEditModel, CustomerNoteType} from "app/modules/company/api/clients.api";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogCustomerNewProps {
    closeModal: () => void;
    setCustomer: (customerId: number, customerName: string) => void;
    title: string;
}

export const DialogCustomerNew = observer((props: DialogCustomerNewProps) => {
    const [formState, _] = useState(new FormState({
        name: new FieldState(null),
        companyName: new FieldState(null).validators(required),
        mcPrefix: new FieldState(null),
        mcNumber: new FieldState(null),
        uSDOT: new FieldState(null),
        address: new FieldState("").validators(required),
        address2: new FieldState(""),
        city: new FieldState("").validators(required),
        state: new FieldState("").validators(required),
        zip: new FieldState(null).validators(required),
        phone: new FieldState(""),
        notes: new FieldState(""),
        noteType: new FieldState(CustomerNoteType.None)
    }));

    const [isLoading, setLoading] = useState(false);

    const store = useInstance(ApiClients);
    const mainStore = useInstance(MainStore);

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);
            const id = await store.contactClient.customerUpsert(new CustomerEditModel({
                name: formState.$.name.value,
                companyName: formState.$.companyName.value,
                mcPrefix: formState.$.mcPrefix.value,
                mcNumber: formState.$.mcNumber.value,
                uSDOT: formState.$.uSDOT.value,
                address: formState.$.address.value,
                address2: formState.$.address2.value,
                city: formState.$.city.value,
                state: formState.$.state.value,
                zIP: formState.$.zip.value,
                phone: formState.$.phone.value,
                notes: formState.$.notes.value,
                noteType: formState.$.noteType.value
            }));

            props.setCustomer(id, formState.$.companyName.value);
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                <Stack spacing={2} direction={"column"}>
                    <SectionTextField
                        label={"Name"}
                        field={formState.$.name}
                        fullWidth
                    />
                    <SectionTextField
                        label={"Company Name"}
                        field={formState.$.companyName}
                        fullWidth
                    />
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"MC Prefix"}
                            field={formState.$.mcPrefix}
                            width={"33%"}
                        />
                        <SectionTextField
                            label={"MC"}
                            field={formState.$.mcNumber}
                            width={"33%"}
                            type={"number"}
                        />
                        <SectionTextField
                            label={"USDOT"}
                            field={formState.$.uSDOT}
                            width={"33%"}
                        />
                    </Stack>
                    <SectionTextField
                        label={"Address"}
                        field={formState.$.address}
                        fullWidth
                    />
                    <SectionTextField
                        label={"Address 2"}
                        field={formState.$.address2}
                        fullWidth
                    />
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"City"}
                            field={formState.$.city}
                            width={"33%"}
                        />
                        <SectionSelectField
                            label={"State"}
                            field={formState.$.state}
                            width={"33%"}
                            items={() => mainStore.tenantData.states.map((x) => <MenuItem value={x.code} key={x.code}>{x.name}</MenuItem>)}
                        />
                        <SectionTextField
                            label={"ZIP"}
                            field={formState.$.zip}
                            width={"33%"}
                        />
                    </Stack>
                    <SectionTextField
                        label={"Phone"}
                        field={formState.$.phone}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
