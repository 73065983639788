import React, {Component} from "react";
import {Loading} from "app/modules/common/layout/components/loading";
import {observer} from "mobx-react";
import {inject, provider} from "react-ioc";
import {
    PaymentDetailsStore,
    PaymentTypes
} from "app/modules/company/components/accounting/payments/details/PaymentDetailsStore";
import {MainStore} from "app/modules/company/stores/MainStore";
import {useParams} from "react-router-dom";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {LoadingButton} from "@mui/lab";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {Box, Grid, IconButton, MenuItem, Stack, TextField} from "@mui/material";
import {SectionDatePickerField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {InvoiceStatus, PaymentMethodType} from "app/modules/company/api/clients.api";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import DeleteIcon from "@mui/icons-material/Delete";
import {FileSingleInput} from "app/modules/company/components/file/file-single";

@provider(PaymentDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject mainStore: MainStore;
    @inject paymentDetailsStore: PaymentDetailsStore;

    render() {
        if(this.paymentDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.paymentDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.AccountingPayments.nav}/>;
        }

        return <Container>
            <PageTitleDetails Title={this.paymentDetailsStore.title} Size={8}/>
            <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                <LoadingButton loading={this.paymentDetailsStore.isLoading} disabled={this.paymentDetailsStore.formState.hasError} variant="contained" onClick={this.paymentDetailsStore.save}>
                    Save
                </LoadingButton>
            </Grid>
            <ContainerDetails>
                <SectionName title="Payment Info" description="Payment basic info"/>
                <Section>
                    <Stack spacing={2} direction={"column"}>
                        <SectionDatePickerField
                            field={this.paymentDetailsStore.formState.$.PaymentDate}
                            label="Payment Date"
                            width={"100%"}
                        />
                        <SectionSelectField
                            field={this.paymentDetailsStore.formState.$.Type}
                            width={"100%"}
                            label={"Payment Type"}
                            none={false}
                            items={() => PaymentTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                        />
                        <Stack spacing={2} direction={"row"}>
                            <SectionTextField
                                label={"Reference"}
                                field={this.paymentDetailsStore.formState.$.Reference}
                                width={this.paymentDetailsStore.formState.$.Type.value == PaymentMethodType.Check ? "50%" : "100%"}
                            />
                            {this.paymentDetailsStore.formState.$.Type.value == PaymentMethodType.Check && <SectionTextField
                                label={"CheckNumber"}
                                field={this.paymentDetailsStore.formState.$.CheckNumber}
                                width={"50%"}
                            />}
                        </Stack>
                        <Box sx={{ cursor: "pointer !important", width: "100%" }}>
                            <TextField
                                id="outlined-disabled"
                                label="Payer"
                                fullWidth
                                onClick={() => this.paymentDetailsStore.changeCustomer()}
                                value={this.paymentDetailsStore.customerName || "Select Payer"}
                            />
                        </Box>
                    </Stack>
                </Section>
                <SectionSeparator/>
                <SectionName title="Invoices Info" description="Invoices info"/>
                <Section>
                    <Stack spacing={2} direction={"column"}>
                        <Stack spacing={2} direction={"row"} display={"flex"} alignItems={"center"}>
                            <SectionTextField
                                label={"Invoice Number"}
                                field={this.paymentDetailsStore.invoiceState.$.InvoiceNumber}
                                width={"60%"}
                            />
                            <Box style={{ width: "20%" }}>
                                <LoadingButton loading={this.paymentDetailsStore.isLoading} disabled={this.paymentDetailsStore.invoiceState.hasError} variant="contained" onClick={this.paymentDetailsStore.addInvoice} fullWidth>
                                    Add Invoice
                                </LoadingButton>
                            </Box>
                            <Box sx={{ pt: 0, width: "20%" }} textAlign={"center"}>
                                <FileSingleInput
                                    title={"Upload Excel RTS Purchase Order File"}
                                    url={"/company/payment/ImportRTSPurchaseOrderInvoices"}
                                    params={{}}
                                    accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                                    callback={this.paymentDetailsStore.uploadRTSPurchaseFileCallback}
                                />
                            </Box>
                        </Stack>
                        {this.paymentDetailsStore.formState.$.PaymentInvoices?.$?.length > 0 && <Stack spacing={2} direction={"row"} style={{ fontWeight: "bold" }}>
                            <div style={{ width: "20%" }}>Customer</div>
                            <div style={{ width: "10%" }}>Number</div>
                            <div style={{ width: "15%" }}>Date</div>
                            <div style={{ width: "10%" }}>Status</div>
                            <div style={{ width: "10%" }}>Amount</div>
                            <div style={{ width: "10%" }}>Diff</div>
                            <div style={{ width: "35%" }}>Amount Received</div>
                        </Stack>}
                        {this.paymentDetailsStore.formState.$.PaymentInvoices?.$?.map(invoice => {
                            let details = this.paymentDetailsStore.invoiceDetails.get(invoice.$.InvoiceId.value);

                            return <Stack spacing={2} direction={"row"} key={invoice.$.InvoiceId.value} display={"flex"} alignItems={"center"}>
                                <div style={{ width: "20%" }}>{details.customer}</div>
                                <div style={{ width: "10%" }}>{details.number}</div>
                                <div style={{ width: "15%" }}>{DateTimeHelper.formatMomentDate(details.invoiceDate)}</div>
                                <div style={{ width: "10%" }}>{details.status == InvoiceStatus.Closed ? "Closed" : "Open"}</div>
                                <div style={{ width: "10%" }}>{NumberHelper.formatUSDollar(details.amount)}</div>
                                <div style={{ width: "10%" }}>{NumberHelper.formatUSDollar(details.amount - invoice.$.Amount.value)}</div>
                                <SectionTextField
                                    field={invoice.$.Amount}
                                    width={"30%"}
                                    label={"Amount Received"}
                                />
                                <div style={{ width: "5%" }}><IconButton aria-label="Edit" color="primary" onClick={() => this.paymentDetailsStore.deleteInvoice(invoice.$.InvoiceId.value)}>
                                    <DeleteIcon />
                                </IconButton></div>
                            </Stack>;
                        })}
                        {this.paymentDetailsStore.formState.$.PaymentInvoices?.$?.length > 0 && <Stack spacing={2} direction={"row"} style={{ fontWeight: "bold" }} textAlign={"right"}>
                            <div style={{ width: "65%"}}>Total Invoiced:</div>
                            <div style={{ width: "35%" }}>{NumberHelper.formatUSDollar(this.paymentDetailsStore.formState.$.PaymentInvoices?.$?.reduce((sum, current) => sum + this.paymentDetailsStore.invoiceDetails.get(current.$.InvoiceId.value).amount, 0))}</div>
                        </Stack>}
                        {this.paymentDetailsStore.formState.$.PaymentInvoices?.$?.length > 0 && <Stack spacing={2} direction={"row"} style={{ fontWeight: "bold" }} textAlign={"right"}>
                            <div style={{ width: "65%"}}>Total Diff:</div>
                            <div style={{ width: "35%" }}>{NumberHelper.formatUSDollar(this.paymentDetailsStore.formState.$.PaymentInvoices?.$?.reduce((sum, current) => sum + (this.paymentDetailsStore.invoiceDetails.get(current.$.InvoiceId.value).amount - current.$.Amount.value), 0))}</div>
                        </Stack>}
                        {this.paymentDetailsStore.formState.$.PaymentInvoices?.$?.length > 0 && <Stack spacing={2} direction={"row"} style={{ fontWeight: "bold" }} textAlign={"right"}>
                            <div style={{ width: "65%"}}>Total Received:</div>
                            <div style={{ width: "35%" }}>{NumberHelper.formatUSDollar(this.paymentDetailsStore.formState.$.PaymentInvoices?.$?.reduce((sum, current) => sum + Number(current.$.Amount.value), 0))}</div>
                        </Stack>}
                    </Stack>
                </Section>
            </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.paymentDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}

