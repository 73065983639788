import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'Nov',
        uv: 4000,
        pv: 3800,
    },
    {
        name: 'Dec',
        uv: 3000,
        pv: 2850,
    },
    {
        name: 'Jan',
        uv: 2000,
        pv: 1800,
    },
    {
        name: 'Fev',
        uv: 2780,
        pv: 2608,
    },
    {
        name: 'March',
        uv: 1890,
        pv: 1700,
    },
    {
        name: 'Apr',
        uv: 2390,
        pv: 2200,
    },
    {
        name: 'May',
        uv: 3490,
        pv: 3300,
    },
];

export default class IncomeChart extends PureComponent {
    render() {
        return (<ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" name="Profit" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" name="Total" dataKey="uv" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        );
    }
}
