import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {Loading} from "app/modules/common/layout/components/loading";
import {Grid, MenuItem, Stack, TextField} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {SectionCheckBoxField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {OrderQuoteDetailsStore} from "app/modules/company/components/quotes/details/OrderQuoteDetailsStore";
import {InfoElement} from "app/modules/common/details/info-element";
import {DateTimePicker} from "@mui/x-date-pickers-pro";
import {VehicleTypesMap} from "app/modules/company/components/vehicles/details/VehicleDetailsStore";

@provider(OrderQuoteDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject orderQuoteDetailsStore: OrderQuoteDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.orderQuoteDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.orderQuoteDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Quotes.nav}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.orderQuoteDetailsStore.title} Size={!this.orderQuoteDetailsStore.isReadOnlyMode ? 8 : 12}/>
                {!this.orderQuoteDetailsStore.isReadOnlyMode && <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.orderQuoteDetailsStore.isLoading} disabled={this.orderQuoteDetailsStore.formState.hasError} variant="contained" onClick={this.orderQuoteDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>}
                <ContainerDetails>
                    <SectionName title="Info" description="Load info" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                <InfoElement
                                    name="Customer"
                                    width={"33%"}
                                    error={this.orderQuoteDetailsStore.formState.$.customerId.error}
                                    hasError={this.orderQuoteDetailsStore.formState.$.customerId.hasError}
                                    value={this.orderQuoteDetailsStore.customer || "N/A"}
                                    onClick={!this.orderQuoteDetailsStore.isReadOnlyMode && this.orderQuoteDetailsStore.changeCustomer}/>
                                <SectionTextField
                                    label={"Order Number"}
                                    field={this.orderQuoteDetailsStore.formState.$.orderNumber}
                                    width={"33%"}
                                />
                                <SectionTextField
                                    label={"Rate"}
                                    field={this.orderQuoteDetailsStore.formState.$.rate}
                                    width={"33%"}
                                    type={"number"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"PickUp Zip"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.pickUpZip}
                                    width={"33%"}
                                />
                                <SectionCheckBoxField
                                    label={"ASAP"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.pickUpASAP}
                                    width={"16%"}
                                    onChange={async () => {
                                        if(this.orderQuoteDetailsStore.formState.$.pickUpDateTime.value) {
                                            this.orderQuoteDetailsStore.formState.$.pickUpDateTime.onChange(null);
                                        }

                                        await this.orderQuoteDetailsStore.formState.$.pickUpDateTime.validate();
                                    } }
                                />
                                <DateTimePicker
                                    label="Date"
                                    readOnly={this.orderQuoteDetailsStore.isReadOnlyMode || !!this.orderQuoteDetailsStore.formState.$.pickUpASAP.value}
                                    renderInput={(params) => <TextField variant="standard" {...params} style={{width: "33%"}} error={this.orderQuoteDetailsStore.formState.$.pickUpDateTime.hasError} helperText={this.orderQuoteDetailsStore.formState.$.pickUpDateTime.error} />}
                                    value={this.orderQuoteDetailsStore.formState.$.pickUpDateTime.value}
                                    onChange={this.orderQuoteDetailsStore.formState.$.pickUpDateTime.onChange}
                                    minutesStep={15}
                                />
                                <SectionCheckBoxField
                                    label={"Inside Pickup"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.pickUpInside}
                                    width={"17%"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Delivery Zip"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.deliveryZip}
                                    width={"33%"}
                                />
                                <SectionCheckBoxField
                                    label={"Direct"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.deliveryDirect}
                                    onChange={async () => {
                                        if(this.orderQuoteDetailsStore.formState.$.deliveryDirect.value) {
                                            this.orderQuoteDetailsStore.formState.$.deliveryDateTime.onChange(null);
                                        }

                                        await this.orderQuoteDetailsStore.formState.$.deliveryDateTime.validate(); }
                                    }
                                    width={"16%"}
                                />
                                <DateTimePicker
                                    label="Date"
                                    readOnly={this.orderQuoteDetailsStore.isReadOnlyMode || !!this.orderQuoteDetailsStore.formState.$.deliveryDirect.value}
                                    renderInput={(params) => <TextField variant="standard" {...params} style={{width: "33%"}} error={this.orderQuoteDetailsStore.formState.$.deliveryDateTime.hasError} helperText={this.orderQuoteDetailsStore.formState.$.deliveryDateTime.error} />}
                                    value={this.orderQuoteDetailsStore.formState.$.deliveryDateTime.value}
                                    onChange={this.orderQuoteDetailsStore.formState.$.deliveryDateTime.onChange}
                                    minutesStep={15}
                                />
                                <SectionCheckBoxField
                                    label={"Inside Delivery"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.deliveryInside}
                                    width={"17%"}
                                />
                            </Stack>
                            <SectionTextField
                                label={"Notes"}
                                isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                field={this.orderQuoteDetailsStore.formState.$.notes}
                                fullWidth
                            />
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Specs" description="Load specs" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionSelectField
                                    field={this.orderQuoteDetailsStore.formState.$.vehicleType}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    width={"50%"}
                                    label={"Type"}
                                    none={false}
                                    items={() => Array.from(VehicleTypesMap.keys()).map((x) => <MenuItem value={x} key={x}>{VehicleTypesMap.get(x)}</MenuItem>)}
                                />
                                <SectionTextField
                                    label={"Dims"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.dims}
                                    width={"50%"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Pieces"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.pieces}
                                    width={"50%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"Weight"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.weight}
                                    width={"50%"}
                                    type={"number"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionCheckBoxField
                                    label={"Dock Level"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.dockLevel}
                                    width={"25%"}
                                />
                                <SectionCheckBoxField
                                    label={"Hazardous"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.hazardous}
                                    width={"25%"}
                                />
                                <SectionCheckBoxField
                                    label={"Lift Gate"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.liftGate}
                                    width={"25%"}
                                />
                                <SectionCheckBoxField
                                    label={"Pallet Jack"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.palletJack}
                                    width={"25%"}
                                />
                            </Stack>
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Post" description="Post load" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionCheckBoxField
                                    label={"Override Driver's Preferences"}
                                    isReadOnlyMode={this.orderQuoteDetailsStore.isReadOnlyMode}
                                    field={this.orderQuoteDetailsStore.formState.$.overrideDriversPreferences}
                                    width={"100%"}
                                />
                            </Stack>
                        </Stack>
                    </Section>
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.orderQuoteDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
