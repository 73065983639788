import {action, makeObservable, observable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    BidListFilters,
    BidModelListView,
    DataListGetParamsOfBidListFilters,
    ExternalOrderBidStatus,
} from "app/modules/company/api/clients.api";
import {ModalOrderDetails} from "app/modules/company/components/orders/details/modal.order.details";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {ModalBidDetails} from "app/modules/company/components/load-boards/bids/details/modal.bid.details";
import {LocalStorageService} from "app/modules/common/stores/LocalStorageService";

export class BidsStore extends BaseListStore<BidModelListView>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;
    @inject localStorageService : LocalStorageService;
    savedParamsName: string = "SelectedBidsStoreAutoRefresh";

    isWonDialogOpen: boolean = false;
    selectedWonBid: number = null;
    enableAutoRefresh: boolean = false;

    filters = new FormState({
        search: new FieldState(''),
        vehicleIds: new FieldState([]),
        statuses: new FieldState([]),
    })

    isAdvanceFilterOpen: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            isWonDialogOpen: observable,
            selectedWonBid: observable,
            isAdvanceFilterOpen: observable,
            enableAutoRefresh: observable,

            openWonDialog: action,
            closeWonDialog: action,
            switchAutoRefresh: action,

            reject: action,
            init: action
        });
    }

    applySpecificFilters() {
    }

    openOrderDetails = (orderId: number) => {
        const key = "order_details_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalOrderDetails orderId={orderId} closeOrderModal={() => this.dialogStore.closeDialog(key)} /></div>
        });
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new BidListFilters({
                search: this.filters.$.search.value,
                vehicleIds: this.filters.$.vehicleIds.value,
                statuses: this.filters.$.statuses.value
            });

            const [result] = await Promise.all([
                this.companyApiClients.bidsClient.getList(new DataListGetParamsOfBidListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    openWonDialog = (bidId: number) => {
        this.selectedWonBid = bidId;
        this.isWonDialogOpen = true;
    }

    switchAutoRefresh = (value) => {
        this.enableAutoRefresh = value;
        this.localStorageService.setItem(this.savedParamsName, value);
    }

    closeWonDialog = () => {
        this.isWonDialogOpen = false;
    }

    reject = (bidId: number) => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.bidsClient.rejectBid(bidId);

            let found = this.items.find(x => x.id == bidId);
            found.status = ExternalOrderBidStatus.Rejected;
            this.closeWonDialog();
        });
    }

    openBid = (bidId: number) => {
        const key = "order_bid_" + bidId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalBidDetails externalOrderBidId={bidId} closeOrderModal={() => {
                this.dialogStore.closeDialog(key);
                this.loadData()
            }} /></div>
        });
    }

    won = () => {
        this.wrapLoadingApiCall(async () => {
            let found = this.items.find(x => x.id == this.selectedWonBid);
            let orderId = null;

            if(found.status == ExternalOrderBidStatus.Rejected)
            {
                orderId = await this.companyApiClients.bidsClient.createOrderForce(this.selectedWonBid);
                runInAction(() => { found.orderId = orderId; });
            }

            if(found.status == ExternalOrderBidStatus.Processed)
            {
                orderId = await this.companyApiClients.bidsClient.createOrder(this.selectedWonBid);
                runInAction(() => { found.orderId = orderId; });
            }

            this.closeWonDialog();
        });
    }

    showHideAdvanceStore = () => {
        this.isAdvanceFilterOpen = !this.isAdvanceFilterOpen;
    }

    async init() {
        this.enableAutoRefresh = this.localStorageService.getItem<boolean>(this.savedParamsName);
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
