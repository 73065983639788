import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Badge, Box, Button, Grid, InputBase, Stack} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {
    TenantClaims, TrackingDeviceModel
} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {LoadingButton} from "@mui/lab";
import {LocationLink} from "app/modules/common/details/location-link";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {TrackingDeviceStore} from "app/modules/company/components/settings/tracking/TrackingDeviceStore";

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 150,
        flex: 4,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => {
            const store = useInstance(TrackingDeviceStore);

            return <Button onClick={() => store.update(params.row.id)}>
                {params.row.name}
            </Button>;
        },
    },
    {
        field: 'phoneNumber',
        headerName: 'PhoneNumber',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => (
            <Stack direction="column"
                   justifyContent="center"
                   alignItems="center">
                {
                    <React.Fragment>
                        <div>{params.row.phoneNumber}</div>
                    </React.Fragment>
                }
            </Stack>
        )
    },
    {
        field: 'vehicle',
        headerName: 'Vehicle',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => (
            StringHelper.formatNull(params.row.vehicle)
        )
    },
/*    {
        field: 'driver',
        headerName: 'Driver',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => (
            StringHelper.formatNull(params.row.driver)
        )
    },*/
    {
        field: 'location',
        headerName: 'Location',
        minWidth: 450,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => <div>
            <div>
                {params.row.location && <Button variant={"text"} onClick={() => navigator.clipboard.writeText(params.row.location)}>COPY</Button>}
                {params.row.location && <LocationLink location={params.row.location} locationDisplay={params.row.displayLocation}/>}
                {!params.row.location && "N/A"}
            </div>
        </div>,
    },
    {
        field: 'enableVehicleLocationUpdate',
        headerName: 'Loc/Vehicle Sync',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => (
            StringHelper.formatYesNo(params.row.enableVehicleLocationUpdate)
        )
    },
    {
        field: 'deviceData',
        headerName: 'Device',
        minWidth: 150,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => (
            <Stack direction={"row"}>
                <Box sx={{ p: 1, pr: 2 }}>
                    <Badge color={params.row?.isDeviceTrackingEnabled ? "success" : "error" } overlap="circular" badgeContent={" "}/>
                </Box>
                <Stack direction="row"
                       justifyContent="center"
                       alignItems="center">
                    {
                        params.row?.deviceData && <div>{params.row.deviceData.device} - {params.row.deviceData.system} ({params.row.deviceData.version})</div> || "No Data"
                    }
                </Stack>
            </Stack>
        )
    },
    {
        field: 'locatedAt',
        headerName: 'LocatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => (
            <div>
                <div>{DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.locatedAt)}</div>
                <div>{params.row.locatedAt?.fromNow()}</div>
            </div>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => (
            StringHelper.formatActive(params.row.active)
        )
    }
];

@provider(TrackingDeviceStore)
@observer
export default class Index extends Component<any, any> {
    @inject trackingDeviceStore: TrackingDeviceStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.trackingDeviceStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.TrackingDeviceEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.trackingDeviceStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.TrackingDeviceEdit) && <Grid item xs={6} textAlign={"right"}>
                <LoadingButton loading={this.trackingDeviceStore.isLoading} variant={"contained"} onClick={this.trackingDeviceStore.generateInvitationCode}>Generate Invitation Code</LoadingButton>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.trackingDeviceStore.filters.$.search.value}
                            onChange={(e)=>this.trackingDeviceStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.trackingDeviceStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.trackingDeviceStore.items}
                    columns={columns}
                    pageSize={this.trackingDeviceStore.pageSize}
                    rowsPerPageOptions={this.trackingDeviceStore.pageSizeOptions}
                    rowCount={this.trackingDeviceStore.totalRows}
                    page={this.trackingDeviceStore.skipRows/this.trackingDeviceStore.pageSize}
                    loading={this.trackingDeviceStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.trackingDeviceStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.trackingDeviceStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.trackingDeviceStore.init();
    }
}

