import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {TenantClaims} from "app/modules/organization/api/clients.api";
import {action, makeObservable, observable} from "mobx";
import {inject} from "react-ioc";
import {MainStore} from "app/modules/organization/stores/MainStore";
import {ApiClients} from "app/modules/organization/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {required} from "app/modules/common/form/validators";
import {
    OrganizationTenantRoleClaimEditModel,
    OrganizationTenantRoleUpdateModel
} from "app/modules/organization/api/clients.api";

export class RoleDetailsStore extends BaseLoadingStore {
    @inject mainStore: MainStore;
    @inject apiClients: ApiClients;

    title: string = 'New Role';
    isReadOnlyMode: boolean = false;
    roleId: string;
    rolePermissions: string[] = [];

    permissions: string[] = [];

    formNameState = new FormState({
        name: new FieldState('').validators(required),
    });

    constructor() {
        super();

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.UsersEdit);

        makeObservable(this, {
            title: observable,
            rolePermissions: observable,
            permissions: observable,

            init: action,
            changeTenantRoleState: action
        });

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.UsersEdit);
    }

    saveName = async () => {
        await this.formNameState.validate();

        if(this.formNameState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.userClient.updateOrganizationRoleName(new OrganizationTenantRoleUpdateModel({
                id: this.roleId,
                name: this.formNameState.$.name.value
            }));
        });
    }

    changeTenantRoleState = (permission) => {
        let found = this.rolePermissions.indexOf(permission);
        if(found >= 0) {
            this.rolePermissions.splice(found, 1);
        } else {
            this.rolePermissions.push(permission);
        }
    }

    saveRolePermissions = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.userClient.updateOrganizationRoleClaims(new OrganizationTenantRoleClaimEditModel({
                roleId: this.roleId,
                claims: this.rolePermissions
            }));
        });
    }

    init = async (id: string) => {
        this.roleId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(!!this.roleId) {
                const [details, rolePermissions, allPermission] = [
                    await this.apiClients.userClient.getRoleDetails(this.roleId),
                    await this.apiClients.userClient.getRoleClaims(this.roleId),
                    await this.apiClients.userClient.getAllAvailableTenantClaims()
                ];

                this.permissions = allPermission;
                this.rolePermissions = rolePermissions;

                this.title = details.name;
                this.formNameState.$.name.value = details.name;
            }
        });
    }
}
