import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container} from "app/modules/common/layout/components/containers";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/organization/stores/paths";
import {TenantNewStore} from "app/modules/organization/components/tenants/new/TenantNewStore";
import {USDOTInfo} from "app/modules/organization/components/tenants/new/usdot-info";

@provider(TenantNewStore)
@observer
export default class Index extends Component<any, any> {
    @inject tenantNewStore: TenantNewStore;

    render() {
        if(this.tenantNewStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Tenants.nav}/>;
        }

        return <Container>
            <USDOTInfo />
        </Container>;
    }
}
