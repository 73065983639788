import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Stack, Typography
} from "@mui/material";
import {LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem,
    TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    VehicleLocationHistoryPoint,
    VehicleLocationHistoryPointsRequest,
} from "app/modules/company/api/clients.api";
import underscore from "underscore";
import Moment from "moment";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {GoogleMap, Marker, Polyline} from "@react-google-maps/api";

import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { faTruckFront } from "@fortawesome/free-solid-svg-icons";
import { NumberHelper } from "app/modules/common/helpers/NumberHelper";

export interface DialogVehicleTrackingProps {
    closeModal: () => void;
    vehicleId: number;
}

enum LogType {
    Stop = 1,
    Driving = 2,
    Future = 3
}

class TimeBlock {
    start: Moment.Moment;
    startDate: Moment.Moment;
    startMinutes: number;
    endMinutes: number;
    type: LogType;
    description?: string;
    lat?: number;
    lon?: number;
    distance: number;
}

class TimeBlockShort {
    startDate: Moment.Moment;
    duration: number;
    distance: number;
    type: LogType;
    description?: string;
    lat?: number;
    lon?: number;
}

class LogDay {
    day: string;
    timeBlocks: TimeBlock[];
    timeBlockShort: TimeBlockShort[];
    today: boolean;
}

export const logTypeColorMap: Map<LogType, string> = new Map<LogType, string>([
    [LogType.Stop, "#606060"],
    [LogType.Driving, "#4caf50"],
    [LogType.Future, "#4c59af"]
]);

export const DialogVehicleTracking = observer((props: DialogVehicleTrackingProps) => {
    const [isLoading, setLoading] = useState(false);
    const [hoverElement, setHoverElement] = useState<TimeBlock>(null);
    const [selectedLogDay, setSelectedLogDay] = useState<LogDay>(null);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [drivingPoints, setDrivingPoints] = useState(null);
    const [_, setStartPoint] = useState(null);
    const [finishPoint, setFinishPoint] = useState(null);


    const store = useInstance(ApiClients);
    const [items, setItems] = useState<LogDay[]>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await store.vehicleClient.vehicleLocationHistoryPoints(new VehicleLocationHistoryPointsRequest({
                from: Moment().add(-3, "day"),
                to: null,
                vehicleId: props.vehicleId
            }));

            if(data?.length > 0) {
                let groupedData = underscore.groupBy(data, (item: VehicleLocationHistoryPoint) => {
                    return item.locatedAt.startOf('day').format();
                }) as any;

                let days = [];

                let today = Moment().format("MM-DD-YYYY");

                for(let item in groupedData) {
                    let day: LogDay = {
                        day: item,
                        timeBlocks: [],
                        timeBlockShort: [],
                        today: Moment(item).format("MM-DD-YYYY") == today
                    }

                    let currentTimeBlock: TimeBlock = {start: Moment(day.day), startDate: Moment(day.day), startMinutes: 0, endMinutes: 0, type: LogType.Stop, distance: 0};

                    for(let i = 0; i < groupedData[item].length; i++) {
                        let logItem: VehicleLocationHistoryPoint = groupedData[item][i];
                        let currentType = !!logItem.updatedAt ? LogType.Stop : LogType.Driving;

                        day.timeBlocks.push(currentTimeBlock)
                        let start = Moment(item);
                        // @ts-ignore
                        let startMinutes = Moment.duration(Moment(logItem.locatedAt._i).diff(start)).as("minute");

                        currentTimeBlock.endMinutes = startMinutes;
                        // @ts-ignore
                        currentTimeBlock = {start: logItem.locatedAt, startDate: Moment(logItem.locatedAt._i), startMinutes: startMinutes, endMinutes: 0, type: currentType, distance: logItem.distance};

                        currentTimeBlock.description = logItem.description;
                        currentTimeBlock.lat = logItem.lat;
                        currentTimeBlock.lon = logItem.lon;
                    }

                    day.timeBlocks.push(currentTimeBlock);

                    if(day.today) {
                        currentTimeBlock.endMinutes = Moment.duration(Moment().diff(currentTimeBlock.start)).as("minute");
                        day.timeBlocks.push({start: Moment(), startDate: Moment(), startMinutes: currentTimeBlock.endMinutes, endMinutes: 1440, type: LogType.Future, distance: 0});
                    } else {
                        currentTimeBlock.endMinutes = 1440;
                    }

                    days.push(day);
                }

                for(let i = 0; i < days.length; i++) {
                    let day = days[i];
                    let timeBoxStart: TimeBlock = day.timeBlocks[0];

                    let currentTimeBlockShort: TimeBlockShort = {
                        startDate: timeBoxStart.startDate,
                        duration: timeBoxStart.endMinutes-timeBoxStart.startMinutes,
                        distance: timeBoxStart.distance,
                        type: timeBoxStart.type,
                        description: timeBoxStart.description,
                        lat: timeBoxStart.lat,
                        lon: timeBoxStart.lon,
                    };

                    day.timeBlockShort.push(currentTimeBlockShort);

                    for(let j = 1; j < day.timeBlocks.length; j++) {
                        let timeBox: TimeBlock = day.timeBlocks[j];

                        if(timeBox.type == currentTimeBlockShort.type) {
                            currentTimeBlockShort.duration += timeBox.endMinutes - timeBox.startMinutes;
                            currentTimeBlockShort.distance += timeBox.distance;
                        } else {
                            if(timeBox.type == LogType.Future) {
                                break;
                            }

                            currentTimeBlockShort = {
                                // @ts-ignore
                                startDate: timeBox.startDate,
                                duration: timeBox.endMinutes - timeBox.startMinutes,
                                type: timeBox.type,
                                distance: timeBoxStart.distance,
                                description: timeBox.description,
                                lat: timeBox.lat,
                                lon: timeBox.lon
                            };

                            day.timeBlockShort.push(currentTimeBlockShort);
                        }
                    }
                }

                let selectedDay = days[days.length - 1]
                setSelectedLogDay(selectedDay);
                setSelectedCenter(selectedDay.timeBlocks.filter(x => x.lon != null)[0]);
                let driving = selectedDay.timeBlocks?.filter(x => x.type == LogType.Driving).map(x => { return { lat: x.lat, lng: x.lon }; });
                setDrivingPoints(driving);
                setStartPoint(driving[0]);
                setFinishPoint(driving[driving.length - 1]);

                setItems(days.reverse());
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    // @ts-ignore
    // @ts-ignore
    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xl"}
    >
        <DialogTitle id="alert-dialog-title">
            Vehicle Tracking History
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                <Stack spacing={2} direction={"row"}>
                    {items?.length > 0 && <Stack direction={"column"} spacing={1}>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={selectedCenter != null ? { lat: selectedCenter.lat, lng: selectedCenter.lon } : center}
                            zoom={7}
                        >
                            {selectedLogDay && <Polyline
                                options={{
                                    geodesic: true,
                                    strokeColor: "#3048e4",
                                    strokeOpacity: 1.0,
                                    strokeWeight: 2,
                                    path: drivingPoints
                                }}
                            />}
                            <Marker
                                icon={{
                                    path: faMapPin.icon[4] as string,
                                    strokeColor: "#ff4e54",
                                    fillColor: "#ff4e54",
                                    fillOpacity: 1,
                                    scale: 0.06,
                                    anchor: new google.maps.Point(faMapPin.icon[0]/2, faMapPin.icon[1])
                                }}
                                position={finishPoint}
                            />
                            {hoverElement && hoverElement.lon != null && <Marker
                                icon={{
                                    path: faTruckFront.icon[4] as string,
                                    strokeColor: "#ffffff",
                                    fillColor: "#0000ff",
                                    fillOpacity: 1,
                                    scale: 0.04,
                                    anchor: new google.maps.Point(faTruckFront.icon[0]/2, faTruckFront.icon[1])
                                }}
                                position={{ lat: hoverElement.lat, lng: hoverElement.lon }}
                            />}
                        </GoogleMap>
                        {items.map(x => <Stack direction={"row"} key={x.day} alignItems={"center"} onClick={() => {
                            setSelectedLogDay(x);
                            setSelectedCenter(x.timeBlocks.filter(x => x.lon != null)[0]);
                            let driving = x.timeBlocks?.filter(x => x.type == LogType.Driving).map(x => { return { lat: x.lat, lng: x.lon }; });
                            setDrivingPoints(driving);
                            setStartPoint(driving[0]);
                            setFinishPoint(driving[driving.length - 1]);
                        }}
                                               sx={{
                                                   backgroundColor: selectedLogDay == x ? "#d3d3d3" : null,
                                                   boxShadow: selectedLogDay == x ? "i0px 0px 2px 2px #d3d3d3" : null,
                                                   borderRadius: "5px",
                                                   padding: "10px 10px" }}>
                            <Box sx={{paddingRight: 2}}>{DateTimeHelper.formatMomentDateShort(Moment(x.day))}</Box>
                            {x.timeBlocks.map(t => <TimeBlockElement item={t} onHover={setHoverElement} key={t.startMinutes}/>)}
                        </Stack>)}
                        <Box sx={{ paddingTop: 2 }}>{}
                            {hoverElement && <div>{DateTimeHelper.formatMomentTimeShortUtcToLocal(Moment(hoverElement.startDate))} {hoverElement.description && "-"} {hoverElement.description} ({Moment.duration(hoverElement.endMinutes-hoverElement.startMinutes, 'minutes').humanize()})</div> || "No details"}
                        </Box>
                    </Stack>}
                    {selectedLogDay && <Stack direction={"column"} spacing={1} sx={{ width: "300px" }}>
                        <Typography component={"div"} variant={"h6"} textAlign={"center"}>{DateTimeHelper.formatMomentDateShort(Moment(selectedLogDay.day))}</Typography>
                        <Divider/>
                        <Box sx={{ height: (524 + (items?.length ?? 0)*50) + "px", overflow: "auto" }}>
                            <Stack direction={"column"} spacing={1}>
                                <Timeline>
                                    {selectedLogDay && selectedLogDay.timeBlockShort.map(t => <TimelineItem sx={{ minHeight: t.type == LogType.Driving ? "20px" : undefined }} key={t.startDate.valueOf()}>
                                        <TimelineOppositeContent color="text.secondary" sx={{ maxWidth: "60px" }}>
                                            <Typography component={"div"} variant={"body2"} sx={{marginLeft: "7px", fontSize: "10px", color: t.type == LogType.Stop ? logTypeColorMap.get(LogType.Stop) : logTypeColorMap.get(LogType.Driving)}}>{DateTimeHelper.formatMomentTimeShortUtcToLocal(Moment(t.startDate))}</Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{ backgroundColor: t.type == LogType.Stop ? logTypeColorMap.get(LogType.Stop) : logTypeColorMap.get(LogType.Driving), maxHeight: "7px" }} />
                                            <TimelineDot color={t.type == LogType.Stop ? "grey" : "success"} sx={{ margin: 0 }} />
                                            <TimelineConnector sx={{ backgroundColor: t.type == LogType.Stop ? logTypeColorMap.get(LogType.Stop) : logTypeColorMap.get(LogType.Driving) }} />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Stack direction={"column"} spacing={1}>
                                                <Box><Typography component={"div"} variant={"subtitle2"} sx={{marginLeft: "7px", fontSize: "10px", color: t.type == LogType.Stop ? logTypeColorMap.get(LogType.Stop) : logTypeColorMap.get(LogType.Driving)}}>{Moment.duration(t.duration, 'minutes').humanize()}{t.type == LogType.Driving && " "}{t.type == LogType.Driving && <b>{NumberHelper.roundDecimal(t.distance)} - mil</b>}</Typography></Box>
                                                {t.type == LogType.Stop && <Typography component={"div"} variant={"subtitle2"} sx={{marginLeft: "7px", fontSize: "10px"}}>{t.description}</Typography>}
                                            </Stack>
                                        </TimelineContent>
                                    </TimelineItem>)}
                                </Timeline>
                            </Stack>
                        </Box>
                    </Stack>}
                </Stack>
                {!selectedLogDay?.timeBlocks?.length && <Typography component={"div"} variant={"h6"} textAlign={"center"}>No Data</Typography>}
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
        </DialogActions>
    </Dialog>;
})

const TimeBlockElement = (props: {item: TimeBlock, onHover: (TimeBlock) => void}) => {
    return <Box
        onMouseOver={() => props.onHover(props.item)}
        key={props.item.startMinutes}
        sx={{
            width: (props.item.endMinutes - props.item.startMinutes)/2,
            backgroundColor: logTypeColorMap.get(props.item.type),
            height: "20px",
            '&:hover': {
                backgroundColor: "#dbdd00",
            }}}
    />;
}

const containerStyle = {
    width: '100%',
    height: '500px',
    marginBottom: "20px"
};

const center = {
    lat: 39.127745,
    lng: -94.782415
};

