import {toBoolean} from "@amcharts/amcharts4/.internal/core/utils/Type";
import {Link} from "@mui/material";
import React from "react";

export const LocationLink = (props: { location: string, locationDisplay: string }) => {
    if(!toBoolean(props.location?.length)) {
        return <span>N/A</span>;
    }

    let url = `https://www.google.com/maps/search/?api=1&query=${props.location}`;

    return <Link href={url} target={"_blank"} underline="none">
        {props.locationDisplay || "Location"}
    </Link>;
}
