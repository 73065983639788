import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Button, Grid, InputBase} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {CompanyIntegrationsStore} from "app/modules/company/components/company-integrations/CompanyIntegrationsStore";
import {Link} from "react-router-dom";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {
    TenantClaims,
    CompanyIntegrationType,
    CompanyIntegrationListModel
} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {Paths} from "app/modules/company/stores/paths";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {MainStore} from "app/modules/company/stores/MainStore";

export const CompanyIntegrationTypeMap = new Map<CompanyIntegrationType, string>([
    [CompanyIntegrationType.Sylectus, "Sylectus"],
    [CompanyIntegrationType.GoogleApi, "GoogleApi"],
    [CompanyIntegrationType.SMTP, "SMTP"],
    [CompanyIntegrationType.DAT, "DAT"]
]);


const columns = [
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 2,
        renderCell: (params: GridRenderCellParams<CompanyIntegrationListModel>) => (
            <Link to={Paths.EditCompanyIntegration.nav(params.row.id)}>{params.row.name}</Link>
        ),
    },
    {
        field: 'type',
        headerName: 'Type',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CompanyIntegrationListModel>) => (
            CompanyIntegrationTypeMap.get(params.row.type)
        ),
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CompanyIntegrationListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.createdAt)
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CompanyIntegrationListModel>) => (
            StringHelper.formatActive(params.row.active)
        )
    }
];

@provider(CompanyIntegrationsStore)
@observer
export default class Index extends Component<any, any> {
    @inject companyIntegrationsStore: CompanyIntegrationsStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.companyIntegrationsStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.IntegrationEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.companyIntegrationsStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.IntegrationEdit) && <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewCompanyIntegration.nav} Text="New Integration"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.companyIntegrationsStore.filters.$.search.value}
                            onChange={(e)=>this.companyIntegrationsStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.companyIntegrationsStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.companyIntegrationsStore.items}
                    columns={columns}
                    pageSize={this.companyIntegrationsStore.pageSize}
                    rowsPerPageOptions={this.companyIntegrationsStore.pageSizeOptions}
                    rowCount={this.companyIntegrationsStore.totalRows}
                    page={this.companyIntegrationsStore.skipRows/this.companyIntegrationsStore.pageSize}
                    loading={this.companyIntegrationsStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.companyIntegrationsStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.companyIntegrationsStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.companyIntegrationsStore.init();
    }
}

