import axios from "axios";

import {
    DataProviderClient,
    IDataProviderClient,
    ITenantManagementClient,
    TenantManagementClient,
    IUsersClient,
    UsersClient
} from "../api/clients.api";

const hostName = window.location.hostname;
export const apiBaseUrl = hostName == 'localhost' ? 'http://localhost:5000' :'https://app.onlogixs.com';

export class AdminApiClients {
    tenantManagementClient: ITenantManagementClient = null;
    dataProviderClient: IDataProviderClient = null;
    usersClient: IUsersClient = null;

    constructor() {
        let axiosInstance = axios.create({
            baseURL: apiBaseUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            validateStatus: (status) => {
                return status >= 200 && status < 500
            }
        });

        axiosInstance.defaults.withCredentials = true;

        this.tenantManagementClient = new TenantManagementClient(apiBaseUrl, axiosInstance);
        this.dataProviderClient = new DataProviderClient(apiBaseUrl, axiosInstance);
        this.usersClient = new UsersClient(apiBaseUrl, axiosInstance);

        axiosInstance.interceptors.response.use((response) => {
            if(response?.status == 401) {
                window.location.href = "/admin/accessDenied";
            }

            /*if(response?.status == 302) {
                window.location.href = response.location;
            }*/

            return response;
        }, (error) => { });
    }
}



