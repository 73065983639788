import { FormState, FieldState } from 'formstate';
import {email, required} from "app/modules/common/form/validators";

export class TenantNewStore {
    formState = new FormState({
        companyName: new FieldState('').validators(required),
        ownerName: new FieldState('').validators(required),
        ownerEmail: new FieldState('').validators(required, email)
    });
}
