import React, {Component} from "react";
import {inject, provider, useInstance} from "react-ioc";
import {RadarStore} from "app/modules/company/components/load-boards/radar/RadarStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {observer} from "mobx-react";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    InputBase,
    MenuItem,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {
    BoxCenter,
    LeftVehicleContentPanel,
    LeftVehicleFilterPanel,
    LeftVehiclePanel
} from "app/modules/common/layout/components/containers";
import {SectionSelectField} from "app/modules/common/details/text-field";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams, GridRowClassNameParams} from "@mui/x-data-grid-pro";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {
    CompanyIntegrationLogType,
    CustomerNoteType,
    OrderSource,
    RadarModel,
    TenantClaims,
    VehicleAvailabilityModel,
    VehicleAvailabilityStatus,
    VehicleRadarAvailabilityStatus
} from "app/modules/company/api/clients.api";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {Alert, LoadingButton} from "@mui/lab";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import {ModalVehicleSettingsEdit} from "app/modules/company/components/load-boards/radar/modal.settings.edit";
import {MainStore} from "app/modules/company/stores/MainStore";
import Colors from "app/modules/common/static/colors";

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import {PusherTenantService} from "app/modules/common/pusher/PusherTenantService";
import * as moment from "moment";
import Moment from "moment";
import {customerNoteTypeColorMap} from "app/modules/company/components/contact/customers";
import {withHooksHOC} from "app/modules/common/grid/data-grid-hoc";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";

export const MapStatus: Map<VehicleAvailabilityStatus, string> = new Map<VehicleAvailabilityStatus, string>([
    [VehicleAvailabilityStatus.Available, "rgb(40, 166, 72)"],
    [VehicleAvailabilityStatus.Unavailable, "rgb(94,94,94)"],
    [VehicleAvailabilityStatus.Broken, "rgb(227,42,42)"]
]);

export const MapRadarStatus: Map<VehicleRadarAvailabilityStatus, string> = new Map<VehicleRadarAvailabilityStatus, string>([
    [VehicleRadarAvailabilityStatus.Available, MapStatus.get(VehicleAvailabilityStatus.Available)],
    [VehicleRadarAvailabilityStatus.Unavailable, MapStatus.get(VehicleAvailabilityStatus.Unavailable)],
    [VehicleRadarAvailabilityStatus.Broken, MapStatus.get(VehicleAvailabilityStatus.Broken)],
    [VehicleRadarAvailabilityStatus.InProgress, "#f0c703"],
    [VehicleRadarAvailabilityStatus.InProgressAvailable, "#a1d71c"]
]);

export const OrderSourceMap: Map<OrderSource, string> = new Map<OrderSource, string>([
    [OrderSource.Sylectus, "Sylectus"]
]);

const StatusAvatar = (prop: {status: VehicleRadarAvailabilityStatus, number: number, availableAt: moment.Moment}) =>
    <Avatar sx={{
        bgcolor: prop.status == VehicleRadarAvailabilityStatus.Available ? (prop.availableAt > Moment() ? "#1976d2" : MapRadarStatus.get(prop.status)) : MapRadarStatus.get(prop.status),
        width: "35px",
        height: "35px",
        fontSize: prop.status ? "12px" : "8px",
        fontWeight: "bold" }}>{prop.number}</Avatar>

const columns = [
    {
        field: 'hide',
        headerName: 'Hide',
        width: 70,
        renderCell: (params: GridRenderCellParams<RadarModel>) => {
            const radarStore = useInstance(RadarStore);

            return <div>
                <IconButton onClick={() => radarStore.hideBid(params.row.id)}>
                    <VisibilityOffIcon sx={{ fontSize: "25px" }} color={"disabled"}/>
                </IconButton>
            </div>;
        }
    },
    {
        field: 'vehicleIds',
        headerName: 'Vehicle',
        width: 150,
        renderCell: (params: GridRenderCellParams<RadarModel>) => <div>
            {params.row.vehicleNumbers.join(", ")}
        </div>
    },
    {
        field: 'bid',
        headerName: 'Bid',
        width: 70,
        renderCell: (params: GridRenderCellParams<RadarModel>) => {
            const store = useInstance(MainStore);
            const radarStore = useInstance(RadarStore);

            return <div>
                {params.row.bidIds?.length > 0 ? <b>Bid</b> : (store.checkClaim(TenantClaims.BidCreate) ? <IconButton onClick={() => radarStore.bet(params.row.id, params.row.vehicleNumbers)}>
                    <EmailIcon sx={{ fontSize: "25px" }} color={params.row.isViewed && "disabled" || "primary"}/>
                </IconButton> : "No Bid")}
            </div>;
        }
    },
    {
        field: 'customer',
        headerName: 'Customer',
        width: 300,
        renderCell: (params: GridRenderCellParams<RadarModel>) => {
            let name = <span>{params.row.customer}</span>;

            if(params.row.customerNote?.length > 0) {
                name = <Tooltip title={params.row.customerNote} arrow>{name}</Tooltip>;
            }

            return <div>
                <div>{params.row.customerNoteType != CustomerNoteType.None ? <span style={{ color: customerNoteTypeColorMap.get(params.row.customerNoteType) }}>{name}</span> : name}</div>
                <div><b>{params.row.customerName?.length > 0 && <span>{params.row.customerName} </span>}{params.row.customerMc && <span>MC:{params.row.customerMc}</span>}</b></div>
            </div>;
        }
    },
    {
        field: 'from',
        headerName: 'From',
        width: 200,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div>{params.row.from + " " + params.row.fromZip}</div>
                <div><b>{params.row.pickup}</b></div>
            </div>
        ),
    },
    {
        field: 'to',
        headerName: 'To',
        width: 200,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div>{params.row.to + " " + params.row.toZip}</div>
                <div><b>{params.row.drop}</b></div>
            </div>
        ),
    },
    {
        field: 'price',
        headerName: 'Rate/OrderNum',
        type: 'number',
        width: 90,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <Box sx={{ textAlign: "right" }}><b>{params.row.price && NumberHelper.formatUSDollar(params.row.price)}</b></Box>
                <Box sx={{ textAlign: "right" }}><b>{params.row.orderNumber}</b></Box>
            </div>
        ),
    },
    {
        field: 'miles',
        headerName: 'Miles',
        type: 'number',
        width: 70,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div><b>{params.row.miles}</b> ml</div>
            </div>
        ),
    },
    {
        field: 'weight',
        headerName: 'Weight',
        width: 110,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div><b>{params.row.weight}</b> lb</div>
                <div><b>{params.row.pieces}</b> pc</div>
            </div>
        ),
    },
    {
        field: 'vehicleType',
        headerName: 'VehicleType',
        width: 150
    },
    {
        field: 'posted',
        headerName: 'Posted',
        width: 150,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(params.row.postedFrom)}</div>
                <div>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(params.row.postedTo)}</div>
            </div>
        ),
    },
    {
        field: 'contact',
        headerName: 'Contact',
        width: 400,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div>{params.row.contact}
                    {params.row.contactEmail && <Button variant={"text"} sx={{ fontSize: "12px", minWidth: 0 }} onClick={() => navigator.clipboard.writeText(params.row.contactEmail)} title={params.row.contactEmail}>Email</Button>}
                    {params.row.contactPhone && <Button variant={"text"} sx={{ fontSize: "12px", minWidth: 0 }} onClick={() => navigator.clipboard.writeText(params.row.contactPhone)} title={params.row.contactPhone}>Phone</Button>}
                </div>
            </div>
        ),
    },
];

@provider(RadarStore)
@observer
class Index extends Component<any, any> {
    @inject radarStore: RadarStore;
    @inject pusherTenantService: PusherTenantService;

    interval = null;

    render() {
        return (<React.Fragment>
            {this.radarStore.isInitLoading ? <Loading/> :
                <Box sx={{
                    '& .order-processed': {
                        bgcolor: "rgb(29 118 35 / 12%)",
                        '&:hover': {
                            bgcolor: "rgb(29 118 35 / 12%)",
                        }
                    }
                }}>
                    <LeftVehicleFilterPanel isHidden={this.radarStore.isLeftPanelHidden}>
                        <BoxCenter>
                            <SearchPaper>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    value={this.radarStore.filters.$.search.value}
                                    onChange={(e)=>this.radarStore.filters.$.search.onChange(e.target.value)}
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <SearchVerDivider />
                                <Button variant="text" onClick={this.radarStore.applyVehicleFilters}>Search</Button>
                            </SearchPaper>

                            <IconButton onClick={() => this.radarStore.clearFilters()}>
                                <ClearIcon/>
                            </IconButton>
                        </BoxCenter>
                        <Box sx={{paddingTop: "12px"}}>
                            <SectionSelectField
                                variant={"filled"}
                                field={this.radarStore.filters.$.carrierId}
                                width={"100%"}
                                label={"Carrier"}
                                onChange={() => this.radarStore.applyVehicleFilters()}
                                items={() => this.radarStore.carrier.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                            />
                        </Box>
                    </LeftVehicleFilterPanel>
                    <LeftVehiclePanel isHidden={this.radarStore.isLeftPanelHidden}>
                        {this.radarStore.filteredItems.map(x => {
                            return <Box sx={{ width: "100%", backgroundColor: this.radarStore.selectedVehicles.indexOf(x.id) >= 0 ? Colors.LeftMenuSelectedColor : null, cursor: "pointer" }} key={x.id}>
                                <Stack direction={"column"}>
                                    <Stack direction={"row"} sx={{ width: "100%", pt: 1, pb: 1 }} >
                                        <Box sx={{ width: "35px", pl: 1, pr: 1 }}>
                                            <StatusAvatar status={x.status} availableAt={x.availability?.availableAt} number={x.number}/>
                                            <IconButton onClick={() => this.radarStore.openEditVehicleSettingsModal(x.id)}>
                                                <SettingsIcon sx={{ fontSize: "20px" }}/>
                                            </IconButton>
                                        </Box>
                                        <Box sx={{ width: "300px" }} onClick={() => this.radarStore.setSelected(x.id)}>
                                            <Stack direction={"column"}>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    {x.make} {x.year}
                                                </Typography>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    PU: <b>{x.availability.pickUpRadius} ml</b>, Max: {x.availability.maxMiles > 0 ? <b>{x.availability.maxMiles} ml</b> : <b>None</b>}
                                                </Typography>
                                                <React.Fragment>
                                                    {x.availability.states?.length > 0 && <Typography variant="caption" display="block" gutterBottom>
                                                        States: <b>{x.availability.states.join(', ')}</b>
                                                    </Typography>}
                                                    {x.status == VehicleRadarAvailabilityStatus.Available && x.availability.availableAt > Moment() && <Typography variant="caption" display="block" gutterBottom>
                                                        Available At: <b>{DateTimeHelper.formatMomentDateTimeUtcToLocal(x.availability.availableAt)}</b>
                                                    </Typography>}
                                                    {x.carrierName?.length > 0 && <Typography variant="caption" display="block" gutterBottom sx={{ fontSize: "9px" }}>
                                                        <i>{x.carrierName}</i>
                                                    </Typography>}
                                                    <Typography variant="caption" display="block" gutterBottom>
                                                        {x.displayLocation} {x.locationAt && "-"} <b>{x.locationAt?.fromNow()}</b>
                                                    </Typography>
                                                    {x.availability.note?.length > 0 && <Typography variant="caption" display="block" gutterBottom>
                                                        Notes: <b>{x.availability.note}</b>
                                                    </Typography>}
                                                </React.Fragment>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                    <Divider/>
                                </Stack>
                            </Box>
                        })}
                    </LeftVehiclePanel>
                    <LeftVehicleContentPanel hideLeftPanel={this.radarStore.isLeftPanelHidden}>
                        <DataGridContainer>
                            <Grid item xs={4}>
                                <Stack direction={"row"}>
                                    <IconButton onClick={() => this.radarStore.switchLeftPanel()} title={"Show/Hide Left Panel"}>
                                        {this.radarStore.isLeftPanelHidden ? <VisibilityIcon color={"primary"}/> : <VisibilityOffIcon color={"disabled"}/>}
                                    </IconButton>
                                    <PageTitleChip Title={"Radar"} TotalRows={this.radarStore.items.length}/>
                                    <LoadingButton
                                        variant={"outlined"}
                                        sx={{ ml:4, height: "35px", mt: "12px" }}
                                        disabled={this.radarStore.selectedVehicles.length == 0}
                                        onClick={() => this.radarStore.loadData()}
                                        loading={this.radarStore.isLoading}
                                    >Refresh</LoadingButton>
                                </Stack>
                            </Grid>
                            <Grid item xs={8} display="flex" justifyContent={"right"} alignItems={"flex-start"}>
                                <Stack direction={"row"} spacing={2}>
                                    <React.Fragment>
                                        {
                                            this.radarStore?.radarData?.sourceUpdates?.length > 0 && this.radarStore.radarData.sourceUpdates.map(x => {
                                                return <Alert icon={false} severity={x.logType == CompanyIntegrationLogType.Error ? "error" : "success"} key={x.source}>
                                                    <b>{x.source}: {x.lastSync != null ? x.lastSync.fromNow() : "N/A"}</b>
                                                </Alert>
                                            })
                                        }
                                    </React.Fragment>
                                    <React.Fragment>
                                        { this.radarStore.integrationUserSettings.map(x => {
                                            return <Alert icon={false} severity={"info"} key={x.id}>
                                                <Stack direction={"row"}>
                                                    <Button variant={"text"} onClick={() => this.radarStore.openIntegrationUserSettings(x)}>{x.name} - {x.settingsJSON?.length > 0 ? "Settings" : "Update"}</Button>
                                                    {x.settingsJSON?.length > 0 && <IconButton onClick={() => this.radarStore.runIntegration(x)}><SyncIcon/></IconButton>}
                                                </Stack>
                                            </Alert>
                                        }) }
                                    </React.Fragment>
                                </Stack>
                            </Grid>
                            <DataGridBody>
                                <DataGridPro
                                    className={"radar-grid"}
                                    rows={this.radarStore.items}
                                    columns={columns}
                                    pageSize={this.radarStore.pageSize}
                                    rowsPerPageOptions={this.radarStore.pageSizeOptions}
                                    detailPanelExpandedRowIds={this.radarStore.expandedRows}
                                    pagination
                                    disableVirtualization
                                    apiRef={this.props.gridApiRef}
                                    paginationMode="client"
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableSelectionOnClick
                                    checkboxSelection={false}
                                    getRowClassName={(params: GridRowClassNameParams<RadarModel>) => params.row.bidIds?.length > 0 ? `order-processed` : ''}
                                    onPageChange={(page) => this.radarStore.setPage(page)}
                                    onPageSizeChange={(pageSize) => this.radarStore.setPageSize(pageSize) }
                                    getDetailPanelHeight={() => 35}
                                    getDetailPanelContent={(params) =>
                                        params.row.note?.length > 0 && <Box sx={{ p: 1, color: "#1976d2" }}><b>{params.row.note}</b></Box>}
                                />
                            </DataGridBody>
                        </DataGridContainer>
                    </LeftVehicleContentPanel>
                </Box>}
            <ModalVehicleSettingsEdit/>
        </React.Fragment>
        )
    }

    async componentDidMount() {
        await this.radarStore.init(this.props.gridApiRef);

        this.interval = setInterval(() => {
            this.radarStore.loadData();
        }, 30 * 1000);

        this.pusherTenantService.bindToTenantChannel("RadarOrderViewed", this.orderViewed);
        this.pusherTenantService.bindToTenantChannel("RadarOrderBet", this.orderBet);
        this.pusherTenantService.bindToTenantChannel("RadarOrderHide", this.hideOrder);
        this.pusherTenantService.bindToTenantChannel("VehiclesUpdateVehicleAvailability", this.vehiclesUpdateVehicleAvailability);
        this.pusherTenantService.bindToTenantChannel("VehiclesUpdateVehicleAvailabilityByOwner", this.vehiclesUpdateVehicleAvailability);
    }

    async componentWillUnmount() {
        clearInterval(this.interval);
        this.pusherTenantService.unbindToTenantChannel("RadarOrderViewed", this.orderViewed);
        this.pusherTenantService.unbindToTenantChannel("RadarOrderBet", this.orderBet);
        this.pusherTenantService.unbindToTenantChannel("RadarOrderHide", this.hideOrder);
        this.pusherTenantService.unbindToTenantChannel("VehiclesUpdateVehicleAvailability", this.vehiclesUpdateVehicleAvailability);
        this.pusherTenantService.unbindToTenantChannel("VehiclesUpdateVehicleAvailabilityByOwner", this.vehiclesUpdateVehicleAvailability);
    }

    orderViewed = (id) => {
        const found = this.radarStore.items.find(x => x.id == id);

        if(found != null) {
            found.isViewed = true;
            this.props.gridApiRef.current.updateRows(this.radarStore.items);
        }
    }

    orderBet = (event) => {
        const found = this.radarStore.items.find(x => x.id == event.Id);

        if(found != null) {
            if(found.bidIds != null) {
                found.bidIds.push(event.BidId);
            } else {
                found.bidIds = [event.BidId];
            }

            this.props.gridApiRef.current.updateRows(this.radarStore.items);
        }
    }

    hideOrder = (id) => this.radarStore.removeOrder(id);

    vehiclesUpdateVehicleAvailability = (data: VehicleAvailabilityModel) => {
        this.radarStore.updateVehicleAvailability(VehicleAvailabilityModel.fromJS(data));
    }
}

export default withHooksHOC(Index);

/*
{
    path: "m113.956,57.006l-97.4,-56.2c-4,-2.3 -9,0.6 -9,5.2l0,112.5c0,4.6 5,7.5 9,5.2l97.4,-56.2c4,-2.401 4,-8.2 0,-10.5z",
        strokeColor: "#5a6366",
    fillColor: "#5e5e5e",
    fillOpacity: 1,
    scale: 0.28,
    labelOrigin: { x: 48, y: 60, equals(other: google.maps.Point): boolean { return true; } }
}
*/
