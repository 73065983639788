import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {
    Box,
    Button,
    Grid,
    InputBase, Stack
} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {InvoiceListModel, TenantClaims} from "app/modules/company/api/clients.api";
import {InvoiceStore} from "app/modules/company/components/accounting/invoices/open/InvoiceStore";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";

const columns = [
    {
        field: 'id',
        headerName: 'Order ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => {
            const store = useInstance(InvoiceStore);
            return <Button variant={"text"} onClick={() => store.openOrder(params.row.orderId)}>{params.row.orderId}</Button>;
        },
    },
    {
        field: 'number',
        headerName: 'Number/PO',
        width: 110,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <div><b>{params.row.number}</b></div>
                <div>{params.row.reference}</div>
            </div>
        ),
    },
    {
        field: 'amount',
        headerName: 'Amount',
        maxWidth: 90,
        type: 'number',
        flex: 1,
        valueGetter: (params: GridRenderCellParams<InvoiceListModel>) =>
            `${NumberHelper.formatUSDollar(params.row.amount)}`,
    },
    {
        field: 'customer',
        headerName: 'Customer',
        minWidth: 300,
        flex: 2,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                {params.row.customer != params.row.payer && <div>{params.row.customer}</div>}
                <div>Payer: <b>{params.row.payer}</b></div>
            </div>
        ),
    },
    {
        field: 'age',
        headerName: 'Age',
        width: 90,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <Box sx={{ color: params.row.overdue ? "#b30000" : "current" }}>{params.row.invoiceAge}</Box>
            </div>
        ),
    },
    {
        field: 'age1',
        headerName: '1-30',
        width: 90,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <Box>{params.row.invoiceAge < 31 ? params.row.invoiceAge : 0}</Box>
            </div>
        ),
    },
    {
        field: 'age2',
        headerName: '31-45',
        width: 90,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <Box>{params.row.invoiceAge < 46 && params.row.invoiceAge > 30 ? params.row.invoiceAge : 0}</Box>
            </div>
        ),
    },
    {
        field: 'age3',
        headerName: '46-60',
        width: 90,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <Box>{params.row.invoiceAge < 61 && params.row.invoiceAge > 45 ? params.row.invoiceAge : 0}</Box>
            </div>
        ),
    },
    {
        field: 'age4',
        headerName: '61-90',
        width: 90,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <Box>{params.row.invoiceAge < 91 && params.row.invoiceAge > 60 ? params.row.invoiceAge : 0}</Box>
            </div>
        ),
    },
    {
        field: 'age5',
        headerName: '90+',
        width: 90,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <Box>{params.row.invoiceAge > 90 ? params.row.invoiceAge : 0}</Box>
            </div>
        ),
    },
    {
        field: 'dueDate',
        headerName: 'DueDate',
        width: 90,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <div>{DateTimeHelper.formatMomentDateShort(params.row.dueDate)}</div>
            </div>
        ),
    },
    {
        field: 'paymentNetTermDays',
        headerName: 'NetTerm',
        width: 90,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            <div>
                <div>{params.row.paymentNetTermDays}</div>
            </div>
        ),
    },
    {
        field: 'commission',
        headerName: 'Commission',
        maxWidth: 120,
        type: 'number',
        flex: 1,
    },
    {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.invoiceDate)
        ),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => {
            const store = useInstance(MainStore);
            const invoiceStore = useInstance(InvoiceStore);

            return <Stack direction={"row"} spacing={2}>
                {store.checkClaim(TenantClaims.AccountingAdmin) ? <Button variant="outlined" size="small" color={"error"} onClick={() => invoiceStore.delete(params.row.id)}>Remove</Button> : "-"}
            </Stack>;
        },
    },
];

@provider(InvoiceStore)
@observer
export default class Index extends Component<any, any> {
    @inject invoiceStore: InvoiceStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.invoiceStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.invoiceStore.totalRows}/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <Stack direction={"row"}>
                        <SearchPaper width={400}>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.invoiceStore.filters.$.search.value}
                                onChange={(e)=>this.invoiceStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.invoiceStore.applyFilters}>Search</Button>
                        </SearchPaper>
                    </Stack>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.invoiceStore.items}
                    columns={columns}
                    pageSize={this.invoiceStore.pageSize}
                    rowsPerPageOptions={this.invoiceStore.pageSizeOptions}
                    rowCount={this.invoiceStore.totalRows}
                    page={this.invoiceStore.skipRows/this.invoiceStore.pageSize}
                    loading={this.invoiceStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.invoiceStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.invoiceStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.invoiceStore.init();
    }
}

