import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {IntegrationDetailsStore} from "app/modules/company/components/integrations/details/IntegrationDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Grid, MenuItem, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {ActivateDeactivateSection} from "app/modules/common/details/activate-section";
import {SectionCheckBoxField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {IntegrationTypeMap} from "app/modules/company/components/integrations";

@provider(IntegrationDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject integrationDetailsStore: IntegrationDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.integrationDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.integrationDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Integrations.nav}/>;
        }

        if(this.integrationDetailsStore.isRedirectToNewEntity) {
            return <NavigateHook path={Paths.EditIntegration.nav(this.integrationDetailsStore.integrationId)}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.integrationDetailsStore.title} Size={!this.integrationDetailsStore.isReadOnlyMode ? 8 : 12}/>
                {!this.integrationDetailsStore.isReadOnlyMode && <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.integrationDetailsStore.isLoading} disabled={this.integrationDetailsStore.formState.hasError} variant="contained" onClick={this.integrationDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>}
                <ContainerDetails>
                    <SectionName title="Info" description="Integration Basic Info"/>
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Name"}
                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                    field={this.integrationDetailsStore.formState.$.name}
                                    fullWidth
                                />
                                <SectionSelectField
                                    field={this.integrationDetailsStore.formState.$.type}
                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode || this.integrationDetailsStore.integrationId > 0}
                                    label={"Type"}
                                    none={false}
                                    width={"100%"}
                                    items={() => Array.from(IntegrationTypeMap.entries()).map((x) => <MenuItem value={x[0]} key={x[0]}>{x[1]}</MenuItem>)}
                                />
                            </Stack>
                            <SectionSelectField
                                field={this.integrationDetailsStore.formState.$.carrierId}
                                isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode || this.integrationDetailsStore.integrationId > 0}
                                label={"Carrier"}
                                none={true}
                                width={"100%"}
                                items={() => this.integrationDetailsStore.carrier.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                            />
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Credentials" description="Credentials to have an access to the API"/>
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            {this.integrationDetailsStore.isShowApiKey() &&
                                <SectionTextField
                                    label={"ApiKey"}
                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                    field={this.integrationDetailsStore.formApiKey.$.apiKey}
                                    type={"password"}
                                    fullWidth
                                />}
                            {this.integrationDetailsStore.isShowGeoTab() &&
                                <Stack spacing={2} direction={"row"}>
                                    <SectionTextField
                                        label={"UserName"}
                                        isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                        field={this.integrationDetailsStore.formGeoTab.$.userName}
                                        fullWidth
                                    />
                                    <SectionTextField
                                        label={"Password"}
                                        isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                        field={this.integrationDetailsStore.formGeoTab.$.password}
                                        type={"password"}
                                        fullWidth
                                    />
                                </Stack>}
                            {this.integrationDetailsStore.isShowGeoTab() &&
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Server"}
                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                    field={this.integrationDetailsStore.formGeoTab.$.server}
                                    fullWidth
                                />
                                <SectionTextField
                                    label={"Database"}
                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                    field={this.integrationDetailsStore.formGeoTab.$.database}
                                    fullWidth
                                />
                            </Stack>}
                        </Stack>
                    </Section>
                    {this.integrationDetailsStore.integrationId > 0 &&
                    <React.Fragment>
                        <SectionSeparator/>
                        <SectionName title="Sync Settings" description="Vehicles/Users can be assigned to Vehicles and Drivers in the system"/>
                        <Section>
                            <Grid container spacing={2} width={"100%"}>
                                <Grid item width={"50%"}>
                                    <Stack spacing={2}  direction={"column"}>
                                        <SectionCheckBoxField
                                            field={this.integrationDetailsStore.formSyncSettings.$.syncAllUsers}
                                            isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                            label="Sync All Drivers"/>
                                        {!this.integrationDetailsStore.formSyncSettings.$.syncAllUsers.value && this.integrationDetailsStore.formSyncUsersSettings.$.map(x => {
                                            return <Stack direction={"row"} key={x.$.id.$} alignItems={"center"}>
                                                <SectionCheckBoxField
                                                    width={"50%"}
                                                    field={x.$.include}
                                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                                    label={x.$.name.value}/>
                                                <SectionSelectField
                                                    field={x.$.map}
                                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                                    label={"Driver"}
                                                    none={true}
                                                    width={"50%"}
                                                    hasError={x.hasError}
                                                    items={() => this.integrationDetailsStore.drivers.map((x) => <MenuItem value={x.id} key={x.id}>{x.fullName}</MenuItem>)}
                                                />
                                            </Stack>
                                        })}
                                    </Stack>
                                </Grid>
                                <Grid item width={"50%"}>
                                    <Stack spacing={2}  direction={"column"}>
                                        <SectionCheckBoxField
                                            field={this.integrationDetailsStore.formSyncSettings.$.syncAllVehicles}
                                            isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                            label="Sync All Vehicles"/>
                                        {!this.integrationDetailsStore.formSyncSettings.$.syncAllVehicles.value && this.integrationDetailsStore.formSyncVehicleSettings.$.map(x => {
                                            return <Stack direction={"row"} key={x.$.id.$} alignItems={"center"}>
                                                <SectionCheckBoxField
                                                    width={"50%"}
                                                    field={x.$.include}
                                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                                    label={x.$.name.value}/>
                                                <SectionSelectField
                                                    field={x.$.map}
                                                    isReadOnlyMode={this.integrationDetailsStore.isReadOnlyMode}
                                                    label={"Vehicle"}
                                                    none={true}
                                                    width={"50%"}
                                                    hasError={x.hasError}
                                                    items={() => this.integrationDetailsStore.vehicles.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                                                />
                                            </Stack>
                                        })}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Section>
                    </React.Fragment>}
                    { !this.integrationDetailsStore.isReadOnlyMode && this.integrationDetailsStore.integrationId > 0 && <ActivateDeactivateSection isActive={this.integrationDetailsStore.isActive} isLoading={this.integrationDetailsStore.isLoading} activate={this.integrationDetailsStore.activate} deactivate={this.integrationDetailsStore.deactivate} />}
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.integrationDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} key={urlParams.id} />;
}
