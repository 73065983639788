import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {runInAction} from "mobx";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {ARMetrics, InvoiceMetrics, InvoiceMetricsRequest} from "app/modules/company/api/clients.api";
import {FieldState, FormState} from "formstate";

export class DashboardStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    metrics: InvoiceMetrics;
    aRmetrics: ARMetrics;

    filters = new FormState({
        from: new FieldState(null),
        to: new FieldState(null),
    })

    loadData = async () => {
        await this.wrapLoadingApiCall(async () => {
            [this.metrics, this.aRmetrics] = await Promise.all([
                this.companyApiClients.invoiceClient.getInvoiceMetrics(new InvoiceMetricsRequest({
                    from: this.filters.$.from.value,
                    to: this.filters.$.to.value,
                })),
                this.companyApiClients.invoiceClient.getARMetrics()
            ]);
        });
    }

    async init() {
        await this.loadData()

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
