import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem, Stack} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {SectionSelectField, SectionTextField, SectionToggleField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    TrackingDeviceEditModel,
    VehicleShortListModel
} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogTrackingDeviceProps {
    closeModal: () => void;
    onSave: () => void;
    deviceId: number;
}

export const DialogTrackingDevice = observer((props: DialogTrackingDeviceProps) => {
    const [formState, _] = useState(new FormState({
        vehicleId: new FieldState(null),
        name: new FieldState(null),
        enableVehicleLocationUpdate: new FieldState(true),
        active: new FieldState(true),
    }));

    const [vehicles, setVehicles] = useState<VehicleShortListModel[]>();
    const [isLoading, setLoading] = useState(true);

    const store = useInstance(ApiClients);
    const cache = useInstance(CacheService);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const [vh, details] = [
                await cache.getVehicles(),
                await store.trackingDeviceClient.getDetails(props.deviceId)
            ];

            formState.$.vehicleId.value = details.vehicleId;
            formState.$.active.value = details.active;
            formState.$.name.value = details.name;
            formState.$.enableVehicleLocationUpdate.value = details.enableVehicleLocationUpdate;

            setVehicles(vh);
            setLoading(false);
        };

        fetchData();
    }, []);

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);

            await store.trackingDeviceClient.update(new TrackingDeviceEditModel({
                id: props.deviceId,
                name: formState.$.name.value,
                active: formState.$.active.value,
                enableVehicleLocationUpdate: formState.$.enableVehicleLocationUpdate.value,
                vehicleId: formState.$.vehicleId.value,
            }));

            props.onSave();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    }

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Update Device Settings
        </DialogTitle>
        <DialogContent>
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                {!isLoading ? <Stack direction={"column"} spacing={2}>
                    <SectionTextField
                        label={"Name"}
                        field={formState.$.name}
                        fullWidth
                    />
                    <SectionSelectField
                        field={formState.$.vehicleId}
                        width={"100%"}
                        label={"Vehicle"}
                        items={() => vehicles.map((x) => <MenuItem value={x.id} key={x.id}>{'#' + x.number + ( x.carrierId ?  " - " + x.carrierName : "")}</MenuItem>)}
                    />
                    <SectionToggleField
                        field={formState.$.enableVehicleLocationUpdate}
                        label="Enable/Disable - Vehicle Location Update"
                    />
                    <SectionToggleField
                        field={formState.$.active}
                        label="Active/Inactive"
                    />
                </Stack> : <LinearProgress/> }
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
