import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate";
import {email, required, requiredEditor} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {EmailBidSettingParam, EmailBidSettings, SendTestBidEmailModel} from "app/modules/company/api/clients.api";
import {ContentState, EditorState} from 'draft-js';

export class BidEmailDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;

    title: string = 'Email Bid Setting';

    params: EmailBidSettingParam[] = [];

    formEmailBidSettingsState = new FormState({
        subjectTemplate: new FieldState('').validators(required),
        bodyTemplate: new FieldState(EditorState.createEmpty()).validators(requiredEditor),
        fromDisplayTemplate: new FieldState('').validators(required),
        fromEmail: new FieldState(null).validators(required, email),
        ccEmail: new FieldState(null).validators(email),
    });

    constructor() {
        super();

        makeObservable(this, {
            title: observable,

            init: action,
            saveSettings: action
        });
    }

    saveSettings = async () => {
        await this.formEmailBidSettingsState.validate();

        if(this.formEmailBidSettingsState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.settingsClient.setEmailBidSettings(new EmailBidSettings({
                subjectTemplate: this.formEmailBidSettingsState.$.subjectTemplate.value,
                bodyTemplate: this.formEmailBidSettingsState.$.bodyTemplate.value.getCurrentContent().getPlainText("\n"),
                fromDisplayTemplate: this.formEmailBidSettingsState.$.fromDisplayTemplate.value,
                fromEmail: this.formEmailBidSettingsState.$.fromEmail.value,
                cCEmail: this.formEmailBidSettingsState.$.ccEmail.value,
            }));
        });
    }

    sendTestEmail = async () => {
        await this.formEmailBidSettingsState.validate();

        if(this.formEmailBidSettingsState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.settingsClient.testEmail(new SendTestBidEmailModel({
                subject: this.formEmailBidSettingsState.$.subjectTemplate.value,
                body: this.formEmailBidSettingsState.$.bodyTemplate.value.getCurrentContent().getPlainText("\n"),
                fromDisplay: this.formEmailBidSettingsState.$.fromDisplayTemplate.value
            }));
        });
    }

    replaceParams = (value: string) => {
        let val = value?.toString() || "";

        for(let i = 0; i < this.params.length; i++) {
            if(this.params[i].param == "[CompanyLogo]") {
                val = val.split(this.params[i].param).join(`<img src='${this.params[i].example}'/>`);
            } else {
                val = val.split(this.params[i].param).join(this.params[i].example);
            }


        }

        return val;
    }

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
            const [settings, params] = [
                await this.companyApiClients.settingsClient.getEmailBidSettings(),
                await this.companyApiClients.settingsClient.getEmailBidSettingParams()
            ];

            this.params = params;

            this.formEmailBidSettingsState.$.subjectTemplate.value = settings?.subjectTemplate;
            this.formEmailBidSettingsState.$.bodyTemplate.value = EditorState.createWithContent(ContentState.createFromText(settings?.bodyTemplate || ''));
            this.formEmailBidSettingsState.$.fromDisplayTemplate.value = settings?.fromDisplayTemplate;
            this.formEmailBidSettingsState.$.fromEmail.value = settings?.fromEmail;
            this.formEmailBidSettingsState.$.ccEmail.value = settings?.cCEmail;
        });
    }
}
