import {observer} from "mobx-react";
import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {SectionTextField} from "app/modules/common/details/text-field";

export interface DialogSetNumberProps {
    closeModal: () => void;
    setNumber: (number: number) => void;
    number: number;
    title: string;
}

export const DialogSetNumber = observer((props: DialogSetNumberProps) => {
    const [formState, _] = useState(new FormState({
        number: new FieldState<number>(props.number || null),
    }));

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
            <SectionTextField
                label={"Number"}
                field={formState.$.number}
                type={"number"}
                fullWidth
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeModal}>Close</Button>
            <Button variant="outlined" onClick={() => { props.setNumber(formState.$.number.value); }} color="primary" autoFocus>
                Save
            </Button>
        </DialogActions>
    </Dialog>;
})
