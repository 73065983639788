import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {TenantDetailsStore} from "app/modules/admin/components/tenants/details/TenantDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Box, Grid, Tabs, Tab} from "@mui/material";
import Details from "app/modules/admin/components/tenants/details/details";
import {useParams} from "react-router-dom";

@provider(TenantDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject tenantDetailsStore: TenantDetailsStore;

    render() {
        if(this.tenantDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        return <Container>
                <PageTitleDetails Title={this.tenantDetailsStore.title} Size={12}/>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Tabs value={this.tenantDetailsStore.selectedTab} onChange={(even, value) =>this.tenantDetailsStore.selectTab(value)} centered>
                            <Tab value={this.tenantDetailsStore.TabDetails} label={this.tenantDetailsStore.TabDetails} />
                            <Tab value={this.tenantDetailsStore.TabUsers} label={this.tenantDetailsStore.TabUsers} />
                        </Tabs>
                    </Box>
                </Grid>

                {this.tenantDetailsStore.selectedTab == this.tenantDetailsStore.TabDetails && <Grid item xs={12}><Details/></Grid>}
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.tenantDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
