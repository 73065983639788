import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Button, Grid, InputBase} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {
    OwnerAccountListModel,
    TenantClaims,
} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {Link} from "react-router-dom";
import {Paths} from "app/modules/company/stores/paths";
import {OwnerAppAccountStore} from "app/modules/company/components/settings/owner-account/OwnerAppAccountStore";
import {BtnLink} from "app/modules/common/layout/components/btn-link";

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 4,
        renderCell: (params: GridRenderCellParams<OwnerAccountListModel>) => (
            <Link to={Paths.EditOwnerAppAccount.nav(params.row.id)}>{params.row.name}</Link>
        ),
    },
    {
        field: 'email',
        headerName: 'Email',
        minWidth: 200,
        flex: 1
    },
    {
        field: 'vehicles',
        headerName: 'Vehicles',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<OwnerAccountListModel>) => (
            StringHelper.formatNull(params.row.vehicles?.join(", "))
        )
    },
    {
        field: 'carrier',
        headerName: 'Carrier',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OwnerAccountListModel>) => (
            StringHelper.formatNull(params.row.carrier)
        )
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OwnerAccountListModel>) => (
            StringHelper.formatTenantOwnerStatus(params.row.status)
        )
    }
];

@provider(OwnerAppAccountStore)
@observer
export default class Index extends Component<any, any> {
    @inject ownerAppAccountStore: OwnerAppAccountStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.ownerAppAccountStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.OwnerAccountEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.ownerAppAccountStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.OwnerAccountEdit) && <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewOwnerAppAccount.nav} Text="New Account"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.ownerAppAccountStore.filters.$.search.value}
                            onChange={(e)=>this.ownerAppAccountStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.ownerAppAccountStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.ownerAppAccountStore.items}
                    columns={columns}
                    pageSize={this.ownerAppAccountStore.pageSize}
                    rowsPerPageOptions={this.ownerAppAccountStore.pageSizeOptions}
                    rowCount={this.ownerAppAccountStore.totalRows}
                    page={this.ownerAppAccountStore.skipRows/this.ownerAppAccountStore.pageSize}
                    loading={this.ownerAppAccountStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.ownerAppAccountStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.ownerAppAccountStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.ownerAppAccountStore.init();
    }
}

