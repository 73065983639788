import React, {useEffect, useRef, useState} from "react";
import queryString from "query-string";
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {FileItemType, FileListItem, FileType, IFileListItem, TenantClaims} from "app/modules/company/api/clients.api";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {MainStore} from "app/modules/company/stores/MainStore";

export interface IFileInputParams {
    fileType: FileType,
    fileItemType: FileItemType;
    objectId: number;
    files?: FileListItem[];
    readonly?: boolean;
}

const FileTypeNames: Map<FileType,string> = new Map([
    [FileType.RateConfirmation, "Rate Confirmation"],
    [FileType.BillOfLanding, "Bill Of Landing"],
    [FileType.DocumentsToPrint, "Documents To Print"],
    [FileType.Receipts, "Support Receipts"],
    [FileType.ProofOfDelivery, "Proof Of Delivery"],
    [FileType.Note, "Note"],
    [FileType.DriverLicense, "Driver License"],
    [FileType.DriverMedicalExam, "Med Exam"],
    [FileType.Agreement, "Agreement"],
    [FileType.VehicleRegistration, "Vehicle Registration"],
    [FileType.OrderCargo, "Order Cargo"],
    [FileType.DriverApplication, "Driver Application"],
    [FileType.Insurance, "Insurance"],
    [FileType.Authority, "Authority"],
    [FileType.W9, "W9"],
    [FileType.VoidCheck, "VoidCheck"],
    [FileType.Certificates, "Certificates"],
    [FileType.QuickPayLetter, "QuickPay Letter"],
    [FileType.FactoringReleaseLetter, "Factoring Release Letter"],
    [FileType.IdentificationDocuments, "Identification Documents"],
    [FileType.Photo, "Photo"],
]);

export const FileInput = (params: IFileInputParams) => {
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [files, setFiles] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedFile, selectFile] = React.useState(null);

    const handleClickOpen = (file: IFileListItem) => {
        setOpen(true);
        selectFile(file);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let apiClients = useInstance(ApiClients);
    let mainStore = useInstance(MainStore);

    const loadFiles = () => apiClients.fileClient.getList(params.objectId, params.fileItemType, params.fileType)
        .then((response) => { setFiles(response)  })
        .finally(() => { setLoading(false); });

    useEffect(() => {
        if(params.files != undefined) {
            setFiles(params.files);
            setLoading(false);
        } else {
            loadFiles();
        }
    }, [params.objectId, params.fileItemType, params.fileType]);

    const fileInput = useRef(null)

    if(loading) {
        return <div>File is uploading...</div>;
    }

    return <Stack direction={"column"} sx={{width: "100%", pt: 4}}>
        <Typography variant="subtitle2" component="div"><b>{ FileTypeNames.get(params.fileType) }:</b></Typography>
        <Divider sx={{ marginTop: "15px", marginBottom: "15px" }}/>
        {Boolean(files.length) && (
            <React.Fragment>
                <Grid container>
                    {files.map((f: IFileListItem) => {
                        return <React.Fragment key={f.id}>
                            <Grid item xs={mainStore.checkClaim(TenantClaims.DeleteFile) ? 11 : 12}>
                                <Typography variant="body2" component="div">
                                    <a href={apiClients.axiosInstance.defaults.baseURL + "/company/file/GetFile?" + queryString.stringify({ id: f.id, itemType: params.fileItemType })} download={f.name} target="_blank">{f.name}</a>
                                </Typography>
                                <Typography variant="body2" component="div" sx={{ fontWeight: "bold", fontStyle: "italic" }}>
                                    <div>{f.description}</div>
                                </Typography>
                            </Grid>
                            {!params.readonly && mainStore.checkClaim(TenantClaims.DeleteFile) && <Grid item xs={1} textAlign={"right"}>
                                <IconButton color="primary" aria-label="Edit" component="span" onClick={() => handleClickOpen(f) }>
                                    <DeleteIcon/>
                                </IconButton>
                            </Grid>}
                        </React.Fragment>;
                    })}
                </Grid>
                <br />
            </React.Fragment>
        )}
        {error?.length > 0 ? <Alert severity="error">
            Error — <strong>{error}</strong>
        </Alert> : null}
        {!params.readonly && <Stack direction={"row"} sx={{width: "100%"}}>
            <TextField id="standard-basic" variant="standard" value={description} label="Description" fullWidth onChange={ (obj: React.ChangeEvent<HTMLInputElement>) => setDescription(obj.target.value) } />
            <div>
                <Box sx={{ paddingTop: "10px" }}>
                    <IconButton color="primary" onClick={() => fileInput.current.click()}>
                        <FileUploadIcon />
                    </IconButton>
                </Box>
                <input
                    style={{ display: "none" }}
                    type="file"
                    multiple={false}
                    ref={fileInput}
                    onChange={e => {
                        setLoading(true);

                        let formData = new FormData();
                        formData.append("file", e.target.files[0]);

                        // @ts-ignore
                        let obj = queryString.stringify({ id: params.objectId, fileType: params.fileType, description: description, itemType: params.fileItemType });

                        apiClients.axiosInstance.post("/company/file/Upload" + "?" + obj,
                            formData, {
                                // @ts-ignore
                                headers: {
                                    'Content-Type': 'application/x-www-url-formencoded'
                                }
                        }).then((value) => {
                            if(value.status == 400) {
                                setError(value?.data?.Message || "Unexpected Error");
                            } else {
                                setDescription('');
                                setError('');
                                loadFiles();
                            }
                        }).catch(() => {
                            setError('File is not uploaded.')
                        }).finally(() => {
                            setLoading(false);
                        })
                    }}
                />
            </div>
        </Stack>}
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Are you sure, you want to delete this file?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <b>{selectedFile?.name}</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={() => apiClients.fileClient.deleteFile(selectedFile.id, params.fileItemType).then(() => { handleClose(); loadFiles(); })} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    </Stack>
}
