import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {
    FuelTypes,
    VehicleDetailsStore, VehicleTypesMap
} from "app/modules/company/components/vehicles/details/VehicleDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Grid, MenuItem, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {ActivateDeactivateSection} from "app/modules/common/details/activate-section";
import {
    SectionCheckBoxField,
    SectionDatePickerField,
    SectionSelectField,
    SectionTextField
} from "app/modules/common/details/text-field";
import {FileInput} from "app/modules/company/components/file/file-uploader";
import {FileItemType, FileType} from "app/modules/company/api/clients.api";

@provider(VehicleDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject vehicleDetailsStore: VehicleDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.vehicleDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.vehicleDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Vehicles.nav}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.vehicleDetailsStore.title} Size={!this.vehicleDetailsStore.isReadOnlyMode ? 8 : 12}/>
                {!this.vehicleDetailsStore.isReadOnlyMode && <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.vehicleDetailsStore.isLoading} disabled={this.vehicleDetailsStore.formState.hasError} variant="contained" onClick={this.vehicleDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>}
                <ContainerDetails>
                    <SectionName title="Basic Info" description="Vehicle basic info"/>
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Number"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.Number}
                                    width={"20%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"VIN"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.VIN}
                                    width={"20%"}
                                />
                                <SectionTextField
                                    label={"Make"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.Make}
                                    width={"20%"}
                                />
                                <SectionTextField
                                    label={"Model"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.Model}
                                    width={"20%"}
                                />
                                <SectionTextField
                                    label={"Year"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.Year}
                                    width={"20%"}
                                    type={"number"}
                                />
                            </Stack>
                            <SectionDatePickerField
                                isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                field={this.vehicleDetailsStore.formState.$.RegistrationExpirationDate}
                                label="Registration Expiration Date"
                                width={"100%"}
                                minDate={new Date()}
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionSelectField
                                    field={this.vehicleDetailsStore.formState.$.Type}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    width={"50%"}
                                    label={"Type"}
                                    none={false}
                                    items={() => Array.from(VehicleTypesMap.keys()).map((x) => <MenuItem value={x} key={x}>{VehicleTypesMap.get(x)}</MenuItem>)}
                                />
                                <SectionSelectField
                                    field={this.vehicleDetailsStore.formState.$.FuelType}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    width={"50%"}
                                    label={"FuelType"}
                                    none={false}
                                    items={() => FuelTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionSelectField
                                    field={this.vehicleDetailsStore.formState.$.LicenseState}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    width={"50%"}
                                    label={"License State"}
                                    items={() => this.mainStore.tenantData.states.map((x) => <MenuItem value={x.code} key={x.code}>{x.name}</MenuItem>)}
                                />
                                <SectionTextField
                                    label={"License Number"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.LicenseNumber}
                                    width={"50%"}
                                />
                            </Stack>
                            <SectionSelectField
                                field={this.vehicleDetailsStore.formState.$.CarrierId}
                                isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                label={"Carrier"}
                                none={true}
                                width={"100%"}
                                items={() => this.vehicleDetailsStore.carrier.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                            />
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Specs" description="Vehicle Specs, please enter them carefully, it will be send as part of a bid" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"DoorWidth"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.DoorWidth}
                                    width={"50%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"DoorHeight"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.DoorHeight}
                                    width={"50%"}
                                    type={"number"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"BoxLength"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.BoxLength}
                                    width={"33%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"BoxWidth"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.BoxWidth}
                                    width={"33%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"BoxHeight"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.BoxHeight}
                                    width={"33%"}
                                    type={"number"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"MaxLoad"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.MaxLoad}
                                    width={"50%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"LG Weight Capacity(lb)"}
                                    isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                    field={this.vehicleDetailsStore.formState.$.LiftGate}
                                    width={"50%"}
                                    type={"number"}
                                />
                            </Stack>
                            <Stack direction={"row"}>
                                <Stack direction={"column"} sx={{width: "50%"}}>
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.IsDockHigh}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="DockHigh"
                                    />
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasLiftGate}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="LiftGate"
                                    />
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasAirRide}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="AirRide"
                                    />
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasETrack}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="ETrack"
                                    />
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasStraps}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="Straps"
                                    />
                                </Stack>
                                <Stack direction={"column"} sx={{width: "50%"}}>
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasRamps}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="Ramps"
                                    />
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasBlankets}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="Blankets"
                                    />
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasPalletJack}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="PalletJack"
                                    />
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasTeam}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="Team"
                                    />
                                    <SectionCheckBoxField
                                        field={this.vehicleDetailsStore.formState.$.HasSideDoor}
                                        isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                        label="Side Door"
                                    />
                                </Stack>
                            </Stack>
                            <SectionSelectField
                                field={this.vehicleDetailsStore.formState.$.FloorType}
                                isReadOnlyMode={this.vehicleDetailsStore.isReadOnlyMode}
                                label={"Floor Type"}
                                width={"100%"}
                                items={() => this.vehicleDetailsStore.floorTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                            />
                            {this.vehicleDetailsStore.vehicleId > 0 && <Stack spacing={2} direction={"row"}>
                                <FileInput fileType={FileType.VehicleRegistration} fileItemType={FileItemType.Vehicle} objectId={this.vehicleDetailsStore.vehicleId}/>
                                <FileInput fileType={FileType.Photo} fileItemType={FileItemType.Vehicle} objectId={this.vehicleDetailsStore.vehicleId}/>
                            </Stack>}
                        </Stack>
                    </Section>
                    {!this.vehicleDetailsStore.isReadOnlyMode && this.vehicleDetailsStore.vehicleId > 0 && <ActivateDeactivateSection isActive={this.vehicleDetailsStore.isActive} isLoading={this.vehicleDetailsStore.isLoading} activate={this.vehicleDetailsStore.activate} deactivate={this.vehicleDetailsStore.deactivate} />}
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.vehicleDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
