import {NavMenuItem} from "app/modules/common/menu/menu";
import {
    Orders,
    Radar,
    Timeline,
    Fleet,
    Contacts,
    Settings,
    FleetDrivers,
    FleetCarriers,
    FleetVehicles,
    Integrations,
    GeneralSettings,
    FleetView,
    Bids,
    EmailSettings,
    TelegramLinks,
    TelegramChannels,
    Accounting,
    AccountingOpenOrders,
    AccountingNetTerms,
    AccountingInvoices,
    AccountingPayments,
    TrackingDevices,
    OrdersAll,
    OrdersProgress,
    OrdersReady,
    OrdersHold,
    OrderTasks,
    LoadBoard,
    ExternalOrders,
    Quotes,
    OwnerAppAccounts,
    AccountingInvoicesClosed,
    AccountingInvoiceDashboard, MobileLogs, CompanyIntegrations
} from "app/modules/company/stores/menu-items";
import {action, makeObservable, observable} from "mobx";
import {TenantClaims} from "app/modules/company/api/clients.api";

export class NavigationStore {
    title: string = "Main";
    menuItems: NavMenuItem[] = observable.array([]);

    constructor() {
        makeObservable(this, {
            title: observable,
            menuItemOnClick: action,
            init: action,
            changeLocation: action
        })
    }

    menuItemOnClick = (item: NavMenuItem) => {
        for(let i = 0; i < this.menuItems.length; i++) {
            if(this.menuItems[i] != item && this.menuItems[i].expanded != false) {
                this.menuItems[i].expanded = false;
            }
        }

        item.expanded = !item.expanded;
    }

    changeLocation = (path: string, search: string) => {
        for(let i = 0; i < this.menuItems.length; i++) {
            this.menuItems[i].selected = this.menuItems[i].path == path;

            if(this.menuItems[i].selected) this.title = this.menuItems[i].title;

            if(this.menuItems[i].subItems?.length > 0) {
                for(let j = 0; j < this.menuItems[i].subItems.length; j++) {
                    this.menuItems[i].subItems[j].selected = this.menuItems[i].subItems[j].path == path;

                    if(this.menuItems[i].subItems[j].selected) this.title = this.menuItems[i].subItems[j].title;
                }
            }
        }
    }

    init = (check: (claim: TenantClaims) => boolean) => {
        if(check(TenantClaims.OrderListView)) { Orders.subItems.push(OrdersAll); }
        if(check(TenantClaims.OrderListView)) { Orders.subItems.push(OrdersProgress); }
        if(check(TenantClaims.OrderListView)) { Orders.subItems.push(OrdersReady); }
        if(check(TenantClaims.OrderListView)) { Orders.subItems.push(OrdersHold); }
        if(check(TenantClaims.OrderTaskView)) { Orders.subItems.push(OrderTasks); }

        if(Orders.subItems.length > 0) {
            this.menuItems.push(Orders);
        }

        if(check(TenantClaims.OrderQuoteListView)) { this.menuItems.push(Quotes); }
        if(check(TenantClaims.BidListView)) { this.menuItems.push(Bids); }

        if(check(TenantClaims.RadarView)) { LoadBoard.subItems.push(Radar); }
        if(check(TenantClaims.RadarView)) { LoadBoard.subItems.push(ExternalOrders); }

        if(LoadBoard.subItems.length > 0) {
            this.menuItems.push(LoadBoard);
        }

        if(check(TenantClaims.VehicleListView)) { this.menuItems.push(FleetView); }
        if(check(TenantClaims.VehicleListView)) { this.menuItems.push(Timeline); }

        if(check(TenantClaims.UsersListView)) { Fleet.subItems.push(FleetDrivers); }
        if(check(TenantClaims.CarrierListView)) { Fleet.subItems.push(FleetCarriers); }
        if(check(TenantClaims.VehicleListView)) { Fleet.subItems.push(FleetVehicles); }

        if(Fleet.subItems.length > 0) {
            this.menuItems.push(Fleet);
        }

        this.menuItems.push(Contacts);

        if(check(TenantClaims.SettingsEditView)) { Settings.subItems.push(GeneralSettings); }
        if(check(TenantClaims.SettingsEditView)) { Settings.subItems.push(EmailSettings); }
        if(check(TenantClaims.IntegrationListView)) { Settings.subItems.push(Integrations); }
        if(check(TenantClaims.IntegrationListView)) { Settings.subItems.push(CompanyIntegrations); }
        if(check(TenantClaims.TrackingDeviceEdit)) { Settings.subItems.push(TrackingDevices); }
        if(check(TenantClaims.OwnerAccountListView)) { Settings.subItems.push(OwnerAppAccounts); }
        if(check(TenantClaims.TelegramChannelEdit)) { Settings.subItems.push(TelegramLinks); }
        if(check(TenantClaims.TelegramChannelEdit)) { Settings.subItems.push(TelegramChannels); }
        if(check(TenantClaims.MobileLogView)) { Settings.subItems.push(MobileLogs); }

        if(Settings.subItems.length > 0) {
            this.menuItems.push(Settings);
        }

        if(check(TenantClaims.AccountingInvoicing)) { Accounting.subItems.push(AccountingInvoiceDashboard); }
        if(check(TenantClaims.AccountingInvoicing)) { Accounting.subItems.push(AccountingOpenOrders); }
        if(check(TenantClaims.AccountingInvoicing)) { Accounting.subItems.push(AccountingInvoices); }
        if(check(TenantClaims.AccountingInvoicing)) { Accounting.subItems.push(AccountingInvoicesClosed); }
        if(check(TenantClaims.AccountingInvoicing)) { Accounting.subItems.push(AccountingNetTerms); }
        if(check(TenantClaims.AccountingPayments)) { Accounting.subItems.push(AccountingPayments); }

        if(Accounting.subItems.length > 0) {
            this.menuItems.push(Accounting);
        }
    }
}

