import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Button, Grid, InputBase} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {UsersStore} from "app/modules/organization/components/users/UsersStore";
import {Link} from "react-router-dom";
import {NavigationStore} from "app/modules/organization/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {UserListModel} from "app/modules/organization/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {Paths} from "app/modules/organization/stores/paths";
import {TenantClaims} from "app/modules/organization/api/clients.api";
import {MainStore} from "app/modules/organization/stores/MainStore";

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 5,
        renderCell: (params: GridRenderCellParams<UserListModel>) => (
             <Link to={Paths.EditUsers.nav(params.row.id)}>{params.row.fullName}</Link>
        ),
    },
    {
        field: 'email',
        headerName: 'Email',
        minWidth: 250,
        flex: 1
    },
    {
        field: 'emailConfirmed',
        headerName: 'Email Confirmed',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<UserListModel>) => (
            StringHelper.formatYesNo(params.row.emailConfirmed)
        ),
    },
    {
        field: 'lockoutEnd',
        headerName: 'Locked',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<UserListModel>) => (
            !!params.row.lockoutEnd && DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.lockoutEnd) || "-"
        ),
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<UserListModel>) => (
            DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.createdAt)
        ),
    }
];

@provider(UsersStore)
@observer
export default class Index extends Component<any, any> {
    @inject usersStore: UsersStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.usersStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.UsersEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.usersStore.totalRows}/>
            </Grid>
            { this.mainStore.checkClaim(TenantClaims.UsersEdit) && <Grid item xs={6} textAlign={"right"}>
                <Button onClick={() => this.usersStore.createNewUser()} variant={"contained"}>New User</Button>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.usersStore.filters.$.search.value}
                            onChange={(e)=>this.usersStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.usersStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.usersStore.items}
                    columns={columns}
                    pageSize={this.usersStore.pageSize}
                    rowsPerPageOptions={this.usersStore.pageSizeOptions}
                    rowCount={this.usersStore.totalRows}
                    page={this.usersStore.skipRows/this.usersStore.pageSize}
                    loading={this.usersStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.usersStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.usersStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.usersStore.init();
    }
}

