import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Button, Grid, InputBase} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {CarriersStore} from "app/modules/company/components/carriers/CarriersStore";
import {Link} from "react-router-dom";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {CarrierListModel, TenantClaims, VehicleListModel} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {Paths} from "app/modules/company/stores/paths";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {MainStore} from "app/modules/company/stores/MainStore";
import {CarrierRating} from "app/modules/company/components/carriers/carrier-rating";

const getColor = (input: Date) => {
    const date = new Date(input);
    const nowPlus30 = DateTimeHelper.addDays(new Date(), 30);
    const nowPlus7 = DateTimeHelper.addDays(new Date(), 7);

    if(date < nowPlus7) {
        return "#f60000";
    } else if(date > nowPlus30) {
        return "#49d535";
    } else {
        return "#a7a742";
    }
}

const columns = [
    {
        field: 'displayName',
        headerName: 'Display Name',
        minWidth: 250,
        flex: 4,
        renderCell: (params: GridRenderCellParams<CarrierListModel>) => (
            <Link to={Paths.EditCarrier.nav(params.row.id)}>{params.row.displayName}</Link>
        ),
    },
    {
        field: 'rating',
        headerName: 'Rating',
        minWidth: 70,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            <CarrierRating
                vehicleRating={null}
                vehicleCustomPeriodRating={null}
                carrierRating={params.row.rating}
                carrierCustomPeriodRating={params.row.customPeriodRating}
            />
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<CarrierListModel>) => (
            <div>
                {params.row.dbaName?.length > 0 ? `${params.row.dbaName} (${params.row.name})` : params.row.name}
            </div>
        ),
    },
    {
        field: 'uSDOT',
        headerName: 'USDOT',
        minWidth: 150,
        flex: 1
    },
    {
        field: 'eIN',
        headerName: 'EIN',
        minWidth: 150,
        flex: 1
    },
    {
        field: 'vehicles',
        headerName: 'Vehicles',
        minWidth: 100,
        flex: 1
    },
    {
        field: 'drivers',
        headerName: 'Drivers',
        minWidth: 100,
        flex: 1
    },
    {
        field: 'insuranceExpirationDate',
        headerName: 'Insurance Exp',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CarrierListModel>) => (
            <div style={params.row.insuranceExpirationDate && {color: getColor(params.row.insuranceExpirationDate)}}>{DateTimeHelper.formatMomentDate(params.row.insuranceExpirationDate)}</div>
        ),
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CarrierListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.createdAt)
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<CarrierListModel>) => (
            StringHelper.formatActive(params.row.active)
        )
    }
];

@provider(CarriersStore)
@observer
export default class Index extends Component<any, any> {
    @inject carriersStore: CarriersStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.carriersStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.CarrierEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.carriersStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.CarrierEdit) && <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewCarrier.nav} Text="New Carrier"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.carriersStore.filters.$.search.value}
                            onChange={(e)=>this.carriersStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.carriersStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.carriersStore.items}
                    columns={columns}
                    pageSize={this.carriersStore.pageSize}
                    rowsPerPageOptions={this.carriersStore.pageSizeOptions}
                    rowCount={this.carriersStore.totalRows}
                    page={this.carriersStore.skipRows/this.carriersStore.pageSize}
                    loading={this.carriersStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.carriersStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.carriersStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.carriersStore.init();
    }
}

