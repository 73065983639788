import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {NavigationStore} from "app/modules/organization/stores/NavigationStore";
import {IOrganizationDetailsModel, IUserInfo} from "app/modules/organization/api/clients.api";
import {TenantClaims} from "app/modules/organization/api/clients.api";
import {ApiClients} from "app/modules/organization/services/AxiosBaseClient";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export class MainStore extends BaseLoadingStore{
    @inject apiClients: ApiClients;
    @inject navigationStore: NavigationStore;
    userData: IUserInfo = null;
    orgInfo: IOrganizationDetailsModel = null;

    isAuthenticated: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            userData: observable,
            isAuthenticated: observable,
            orgInfo: observable,

            init: action
        });
    }

    checkClaim = (claim: TenantClaims) : boolean => this.userData.isAdmin || (this.userData.claims.find(x => x == claim) || this.userData.claims.find(x => x == TenantClaims.FullAccess)) != null;

    async init() {
        try {
            const [userInfo, orgInfo] = await Promise.all([
                this.apiClients.dataProviderClient.getUserData(),
                this.apiClients.organizationClient.getOrganizationDetails()
            ]);

            this.userData = userInfo;
            this.orgInfo = orgInfo;

            this.navigationStore.init(this.checkClaim);

            runInAction(() => {
                this.isAuthenticated = true;
            });
        } catch(ex) {
            runInAction(() => {
                this.isAuthenticated = false;
            });

            ApiErrorHandler(ex);
        }
        finally {
            runInAction(() => {
                this.isInitLoading = false;
            });
        }
    }
}
