import React, { PureComponent } from 'react';
import {ResponsiveContainer, BarChart, Tooltip, Legend, Bar, XAxis, YAxis} from 'recharts';

export default class ArChart extends PureComponent<{ open: number, due: number, openTotal: number, dueTotal: number }> {
    render() {
        const data = [
            {
                "name": "Receivable",
                "open": this.props.openTotal,
                "due": this.props.dueTotal
            }
        ]

        return (<ResponsiveContainer width="100%" height="100%">
                <BarChart width={730} height={250} data={data}>
                    <XAxis dataKey="name"/>
                    <YAxis unit={"$"} />
                    <Tooltip formatter={(value, name, props) => `${value}`} />
                    <Legend />
                    <Bar dataKey="open" fill="#8884d8" name={"Open (" + this.props.open + ")"} unit={"$"} />
                    <Bar dataKey="due" fill="#82ca9d" name={"Overdue (" + this.props.due + ")"} unit={"$"} />
                </BarChart>
            </ResponsiveContainer>
        );
    }
}
