import {action, makeObservable, observable} from "mobx";
import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";

export abstract class BaseListStore<T> extends BaseLoadingStore {
    items: T[] = [];

    pageSize: number = 50;
    totalRows: number = 0;

    skipRows: number = 0;
    pageSizeOptions: number[] = [25, 50, 100];

    protected constructor() {
        super();

        makeObservable(this, {
            pageSize: observable,
            totalRows: observable,
            skipRows: observable,
            pageSizeOptions: observable,

            setPageSize: action,
            setPage: action,
            applyFilters: action,
            loadData: action,
            applySpecificFilters: action
        });
    }

    setPageSize = async (pageSize: number) => {
        this.pageSize = pageSize;
        this.skipRows = 0;

        await this.loadData();
    }

    setPage = async (page: number) => {
        this.skipRows = this.pageSize * page;

        await this.loadData();
    }

    abstract loadData();
    abstract applySpecificFilters();

    applyFilters = () => {
        this.skipRows = 0;

        this.applySpecificFilters();

        this.loadData();
    }
}
