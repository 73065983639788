import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {required} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {CustomerEditModel, CustomerNoteType, NamedValueLong, TenantClaims} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";
import {DialogSelectCustomer} from "app/modules/company/components/orders/details/dialog.customer";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";

export class CustomerDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject cacheService: CacheService;
    @inject dialogStore: DialogStore;

    noteTypes: NamedValueLong[] = [
        new NamedValueLong({ id: CustomerNoteType.None, name: "None" }),
        new NamedValueLong({ id: CustomerNoteType.Warning, name: "Warning" }),
        new NamedValueLong({ id: CustomerNoteType.Avoid, name: "Avoid" }),
        new NamedValueLong({ id: CustomerNoteType.Preferable, name: "Preferable" }),
    ];

    title: string = 'New Customer';
    customerId?: number = null;
    paymentNetTerms: NamedValueLong[] = null;

    formState = new FormState({
        name: new FieldState(null),
        companyName: new FieldState(null).validators(required),
        mcPrefix: new FieldState(null),
        mcNumber: new FieldState(null),
        uSDOT: new FieldState(null),

        address: new FieldState("").validators(required),
        address2: new FieldState(""),
        city: new FieldState("").validators(required),
        state: new FieldState("").validators(required),
        zip: new FieldState(null).validators(required),

        remitAddress: new FieldState(""),
        remitAddress2: new FieldState(""),
        remitCity: new FieldState(""),
        remitState: new FieldState(""),
        remitZip: new FieldState(null),

        defaultPayerId: new FieldState(null),
        defaultPayer: new FieldState(""),
        defaultPaymentNetTermId: new FieldState(null),

        phone: new FieldState(""),
        notes: new FieldState(""),
        noteType: new FieldState(CustomerNoteType.None)
    });

    isReadOnlyMode: boolean = false;

    constructor() {
        super();

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.CustomerEdit);

        makeObservable(this, {
            title: observable,

            init: action,
            save: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.contactClient.customerUpsert(new CustomerEditModel({
                id: this.customerId,
                name: this.formState.$.name.value,
                companyName: this.formState.$.companyName.value,
                mcPrefix: this.formState.$.mcPrefix.value,
                mcNumber: this.formState.$.mcNumber.value,
                uSDOT: this.formState.$.uSDOT.value,

                address: this.formState.$.address.value,
                address2: this.formState.$.address2.value,
                city: this.formState.$.city.value,
                state: this.formState.$.state.value,
                zIP: this.formState.$.zip.value,

                remitAddress: this.formState.$.remitAddress.value,
                remitAddress2: this.formState.$.remitAddress2.value,
                remitCity: this.formState.$.remitCity.value,
                remitState: this.formState.$.remitState.value,
                remitZIP: this.formState.$.remitZip.value,

                defaultPayerId: this.formState.$.defaultPayerId.value,
                defaultPaymentNetTermId: this.formState.$.defaultPaymentNetTermId.value,

                phone: this.formState.$.phone.value,
                notes: this.formState.$.notes.value,
                noteType: this.formState.$.noteType.value,
            }));

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }

    changeCustomer = () => {
        const key = "vehicle_" + this.customerId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSelectCustomer closeModal={() => this.dialogStore.closeDialog(key)} setCustomer={async (customerId, name) => {
                this.formState.$.defaultPayerId.onChange(customerId);
                this.formState.$.defaultPayer.onChange(name);
                this.dialogStore.closeDialog(key);
            }} title="Update Default Payer"/>
        });
    }

    init = async (id: number) => {
        this.customerId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(this.customerId > 0) {
                const [result, paymentNetTerms] = [
                    await this.companyApiClients.contactClient.getCustomerDetails(this.customerId),
                    await this.cacheService.getPaymentNetTerms()
                ];

                this.paymentNetTerms = paymentNetTerms;

                this.title = result.companyName;

                this.formState.$.name.value = result.name;
                this.formState.$.companyName.value = result.companyName;
                this.formState.$.mcPrefix.value = result.mcPrefix;
                this.formState.$.mcNumber.value = result.mcNumber;
                this.formState.$.uSDOT.value = result.uSDOT;

                this.formState.$.address.value = result.address;
                this.formState.$.address2.value = result.address2;
                this.formState.$.city.value = result.city;
                this.formState.$.state.value = result.state;
                this.formState.$.zip.value = result.zIP;

                this.formState.$.remitAddress.value = result.remitAddress;
                this.formState.$.remitAddress2.value = result.remitAddress2;
                this.formState.$.remitCity.value = result.remitCity;
                this.formState.$.remitState.value = result.remitState;
                this.formState.$.remitZip.value = result.remitZIP;

                this.formState.$.defaultPayerId.value = result.defaultPayerId;
                this.formState.$.defaultPaymentNetTermId.value = result.defaultPaymentNetTermId;

                this.formState.$.notes.value = result.notes;
                this.formState.$.noteType.value = result.noteType;
                this.formState.$.phone.value = result.phone;
                this.formState.$.defaultPayer.value = result.defaultPayer;
            } else {
                this.paymentNetTerms = await this.cacheService.getPaymentNetTerms()
            }
        });
    }
}
