import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container} from "app/modules/common/layout/components/containers";
import {BidDetailsStore} from "app/modules/company/components/load-boards/bids/details/BidDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Button, Divider, Grid, MenuItem, Stack, TextareaAutosize, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {useParams} from "react-router-dom";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {customerNoteTypeColorMap} from "app/modules/company/components/contact/customers";
import {CustomerNoteType} from "app/modules/company/api/clients.api";
import Moment from "moment";

const queryString = require('query-string');

export interface IBidComponent {
    externalOrderId: number,
    externalOrderBidId: number;
    vehiclesNumbers: number[];
    bidCreated: () => void
}

const InfoElement = (props: {name: string, value: string}) =>
    <Typography variant="caption" display="block">
        {props.name}: <b>{props.value}</b>
    </Typography>

@provider(BidDetailsStore)
@observer
export default class Index extends Component<IBidComponent, any> {
    @inject bidDetailsStore: BidDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.bidDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.bidDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.OrderBids.nav}/>;
        }

        return <Container>
            <Grid item xs={6}>
                <Stack spacing={2} direction={"column"}>
                    <Stack direction={"row"} sx={{width: "100%"}}>
                        <Stack spacing={1} direction={"column"} sx={{width: "100%"}}>
                            <InfoElement name="Company" value={this.bidDetailsStore.bidData.companyName}/>
                            {(this.bidDetailsStore.bidData.customerNote?.length > 0 || this.bidDetailsStore.bidData.customerNoteType != CustomerNoteType.None) &&
                                <Typography variant="caption" display="block" style={{ color: customerNoteTypeColorMap.get(this.bidDetailsStore.bidData.customerNoteType) }}>{this.bidDetailsStore.bidData.customerNote || "No Notes"}</Typography> }
                            <InfoElement name="Customer" value={this.bidDetailsStore.bidData.customerName}/>
                            <InfoElement name="MC" value={this.bidDetailsStore.bidData.customerMCNumber}/>
                            <br/>
                            <InfoElement name="Contact" value={this.bidDetailsStore.bidData.postedBy}/>
                            <InfoElement name="Phone" value={this.bidDetailsStore.bidData.phone}/>
                            <InfoElement name="Email" value={this.bidDetailsStore.bidData.postedByEmail}/>
                            <br/>
                            <InfoElement name="Pays" value={this.bidDetailsStore.bidData.pays?.toString() || "N/A"}/>
                        </Stack>
                        <Stack spacing={1} direction={"column"} sx={{width: "100%"}}>
                            <InfoElement name="From" value={`${this.bidDetailsStore.bidData.pickUpCity}, ${this.bidDetailsStore.bidData.pickUpState} ${this.bidDetailsStore.bidData.pickUpZip}`}/>
                            <InfoElement name="PickUp" value={this.bidDetailsStore.bidData.pickUpASAP && "ASAP" || this.bidDetailsStore.bidData.pickUpDateTime && DateTimeHelper.formatMomentDateTimeUtcToLocal(this.bidDetailsStore.bidData.pickUpDateTime) || 'N/A'}/>
                            {!this.bidDetailsStore.bidData.pickUpASAP && this.bidDetailsStore.bidData.pickUpDateTimeOffset && <InfoElement name="PickUp (Local)" value={this.bidDetailsStore.bidData.pickUpDateTime && DateTimeHelper.formatMomentDateTime(Moment.utc(this.bidDetailsStore.bidData.pickUpDateTime).add(this.bidDetailsStore.bidData.pickUpDateTimeOffset ?? 0,'hours')) || 'N/A'}/>}
                            <InfoElement name="To" value={`${this.bidDetailsStore.bidData.deliveryCity}, ${this.bidDetailsStore.bidData.deliveryState} ${this.bidDetailsStore.bidData.deliveryZip}`}/>
                            <InfoElement name="Drop" value={this.bidDetailsStore.bidData.deliverDirect && "Direct" || this.bidDetailsStore.bidData.deliveryDateTime && DateTimeHelper.formatMomentDateTimeUtcToLocal(this.bidDetailsStore.bidData.deliveryDateTime) || 'N/A'}/>
                            {!this.bidDetailsStore.bidData.deliverDirect && this.bidDetailsStore.bidData.deliveryDateTimeOffset && <InfoElement name="Drop (Local)" value={this.bidDetailsStore.bidData.deliveryDateTime && DateTimeHelper.formatMomentDateTime(Moment.utc(this.bidDetailsStore.bidData.deliveryDateTime).add(this.bidDetailsStore.bidData.deliveryDateTimeOffset ?? 0,'hours')) || 'N/A'}/>}
                            <br/>
                            <InfoElement name="Miles" value={this.bidDetailsStore.bidData.miles?.toString()}/>
                            <InfoElement name="Pieces" value={this.bidDetailsStore.bidData.pieces?.toString()}/>
                            <InfoElement name="Dims" value={this.bidDetailsStore.bidData.dims}/>
                            <InfoElement name="Weight" value={this.bidDetailsStore.bidData.weight?.toString()}/>
                        </Stack>
                    </Stack>
                    <Divider/>
                    <InfoElement name="Note" value={this.bidDetailsStore.bidData.notes || "N/A"}/>
                    {this.bidDetailsStore.bidData.driverNotes && <InfoElement name="Driver Bid Notes" value={this.bidDetailsStore.bidData.driverNotes || "N/A"}/>}
                    <Divider/>
                    <SectionSelectField
                        field={this.bidDetailsStore.formState.$.vehicleId}
                        width={"100%"}
                        label="Vehicle"
                        onChange={async () => { await this.bidDetailsStore.recalculateRoute() }}
                        items={() => this.bidDetailsStore.vehicles.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                    />
                    {this.bidDetailsStore.formState.$.vehicleId.value > 0 && <InfoElement name="SPECS" value={this.bidDetailsStore.vehicles.find(x => x.id == this.bidDetailsStore.formState.$.vehicleId.value).specs}/>}
                    {this.bidDetailsStore.formState.$.vehicleId.value > 0 && <InfoElement name="DIMS" value={this.bidDetailsStore.vehicles.find(x => x.id == this.bidDetailsStore.formState.$.vehicleId.value).dims}/>}
                    <Divider/>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Rate"}
                            field={this.bidDetailsStore.formState.$.rate}
                            width={"50%"}
                            type={"number"}
                            onChange={() => {
                                const value = Math.round((this.bidDetailsStore.formState.$.rate.value / (this.bidDetailsStore.route?.routeMiles || 1)) * 100) / 100;
                                this.bidDetailsStore.formState.$.ratePerMile.onChange(value);
                            }}
                        />
                        <SectionTextField
                            label={"Miles Out"}
                            field={this.bidDetailsStore.formState.$.milesOut}
                            width={"50%"}
                            type={"number"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"$/Mile"}
                            field={this.bidDetailsStore.formState.$.ratePerMile}
                            width={"50%"}
                            type={"number"}
                            onChange={() => {
                                const value = Math.round((this.bidDetailsStore.formState.$.ratePerMile.value * (this.bidDetailsStore.route?.routeMiles || 1)) * 100) / 100;
                                this.bidDetailsStore.formState.$.rate.onChange(value);
                            }}
                        />
                        <SectionTextField
                            label={"Time Adjustment(min) - Driver to vehicle"}
                            field={this.bidDetailsStore.formState.$.driverTime}
                            width={"50%"}
                            type={"number"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"} sx={{width: "100%"}}>
                        <LoadingButton
                            loading={this.bidDetailsStore.isLoading}
                            disabled={this.bidDetailsStore.isLoading || true}
                            variant="outlined"
                            sx={{width: "50%"}}
                        >
                            Price History
                        </LoadingButton>
                        <LoadingButton
                            loading={this.bidDetailsStore.isLoading}
                            color={"success"}
                            disabled={this.bidDetailsStore.isLoading || !(this.bidDetailsStore.formState.$.vehicleId.value > 0)}
                            variant="contained"
                            onClick={this.bidDetailsStore.recalculateRoute}
                            sx={{width: "50%"}}
                        >
                            Recalculate Route
                        </LoadingButton>
                    </Stack>
                    <Divider/>
                    {this.bidDetailsStore.route && <Stack spacing={2} direction={"row"} sx={{width: "100%"}}>
                        <Button variant={"text"} onClick={() => this.bidDetailsStore.addMessage(this.bidDetailsStore.getMilesOutText())}>{this.bidDetailsStore.getMilesOutText()}</Button>
                        <Button variant={"text"} onClick={() => this.bidDetailsStore.addMessage(this.bidDetailsStore.getPUTimeText())}>{this.bidDetailsStore.getPUTimeText()}</Button>
                        <Button variant={"text"} onClick={() => this.bidDetailsStore.addMessage(this.bidDetailsStore.getTransitionTimeSoloText())}>{this.bidDetailsStore.getTransitionTimeSoloText()}</Button>
                        <Button variant={"text"} onClick={() => this.bidDetailsStore.addMessage(this.bidDetailsStore.getTransitionTimeTeamText())}>{this.bidDetailsStore.getTransitionTimeTeamText()}</Button>
                    </Stack>}
                    <TextareaAutosize
                        minRows={10}
                        placeholder="Message"
                        value={this.bidDetailsStore.formState.$.customMessage.value}
                        onChange={(e) => this.bidDetailsStore.formState.$.customMessage.onChange(e.target.value)}
                        style={{ width: '100%' }}
                    />
                    <LoadingButton loading={this.bidDetailsStore.isLoading} disabled={this.bidDetailsStore.formState.hasError} variant="contained" onClick={this.bidDetailsStore.post}>
                        Send Bid
                    </LoadingButton>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                {this.bidDetailsStore.route && <iframe width="100%" height="900px" style={{border: 0, marginLeft: "30px"}} loading="lazy" allowFullScreen
                         src={`https://www.google.com/maps/embed/v1/directions?${this.bidDetailsStore.getGoogleMapParams()}`}></iframe>}
            </Grid>
        </Container>;
    }

    componentDidMount() {
        this.bidDetailsStore.init(this.props.externalOrderId, this.props.externalOrderBidId, this.props.vehiclesNumbers, this.props.bidCreated);
    }
}

export const IndexDetails = (props) => {
    const { id } = useParams();
    const parsed = queryString.parse(location.search);
    return <Index {...props} externalOrderBidId={id} vehiclesNumbers={parsed.vehicleNumbers} />;
}

export const IndexNewBidDetails = (props) => {
    const { id } = useParams();
    const parsed = queryString.parse(location.search);
    return <Index {...props} externalOrderId={id} vehiclesNumbers={parsed.vehicleNumbers} />;
}
