import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {RoleListModel} from "app/modules/organization/api/clients.api";
import {Link} from "react-router-dom";
import {Paths} from "app/modules/organization/stores/paths";
import React, {Component} from "react";
import {observer} from "mobx-react";
import {inject, provider} from "react-ioc";
import {RolesStore} from "app/modules/organization/components/roles/RolesStore";
import {NavigationStore} from "app/modules/organization/stores/NavigationStore";
import {MainStore} from "app/modules/organization/stores/MainStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Button, Grid, InputBase} from "@mui/material";
import {TenantClaims} from "app/modules/organization/api/clients.api";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 5,
        renderCell: (params: GridRenderCellParams<RoleListModel>) => (
            <Link to={Paths.EditRoles.nav(params.row.id)}>{params.row.name}</Link>
        ),
    }
]

@provider(RolesStore)
@observer
export default class Index extends Component<any, any> {
    @inject rolesStore: RolesStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.rolesStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.RoleEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.rolesStore.totalRows}/>
            </Grid>
            { this.mainStore.checkClaim(TenantClaims.RoleEdit) && <Grid item xs={6} textAlign={"right"}>
                <Button onClick={() => this.rolesStore.createNewRole()} variant={"contained"}>New Role</Button>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.rolesStore.filters.$.search.value}
                            onChange={(e)=>this.rolesStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.rolesStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.rolesStore.items}
                    columns={columns}
                    pageSize={this.rolesStore.pageSize}
                    rowsPerPageOptions={this.rolesStore.pageSizeOptions}
                    rowCount={this.rolesStore.totalRows}
                    page={this.rolesStore.skipRows/this.rolesStore.pageSize}
                    loading={this.rolesStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.rolesStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.rolesStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.rolesStore.init();
    }
}
