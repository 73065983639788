import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfTrackingDeviceListFilters,
    TrackingDeviceListFilters, TrackingDeviceModel
} from "app/modules/company/api/clients.api";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogInfo} from "app/modules/common/dialog/dialog.info";
import {Button} from "@mui/material";
import {DialogTrackingDevice} from "app/modules/company/components/settings/tracking/dialog.tracking";

export class TrackingDeviceStore extends BaseListStore<TrackingDeviceModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    update = async (deviceId: number) => {
        const key = "update_device";
        this.dialogStore.addDialog({
            key: key,
            component: <DialogTrackingDevice closeModal={() => this.dialogStore.closeDialog(key)} deviceId={deviceId} onSave={() => {
                this.dialogStore.closeDialog(key);
                this.loadData();
            }}/>
        });
    }

    generateInvitationCode = async () => {
        await this.wrapLoadingApiCall(async () => {
            const code = await this.companyApiClients.trackingDeviceClient.generateInvitationCode();

            const key = code;
            this.dialogStore.addDialog({
                key: key,
                component: <DialogInfo closeModal={() => this.dialogStore.closeDialog(key)} title="Auth Code" text={<div>
                    <div>Please use this code: <Button onClick={() => navigator.clipboard.writeText(code)}>{code}</Button> to authorize a new device to be used.</div>
                    <div>This code is valid for 15 mins.</div>
                </div>}/>
            });
        });
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new TrackingDeviceListFilters({search: this.filters.$.search.value});
            const [result] = await Promise.all([
                this.companyApiClients.trackingDeviceClient.getList(new DataListGetParamsOfTrackingDeviceListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
