import {Box, IconButton, Stack, Typography} from "@mui/material";
import React, {useRef} from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export interface FileBase64InputParams {
    multiple: boolean;
    label: string;
    onDone: (any) => void;
}

export const FileBase64Input = (params: FileBase64InputParams) => {
    const fileInput = useRef(null)

    const handleChange = (e) => {

        // get the files
        let files = e.target.files;

        // Process each file
        let allFiles = [];
        for (let i = 0; i < files.length; i++) {

            let file = files[i];

            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {

                // Make a fileInfo Object
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                // Push it to the state
                allFiles.push(fileInfo);

                // If all files have been proceed
                if(allFiles.length == files.length){
                    // Apply Callback function
                    if(params.multiple) params.onDone(allFiles);
                    else params.onDone(allFiles[0]);
                }

            } // reader.onload

        } // for

    }

    return <Stack direction={"row"} sx={{width: "100%"}} alignItems={"center"}>
        <Typography variant="subtitle2" component="div"><b>{params.label}:</b></Typography>
        <div>
            <Box sx={{ paddingTop: "10px" }}>
                <IconButton color="primary" onClick={() => fileInput.current.click()}>
                    <FileUploadIcon />
                </IconButton>
            </Box>
            <input
                style={{ display: "none" }}
                type="file"
                multiple={false}
                ref={fileInput}
                onChange={handleChange}
            />
        </div>
    </Stack>
}
