import {useEffect, useState} from "react";
import PubSub from 'pubsub-js';
import Events from "app/modules/common/static/EventNames";
import {Alert, Box, Stack, Typography} from "@mui/material";
import React from "react";

export const NotificationHandler = () => {
    const [messages, setMessages] = useState([]);

    let hideMessage = (key) => {
        setMessages(messages.filter(x => x.key != key));
    }

    useEffect( () => {
        let tokenOrderApiMessage = PubSub.subscribe(Events.Notification, (eventName: string, message: string) => {
            let key = new Date().valueOf();
            setMessages(messages.concat([{ key: key, title: message }]));
            setTimeout(() => hideMessage(key), 1000 * 10) as any;
        });

        let tokenOrderTasApiMessage = PubSub.subscribe(Events.OrderTaskNotification, (eventName: string, message: string) => {
            let key = new Date().valueOf();
            setMessages(messages.concat([{ key: key, title: message, severity: "warning" }]));
            setTimeout(() => hideMessage(key), 1000 * 60) as any;
        });

        return () => {
            PubSub.unsubscribe(tokenOrderApiMessage);
            PubSub.unsubscribe(tokenOrderTasApiMessage);
        };
    }, [])

    return messages && <Box style={{ position: "absolute", minWidth: "300px", right: "50px", top: "50px", zIndex: 9999 }}>
        <Stack spacing={1} direction={"column"}>
            {messages.map(x => <Alert key={x.key} severity={x.severity || "success"} onClick={() => hideMessage(x.key)}>
                <Typography variant="subtitle2">{x.title || "Notification"}</Typography>
                {x.message}
            </Alert>)}
        </Stack>
    </Box> || <React.Fragment/>;
}
