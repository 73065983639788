import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {SectionSelectChipsField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {DriverShortListModel} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";

export interface DialogDriversProps {
    closeModal: () => void;
    setVehicle: (driverIds: number[]) => void;
    driverIds: number[];
    title: string;
}

export const DialogDrivers = observer((props: DialogDriversProps) => {
    const [formState, _] = useState(new FormState({
        driverIds: new FieldState(props.driverIds || null),
    }));

    const [items, setItems] = useState<DriverShortListModel[]>();
    const [isLoading, setLoading] = useState(true);

    const store = useInstance(CacheService);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await store.getDrivers();
            setItems(data);
            setLoading(false);
        };

        fetchData();
    }, []);

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                {!isLoading ? <SectionSelectChipsField
                    field={formState.$.driverIds}
                    width={"100%"}
                    label={"Drivers"}
                    none={true}
                    items={items.map((x) => { return { id: x.id, name: x.fullName + ( x.carrierId ?  " - " + x.carrier : "") }; })}
                /> : <LinearProgress/> }
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={() => { props.setVehicle(formState.$.driverIds.value); }} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
