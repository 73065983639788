import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputBase,
    LinearProgress, List, ListItem, ListItemText,
    Stack, Typography
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    CustomerListFilters,
    CustomerListModel,
    DataListGetParamsOfCustomerListFilters,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {requiredMinLength} from "app/modules/common/form/validators";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogCustomerNew} from "app/modules/company/components/contact/dialog.customer.new";
import {MainStore} from "app/modules/company/stores/MainStore";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogSelectCustomerProps {
    closeModal: () => void;
    setCustomer: (customerId: number, name: string) => void;
    title: string;
}

export const DialogSelectCustomer = observer((props: DialogSelectCustomerProps) => {
    const [formState, _] = useState(new FormState({
        customerId: new FieldState(null),
        customerName: new FieldState(''),
        search: new FieldState("").validators(requiredMinLength(2))
    }));

    const [items, setItems] = useState<CustomerListModel[]>();
    const [isLoading, setLoading] = useState(false);

    const store = useInstance(ApiClients);
    const dialogStore = useInstance(DialogStore);
    const mainStore = useInstance(MainStore);

    const fetchData = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);
            const data = await store.contactClient.getCustomerList(new DataListGetParamsOfCustomerListFilters({
                offset: 0,
                pageSize: 100,
                filter: new CustomerListFilters({ search: formState.$.search.value })
            }));
            setItems(data.items);
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    const createNewCustomer = () => {
        const key = "new_customer";
        props.closeModal();
        dialogStore.addDialog({
            key: key,
            component: <DialogCustomerNew closeModal={() => dialogStore.closeDialog(key)} setCustomer={async (customerId, customerName: string) => {
                props.setCustomer(customerId, customerName);
                dialogStore.closeDialog(key);
            }} title="Create Customer"/>
        });
    }

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
            id="alert-dialog-description" style={{ minWidth: "400px", padding: "25px" }}
        >
            <Stack spacing={2} direction={"column"}>
                <SearchPaper width={400}>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        value={formState.$.search.value}
                        onChange={(e)=> formState.$.search.onChange(e.target.value)}
                        placeholder="Search (min 2 chars)"
                        inputProps={{ 'aria-label': 'search' }}
                        onKeyPress={e => e.key === "Enter" && fetchData()}
                        error={formState.$.search.hasError}
                    />
                    <SearchVerDivider />
                    <LoadingButton loading={isLoading} variant="text" onClick={fetchData}>Search</LoadingButton>
                </SearchPaper>
                {mainStore.checkClaim(TenantClaims.CustomerEdit) && <Button onClick={createNewCustomer}>Create New Customer</Button>}
                {isLoading ? <LinearProgress/> : <List dense={true} sx={{
                    overflow: 'auto',
                    maxHeight: 300
                }}>
                    {items?.map(x => <ListItem sx={{cursor: "pointer"}} key={x.id} selected={x.id == formState.$.customerId.value} onClick={() => {
                        formState.$.customerId.onChange(x.id);
                        formState.$.customerName.onChange(x.displayName);
                    }}>
                        <ListItemText
                            primary={x.companyName}
                            secondary={[x.name, (x.mcNumber && "MC:") + x.mcNumber, (x.uSDOT && "USDOT:") + x.uSDOT].filter(x => !!x).join(", ")}
                        />
                    </ListItem>)}
                    {!items?.length && <Typography sx={{ width: "100%" }} align={"center"} variant="subtitle2" display="block">No result.</Typography>}
                </List>}
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} disabled={!formState.$.customerId.value} variant="outlined" onClick={() => { props.setCustomer(formState.$.customerId.value, formState.$.customerName.value); }} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
