import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {email, int, required, requiredNumber} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {
    CompanyIntegrationEditModel,
    CompanyIntegrationType,
    NamedValue,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";

export class CompanyIntegrationDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject cacheService: CacheService;

    title: string = 'New Integration';
    integrationId: number;
    isActive: boolean = true;
    eldDrivers: NamedValue[] = [];
    eldVehicles: NamedValue[] = [];

    formState = new FormState({
        name: new FieldState('').validators(required),
        type: new FieldState<CompanyIntegrationType>(CompanyIntegrationType.Sylectus).validators(requiredNumber, int)
    });

    formGoogleKey = new FormState({
        apiKey: new FieldState('').validators(required),
    });

    formSmtp = new FormState({
        address: new FieldState('').validators(required),
        user: new FieldState('').validators(required),
        password: new FieldState('').validators(required),
        port: new FieldState<number>(null).validators(required, int),
    });

    formSylectus = new FormState({
        corporationId: new FieldState('').validators(required),
        corporationPassword: new FieldState('').validators(required),
        userId: new FieldState('').validators(required),
        userPassword: new FieldState('').validators(required),
    });

    formDAT = new FormState({
        corporationEmail: new FieldState('').validators(required, email),
        corporationPassword: new FieldState('').validators(required),
    });

    isReadOnlyMode: boolean = false;
    isRedirectToNewEntity: boolean = false;

    constructor() {
        super();

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.IntegrationEdit);

        makeObservable(this, {
            title: observable,
            isActive: observable,
            isRedirectToNewEntity: observable,

            init: action,
            activate: action,
            deactivate: action,
            save: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        let settings = {} as any;

        if(this.formState.$.type.value == CompanyIntegrationType.Sylectus) {
            await this.formSylectus.validate();

            if(this.formSylectus.hasError) {
                return;
            }

            settings.CorporationId = +this.formSylectus.$.corporationId.value;
            settings.CorporationPassword = this.formSylectus.$.corporationPassword.value;
            settings.UserId = +this.formSylectus.$.userId.value;
            settings.UserPassword = this.formSylectus.$.userPassword.value;
        }

        if(this.formState.$.type.value == CompanyIntegrationType.DAT) {
            await this.formDAT.validate();

            if(this.formDAT.hasError) {
                return;
            }

            settings.Email = this.formDAT.$.corporationEmail.value;
            settings.Password = this.formDAT.$.corporationPassword.value;
        }

        if(this.formState.$.type.value == CompanyIntegrationType.SMTP) {
            await this.formSmtp.validate();

            if(this.formSmtp.hasError) {
                return;
            }

            settings.Address = this.formSmtp.$.address.value;
            settings.User = this.formSmtp.$.user.value;
            settings.Password = this.formSmtp.$.password.value;
            settings.Port = +this.formSmtp.$.port.value;
        }

        if(this.formState.$.type.value == CompanyIntegrationType.GoogleApi) {
            await this.formGoogleKey.validate();

            if(this.formGoogleKey.hasError) {
                return;
            }

            settings.ApiKey = this.formGoogleKey.$.apiKey.value;
        }

        await this.wrapLoadingApiCall(async () => {
            this.integrationId = await this.companyApiClients.companyIntegrationClient.upsert(new CompanyIntegrationEditModel({
                id: this.integrationId,
                name: this.formState.$.name.value,
                type: this.formState.$.type.value,
                active: this.isActive,
                settingsJSON: JSON.stringify(settings)
            }));

            runInAction(() => {
                if(this.integrationId > 0) {
                    this.isSavedSuccessfully = true;
                } else {
                    this.isRedirectToNewEntity = true;
                }
            });
        });
    }

    activate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.companyIntegrationClient.activate(this.integrationId);
            runInAction(() => {
                this.isActive = true;
            });
        });
    }

    deactivate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.companyIntegrationClient.deactivate(this.integrationId);
            runInAction(() => {
                this.isActive = false;
            });
        });
    }

    init = async (id: number) => {
        this.integrationId = id;

        await this.wrapInitLoadingApiCall(async () => {

            if(this.integrationId > 0) {
                const result = await this.companyApiClients.companyIntegrationClient.getById(this.integrationId);

                this.title = result.name;
                this.isActive = result.active;
                this.formState.$.name.value = result.name;
                this.formState.$.type.value = result.type;

                const settings = JSON.parse(result.settingsJSON) || {};

                if(this.formState.$.type.value == CompanyIntegrationType.GoogleApi) {
                    this.formGoogleKey.$.apiKey.value = settings.ApiKey;
                }

                if(this.formState.$.type.value == CompanyIntegrationType.SMTP) {
                    this.formSmtp.$.address.value = settings.Address;
                    this.formSmtp.$.user.value = settings.User;
                    this.formSmtp.$.password.value = settings.Password;
                    this.formSmtp.$.port.value = settings.Port;
                }

                if(this.formState.$.type.value == CompanyIntegrationType.Sylectus) {
                    this.formSylectus.$.corporationId.value = settings.CorporationId;
                    this.formSylectus.$.corporationPassword.value = settings.CorporationPassword;
                    this.formSylectus.$.userId.value = settings.UserId;
                    this.formSylectus.$.userPassword.value = settings.UserPassword;
                }

                if(this.formState.$.type.value == CompanyIntegrationType.DAT) {
                    this.formDAT.$.corporationEmail.value = settings.Email;
                    this.formDAT.$.corporationPassword.value = settings.Password;
                }
            }
        });
    }
}
