import React from "react";
import {useInstance} from "react-ioc";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {observer} from "mobx-react";

export const DialogContainer = observer(() => {
    const store = useInstance(DialogStore);

    return <React.Fragment>
        {store.dialogs?.map((x) => <React.Fragment key={x.key}>{x.component}</React.Fragment>)}
    </React.Fragment>;
})
