import {observer} from "mobx-react";
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export interface DialogInfoProps {
    closeModal: () => void;
    text: React.ReactNode;
    title: string;
}

export const DialogInfo = observer((props: DialogInfoProps) => {
    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
            {props.text}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeModal}>Close</Button>
        </DialogActions>
    </Dialog>;
})
