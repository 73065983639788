import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfOrderQuoteListFilters,
    OrderListFilters, OrderQuoteModelListView
} from "app/modules/company/api/clients.api";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {ModalOrderDetails} from "app/modules/company/components/orders/details/modal.order.details";
import React from "react";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export class QuotesStore extends BaseListStore<OrderQuoteModelListView>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action,
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            await this.filters.validate();

            if(this.filters.hasError) {
                return;
            }

            let filters = new OrderListFilters({});

            const [result] = await Promise.all([
                this.companyApiClients.orderQuoteClient.getList(new DataListGetParamsOfOrderQuoteListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    postQuote = (quoteId) => {
        this.dialogStore.addConfirmDialog({
            okColor: "info",
            title: "Post this order quote",
            description: "Are you sure, you want to post this order quote?",
            cancelText: "Close",
            okText: "Post",
            okAction: async () => {
                try {
                    await this.companyApiClients.orderQuoteClient.postQuote(quoteId);
                }
                catch (ex) { ApiErrorHandler(ex); }

                await this.loadData();
            }
        });
    };

    cancelQuote = (quoteId) => {
        this.dialogStore.addConfirmDialog({
            okColor: "error",
            title: "Cancel this order quote",
            description: "Are you sure, you want to cancel this order quote?",
            cancelText: "Close",
            okText: "Cancel",
            okAction: async () => {
                try {
                    await this.companyApiClients.orderQuoteClient.cancelQuote(quoteId);
                }
                catch (ex) { ApiErrorHandler(ex); }

                await this.loadData();
            }
        });
    };

    openOrderDetails = (orderId: number) => {
        const key = "order_details_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalOrderDetails orderId={orderId} closeOrderModal={() => this.dialogStore.closeDialog(key)} /></div>
        });
    }

    async init() {
        await this.loadData()

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
