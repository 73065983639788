import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {BidEmailDetailsStore} from "app/modules/company/components/settings/bid-email/BidEmailDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {MainStore} from "app/modules/company/stores/MainStore";
import {SectionTextField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {Editor} from 'draft-js';

@provider(BidEmailDetailsStore)
@observer
export default class Index extends Component<any, any> {
    @inject bidEmailDetailsStore: BidEmailDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.bidEmailDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        return <Container>
                <PageTitleDetails Title={this.bidEmailDetailsStore.title} Size={12}/>
                <ContainerDetails>
                    <SectionName title="Email Bid Settings" description="Email Bid Settings. Please configure email templates and settings." />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <SectionTextField
                                label={"Subject Template"}
                                field={this.bidEmailDetailsStore.formEmailBidSettingsState.$.subjectTemplate}
                                fullWidth/>

                            <Typography variant="caption" display="block">
                                <b>Email Body:</b>
                            </Typography>
                            <Editor
                                editorState={this.bidEmailDetailsStore.formEmailBidSettingsState.$.bodyTemplate.value}
                                onChange={this.bidEmailDetailsStore.formEmailBidSettingsState.$.bodyTemplate.onChange} />

                            <SectionTextField
                                label={"From Display Template"}
                                field={this.bidEmailDetailsStore.formEmailBidSettingsState.$.fromDisplayTemplate}
                                fullWidth/>
                            <SectionTextField
                                label={"From Email"}
                                field={this.bidEmailDetailsStore.formEmailBidSettingsState.$.fromEmail}
                                fullWidth/>
                            <SectionTextField
                                label={"CC Email"}
                                field={this.bidEmailDetailsStore.formEmailBidSettingsState.$.ccEmail}
                                fullWidth/>
                            <LoadingButton loading={this.bidEmailDetailsStore.isLoading} disabled={this.bidEmailDetailsStore.formEmailBidSettingsState.hasError} variant="contained" onClick={this.bidEmailDetailsStore.saveSettings}>
                                Save
                            </LoadingButton>
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Preview" description="Email Preview" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Typography variant="caption" display="block">
                                <b>Subject:</b>
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {this.bidEmailDetailsStore.replaceParams(this.bidEmailDetailsStore.formEmailBidSettingsState.$.subjectTemplate.value)}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                From: <b>{this.bidEmailDetailsStore.replaceParams(this.bidEmailDetailsStore.formEmailBidSettingsState.$.fromDisplayTemplate.value)}</b>
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                CC: <b>{this.bidEmailDetailsStore.formEmailBidSettingsState.$.ccEmail.value}</b>
                            </Typography>
                            <br/>
                            <Typography variant="caption" display="block">
                                <b>Email Body:</b>
                            </Typography>
                            <Divider/>
                            <div dangerouslySetInnerHTML={{ __html: this.bidEmailDetailsStore.replaceParams(this.bidEmailDetailsStore.formEmailBidSettingsState.$.bodyTemplate.value.getCurrentContent().getPlainText("</br>")) }} />
                            <LoadingButton loading={this.bidEmailDetailsStore.isLoading} disabled={this.bidEmailDetailsStore.formEmailBidSettingsState.hasError} variant="contained" onClick={this.bidEmailDetailsStore.sendTestEmail}>
                                Send Test Email
                            </LoadingButton>
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Params" description="Email Params" />
                    <Section>
                        <Stack spacing={0} direction={"column"}>
                            {this.bidEmailDetailsStore.params.map(x =>
                                <div key={x.param}><Button key={x.param} variant={"text"} sx={{ fontSize: "12px", minWidth: 0 }} onClick={() => navigator.clipboard.writeText(x.param)} title={x.param}>{x.param}</Button>:
                                    {x.param == '[CompanyLogo]' ? <img src={x.example}/> : x.example}
                                </div>)}
                        </Stack>
                    </Section>
                </ContainerDetails>
        </Container>;
    }

    async componentDidMount() {
        await this.bidEmailDetailsStore.init();
    }
}
