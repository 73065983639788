import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {DriverDetailsStore} from "app/modules/company/components/drivers/details/DriverDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Grid, MenuItem, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {FileInput} from "app/modules/company/components/file/file-uploader";
import {FileItemType, FileType} from "app/modules/company/api/clients.api";
import {ActivateDeactivateSection} from "app/modules/common/details/activate-section";
import {SectionDatePickerField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";

@provider(DriverDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject driverDetailsStore: DriverDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.driverDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.driverDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Drivers.nav}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.driverDetailsStore.title} Size={!this.driverDetailsStore.isReadOnlyMode ? 8 : 12}/>
                {!this.driverDetailsStore.isReadOnlyMode && <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.driverDetailsStore.isLoading} disabled={this.driverDetailsStore.formState.hasError} variant="contained" onClick={this.driverDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>}
                <ContainerDetails>
                    <SectionName title="Basic Info" description="Driver Basic Info" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <SectionTextField
                                label={"Full Name"}
                                isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                field={this.driverDetailsStore.formState.$.fullName}
                                fullWidth={true}
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Phone"}
                                    isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                    field={this.driverDetailsStore.formState.$.phone}
                                    width={"50%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"Email"}
                                    isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                    field={this.driverDetailsStore.formState.$.email}
                                    width={"50%"}
                                    type={"email"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionSelectField
                                    field={this.driverDetailsStore.formState.$.carrierId}
                                    isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                    width={"50%"}
                                    label={"Carrier"}
                                    none={true}
                                    onChange={() => this.driverDetailsStore.formState.$.vehicleId.onChange(null) }
                                    items={() => this.driverDetailsStore.carrier.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                                />
                                <SectionSelectField
                                    field={this.driverDetailsStore.formState.$.vehicleId}
                                    isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                    width={"50%"}
                                    label={"Default Vehicle"}
                                    none={true}
                                    items={() => {
                                        let carrierId = this.driverDetailsStore.formState.$.carrierId.value == "" ? null : this.driverDetailsStore.formState.$.carrierId.value

                                        return this.driverDetailsStore.vehicles.filter(x =>x.carrierId == carrierId).map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>);
                                    }}
                                />
                            </Stack>
                            <SectionTextField
                                label={"Note"}
                                isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                field={this.driverDetailsStore.formState.$.note}
                                fullWidth={true}
                            />
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="DL/MED" description="Information regards Driver License and Medical Exam" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionDatePickerField
                                    isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                    field={this.driverDetailsStore.formState.$.medicalExpiration}
                                    label="Med. Exp."
                                    width={"50%"}
                                    minDate={new Date()}
                                />
                                <SectionDatePickerField
                                    isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                    field={this.driverDetailsStore.formState.$.driverLicenseExpiration}
                                    label="DL Exp."
                                    width={"50%"}
                                    minDate={new Date()}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionSelectField
                                    field={this.driverDetailsStore.formState.$.driverLicenseState}
                                    isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                    width={"50%"}
                                    label={"DL State"}
                                    items={() => this.mainStore.tenantData.states.map((x) => <MenuItem value={x.code} key={x.code}>{x.name}</MenuItem>)}
                                />
                                <SectionTextField
                                    label={"DL"}
                                    isReadOnlyMode={this.driverDetailsStore.isReadOnlyMode}
                                    field={this.driverDetailsStore.formState.$.driverLicense}
                                    width={"50%"}
                                />
                            </Stack>
                            {this.driverDetailsStore.driverId > 0 && <Stack spacing={2} direction={"row"}>
                                <FileInput fileType={FileType.DriverLicense} fileItemType={FileItemType.Driver} objectId={this.driverDetailsStore.driverId}/>
                                <FileInput fileType={FileType.DriverMedicalExam} fileItemType={FileItemType.Driver} objectId={this.driverDetailsStore.driverId}/>
                            </Stack>}
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Additional Docs" description="Information regards Driver Agreement/Certificates" />
                    <Section>
                        {this.driverDetailsStore.driverId > 0 && <Stack spacing={2} direction={"row"}>
                            <FileInput fileType={FileType.Agreement} fileItemType={FileItemType.Driver} objectId={this.driverDetailsStore.driverId}/>
                            <FileInput fileType={FileType.W9} fileItemType={FileItemType.Driver} objectId={this.driverDetailsStore.driverId}/>
                        </Stack>}
                        {this.driverDetailsStore.driverId > 0 && <Stack spacing={2} direction={"row"}>
                            <FileInput fileType={FileType.VoidCheck} fileItemType={FileItemType.Driver} objectId={this.driverDetailsStore.driverId}/>
                            <FileInput fileType={FileType.Photo} fileItemType={FileItemType.Driver} objectId={this.driverDetailsStore.driverId}/>
                        </Stack>}
                        {this.driverDetailsStore.driverId > 0 && <FileInput fileType={FileType.Certificates} fileItemType={FileItemType.Driver} objectId={this.driverDetailsStore.driverId}/>}
                    </Section>
                    {!this.driverDetailsStore.isReadOnlyMode && this.driverDetailsStore.driverId > 0 && <ActivateDeactivateSection isActive={this.driverDetailsStore.isActive} isLoading={this.driverDetailsStore.isLoading} activate={this.driverDetailsStore.activate} deactivate={this.driverDetailsStore.deactivate} />}
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.driverDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
