import {SectionSelectChipsField} from "app/modules/common/details/text-field";
import React, {useEffect, useState} from "react";
import {FieldState} from "formstate";
import {useInstance} from "react-ioc";
import {CacheService} from "app/modules/company/services/CacheService";

export const VehicleFilter = (props: {value: FieldState<any[]>, width?: string }) => {
    const [vehicles, setVehicle] = useState([]);
    let cacheService = useInstance(CacheService);

    useEffect(() => {
        const fetchData = async () => {
            setVehicle(await cacheService.getVehicles())
        };

        fetchData();
    },[]);

    return <SectionSelectChipsField
        field={props.value}
        label={"Vehicles"}
        width={props.width}
        none={true}
        items={vehicles.map((x) => { return { id: x.id, name: '#' + x.number + ( x.carrierId ?  " - " + x.carrierName : "") }; })}
    />;
}
