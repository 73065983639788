import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    Grid,
    InputBase,
    Stack,
    Typography
} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {BidsStore} from "app/modules/company/components/load-boards/bids/BidsStore";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {
    BidModelListView,
    ExternalOrderBidStatus, OrderSourceType, OrderTaskModel,
    TenantClaims,
} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {MainStore} from "app/modules/company/stores/MainStore";
import {VehicleFilter} from "app/modules/company/components/filters/vehicle";
import {BidStatusFilter} from "app/modules/company/components/filters/bid-status";
import {CarrierRating} from "app/modules/company/components/carriers/carrier-rating";
import {LocationLink} from "app/modules/common/details/location-link";
import {MouseOverText} from "app/modules/common/dialog/mouse-over-text";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import {PusherTenantService} from "app/modules/common/pusher/PusherTenantService";

export const ExternalOrderBidStatusNameMap: Map<ExternalOrderBidStatus, string> = new Map<ExternalOrderBidStatus, string>([
    [ExternalOrderBidStatus.Requested, "Requested"],
    [ExternalOrderBidStatus.Rejected, "Rejected"],
    [ExternalOrderBidStatus.Processed, "Processed"]
]);

const Status = (props: {status: ExternalOrderBidStatus, orderId: number}) => {
    let background = "#ffd385";

    if(props.orderId > 0) {
        background = "#4caf50";
    }

    if(props.status == ExternalOrderBidStatus.Rejected){
        background = "#fdb1b1";
    }

    if(props.status == ExternalOrderBidStatus.Requested){
        background = "#e3781b";
    }

    return <Box sx={{ width: "5px", height: "100%", backgroundColor: background }} />;
}


const columns = [
    {
        field: 'status',
        headerName: '',
        maxWidth: 30,
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <Status status={params.row.status} orderId={params.row.orderId}/>
        ),
    },
    {
        field: 'customer',
        headerName: 'Customer',
        minWidth: 250,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>
                <div>{params.row.status == ExternalOrderBidStatus.Requested ? <Typography variant="subtitle2" display="block" sx={{ fontWeight: "bolder" }}>{params.row.customer}</Typography> : params.row.customer}</div>
                {params.row.customerMC && <Typography variant={"body2"} sx={{ fontSize: "12px" }}>MC:<b> {params.row.customerMC}</b></Typography>}
            </div>
        ),
    },
    {
        field: 'source',
        headerName: 'Source',
        minWidth: 70,
        type: 'number',
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>{params.row.orderSourceType == OrderSourceType.Internal ? "Internal" : "External"}</div>
        ),
    },
    {
        field: 'pays',
        headerName: 'Pays',
        minWidth: 110,
        type: 'number',
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>{params.row.pays && NumberHelper.formatUSDollar(params.row.pays) || "N/A"}</div>
        ),
    },
    {
        field: 'rate',
        headerName: 'Rate/Notes/Bid',
        minWidth: 150,
        type: 'number',
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <Stack>
                <MouseOverText text={params.row.driverNotes}>{params.row.carrierRate && NumberHelper.formatUSDollar(params.row.carrierRate) || "N/A"}</MouseOverText>
                {params.row.rate && params.row.carrierRate != params.row.rate && <div><b>{NumberHelper.formatUSDollar(params.row.rate)}</b></div>}
            </Stack>
        ),
    },
    {
        field: 'miles',
        headerName: 'Miles',
        minWidth: 70,
        type: 'number',
        flex: 1
    },
    {
        field: 'milesOut',
        headerName: 'Miles Out',
        minWidth: 100,
        type: 'number',
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>
                <div>{params.row.milesOut}</div>
                {params.row.vehicleLat != null && params.row.vehicleLon != null && <LocationLink location={`${params.row.vehicleLat}, ${params.row.vehicleLon}`} locationDisplay="Location"/>}
            </div>
        ),
    },
    {
        field: 'vehicle',
        headerName: 'Vehicle',
        minWidth: 300,
        flex: 2,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>
                <Stack direction={"column"}>
                    <div>
                        <b>{params.row.vehicle}</b> {params.row.carrier?.length > 0 && <Typography variant="caption" display="span">{params.row.carrier}</Typography>}
                    </div>
                    <CarrierRating
                        vehicleRating={params.row.vehicleRating}
                        vehicleCustomPeriodRating={params.row.vehicleCustomPeriodRating}
                        carrierRating={params.row.rating}
                        carrierCustomPeriodRating={params.row.customPeriodRating}
                    />
                </Stack>
            </div>
        ),
    },
    {
        field: 'vehicleType',
        headerName: 'Type/Dims',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>
                <div>{StringHelper.formatNull(params.row.vehicleType)}</div>
                <div><b>{params.row.dims}</b> ({params.row.pieces || "N"} pc)</div>
            </div>
        ),
    },
    {
        field: 'weight',
        headerName: 'Weight (LB)',
        minWidth: 100,
        flex: 1
    },
    {
        field: 'orderNumber',
        headerName: 'Order #',
        minWidth: 100,
        flex: 1
    },
    {
        field: 'pickUp',
        headerName: 'Pick Up',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>
                <div>
                    {params.row.pickUpAddress}
                </div>
                <div>
                    <b>{params.row.pickUpASAP && "ASAP" || params.row.pickUpDateTime && DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.pickUpDateTime) || 'N/A'}</b>
                </div>
            </div>
        ),
    },
    {
        field: 'delivery',
        headerName: 'Delivery',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>
                <div>
                    {params.row.deliveryAddress}
                </div>
                <div>
                    <b>{params.row.deliverDirect && "Direct" || params.row.deliveryDateTime && DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.deliveryDateTime) || 'N/A'}</b>
                </div>
            </div>
        ),
    },
    {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderTaskModel>) => (
            <div>
                <div>{DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.createdAt)}</div>
                <b>{params.row.createdAt.fromNow()}</b>
            </div>
        ),
    },
    {
        field: 'isBidSend',
        headerName: 'Bid Sent',
        minWidth: 40,
        flex: 2,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => (
            <div>
                <div>{StringHelper.formatYesNo(params.row.isBidSend)}</div>
                <div>{params.row.isBidSend && params.row.sentBy && <b>{params.row.sentBy}</b>}</div>
            </div>
        ),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => {
            const store = useInstance(MainStore);
            const bidStore = useInstance(BidsStore);

            return <Stack direction={"row"} spacing={2}>
                {params.row.status == ExternalOrderBidStatus.Processed && !params.row.orderId && store.checkClaim(TenantClaims.BidWon) && <Button variant="outlined" size="small" onClick={() => bidStore.openWonDialog(params.row.id)}>Won</Button>}
                {params.row.isBidSend && params.row.status == ExternalOrderBidStatus.Rejected && !params.row.orderId && store.checkClaim(TenantClaims.BidForceWon) && <Button size="small" onClick={() => bidStore.openWonDialog(params.row.id)}>Create Order</Button>}
                {params.row.orderSourceType == OrderSourceType.External && params.row.status == ExternalOrderBidStatus.Requested && store.checkClaim(TenantClaims.BidCreate) && <Button size="small" onClick={() => bidStore.openBid(params.row.id)}>Review</Button>}
                {params.row.orderSourceType == OrderSourceType.External && params.row.status == ExternalOrderBidStatus.Rejected && !params.row.isBidSend && store.checkClaim(TenantClaims.BidForceWon) && <Button size="small" onClick={() => bidStore.openBid(params.row.id)}>Review</Button>}
                {params.row.status == ExternalOrderBidStatus.Processed && !params.row.orderId && !store.checkClaim(TenantClaims.BidForceWon) && !store.checkClaim(TenantClaims.BidForceWon) && "-"}
                {params.row.status != ExternalOrderBidStatus.Rejected && !params.row.orderId && store.checkClaim(TenantClaims.BidReject) && <Button color="error" size="small" onClick={() => bidStore.reject(params.row.id)}>Reject</Button>}
                {params.row.orderId && store.checkClaim(TenantClaims.OrderListView) && <Button onClick={() => bidStore.openOrderDetails(params.row.orderId)}>Order Details</Button>}
                {params.row.orderId && !store.checkClaim(TenantClaims.OrderListView) && "-"}
            </Stack>;
        },
    },
];

@provider(BidsStore)
@observer
export default class Index extends Component<any, any> {
    @inject bidsStore: BidsStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;
    @inject pusherTenantService: PusherTenantService;

    render() {
        if(this.bidsStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.bidsStore.totalRows}/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <Stack direction={"row"}>
                        <SearchPaper width={400}>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.bidsStore.filters.$.search.value}
                                onChange={(e)=>this.bidsStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.bidsStore.applyFilters}>Search</Button>
                        </SearchPaper>
                        {false && <Button variant={"text"} size={"small"} sx={{ ml: 2 }} onClick={this.bidsStore.showHideAdvanceStore}>Advance Filters</Button>}
                    </Stack>
                </BoxCenter>
            </DataGridHeader>
            <Grid item xs={12}>
                <Box sx={{ mb: 1, width: "100%", backgroundColor: "#fcfcfc" }}>
                    <Stack direction={"row"} spacing={2} sx={{ width: "100%" }} alignItems="center" justifyContent="center">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.bidsStore.enableAutoRefresh} onChange={(e) => this.bidsStore.switchAutoRefresh(e.target.checked)} />}
                            label="Auto Refresh (15sec)"
                        />
                        <div><BidStatusFilter value={this.bidsStore.filters.$.statuses} width={"300px"} /></div>
                        <div><VehicleFilter value={this.bidsStore.filters.$.vehicleIds} width={"300px"} /></div>
                    </Stack>
                </Box>
            </Grid>
            <DataGridBody openFilter={true}>
                <DataGridPro
                    disableVirtualization
                    rows={this.bidsStore.items}
                    columns={columns}
                    pageSize={this.bidsStore.pageSize}
                    rowsPerPageOptions={this.bidsStore.pageSizeOptions}
                    rowCount={this.bidsStore.totalRows}
                    page={this.bidsStore.skipRows/this.bidsStore.pageSize}
                    loading={this.bidsStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.bidsStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.bidsStore.setPageSize(pageSize) }/>
            </DataGridBody>

            <Dialog
                open={this.bidsStore.isWonDialogOpen}
                onClose={this.bidsStore.closeWonDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Did this bid win?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to mark this bid as Won and create a new order based on this bid?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.bidsStore.closeWonDialog}>Close</Button>
                    <Button variant="outlined" onClick={this.bidsStore.won} autoFocus>
                        New Order
                    </Button>
                </DialogActions>
            </Dialog>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.bidsStore.init();
        this.pusherTenantService.bindToTenantChannel("BidIsCreatedByOwner", this.updateBid);
    }

    updateBid() {
        if(this.bidsStore.enableAutoRefresh) {
            this.bidsStore.loadData();
        }
    }

    async componentWillUnmount() {
        this.pusherTenantService.unbindToTenantChannel("BidIsCreatedByOwner", this.updateBid);
    }
}

