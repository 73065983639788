import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {AdminApiClients} from "app/modules/admin/services/AxiosBaseClient";
import {IOrganizationDetailsModel, UserListModel} from "app/modules/admin/api/clients.api";

export class OrganizationDetailsStore extends BaseLoadingStore {
    @inject adminApiClients: AdminApiClients;

    model: IOrganizationDetailsModel = null;
    owners: UserListModel[];

    TabDetails: string = "Details";
    TabUsers: string = "Users";
    TabTenants: string = "Tenants";
    selectedTab: string = this.TabDetails;

    constructor() {
        super();

        makeObservable(this, {
            selectedTab: observable,
            model: observable,

            init: action,
            selectTab: action,
            deactivate: action,
            activate: action,
            delete: action
        });
    }

    init = async (id: number) => {
        this.wrapInitLoadingApiCall(async () => {
            const [details, owners] = await Promise.all([
                this.adminApiClients.tenantManagementClient.getOrganizationDetails(id),
                this.adminApiClients.usersClient.getOrganizationOwners(id)
            ])

            runInAction(() => {
                this.model = details;
                this.owners = owners;
            })
        });
    }

    selectTab = (tab: string) => this.selectedTab = tab;

    activate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.adminApiClients.tenantManagementClient.activateOrganization(this.model.id);
            runInAction(() => {
                this.model.active = true;
            });
        });
    }

    deactivate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.adminApiClients.tenantManagementClient.deactivateOrganization(this.model.id);
            runInAction(() => {
                this.model.active = false;
            });
        });
    }

    delete = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.adminApiClients.tenantManagementClient.deleteOrganization(this.model.id);
            runInAction(() => {
                this.isSavedSuccessfully = true;
            });
        });
    }
}
