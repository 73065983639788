import Pusher from 'pusher-js';

export class PusherTenantService {
    pusher: Pusher = null;
    tenantId: number = null;

    presenceChannel: any = null;
    tenantChannel: any = null;
    pusherNamespace: string = null;

    init = (appKey, cluster, pusherNamespace, channelAuthorization, tenantId: number) => {
        this.tenantId = tenantId;
        this.pusherNamespace = pusherNamespace;
        this.pusher = new Pusher(appKey, {
            cluster: cluster,
            channelAuthorization:  {
                endpoint: channelAuthorization,
                transport: "ajax",
                /*headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }*/
            }
        });

        this.subscribePresence();
        this.subscribeToTenantChannel();
    }

    subscribeToTenantChannel = () => {
        this.tenantChannel = this.subscribePrivate();
    }

    subscribePrivate = (channel: string = null, userId = null) => {
        return this.pusher.subscribe(this.getChannelName('private', channel, userId));
    }

    subscribePresence = () => {
        this.presenceChannel = this.pusher.subscribe(this.getChannelName('presence'));
    }

    bindToTenantChannel = (event: string, callback) => {
        this.tenantChannel?.bind(event, callback);
    }

    unbindToTenantChannel = (event: string, callback) => {
        this.tenantChannel?.unbind(event, callback);
    }

    getChannelName = (prefix, channel = null, userId = null) => [prefix, this.pusherNamespace, channel, this.tenantId, userId || 'all'].filter(x => x != null).join('-')
}
