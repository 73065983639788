import {observer} from "mobx-react";
import React, {Component} from "react";
import {Route, Routes} from "react-router-dom";
import OrderLinkIndex from "app/modules/external/order-link/index";
import {provider} from "react-ioc";
import {OrderLinkStore} from "app/modules/external/order-link/OrderLinkStore";
import {ApiClients} from "app/modules/external/services/AxiosBaseClient";

@provider(OrderLinkStore, ApiClients)
@observer
export class ExternalIndex extends Component<any, any> {
    render() {
        return <React.Fragment>
            <Routes>
                <Route path={"order-link/:link"} element={<OrderLinkIndex/>}/>
            </Routes>
        </React.Fragment>
    }
}
