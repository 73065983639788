import {
    AppBar,
    Box,
    IconButton,
    Paper,
    Toolbar,
    Typography
} from "@mui/material";
import React from "react";
import {AccountCircle} from "@mui/icons-material";

export function MainView(props: {Title: React.ReactNode, Menu: React.ReactNode, Content: React.ReactNode}) {
    return <React.Fragment>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {props.Title}
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => {}}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Paper sx={{ width: 200, height: "calc(100vh - 64px)", position: "absolute", borderRadius: "0", overflow: "auto"}}>
                {props.Menu}
            </Paper>
            <Box sx={{marginLeft: "200px", height: "calc(100vh - 64px)", overflow: "auto", backgroundColor: "#f0f0f0"}}>
                {props.Content}
            </Box>
        </Box>
    </React.Fragment>;
}

