import {observer} from "mobx-react";
import React from "react";
import {RadarStore} from "app/modules/company/components/load-boards/radar/RadarStore";
import {inject} from "react-ioc";
import {Button, Grid, MenuItem, Modal, Stack, TextField, Typography} from "@mui/material";
import {ModalContent} from "app/modules/common/layout/modal/modal-content";
import {
    SectionSelectChipsField,
    SectionSelectField,
    SectionTextField,
    SectionToggleField
} from "app/modules/common/details/text-field";
import {DateTimePicker} from "@mui/lab";

@observer
export class ModalVehicleSettingsEdit extends React.Component<any, any> {
    @inject radarStore: RadarStore;

    render () {
        return <Modal
            open={this.radarStore.openEditVehicleSettings}
            onClose={() => { this.radarStore.closeEditVehicleSettingsModal(); }}
        >
            <div>
                <ModalContent>
                    <Grid item xs={12}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Update Settings - #{this.radarStore.selectedVehicleNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 4, pb: 4 }}>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Max Miles"}
                                    field={this.radarStore.vehicleSettings.$.maxMiles}
                                    width={"50%"}
                                    type={"number"}
                                />
                                <SectionTextField
                                    label={"PickUp Radius"}
                                    field={this.radarStore.vehicleSettings.$.pickUpRadius}
                                    width={"50%"}
                                    type={"number"}
                                />
                            </Stack>
                            <SectionSelectChipsField
                                field={this.radarStore.vehicleSettings.$.states}
                                width={"100%"}
                                label={"States"}
                                items={this.radarStore.states}
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionSelectField
                                    field={this.radarStore.vehicleSettings.$.status}
                                    width={"50%"}
                                    label={"Status"}
                                    items={() => this.radarStore.vehicleAvailabilityStatuses.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                                />
                                <DateTimePicker
                                    label="AvailableAt"
                                    renderInput={(params) => <TextField variant="standard" {...params} />}
                                    value={this.radarStore.vehicleSettings.$.availableAt.value}
                                    onChange={this.radarStore.vehicleSettings.$.availableAt.onChange}
                                    minutesStep={15}
                                />
                            </Stack>
                            <SectionToggleField
                                field={this.radarStore.vehicleSettings.$.partial}
                                label="Look for a Partial load?"
                            />
                            {!this.radarStore.vehicleSettings.$.hasIntegration.value &&
                                <SectionTextField
                                    label="Location"
                                    field={this.radarStore.vehicleSettings.$.location}
                                    fullWidth
                                />}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="text" onClick={() => { this.radarStore.closeEditVehicleSettingsModal(); }}>Close</Button>
                        <Button color="primary" variant="contained" onClick={() => { this.radarStore.saveVehicleSettings(); }}>Save</Button>
                    </Grid>
                </ModalContent>
            </div>
        </Modal>
    }
}
