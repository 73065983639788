import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {
    Box,
    Button,
    Grid,
    InputBase,
    Stack
} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {
    OrderQuoteModelListView,
    OrderQuoteStatus,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";

import {QuotesStore} from "app/modules/company/components/quotes/all/QuotesStore";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {Paths} from "app/modules/company/stores/paths";
import {Link} from "react-router-dom";
import {VehicleTypesMap} from "app/modules/company/components/vehicles/details/VehicleDetailsStore";

export const orderQuoteStatusColorMap: Map<OrderQuoteStatus, string> = new Map<OrderQuoteStatus, string>([
    [OrderQuoteStatus.Open, "#eaba02"],
    [OrderQuoteStatus.Canceled, "#ff1744"],
    [OrderQuoteStatus.Covered, "#4caf50"]
]);

const columns = [
    {
        field: 'status',
        headerName: '',
        maxWidth: 30,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => (
            <Box sx={{ width: "5px", height: "100%", backgroundColor: orderQuoteStatusColorMap.get(params.row.status) }} />
        ),
    },
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => <Link to={Paths.EditQuote.nav(params.row.id)}>
            {params.row.id}
        </Link>,
    },
    {
        field: 'bids',
        headerName: 'Bids',
        maxWidth: 80,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => (
            <div>
                <div><b>{params.row.bids || "-"}</b></div>
            </div>
        ),
    },
    {
        field: 'from',
        headerName: 'From',
        minWidth: 200,
        flex: 1,
        valueGetter: (params: GridRenderCellParams<OrderQuoteModelListView>) =>
            `${params.row.from || 'N/A'}`,
    },
    {
        field: 'to',
        headerName: 'To',
        minWidth: 200,
        flex: 1,
        valueGetter: (params: GridRenderCellParams<OrderQuoteModelListView>) =>
            `${params.row.to || 'N/A'}`,
    },
    {
        field: 'pickupdate',
        headerName: 'PickUp',
        width: 90,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => (
            <div>
                <b><div>{params.row.pickUpASAP ? "ASAP" : DateTimeHelper.formatMomentDateTimeShortUtcToLocal(params.row.pickUpDateTime)}</div></b>
            </div>
        ),
    },
    {
        field: 'dropdate',
        headerName: 'Drop',
        width: 90,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => (
            <div>
                <b><div>{params.row.deliverDirect ? "Direct": DateTimeHelper.formatMomentDateTimeShortUtcToLocal(params.row.deliveryDateTime)}</div></b>
            </div>
        ),
    },
    {
        field: 'vehicleType',
        headerName: 'VehicleType',
        minWidth: 80,
        flex: 1,
        valueGetter: (params: GridRenderCellParams<OrderQuoteModelListView>) =>
            VehicleTypesMap.get(params.row.vehicleType),
    },
    {
        field: 'miles',
        headerName: 'Miles',
        maxWidth: 90,
        type: 'number',
        flex: 1
    },
    {
        field: 'customer',
        headerName: 'Customer',
        minWidth: 300,
        flex: 2,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => (
            <div>
                <div>{params.row.customer || 'N/A'}</div>
            </div>
        ),
    },
    {
        field: 'orderNumber',
        headerName: 'PO',
        width: 100,
        flex: 1,
        valueGetter: (params: GridRenderCellParams<OrderQuoteModelListView>) =>
            `${StringHelper.formatNull(params.row.orderNumber)}`,
    },
    {
        field: 'weight',
        headerName: 'Weight',
        width: 110,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => (
            <div>
                <div><b>{params.row.weight}</b> lb</div>
                <div><b>{params.row.pieces}</b> pc</div>
            </div>
        ),
    },
    {
        field: 'lastPostedAt',
        headerName: 'PostedAt',
        width: 90,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => (
            <div>
                <b>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(params.row.lastPostedAt)}</b>
            </div>
        ),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderQuoteModelListView>) => {
            const store = useInstance(MainStore);
            const quotesStore = useInstance(QuotesStore);

            return <Stack direction={"row"} spacing={2}>
                {params.row.status == OrderQuoteStatus.Open && store.checkClaim(TenantClaims.OrderQuoteEdit) && <Button variant="outlined" size="small" onClick={() => quotesStore.postQuote(params.row.id)}>{params.row.lastPostedAt ? "Re-Post" : "Post" }</Button>}
                {params.row.status == OrderQuoteStatus.Open && store.checkClaim(TenantClaims.OrderQuoteEdit) && <Button variant="outlined" color="error" size="small" onClick={() => quotesStore.cancelQuote(params.row.id)}>Cancel</Button>}
                {params.row.orderId && store.checkClaim(TenantClaims.OrderListView) && <Button onClick={() => quotesStore.openOrderDetails(params.row.orderId)}>Order Details</Button>}
                {params.row.status == OrderQuoteStatus.Canceled && "-"}
            </Stack>
        },
    }
];

@provider(QuotesStore)
@observer
export default class Index extends Component<any, any> {
    @inject quotesStore: QuotesStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.quotesStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.OrderQuoteEdit) ? 8 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.quotesStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.OrderQuoteEdit) && <Grid item xs={4} textAlign={"right"}>
                <BtnLink Link={Paths.NewQuote.nav} Text="New Quote"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <Stack direction={"row"}>
                        <SearchPaper width={400}>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.quotesStore.filters.$.search.value}
                                onChange={(e)=>this.quotesStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.quotesStore.applyFilters}>Search</Button>
                        </SearchPaper>
                    </Stack>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.quotesStore.items}
                    columns={columns}
                    pageSize={this.quotesStore.pageSize}
                    rowsPerPageOptions={this.quotesStore.pageSizeOptions}
                    rowCount={this.quotesStore.totalRows}
                    page={this.quotesStore.skipRows/this.quotesStore.pageSize}
                    loading={this.quotesStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.quotesStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.quotesStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.quotesStore.init();
    }
}
