import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {int, required} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {
    OrderQuoteModel,
    OrderQuoteVisibilityType,
    TenantClaims,
    VehicleType
} from "app/modules/company/api/clients.api";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import * as moment from "moment";
import {DialogSelectCustomer} from "app/modules/company/components/orders/details/dialog.customer";

export class OrderQuoteDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject dialogStore: DialogStore;

    title: string = 'New Quote';
    orderQuoteId?: number = null;

    customer: string = null;

    formState = new FormState({
        rate: new FieldState(null),
        customerId: new FieldState(null).validators(required, int),
        vehicleType: new FieldState(VehicleType.LargeStraight).validators(required),
        orderNumber: new FieldState<string>(null),

        pickUpZip: new FieldState(null).validators(required),
        pickUpASAP: new FieldState<boolean>(false),
        pickUpDateTime: new FieldState<moment.Moment>(null).validators((value => { return !this.formState.$.pickUpASAP.value && !value ? "Required" : null; })),
        pickUpInside: new FieldState<boolean>(false),

        deliveryZip: new FieldState(null).validators(required),
        deliveryDirect: new FieldState<boolean>(false),
        deliveryDateTime: new FieldState<moment.Moment>(null).validators((value => { return !this.formState.$.deliveryDirect.value && !value ? "Required" : null; })),
        deliveryInside: new FieldState<boolean>(false),

        pieces: new FieldState(null).validators(required, int),
        weight: new FieldState(null).validators(required, int),
        dims: new FieldState<string>(null),

        notes: new FieldState<string>(null),

        dockLevel: new FieldState<boolean>(true),
        hazardous: new FieldState<boolean>(false),
        liftGate: new FieldState<boolean>(true),
        palletJack: new FieldState<boolean>(true),

        overrideDriversPreferences: new FieldState<boolean>(false)
    });

    isReadOnlyMode: boolean = false;

    constructor() {
        super();

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.OrderQuoteEdit);

        makeObservable(this, {
            title: observable,
            customer: observable,

            init: action,
            save: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            let result = await this.companyApiClients.orderQuoteClient.upsertQuote(new OrderQuoteModel({
                id: this.orderQuoteId,
                visibility: OrderQuoteVisibilityType.All,
                rate: this.formState.$.rate.value,
                customerId: this.formState.$.customerId.value,
                vehicleType: this.formState.$.vehicleType.value,
                orderNumber: this.formState.$.orderNumber.value,
                pickUpZip: this.formState.$.pickUpZip.value,
                pickUpASAP: this.formState.$.pickUpASAP.value,
                pickUpDateTime: this.formState.$.pickUpDateTime.value,
                pickUpInside: this.formState.$.pickUpInside.value,
                deliveryZip: this.formState.$.deliveryZip.value,
                deliverDirect: this.formState.$.deliveryDirect.value,
                deliveryDateTime: this.formState.$.deliveryDateTime.value,
                deliveryInside: this.formState.$.deliveryInside.value,
                pieces: this.formState.$.pieces.value,
                weight: this.formState.$.weight.value,
                dims: this.formState.$.dims.value,
                notes: this.formState.$.notes.value,
                dockLevel: this.formState.$.dockLevel.value,
                hazardous: this.formState.$.hazardous.value,
                liftGate: this.formState.$.liftGate.value,
                palletJack: this.formState.$.palletJack.value,
                overrideDriversPreferences: this.formState.$.overrideDriversPreferences.value
            }));

            runInAction(() => {
                if(this.orderQuoteId > 0) {
                    this.init(this.orderQuoteId);
                } else {
                    this.orderQuoteId = result;
                    this.isSavedSuccessfully = true;
                }
            })
        });
    }

    changeCustomer = () => {
        const key = "quote_" + this.orderQuoteId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSelectCustomer closeModal={() => this.dialogStore.closeDialog(key)} setCustomer={async (customerId, name) => {
                await this.wrapLoadingApiCall(async () => {
                    runInAction(() => {
                        this.formState.$.customerId.onChange(customerId);
                        this.customer = name;
                    });

                    this.dialogStore.closeDialog(key);
                });
            }} title="Update Customer"/>
        });
    }

    init = async (id: number) => {
        this.orderQuoteId = id;

        this.resetSavedSuccessfully();

        await this.wrapInitLoadingApiCall(async () => {
            if(this.orderQuoteId > 0) {
                const result = await this.companyApiClients.orderQuoteClient.getQuoteDetails(this.orderQuoteId);

                this.title = `Quote #${this.orderQuoteId}`;

                this.formState.$.rate.value =  result.rate;
                this.formState.$.customerId.value = result.customerId;
                this.formState.$.vehicleType.value = result.vehicleType;
                this.formState.$.orderNumber.value = result.orderNumber;

                this.formState.$.pickUpZip.value = result.pickUpZip;
                this.formState.$.pickUpASAP.value = result.pickUpASAP
                this.formState.$.pickUpDateTime.value = result.pickUpDateTime;
                this.formState.$.pickUpInside.value = result.pickUpInside;

                this.formState.$.deliveryZip.value = result.deliveryZip;
                this.formState.$.deliveryDirect.value = result.deliverDirect;
                this.formState.$.deliveryDateTime.value = result.deliveryDateTime;
                this.formState.$.deliveryInside.value = result.deliveryInside;

                this.formState.$.pieces.value = result.pieces;
                this.formState.$.weight.value = result.weight;
                this.formState.$.dims.value = result.dims;

                this.formState.$.notes.value = result.notes;

                this.formState.$.dockLevel.value = result.dockLevel;
                this.formState.$.hazardous.value = result.hazardous;
                this.formState.$.liftGate.value = result.liftGate;
                this.formState.$.palletJack.value = result.palletJack;

                this.formState.$.overrideDriversPreferences.value = result.overrideDriversPreferences;

                this.customer = result.customer;
            }
        });
    }
}
