import {
    OrderStatus
} from "app/modules/company/api/clients.api";
import {OrdersStore} from "app/modules/company/components/orders/all/OrdersStore";

export class OrdersProgressStore extends OrdersStore {
    constructor() {
        super();

        this.filters.$.statuses.value = [OrderStatus.InProgress];
    }
}
