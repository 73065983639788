import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import React from "react";

export const RouteChangeHandlerComponent = (props: { handler: (path: string, search: string) => void }) => {
    const location = useLocation();

    useEffect(() => {
        props.handler(location.pathname, location.search);
    }, [location]);

    return <React.Fragment/>
};
