import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Button, Grid, InputBase, Stack} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {
    TelegramChannelSettingsListModel, TelegramChannelType,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {TelegramChannelStore} from "app/modules/company/components/settings/telegram/types/TelegramChannelStore";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {LoadingButton} from "@mui/lab";

export const TelegramChannelTypeMap: Map<TelegramChannelType, string> = new Map<TelegramChannelType, string>([
    [TelegramChannelType.OrderInfo, "OrderInfo"],
    [TelegramChannelType.OrderFeed, "OrderFeed"],
    [TelegramChannelType.AwardedOrders, "AwardedOrders"],
    [TelegramChannelType.ContractorFeed, "ContractorFeed"]
]);

const columns = [
    {
        field: 'title',
        headerName: 'Title',
        minWidth: 250,
        flex: 4,
        renderCell: (params: GridRenderCellParams<TelegramChannelSettingsListModel>) => {
            const store = useInstance(TelegramChannelStore);

            return <Button onClick={() => store.upsert(params.row.id)}>
                {params.row.title}
            </Button>;
        },
    },
    {
        field: 'type',
        headerName: 'Type',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TelegramChannelSettingsListModel>) => TelegramChannelTypeMap.get(params.row.type)
    },
    {
        field: 'vehicles',
        headerName: 'Vehicles',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TelegramChannelSettingsListModel>) => (
            StringHelper.formatNull(params.row.vehicleNumbers?.join(", "))
        )
    },
    {
        field: 'drivers',
        headerName: 'Drivers',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TelegramChannelSettingsListModel>) => (
            StringHelper.formatNull(params.row.drivers?.join(", "))
        )
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TelegramChannelSettingsListModel>) => (
            StringHelper.formatActive(params.row.active)
        )
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TelegramChannelSettingsListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.createdAt)
        ),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TelegramChannelSettingsListModel>) => {
            const store = useInstance(MainStore);
            const telegramChannelStore = useInstance(TelegramChannelStore);

            return <Stack direction={"row"} spacing={2}>
                {store.checkClaim(TenantClaims.TelegramChannelEdit) ? <Button variant="outlined" size="small" color={"error"} onClick={() => telegramChannelStore.delete(params.row.id)}>Remove</Button> : "-"}
            </Stack>;
        },
    },
];

@provider(TelegramChannelStore)
@observer
export default class Index extends Component<any, any> {
    @inject telegramChannelStore: TelegramChannelStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.telegramChannelStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.CarrierEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.telegramChannelStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.ContactEdit) && <Grid item xs={6} textAlign={"right"}>
                <LoadingButton loading={this.telegramChannelStore.isLoading} variant={"contained"} onClick={() => this.telegramChannelStore.upsert()}>New</LoadingButton>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.telegramChannelStore.filters.$.search.value}
                            onChange={(e)=>this.telegramChannelStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.telegramChannelStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.telegramChannelStore.items}
                    columns={columns}
                    pageSize={this.telegramChannelStore.pageSize}
                    rowsPerPageOptions={this.telegramChannelStore.pageSizeOptions}
                    rowCount={this.telegramChannelStore.totalRows}
                    page={this.telegramChannelStore.skipRows/this.telegramChannelStore.pageSize}
                    loading={this.telegramChannelStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.telegramChannelStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.telegramChannelStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.telegramChannelStore.init();
    }
}

