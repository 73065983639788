import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {int, required, requiredNumber} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {
    CarrierShortListModel, DriverShortListModel, ELDIntegrationSettingItemOfString,
    IntegrationEditModel, IntegrationMappingItemEdit,
    IntegrationSettings, IntegrationSettingsParam,
    IntegrationType, NamedValue,
    TenantClaims, VehicleShortListModel
} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";

export class IntegrationDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject cacheService: CacheService;

    title: string = 'New Integration';
    integrationId: number;
    isActive: boolean = true;
    eldDrivers: NamedValue[] = [];
    eldVehicles: NamedValue[] = [];

    formState = new FormState({
        name: new FieldState('').validators(required),
        type: new FieldState(IntegrationType.KeepTruckin).validators(requiredNumber, int),
        settings: new FieldState(new IntegrationSettings({ params: [] })),
        carrierId: new FieldState(null),
    });

    formApiKey = new FormState({
        apiKey: new FieldState('').validators(required),
    });

    formGeoTab = new FormState({
        userName: new FieldState('').validators(required),
        password: new FieldState('').validators(required),
        server: new FieldState('').validators(required),
        database: new FieldState('').validators(required),
    });

    formSyncSettings = new FormState({
        syncAllUsers: new FieldState(true).validators(required),
        syncAllVehicles: new FieldState(true).validators(required),
    });

    formSyncVehicleSettings : FormState<FormState<any>[]> = null;
    formSyncUsersSettings : FormState<FormState<any>[]> = null;

    isReadOnlyMode: boolean = false;
    isRedirectToNewEntity: boolean = false;
    carrier: CarrierShortListModel[] = [];
    vehicles: VehicleShortListModel[] = [];
    drivers: DriverShortListModel[] = [];

    constructor() {
        super();

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.IntegrationEdit);

        makeObservable(this, {
            title: observable,
            isActive: observable,
            isRedirectToNewEntity: observable,

            init: action,
            activate: action,
            deactivate: action,
            save: action
        });
    }

    setParam = (key: string, value: string) => {
        let find = this.formState.$.settings.value.params.find(x => x.name == key);

        if(find == null) {
            find = new IntegrationSettingsParam({name: key});
            this.formState.$.settings.value.params.push(find);
        }

        find.value = value;
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        let vehicleMapping: IntegrationMappingItemEdit[] = [];
        let userMapping: IntegrationMappingItemEdit[] = [];

        if(this.integrationId > 0) {
            await this.formSyncVehicleSettings.validate();
            await this.formSyncUsersSettings.validate();

            if(this.formSyncVehicleSettings.hasError || this.formSyncUsersSettings.hasError) {
                return;
            }

            if(!this.formState.$.settings.$.eldUsers) {
                this.formState.$.settings.$.eldUsers = new ELDIntegrationSettingItemOfString();
                this.formState.$.settings.$.eldUsers.enable = true;
            }

            if(!this.formState.$.settings.$.eldVehicles) {
                this.formState.$.settings.$.eldVehicles = new ELDIntegrationSettingItemOfString();
                this.formState.$.settings.$.eldVehicles.enable = true;
            }

            let includedUsers = this.formSyncUsersSettings.$.filter(x => x.$.include.$);
            for(let i = 0; i < includedUsers.length; i++) {
                userMapping.push(new IntegrationMappingItemEdit({ id: includedUsers[i].$.map.$, externalId: includedUsers[i].$.id.$ }));
            }
            this.formState.$.settings.$.eldUsers.ids = includedUsers.map(x => x.$.id.$);

            let includedVehicles = this.formSyncVehicleSettings.$.filter(x => x.$.include.$);
            for(let i = 0; i < includedVehicles.length; i++) {
                vehicleMapping.push(new IntegrationMappingItemEdit({ id: includedVehicles[i].$.map.$, externalId: includedVehicles[i].$.id.$ }));
            }
            this.formState.$.settings.$.eldVehicles.ids = includedVehicles.map(x => x.$.id.$);
        }

        if(this.isShowApiKey()) {
            await this.formApiKey.validate();

            if(this.formApiKey.hasError) {
                return;
            }

            this.setParam("ApiKey", this.formApiKey.$.apiKey.value);
        }

        if(this.isShowGeoTab()) {
            await this.formGeoTab.validate();

            if(this.formGeoTab.hasError) {
                return;
            }

            this.setParam("UserName", this.formGeoTab.$.userName.value);
            this.setParam("Password", this.formGeoTab.$.password.value);
            this.setParam("Server", this.formGeoTab.$.server.value);
            this.setParam("Database", this.formGeoTab.$.database.value);
        }

        await this.wrapLoadingApiCall(async () => {
            this.integrationId = await this.companyApiClients.integrationClient.upsert(new IntegrationEditModel({
                id: this.integrationId,
                name: this.formState.$.name.value,
                type: this.formState.$.type.value,
                carrierId: this.formState.$.carrierId.value || null,
                settings: this.formState.$.settings.value,
                active: this.integrationId > 0 ? undefined : false,
                userMapping: userMapping,
                vehicleMapping: vehicleMapping
            }));

            runInAction(() => {
                if(this.integrationId > 0) {
                    this.isSavedSuccessfully = true;
                } else {
                    this.isRedirectToNewEntity = true;
                }
            });
        });
    }

    activate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.integrationClient.activate(this.integrationId);
            runInAction(() => {
                this.isActive = true;
            });
        });
    }

    deactivate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.integrationClient.deactivate(this.integrationId);
            runInAction(() => {
                this.isActive = false;
            });
        });
    }

    isShowApiKey = () => this.formState.$.type.value == IntegrationType.KeepTruckin || this.formState.$.type.value == IntegrationType.Samsara || this.formState.$.type.value == IntegrationType.Linxup;
    isShowGeoTab = () => this.formState.$.type.value == IntegrationType.Geotab;

    init = async (id: number) => {
        this.integrationId = id;

        await this.wrapInitLoadingApiCall(async () => {
            const [carriers] = [
                await this.cacheService.getCarriers(),
            ];

            this.carrier = carriers;

            if(this.integrationId > 0) {
                const [result, eldDrivers, eldVehicles, vehicles, drivers] = [
                    await this.companyApiClients.integrationClient.getById(this.integrationId),
                    await this.companyApiClients.integrationClient.getAllEldDrivers(this.integrationId),
                    await this.companyApiClients.integrationClient.getAllEldVehicles(this.integrationId),
                    await this.cacheService.getVehicles(),
                    await this.cacheService.getDrivers(),
                ];

                this.title = result.name;
                this.isActive = result.active;
                this.eldDrivers = eldDrivers;
                this.eldVehicles = eldVehicles;
                this.vehicles = vehicles.filter(x => x.carrierId == result.carrierId);
                this.drivers = drivers.filter(x => x.carrierId == result.carrierId);

                let vehicleForm = [];
                for (let i = 0; i < this.eldVehicles.length; i++) {
                    let find = result.vehicleMapping.find(x => x.externalId == this.eldVehicles[i].id);
                    vehicleForm.push(new FormState({
                        id: new FieldState(this.eldVehicles[i].id),
                        name: new FieldState(this.eldVehicles[i].name),
                        include: new FieldState(!!find),
                        map: new FieldState(find?.id)
                    }).validators(
                        ($) => $.include.$ && !$.map.$ && 'Map is required',
                        ($) => $.include.$ && $.map.$ && vehicleForm.find(x => x.$.include.$ && x.$.id.$ != $.id.$ && x.$.map.$ == $.map.$) && 'Map should be unique')
                    );
                }
                this.formSyncVehicleSettings = new FormState(vehicleForm);

                let userForm = [];
                for (let i = 0; i < this.eldDrivers.length; i++) {
                    let find = result.userMapping.find(x => x.externalId == this.eldDrivers[i].id);
                    userForm.push(new FormState({
                            id: new FieldState(this.eldDrivers[i].id),
                            name: new FieldState(this.eldDrivers[i].name),
                            include: new FieldState(!!find),
                            map: new FieldState(find?.id)
                        }).validators(
                        ($) => $.include.$ && !$.map.$ && 'Map is required',
                        ($) => $.include.$ && $.map.$ && vehicleForm.find(x => x.$.include.$ && x.$.id.$ != $.id.$ && x.$.map.$ == $.map.$) && 'Map should be unique')
                    );
                }
                this.formSyncUsersSettings = new FormState(userForm);

                this.formState.$.type.value = result.type;
                this.formState.$.name.value = result.name;
                this.formState.$.carrierId.value = result.carrierId;

                this.formSyncSettings.$.syncAllUsers.value = !(result.settings.eldUsers?.ids?.length > 0);
                this.formSyncSettings.$.syncAllVehicles.value = !(result.settings.eldVehicles?.ids?.length > 0);

                if(this.isShowApiKey()) {
                    this.formApiKey.$.apiKey.value = result.settings.params.find(x => x.name == "ApiKey").value;
                }

                if(this.isShowGeoTab()) {
                    this.formGeoTab.$.userName.value = result.settings.params.find(x => x.name == "UserName").value;
                    this.formGeoTab.$.password.value = result.settings.params.find(x => x.name == "Password").value;
                    this.formGeoTab.$.server.value = result.settings.params.find(x => x.name == "Server").value;
                    this.formGeoTab.$.database.value = result.settings.params.find(x => x.name == "Database").value;
                }
            }
        });
    }
}
