import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {Stack} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React from "react";

export const ActivateDeactivateSection = (props: { isActive: boolean, isLoading: boolean, activate: () => void, deactivate: () => void }) => <React.Fragment>
    <SectionSeparator/>
    <SectionName title="Actions" description="Actions to Activate/Deactivate" />
    <Section>
        <ActivateDeactivate {...props}/>
    </Section>
</React.Fragment>

export const ActivateDeactivate = (props: { isActive: boolean, isLoading: boolean, activate: () => void, deactivate: () => void }) => <Stack spacing={2} direction={"row"}>
    {!props.isActive && <LoadingButton loading={props.isLoading} variant="outlined" color="primary" onClick={props.activate}>
        Activate
    </LoadingButton>}
    {props.isActive && <LoadingButton loading={props.isLoading} variant="outlined" color="error" onClick={props.deactivate}>
        Deactivate
    </LoadingButton>}
</Stack>
