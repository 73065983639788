import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    CarrierShortListModel,
    DriverShortListModel, NamedValue,
    NamedValueLong,
    VehicleShortListModel
} from "app/modules/company/api/clients.api";
import {PusherTenantService} from "app/modules/common/pusher/PusherTenantService";

export class CacheService {
    @inject companyApiClients: ApiClients;
    @inject pusherTenantService: PusherTenantService;

    private drivers: DriverShortListModel[] = null;
    private vehicles: VehicleShortListModel[] = null;
    private carriers: CarrierShortListModel[] = null;
    private paymentNetTerms: NamedValueLong[] = null;
    private users: NamedValue[] = null;

    getDrivers = async () => {
        if(this.drivers != null) {
            return this.drivers;
        }

        this.drivers = await this.companyApiClients.driverClient.getShortList();
        return this.drivers;
    }

    getUsers = async () => {
        if(this.users != null) {
            return this.users;
        }

        this.users = await this.companyApiClients.tenantDataProviderClient.getUsers();
        return this.users;
    }

    getVehicles = async () => {
        if(this.vehicles != null) {
            return this.vehicles;
        }

        this.vehicles = await this.companyApiClients.vehicleClient.getShortList();
        return this.vehicles;
    }

    getCarriers = async () => {
        if(this.carriers != null) {
            return this.carriers;
        }

        this.carriers = await this.companyApiClients.carrierClient.getShortList();
        return this.carriers;
    }

    getPaymentNetTerms = async () => {
        if(this.paymentNetTerms != null) {
            return this.paymentNetTerms;
        }

        this.paymentNetTerms = await this.companyApiClients.paymentClient.getPaymentNetTerms();
        return this.paymentNetTerms;
    }

    init = async () => {
        setInterval(() => {
            this.drivers = null;
            this.carriers = null;
            this.vehicles = null;
            this.paymentNetTerms = null;
            this.users = null;
        }, 60 * 1000 * 10);

        this.pusherTenantService.bindToTenantChannel("DriversUpdated", () => this.drivers = null);
        this.pusherTenantService.bindToTenantChannel("CarriersUpdated", () => this.carriers = null);
        this.pusherTenantService.bindToTenantChannel("VehiclesUpdated", () => this.vehicles = null);
        this.pusherTenantService.bindToTenantChannel("NetTermUpdated", () => this.paymentNetTerms = null);
        this.pusherTenantService.bindToTenantChannel("UserUpdated", () => this.users = null);
    }
}
