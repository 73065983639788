import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {int, required, requiredNumber} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {
    CarrierShortListModel,
    VehicleEditModel,
    TenantClaims,
    VehicleType, FuelType, DriverShortListModel, VehicleFloorType, NamedValueLong
} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";
import moment from "moment";

export const VehicleTypesMap: Map<VehicleType, string> = new Map([
    [ VehicleType.LargeStraight, "Large Straight" ],
    [ VehicleType.SmallStraight, "Small Straight" ],
    [ VehicleType.CargoVan, "Cargo Van" ],
    [ VehicleType.Tractor, "Tractor" ]
]);

export const FuelTypes = [
    { id: FuelType.Diesel, name: "Diesel" },
    { id: FuelType.Gas, name: "Gas" }
];

export class VehicleDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject cacheService: CacheService;

    title: string = 'New Vehicle';
    vehicleId: number;
    isActive: boolean = true;

    formState = new FormState({
        Number: new FieldState(null).validators(requiredNumber, int),
        VIN: new FieldState('').validators(required),
        Year: new FieldState(null).validators(requiredNumber, int),
        Make: new FieldState(null).validators(required),
        Model: new FieldState(null).validators(required),
        Type: new FieldState(VehicleType.LargeStraight),
        FuelType: new FieldState(FuelType.Diesel),
        LicenseState: new FieldState('').validators(required),
        LicenseNumber: new FieldState(null).validators(required),

        DoorWidth: new FieldState(null).validators(requiredNumber, int),
        DoorHeight: new FieldState(null).validators(requiredNumber, int),

        BoxHeight: new FieldState(null).validators(requiredNumber, int),
        BoxWidth: new FieldState(null).validators(requiredNumber, int),
        BoxLength: new FieldState(null).validators(requiredNumber, int),

        IsDockHigh: new FieldState(false),
        HasRamps: new FieldState(false),
        HasLiftGate: new FieldState(false),
        HasAirRide: new FieldState(false),
        HasETrack: new FieldState(false),
        HasStraps: new FieldState(false),
        HasBlankets: new FieldState(false),
        HasPalletJack: new FieldState(false),
        HasTeam: new FieldState(false),
        HasSideDoor: new FieldState(false),
        FloorType: new FieldState(VehicleFloorType.Wooden),

        MaxLoad: new FieldState(null).validators(requiredNumber, int),
        LiftGate: new FieldState(0).validators(int),

        CarrierId: new FieldState(null),
        DefaultDriverIds: new FieldState([]),
        RegistrationExpirationDate: new FieldState<moment.Moment>(null).validators(required)
    });

    isReadOnlyMode: boolean = false;
    drivers: DriverShortListModel[] = [];
    carrier: CarrierShortListModel[] = [];

    floorTypes: NamedValueLong[] = [
        new NamedValueLong({ id: VehicleFloorType.Wooden, name: "Wooden" }),
        new NamedValueLong({ id: VehicleFloorType.PlasticTiles, name: "PlasticTiles" }),
        new NamedValueLong({ id: VehicleFloorType.Rubber, name: "Rubber" }),
        new NamedValueLong({ id: VehicleFloorType.Steel, name: "Steel" })
    ];

    constructor() {
        super();

        this.formState.$.LicenseState.value = this.mainStore.tenantData.states[0].code;
        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.VehicleEdit);

        makeObservable(this, {
            title: observable,
            isActive: observable,

            init: action,
            activate: action,
            deactivate: action,
            save: action
        });
    }

    activate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.vehicleClient.activate(this.vehicleId);
            runInAction(() => {
                this.isActive = true;
            });
        });
    }

    deactivate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.vehicleClient.deactivate(this.vehicleId);
            runInAction(() => {
                this.isActive = false;
            });
        });
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.vehicleClient.upsert(new VehicleEditModel({
                id: this.vehicleId,
                number: this.formState.$.Number.value,
                vIN: this.formState.$.VIN.value,
                year: this.formState.$.Year.value,
                make: this.formState.$.Make.value,
                model: this.formState.$.Model.value,
                type: this.formState.$.Type.value,
                fuelType: this.formState.$.FuelType.value,
                licenseNumber: this.formState.$.LicenseNumber.value,
                licenseState: this.formState.$.LicenseState.value,
                doorWidth: this.formState.$.DoorWidth.value,
                doorHeight: this.formState.$.DoorHeight.value,
                boxHeight: this.formState.$.BoxHeight.value,
                boxWidth: this.formState.$.BoxWidth.value,
                boxLength: this.formState.$.BoxLength.value,
                isDockHigh: this.formState.$.IsDockHigh.value,
                hasLiftGate: this.formState.$.HasLiftGate.value,
                hasAirRide: this.formState.$.HasAirRide.value,
                hasRamps: this.formState.$.HasRamps.value,
                hasETrack: this.formState.$.HasETrack.value,
                hasStraps: this.formState.$.HasStraps.value,
                hasBlankets: this.formState.$.HasBlankets.value,
                hasPalletJack: this.formState.$.HasPalletJack.value,
                hasTeam: this.formState.$.HasTeam.value,
                hasSideDoor: this.formState.$.HasSideDoor.value,
                floorType: this.formState.$.FloorType.value,
                maxLoad: this.formState.$.MaxLoad.value,
                liftGate: this.formState.$.LiftGate.value,
                carrierId: this.formState.$.CarrierId.value || null,
                defaultDriverIds: this.formState.$.DefaultDriverIds.value,
                registrationExpirationDate:this.formState.$.RegistrationExpirationDate.value
            }));

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }

    init = async (id: number) => {
        this.vehicleId = id;

        await this.wrapInitLoadingApiCall(async () => {
            const [carriers, drivers] = [
                await this.cacheService.getCarriers(),
                await this.cacheService.getDrivers()
            ];

            this.carrier = carriers;
            this.drivers = drivers;

            if(this.vehicleId > 0) {
                const [vehicle] = [
                    await this.companyApiClients.vehicleClient.getById(this.vehicleId),
                ];

                this.title = "Vehicle: " + vehicle.number;
                this.isActive = vehicle.active;

                this.formState.$.Number.value = vehicle.number;
                this.formState.$.VIN.value = vehicle.vIN;
                this.formState.$.Year.value = vehicle.year;
                this.formState.$.Make.value = vehicle.make;
                this.formState.$.Model.value = vehicle.model;
                this.formState.$.Type.value = vehicle.type;
                this.formState.$.LicenseState.value = vehicle.licenseState;
                this.formState.$.LicenseNumber.value = vehicle.licenseNumber;
                this.formState.$.FuelType.value = vehicle.fuelType;
                this.formState.$.DoorWidth.value = vehicle.doorWidth;
                this.formState.$.DoorHeight.value = vehicle.doorHeight;
                this.formState.$.BoxHeight.value = vehicle.boxHeight;
                this.formState.$.BoxWidth.value = vehicle.boxWidth;
                this.formState.$.BoxLength.value = vehicle.boxLength;
                this.formState.$.IsDockHigh.value = vehicle.isDockHigh;
                this.formState.$.HasLiftGate.value = vehicle.hasLiftGate;
                this.formState.$.HasAirRide.value = vehicle.hasAirRide;
                this.formState.$.HasRamps.value = vehicle.hasRamps;
                this.formState.$.HasETrack.value = vehicle.hasETrack;
                this.formState.$.HasStraps.value = vehicle.hasStraps;
                this.formState.$.HasBlankets.value = vehicle.hasBlankets;
                this.formState.$.HasPalletJack.value = vehicle.hasPalletJack;
                this.formState.$.HasTeam.value = vehicle.hasTeam;
                this.formState.$.HasSideDoor.value = vehicle.hasSideDoor;
                this.formState.$.FloorType.value = vehicle.floorType;
                this.formState.$.MaxLoad.value = vehicle.maxLoad;
                this.formState.$.LiftGate.value = vehicle.liftGate;
                this.formState.$.CarrierId.value = vehicle.carrierId;
                this.formState.$.DefaultDriverIds.value = vehicle.defaultDriverIds;
                this.formState.$.RegistrationExpirationDate.value = vehicle.registrationExpirationDate;
            }
        });
    }
}
