import * as React from "react";
import {provider, inject} from "react-ioc";
import "react-widgets/styles.css";
import 'draft-js/dist/Draft.css';
import "./index.scss";
import { observer } from "mobx-react";

import './const/constants';
import {HistoryStore} from "app/stores/HistoryStore";

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
    '6e537d00c46cf501c0f03c339bf425d9T1JERVI6NDAwMzgsRVhQSVJZPTE2NzkzNDAwNjcwMDAsS0VZVkVSU0lPTj0x',
);

import Moment from 'moment'
import {Route, Routes} from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
Moment.locale('en')

import "react-datepicker/dist/react-datepicker.css";
import {ThemeProvider} from "@mui/styles";
import {createTheme} from "@mui/material";

import { IndexAdmin } from "app/modules/admin/index";
import { IndexOrganization } from "app/modules/organization/index";
import { IndexCompany } from "app/modules/company/index";
import { IndexAuth } from "app/modules/auth/index";
import {ErrorHandler} from "app/modules/common/layout/error-handler";
import {Maintenance} from "app/modules/company/components/main/maintenance";
import {LocalStorageService} from "app/modules/common/stores/LocalStorageService";
import {AdminAccessDenied} from "app/modules/admin/components/main/access-denied";
import {NotificationHandler} from "app/modules/common/layout/notification-handler";
import TermsIndex from "app/modules/common/terms/index";
import SupportIndex from "app/modules/common/support/index";
import MobileIndex from "app/modules/common/mobile/index";
import { ExternalIndex } from "app/modules/external/index";

const theme = createTheme({});

@provider(HistoryStore, LocalStorageService)
@observer
export class App extends React.Component<any> {
  @inject historyStore: HistoryStore;

  render() {
    return <LocalizationProviderPro dateAdapter={AdapterDateFns}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/terms" element={<TermsIndex />} />
                <Route path="/mobile" element={<MobileIndex />} />
                <Route path="/support" element={<SupportIndex />} />
                <Route path="/maintenance" element={<Maintenance />} />
                <Route path="/admin/accessDenied" element={<AdminAccessDenied />} />
                <Route path="/admin/*" element={<IndexAdmin/>} />
                <Route path="/external/*" element={<ExternalIndex/>} />
                <Route path="/org/*" element={<IndexOrganization/>} />
                <Route path="/auth/*" element={<IndexAuth/>} />
                <Route path="/login" element={<IndexAuth/>} />
                <Route path="/*" element={<IndexCompany/>} />
            </Routes>
          </ThemeProvider>
          <ErrorHandler/>
          <NotificationHandler/>
        </LocalizationProvider>
    </LocalizationProviderPro>;
  }

  UNSAFE_componentWillMount() {
    this.historyStore.history = this.props.history;
  }
}
