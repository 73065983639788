import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container} from "app/modules/common/layout/components/containers";
import {OrderDetailsStore} from "app/modules/company/components/orders/details/OrderDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Box, Button, Card, Chip, Divider, Grid, IconButton, Stack, Typography} from "@mui/material";
import {MainStore} from "app/modules/company/stores/MainStore";
import {useParams} from "react-router-dom";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {
    Alert,
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {
    CustomerNoteType,
    FileItemType,
    FileType,
    NoteItemType,
    NoteType,
    OrderItemType,
    OrderStatus,
    OrderStopType,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";

import ArchiveIcon from '@mui/icons-material/Archive';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {FileInput} from "app/modules/company/components/file/file-uploader";
import {Notes} from "app/modules/company/components/notes/notes";
import {ModalOrderItem} from "app/modules/company/components/orders/details/modal.item";
import {sum} from "mathjs";
import {ModalOrderStop} from "app/modules/company/components/orders/details/modal.stop";
import {orderStatusColorMap} from "app/modules/company/components/orders/all";
import {Paths} from "app/modules/company/stores/paths";
import {apiBaseUrl} from "app/modules/company/services/AxiosBaseClient";
import {customerNoteTypeColorMap} from "app/modules/company/components/contact/customers";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import {InfoElement, LinkPointerStyle} from "app/modules/common/details/info-element";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";

export interface IOrderComponent {
    orderId: number
}

export const OrderStatusNameMap: Map<OrderStatus, string> = new Map<OrderStatus, string>([
    [OrderStatus.Draft, "Draft"],
    [OrderStatus.Ready, "Ready"],
    [OrderStatus.InProgress, "InProgress"],
    [OrderStatus.Canceled, "Canceled"],
    [OrderStatus.Completed, "Completed"],
    [OrderStatus.OnHold, "OnHold"],
    [OrderStatus.Closed, "Closed"]
]);

@provider(OrderDetailsStore)
@observer
export class OrderDetailIndex extends Component<IOrderComponent, any> {
    @inject orderDetailsStore: OrderDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.orderDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        return <Container spacing={2}>
            <Grid item md={12} lg={7}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{p: 2}}>
                            <Stack spacing={2} direction={"column"}>
                                <Stack direction={"row"}>
                                    <Box sx={{ width: "50%" }}>
                                        <Typography variant="h4" display="block">
                                            <Box component={"span"} sx={{cursor: "pointer", textDecoration: "underline"}} onClick={() => navigator.clipboard.writeText(apiBaseUrl + Paths.EditOrder.nav(this.orderDetailsStore.orderId))}>{this.orderDetailsStore.title}</Box> <Chip label={OrderStatusNameMap.get(this.orderDetailsStore.details.status)} sx={{ color: "white", backgroundColor: orderStatusColorMap.get(this.orderDetailsStore.details.status) }} />
                                        </Typography>
                                    </Box>
                                    <Stack spacing={1} direction={"row"} alignItems={"right"} sx={{ width: "50%" }} justifyContent={"right"}>
                                        {this.mainStore.checkClaim(TenantClaims.OrderTaskEdit) && <Button variant="text" onClick={this.orderDetailsStore.openTask}>Create Task</Button>}

                                        {this.mainStore.checkClaim(TenantClaims.OrderCancel) && this.orderDetailsStore.details.canMarkAsCanceled &&
                                        <Button variant="outlined" sx={{ color: orderStatusColorMap.get(OrderStatus.Canceled) }} onClick={this.orderDetailsStore.markAsCanceled}>Cancel</Button>}

                                        {this.mainStore.checkClaim(TenantClaims.OrderDraft) && this.orderDetailsStore.details.canMarkAsDraft &&
                                            <Button variant="outlined" sx={{ color: orderStatusColorMap.get(OrderStatus.Draft) }} onClick={this.orderDetailsStore.markAsDraft}>Draft</Button>}

                                        {this.mainStore.checkClaim(TenantClaims.OrderReady) && this.orderDetailsStore.details.canMarkAsReady &&
                                            <Button variant="outlined" sx={{ color: orderStatusColorMap.get(OrderStatus.Ready) }} onClick={this.orderDetailsStore.markAsReady}>Ready</Button>}

                                        {this.mainStore.checkClaim(TenantClaims.OrderProgress) && this.orderDetailsStore.details.canMarkAsInProgress &&
                                            <Button variant="outlined" disabled={this.orderDetailsStore.details.errors?.length > 0} sx={{ color: orderStatusColorMap.get(OrderStatus.InProgress) }} onClick={this.orderDetailsStore.markAsInProgress}>Progress</Button>}

                                        {this.mainStore.checkClaim(TenantClaims.OrderOnHold) && this.orderDetailsStore.details.canMarkAsOnHold &&
                                        <Button variant="outlined" sx={{ color: orderStatusColorMap.get(OrderStatus.OnHold) }} onClick={this.orderDetailsStore.markAsOnHold}>OnHold</Button>}

                                        {this.mainStore.checkClaim(TenantClaims.OrderComplete) && this.orderDetailsStore.details.canMarkAsCompleted &&
                                            <Button variant="outlined" sx={{ color: orderStatusColorMap.get(OrderStatus.Completed) }} onClick={this.orderDetailsStore.markAsCompleted}>Completed</Button>}
                                    </Stack>
                                </Stack>

                                <Divider />
                                <Stack spacing={1} direction={"column"}>
                                    {this.orderDetailsStore.details.errors?.length > 0 && this.orderDetailsStore.details.errors.map(x => <Alert severity="error" key={x}>{x}</Alert>)}
                                    {this.orderDetailsStore.details.warnings?.length > 0 && this.orderDetailsStore.details.warnings.map(x => <Alert severity="warning" key={x}>{x}</Alert>)}
                                </Stack>
                                <Stack direction={"row"}>
                                    <Stack spacing={1} direction={"column"} sx={{width: "100%"}}>
                                        <InfoElement name="Customer" value={this.orderDetailsStore.details.customer || "N/A"} onClick={this.orderDetailsStore.details.canUpdateCustomer && this.orderDetailsStore.changeCustomer}/>
                                        {this.orderDetailsStore.details.customerMC && <InfoElement name="MC" value={this.orderDetailsStore.details.customerMC}/>}
                                        {this.orderDetailsStore.details.customer && (this.orderDetailsStore.details.customerNote?.length > 0 || this.orderDetailsStore.details.customerNoteType != CustomerNoteType.None) &&
                                            <Typography variant="caption" display="block" style={{ color: customerNoteTypeColorMap.get(this.orderDetailsStore.details.customerNoteType) }}>{this.orderDetailsStore.details.customerNote || "No Notes"}</Typography> }
                                        {this.orderDetailsStore.details.customerId && <br/>}
                                        {this.orderDetailsStore.details.customerId && <InfoElement name="Contact" value={this.orderDetailsStore.details.contact || "N/A"} onClick={this.orderDetailsStore.details.canUpdateCustomer && this.orderDetailsStore.changeContact}/>}
                                        {this.orderDetailsStore.details.contactPhone && <InfoElement name="Phone" value={this.orderDetailsStore.details.contactPhone}/>}
                                        {this.orderDetailsStore.details.contactEmail && <InfoElement name="Email" value={this.orderDetailsStore.details.contactEmail}/>}
                                        {this.orderDetailsStore.details.status == OrderStatus.InProgress && <React.Fragment>
                                            <br />
                                            <InfoElement name="External Link" value={(this.orderDetailsStore.details.externalLink?.length > 0 ? "Open External Link" : "Generate External Link")} onClick={this.orderDetailsStore.openOrGenerateExternalLink}/>
                                        </React.Fragment>}
                                        <br/>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <InfoElement name="Vehicle" value={this.orderDetailsStore.details.vehicle || "N/A"} onClick={this.orderDetailsStore.details.canUpdateVehicle && this.orderDetailsStore.changeVehicle}/>
                                            {this.orderDetailsStore.details.vehicle && <IconButton color="primary" onClick={this.orderDetailsStore.openVehicleHistory}><TravelExploreIcon/></IconButton>}
                                        </Stack>
                                        {this.orderDetailsStore.details.carrier && <InfoElement name="Carrier" value={this.orderDetailsStore.details.carrier}/>}
                                        <InfoElement name="Drivers" value={this.orderDetailsStore.details.drivers.map(x => `${x.fullName} - ${x.phone} ${x.carrier?.length > 0 ? `(${x.carrier})` : ''}`)?.join(", ") || "N/A"} onClick={this.orderDetailsStore.details.canUpdateDrivers && this.orderDetailsStore.changeDrivers}/>

                                        <React.Fragment>
                                            {this.orderDetailsStore.details.status == OrderStatus.Ready || this.orderDetailsStore.details.status == OrderStatus.InProgress && this.mainStore.checkClaim(TenantClaims.OrderNotification) && (this.orderDetailsStore.details.drivers?.length > 0 || this.orderDetailsStore.details.vehicle) && <Button onClick={this.orderDetailsStore.sendDriverInfo}>Send Driver Info</Button>}
                                        </React.Fragment>
                                    </Stack>
                                    <Divider orientation="vertical" flexItem sx={{mr: 2, pl: 2}}/>
                                    <Stack spacing={1} direction={"column"} sx={{width: "100%"}}>
                                        <InfoElement name="Order Number" value={this.orderDetailsStore.details.orderNo || "N/A"} onClick={this.orderDetailsStore.details.canUpdate && this.orderDetailsStore.changeOrderNumber}/>
                                        <br/>
                                        <InfoElement name="From" value={this.orderDetailsStore.getFrom() || "N/A"}/>
                                        <InfoElement name="To" value={this.orderDetailsStore.getTo() || "N/A"}/>
                                        <br/>
                                        <InfoElement name="Team" value={StringHelper.formatYesNo(this.orderDetailsStore.details.team)}/>
                                        <br/>
                                        <InfoElement name="Rate" value={this.orderDetailsStore.details.rate && NumberHelper.formatUSDollar(this.orderDetailsStore.details.rate)} onClick={this.orderDetailsStore.details.canUpdateRate && this.orderDetailsStore.changeRate}/>
                                        <InfoElement name="Carrier Rate" value={this.orderDetailsStore.details.carrierRate && NumberHelper.formatUSDollar(this.orderDetailsStore.details.carrierRate)} onClick={this.orderDetailsStore.details.canUpdateRate && this.orderDetailsStore.changeCarrierRate}/>
                                    </Stack>
                                </Stack>
                                <Divider/>
                                <Stack spacing={1}>
                                    <div>Driver Notes:</div>
                                    <div onClick={this.orderDetailsStore.details.canUpdate && this.orderDetailsStore.changeNotes || undefined} style={{ cursor: "pointer", color: "rgb(25, 118, 210)", fontWeight: "bold", textDecoration: "underline" }} dangerouslySetInnerHTML={{ __html: this.orderDetailsStore.details.driverSharedNotes?.replace(new RegExp('\n', 'g'), "</br>") || "N/A" }} />
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{p: 2}}>
                            <Stack spacing={2} direction={"column"}>
                                <Stack direction={"column"} sx={{width: "100%", backgroundColor: "white", borderRadius: "5px"}}>
                                    <Typography variant="h5" display="block" gutterBottom>
                                        Items (<b>{sum(this.orderDetailsStore.items?.map(x => x.pieceUnits) || [0])}</b> pc • <b>{sum(this.orderDetailsStore.items?.map(x => x.weight) || [0])}</b> lb) {this.orderDetailsStore.items?.filter(x => x.hazardous)?.length > 0 && <Typography variant={"h5"} color={"error"} component={"span"}><b>Hazardous</b></Typography>}
                                    </Typography>
                                    <Divider sx={{ pt: 1, mb: 1 }}/>
                                    { this.orderDetailsStore.items?.map(x => <Stack key={x.id} direction={"row"} spacing={2} alignItems={"center"}>
                                        <Typography variant={"subtitle2"} gutterBottom>{x.description}</Typography>
                                        <Typography variant={"subtitle2"} gutterBottom>{this.mainStore.tenantData.cargoTypes.find(c => parseInt(c.id) as OrderItemType == x.cargoType).name}</Typography>
                                        <Typography variant={"subtitle2"} gutterBottom>{x.packing}</Typography>
                                        <Typography variant={"subtitle2"} gutterBottom><b>{x.pieceUnits}</b> pc</Typography>
                                        <Typography variant={"subtitle2"} gutterBottom><b>{x.weight}</b> lb</Typography>
                                        <Typography variant={"subtitle2"} gutterBottom><b>{x.length || 0}x{x.width || 0}x{x.height || 0}</b> (LxWxH)</Typography>
                                        {x.hazardous && <Typography variant={"subtitle2"} color={"error"} gutterBottom><b>Hazardous</b></Typography>}
                                        {this.orderDetailsStore.details.canUpdateItems && <IconButton aria-label="delete" color="primary" onClick={() => this.orderDetailsStore.openEditOrderItemModal(x)}>
                                            <EditIcon />
                                        </IconButton>}
                                        {this.orderDetailsStore.details.canUpdateItems && <IconButton aria-label="Edit" color="primary" onClick={() => this.orderDetailsStore.openDeleteOrderItemDialog(x)}>
                                            <DeleteIcon />
                                        </IconButton>}
                                    </Stack>)}
                                    {this.orderDetailsStore.details.canUpdateItems && <Button color="primary" variant="contained" startIcon={<AddCircleIcon />} sx={{ width: "200px", mt: 2 }} onClick={() => this.orderDetailsStore.openEditOrderItemModal(null)}>
                                        Add Order Item
                                    </Button>}
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Card sx={{p: 2}}>
                                    <Notes notes={this.orderDetailsStore.initNotes} noteType={NoteType.Order} noteItemType={NoteItemType.Order} objectId={this.orderDetailsStore.orderId} />
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card sx={{p: 2}}>
                                    <Notes notes={this.orderDetailsStore.initNoteAccounting} noteType={NoteType.OrderAccounting} noteItemType={NoteItemType.Order} objectId={this.orderDetailsStore.orderId} />
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{p: 2, pt: 0}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}><FileInput readonly={!this.orderDetailsStore.details.canUpdate} files={this.orderDetailsStore.initFileRateConfirmations} fileType={FileType.RateConfirmation} fileItemType={FileItemType.Order} objectId={this.orderDetailsStore.orderId}/></Grid>
                                <Grid item xs={12} md={3}><FileInput readonly={!this.orderDetailsStore.details.canUpdate} files={this.orderDetailsStore.initFileBillsOfLanding} fileType={FileType.BillOfLanding} fileItemType={FileItemType.Order} objectId={this.orderDetailsStore.orderId}/></Grid>
                                <Grid item xs={12} md={3}><FileInput readonly={!this.orderDetailsStore.details.canUpdate} files={this.orderDetailsStore.initFileProofsOfDelivery} fileType={FileType.ProofOfDelivery} fileItemType={FileItemType.Order} objectId={this.orderDetailsStore.orderId}/></Grid>
                                <Grid item xs={12} md={3}><FileInput readonly={!this.orderDetailsStore.details.canUpdate} files={this.orderDetailsStore.initFileOrderCargo} fileType={FileType.OrderCargo} fileItemType={FileItemType.Order} objectId={this.orderDetailsStore.orderId}/></Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} lg={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{p: 2}}>
                            <Typography variant="h5" display="block" gutterBottom>
                                Stops
                            </Typography>
                            <Divider/>
                            <Stack direction={"row"} sx={{width: "100%"}}>
                                <Timeline>
                                    {this.orderDetailsStore.stops?.length > 0 && this.orderDetailsStore.stops.map((x, index) => {
                                        let hours, minutes;

                                        if(x.arrived) {
                                            [hours, minutes] = DateTimeHelper.formatDiff(x.arrived, x.departed);
                                        }

                                        return <TimelineItem key={x.id} position={"right"}>
                                            <TimelineOppositeContent
                                                align="right"
                                                variant="body2"
                                                sx={{width: "250px", flex: "none", pb: "40px"}}
                                            >
                                                <Box sx={{
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    pt: "20px"
                                                }}>
                                                    <Stack direction={"column"} sx={{width: "100%"}}>
                                                        <Typography variant={"caption"} gutterBottom><b>Scheduled: </b>{DateTimeHelper.formatMomentDate(x.date)} <b>{DateTimeHelper.formatMomentTimeShortUtcToLocal(x.date)}</b></Typography>

                                                        {(index == 0 || this.orderDetailsStore.stops[index-1].departed) && <Divider sx={{ pt: 1, mb: 1 }}/>}

                                                        {((index == 0 || this.orderDetailsStore.stops[index-1].departed) || x.arrived) &&
                                                        <div><b>Arrived:</b> {x.arrived ? <Typography variant={"caption"} gutterBottom onClick={() => this.orderDetailsStore.details.canUpdateStops && this.orderDetailsStore.setArrivalTime(x)}>
                                                                <span style={this.orderDetailsStore.details.canUpdateStops && LinkPointerStyle || {}}>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(x.arrived)}</span>
                                                            </Typography> :
                                                        <Button sx={{ fontSize: "10px" }} onClick={() => this.orderDetailsStore.setArrivalTime(x)}>Set Arrival Time</Button>}</div>}

                                                        {x.arrived &&
                                                        <div><b>Departed:</b> {x.departed ? <Typography variant={"caption"} gutterBottom onClick={() => this.orderDetailsStore.details.canUpdateStops && this.orderDetailsStore.setDepartTime(x)}>
                                                                <span style={this.orderDetailsStore.details.canUpdateStops && LinkPointerStyle || {}}>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(x.departed)}</span>
                                                            </Typography> :
                                                        <Button sx={{ fontSize: "10px" }} onClick={() => this.orderDetailsStore.setDepartTime(x)}>Set Depart Time</Button>}</div>}

                                                        {x.arrived && <Divider sx={{ pt: 1, mb: 1 }}/>}
                                                        {x.arrived && <div><b>Time Spent:</b> <Typography variant={"subtitle2"} component="span" gutterBottom color={hours >= 2 && "error"}><b>{hours}</b> hrs and <b>{minutes}</b> mins</Typography></div>}
                                                    </Stack>
                                                </Box>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                                <TimelineDot
                                                    sx={{ cursor: "pointer" }}
                                                    color={ x.departed ? ( x.type == OrderStopType.Shipper ? 'primary' : 'secondary') : 'grey'}
                                                    onClick={() => this.orderDetailsStore.details.canUpdateStops && this.orderDetailsStore.openEditStopItemModal(x)}
                                                >
                                                    {x.type == OrderStopType.Shipper ? <ArchiveIcon/> : <LocalShippingIcon/>}
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, pb: "40px" }}>
                                                <Box sx={{
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    pt: "20px"
                                                }}>
                                                    {x.name && <Typography variant="subtitle2" component="span">
                                                        {x.name || "N/A"}
                                                    </Typography>}
                                                    {x.address?.length > 0 && <Typography variant="subtitle2">
                                                        {x.address}
                                                    </Typography>}
                                                    {x.address?.length > 0 && <Typography variant={"h6"}>
                                                        {x.city}, {x.state} {x.zipCode}
                                                    </Typography>}

                                                    {(x.pOCName?.length > 0 || x.description?.length > 0) && <Divider sx={{ pt: 1, mb: 1, width: "200px"}}/>}
                                                    {x.pOCName?.length > 0 || x.pOCPhone?.length > 0 && <Typography variant="caption">
                                                        POC: <b>{(x.pOCName || "" + " " + x.pOCPhone || "").trim()}</b>
                                                    </Typography>}
                                                    {x.description?.length > 0 && <Typography variant="caption">
                                                        <div>
                                                            {x.description}
                                                        </div>
                                                    </Typography>}
                                                </Box>
                                            </TimelineContent>
                                        </TimelineItem>;
                                    })}
                                    {this.orderDetailsStore.details.canUpdateStops && <TimelineItem position={"right"}>
                                        <TimelineOppositeContent sx={{width: "250px", flex: "none"}} align="right" variant="body2"/>
                                        <TimelineSeparator>
                                            <TimelineDot color="primary" sx={{ cursor: "pointer" }} onClick={() => this.orderDetailsStore.openEditStopItemModal(null)}>
                                                <AddIcon/>
                                            </TimelineDot>
                                        </TimelineSeparator>
                                        <TimelineContent/>
                                    </TimelineItem>}
                                </Timeline>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        {this.orderDetailsStore.stops?.length > 1 && <iframe width="100%" height="500px" style={{border: 0}} loading="lazy" allowFullScreen
                                                                             src={`https://www.google.com/maps/embed/v1/directions?${this.orderDetailsStore.getGoogleMapParams()}`}></iframe>}
                    </Grid>
                </Grid>
            </Grid>

            {this.orderDetailsStore.isEditOrderItemModalOpen && <ModalOrderItem orderId={this.orderDetailsStore.orderId} closeOrderItemModal={this.orderDetailsStore.closeEditOrderItemModal} item={this.orderDetailsStore.selectedEditOrderItem} refreshOrderItems={this.orderDetailsStore.refreshOrderItems}/>}
            {this.orderDetailsStore.isEditStopItemModalOpen && <ModalOrderStop orderId={this.orderDetailsStore.orderId} closeOrderStopModal={this.orderDetailsStore.closeEditStopItemModal} item={this.orderDetailsStore.selectedEditStopItem} refreshOrderStops={this.orderDetailsStore.refreshOrderStops}/>}
        </Container>;
    }

    componentDidMount() {
        this.orderDetailsStore.init(this.props.orderId);
    }
}

export const IndexDetails = (props) => {
    const { id } = useParams();
    return <OrderDetailIndex {...props} orderId={id} />;
}
