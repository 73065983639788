import {NavMenuItem} from "app/modules/common/menu/menu";
import {Paths} from "app/modules/admin/stores/paths";
import React from "react";

import BusinessIcon from '@mui/icons-material/Business';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkIcon from '@mui/icons-material/Work';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

export const Tenants = new NavMenuItem({
    path: Paths.Tenants.nav,
    title: "Tenants",
    icon: <WorkIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Organizations = new NavMenuItem({
    path: Paths.Organizations.nav,
    title: "Organizations",
    icon: <BusinessIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const Users = new NavMenuItem({
    path: Paths.Users.nav,
    title: "Users",
    icon: <PeopleAltIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});

export const HangfireDashboard = new NavMenuItem({
    path: Paths.HangfireDashboard.nav,
    externalUrl: true,
    title: "Hangfire",
    icon: <ManageHistoryIcon fontSize={"small"}/>,
    expanded: false,
    selected: false
});
