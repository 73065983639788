import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {long, required} from "app/modules/common/form/validators";
import {
    CompanyIntegrationType,
    CompanyIntegrationUserEditModel
} from "app/modules/company/api/clients.api";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";
import {SectionTextField} from "app/modules/common/details/text-field";

export interface DialogIntegrationUserSettingEditProps {
    closeModal: () => void;
    updated: () => void;
    id: number;
    type: CompanyIntegrationType;
    settingsJSON?: string | null;
}

export const DialogIntegrationUserSettingEdit = observer((props: DialogIntegrationUserSettingEditProps) => {
    const [formDatState, _] = useState(new FormState({
        id: new FieldState(null).validators(long),
        username: new FieldState(null).validators(required)
    }));

    const [isLoading, setLoading] = useState(true);

    const store = useInstance(ApiClients);

    useEffect(() => {
        const fetchData = async () => {
            const settings = JSON.parse(props.settingsJSON) || {};

            switch (props.type) {
                case CompanyIntegrationType.DAT:
                    formDatState.$.id.value = props.id;
                    formDatState.$.username.value = settings.Username;
                    break;
                default:
                    throw new Error();
            }

            setLoading(false);
        };

        fetchData();
    }, [])

    const save = async () => {
        if(isLoading) {
            return;
        }

        let formState;

        switch (props.type) {
            case CompanyIntegrationType.DAT:
                formState = formDatState;
                break;
            default:
                throw new Error();
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);

            let settings = {} as any;

            switch (props.type) {
                case CompanyIntegrationType.DAT:
                    settings.Username = formDatState.$.username.value;
                    break;
                default:
                    throw new Error();
            }

            await store.companyIntegrationClient.upsertUserSettings(new CompanyIntegrationUserEditModel({
                id: formState.$.id.value,
                settingsJSON: JSON.stringify(settings)
            }));

            props.updated();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Update</DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                { props.type == CompanyIntegrationType.DAT && <Stack spacing={2} direction={"column"}>
                    <SectionTextField
                        label={"DAT Username"}
                        field={formDatState.$.username}
                        fullWidth
                    />
                </Stack> }
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
