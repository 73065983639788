import {Popover, Stack, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {LinkPointerStyle} from "app/modules/common/details/info-element";


export const MouseOverText = (params: {children: ReactNode, text?: string}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <React.Fragment>
        {params.text?.length > 0 ? <Stack
            direction={"row"}
            spacing={1}
            ria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <Typography style={LinkPointerStyle} variant={"body2"}>{params.children}</Typography>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Stack direction={"row"} spacing={1} sx={{ padding: 2 }}>
                    <Typography variant={"body2"}>{params.text}</Typography>
                </Stack>
            </Popover>
        </Stack> : params.children}
    </React.Fragment>;
}
