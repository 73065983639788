import React, {Component} from "react";
import {GoogleMap, Marker} from "@react-google-maps/api";
import {inject, provider} from "react-ioc";
import {FleetViewStore} from "app/modules/company/components/fleet-view/FleetViewStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {observer} from "mobx-react";
import {Avatar, Box, Button, Divider, InputBase, MenuItem, Stack, Typography} from "@mui/material";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {
    BoxCenter,
    LeftVehicleContentPanel,
    LeftVehicleFilterPanel,
    LeftVehiclePanel
} from "app/modules/common/layout/components/containers";
import {SectionSelectField} from "app/modules/common/details/text-field";
import {LocationLink} from "app/modules/common/details/location-link";
import Colors from "app/modules/common/static/colors";
//
// import IconDriving from "app/modules/company/assets/map/moving-street.svg";
// import IconStop from "app/modules/company/assets/map/stationary-street.svg";


const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: 39.127745,
    lng: -94.782415
};

const StatusAvatar = (prop: {status: boolean}) =>
    <Avatar sx={{
        bgcolor: prop.status ? "rgb(40, 166, 72)" : "rgb(94, 94, 94)",
        width: "22px",
        height: "22px",
        fontSize: prop.status ? "12px" : "8px",
        fontWeight: "bold" }}>{prop.status ? "D" : "OFF"}</Avatar>

@provider(FleetViewStore)
@observer
export default class Index extends Component<any, any> {
    @inject fleetViewStore: FleetViewStore;
    interval = null;

    render() {
        if(this.fleetViewStore.isInitLoading) {
            return <Loading/>;
        }

        return (
            <Box>
                <LeftVehicleFilterPanel>
                    <BoxCenter>
                        <SearchPaper>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.fleetViewStore.filters.$.search.value}
                                onChange={(e)=>this.fleetViewStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.fleetViewStore.applyFilters}>Search</Button>
                        </SearchPaper>
                    </BoxCenter>
                    <Box sx={{paddingTop: "12px"}}>
                        <SectionSelectField
                            variant={"filled"}
                            field={this.fleetViewStore.filters.$.carrierId}
                            width={"100%"}
                            label={"Carrier"}
                            onChange={() => this.fleetViewStore.applyFilters()}
                            items={() => this.fleetViewStore.carrier.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                        />
                    </Box>
                </LeftVehicleFilterPanel>
                <LeftVehiclePanel>
                    {this.fleetViewStore.filteredItems.map(x => {
                        return <Box sx={{ width: "100%", backgroundColor: this.fleetViewStore.selectedVehicle == x.id ? Colors.LeftMenuSelectedColor : null, cursor: "pointer" }} key={x.id} onClick={() => this.fleetViewStore.setSelected(x.id)}>
                                <Stack direction={"row"} sx={{ width: "100%", pt: 1, pb: 1 }}>
                                    <Box sx={{ width: "20px", pl: 1, pr: 1 }}>
                                        <StatusAvatar status={x.speed > 0}/>
                                    </Box>
                                    <Box sx={{ width: "250px" }}>
                                        <Stack direction={"column"}>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                <b>#{x.number}</b> {x.make} {x.year}
                                            </Typography>
                                            {x.speed > 0 && <Typography variant="caption" display="block" gutterBottom>
                                                <b>Driver name</b>
                                            </Typography>}
                                            {x.carrierName?.length > 0 && <Typography variant="caption" display="block" gutterBottom sx={{ fontSize: "9px" }}>
                                                <i>{x.carrierName}</i>
                                            </Typography>}
                                            <Typography variant="caption" display="block" gutterBottom>
                                                <LocationLink location={x.location} locationDisplay={x.displayLocation}/> {x.locationAt && "-"} <b>{x.locationAt?.fromNow()}</b>
                                            </Typography>
                                            {x.orders?.length > 0 && <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                                <Typography variant="body2" display="block" component={"div"}><b>Orders:</b></Typography>
                                                {x.orders.map(o => <Button key={o.orderId} style={{fontSize: "7pt"}} onClick={(event) => {
                                                    this.fleetViewStore.openOrderDetails(o.orderId);
                                                    event.stopPropagation();
                                                }}>{o.pickUpLocation} - {o.deliveryLocation} ({o.orderId})</Button>)}
                                            </Stack>}
                                        </Stack>
                                    </Box>
                                    <Box sx={{ width: "80px" }}>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {x.speed > 0 && (x.speed + " mph")}
                                        </Typography>
                                    </Box>
                                </Stack>
                            <Divider/>
                        </Box>
                    })}
                </LeftVehiclePanel>
                <LeftVehicleContentPanel>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={5}
                    >
                        {(this.fleetViewStore.selectedVehicle > 0 ? this.fleetViewStore.filteredItems
                            .filter(x => x.id == this.fleetViewStore.selectedVehicle) : this.fleetViewStore.filteredItems)
                            .filter(x => x.lat != null && x.lon != null)
                            .map(x => {
                                return <Marker
                                    key={x.id}
                                    icon={x.speed > 0 ? null : {
                                        path: "M4 .565h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-12a4 4 0 0 1 4-4z",
                                        strokeColor: "#5a6366",
                                        fillColor: "#5e5e5e",
                                        fillOpacity: 1,
                                        scale: 1.2,
                                        labelOrigin: { x: 10, y: 10, equals(other: google.maps.Point): boolean { return true; } }
                                    }}
                                    position={{
                                        lat: x.lat,
                                        lng: x.lon
                                    }}
                                    label={{
                                        text: x.number?.toString(),
                                        color: "White",
                                        fontSize: "10px",
                                        fontWeight: "500"
                                    }}
                                />
                            })}
                    </GoogleMap>
                </LeftVehicleContentPanel>
            </Box>
        )
    }

    async componentDidMount() {
        await this.fleetViewStore.init();

        this.interval = setInterval(() => {
            this.fleetViewStore.refreshFleet();
        }, 60 * 5 * 1000);
    }

    async componentWillUnmount() {
        clearInterval(this.interval);
    }
}

/*
{
    path: "m113.956,57.006l-97.4,-56.2c-4,-2.3 -9,0.6 -9,5.2l0,112.5c0,4.6 5,7.5 9,5.2l97.4,-56.2c4,-2.401 4,-8.2 0,-10.5z",
        strokeColor: "#5a6366",
    fillColor: "#5e5e5e",
    fillOpacity: 1,
    scale: 0.28,
    labelOrigin: { x: 48, y: 60, equals(other: google.maps.Point): boolean { return true; } }
}
*/
