import {observer} from "mobx-react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography} from "@mui/material";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {NoteType, OrderNotesList} from "app/modules/company/api/clients.api";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";

export interface DialogOrderNotesProps {
    closeModal: () => void;
    notes: OrderNotesList[];
}

export const DialogOrderNotes = observer((props: DialogOrderNotesProps) => {
    let notes =  props.notes?.filter(x => x.type == NoteType.Order);
    let accountingNotes =  props.notes?.filter(x => x.type == NoteType.OrderAccounting);

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xl"}
    >
        <DialogTitle id="alert-dialog-title">
            Order Notes
        </DialogTitle>
        <DialogContent
        sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "700px" }}>
                <Stack spacing={1} direction={"row"}>
                    <Stack spacing={1} direction={"column"} sx={{ width: "50%"}}>
                        <Typography variant={"h6"} color={"#1976d2"}>Notes</Typography>
                        <Divider/>
                        {notes?.length > 0 ? notes.map(x => <NoteElement key={x.createdAt.valueOf()} note={x}/>) : "N/A"}
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{ marginTop: "50px !important" }}/>
                    <Stack spacing={1} direction={"column"} sx={{ width: "50%"}}>
                        <Typography variant={"h6"} color={"#1976d2"}>Accounting Notes</Typography>
                        <Divider/>
                        {accountingNotes?.length > 0 ? accountingNotes.map(x => <NoteElement key={x.createdAt.valueOf()} note={x}/>) : "N/A"}
                    </Stack>
                </Stack>
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={props.closeModal}>Close</LoadingButton>
        </DialogActions>
    </Dialog>
});

const NoteElement = (props: {note: OrderNotesList}) => <Stack direction={"column"}>
    <Typography variant={"subtitle2"}><b>{props.note.notes}</b></Typography>
    <Typography variant={"body2"}><i>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(props.note.createdAt)} - {props.note.createdBy}</i></Typography>
</Stack>
