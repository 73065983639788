import React, {Component} from "react";
import {Button, Chip, Grid, InputBase, Stack, Typography} from "@mui/material";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {Link} from "react-router-dom";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {TenantsStore} from "app/modules/admin/components/tenants/TenantsStore";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {NavigationStore} from "app/modules/admin/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
/*import {BtnLink} from "app/modules/common/layout/components/btn-link";*/
import {Paths} from "app/modules/admin/stores/paths";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {TenantAdminListModel} from "app/modules/admin/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {StringHelper} from "app/modules/common/helpers/StringHelper";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        minWidth: 90,
        flex: 1
    },
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 5,
        renderCell: (params: GridRenderCellParams<TenantAdminListModel>) => (
            <Link to={Paths.EditTenant.nav(params.row.id)}>{params.row.name}</Link>
        ),
    },
    {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 300,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TenantAdminListModel>) => (
            <Stack direction="row" spacing={1}>
                <Chip label="pending" color="primary" size="small" />
                <Chip label="test" color="success" size="small" />
            </Stack>
        ),
    },
    {
        field: 'organizationName',
        headerName: 'Organization',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TenantAdminListModel>) => (
            params.row.organizationName
        ),
    },
    {
        field: 'uSDOT',
        headerName: 'USDOT',
        minWidth: 150,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TenantAdminListModel>) => (
            params.row.uSDOT
        ),
    },
    {
        field: 'mC_MX_FF',
        headerName: 'MC/MX/FF',
        minWidth: 150,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TenantAdminListModel>) => (
            params.row.mC_MX_FF
        ),
    },
    {
        field: 'node',
        headerName: 'Node',
        minWidth: 250,
        flex: 2,
        renderCell: (params: GridRenderCellParams<TenantAdminListModel>) => (
            <Stack direction="column" spacing={0}>
                <Typography variant="subtitle2" component="div">{params.row.serverName}</Typography>
                <Typography variant="body2" component="div">{params.row.serverDb}.{params.row.serverSchema}</Typography>
            </Stack>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TenantAdminListModel>) => (
            StringHelper.formatActive(params.row.active)
        )
    }
];

@provider(TenantsStore)
@observer
export default class Index extends Component<any, any> {
    @inject tenantsStore: TenantsStore;
    @inject navigationStore: NavigationStore;

    render() {
        if(this.tenantsStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.tenantsStore.totalRows}/>
            </Grid>
            {/*<Grid item xs={6} textAlign={"right"}>*/}
            {/*    <BtnLink Link={Paths.NewTenant.nav} Text="New Tenant"/>*/}
            {/*</Grid>*/}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.tenantsStore.filters.$.search.value}
                            onChange={(e)=>this.tenantsStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.tenantsStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.tenantsStore.items}
                    columns={columns}
                    pageSize={this.tenantsStore.pageSize}
                    rowsPerPageOptions={this.tenantsStore.pageSizeOptions}
                    rowCount={this.tenantsStore.totalRows}
                    page={this.tenantsStore.skipRows/this.tenantsStore.pageSize}
                    loading={this.tenantsStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.tenantsStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.tenantsStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.tenantsStore.init();
    }
}

