import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {Divider, Grid, Stack, TextField, Typography} from "@mui/material";
import {useInstance} from "react-ioc";
import {TenantNewStore} from "app/modules/organization/components/tenants/new/TenantNewStore";
import {BoxCenter, ContainerDetails} from "app/modules/common/layout/components/containers";
import {Section, SectionSeparator} from "app/modules/common/layout/components/details";
import {observer} from "mobx-react";
import {USDOTInfoDetails} from "app/modules/organization/components/tenants/new/usdot-info-details";

export const USDOTInfo = observer(() => {
    let tenantNewStore = useInstance(TenantNewStore);

    return <React.Fragment>
        <PageTitleDetails Title={"New Company - Details"} Size={12}/>
        <ContainerDetails>
            <Grid item xs={12}>
                <Typography variant="h5" component="div" textAlign={"center"}>
                    Company Info
                </Typography>
                <Divider sx={{ pt: 2, mb: 4 }} light />
            </Grid>
            <Grid item xs={12} sx={{ pl: 4 }}>
                <BoxCenter>
                    <Stack direction={"row"} spacing={2}>
                        <TextField
                            value={tenantNewStore.usdotSearchForm.$.usdot.value || ''}
                            error={tenantNewStore.usdotSearchForm.$.usdot.hasError}
                            helperText={tenantNewStore.usdotSearchForm.$.usdot.error}
                            onChange={(e)=>tenantNewStore.usdotSearchForm.$.usdot.onChange(e.target.value)}
                            label="USDOT"
                            type="number"
                            variant="standard"
                        />
                        <LoadingButton loading={tenantNewStore.isLoading} variant="text" onClick={tenantNewStore.getUSDOTDetails}>Search</LoadingButton>
                    </Stack>
                </BoxCenter>
            </Grid>
            <SectionSeparator/>
            {tenantNewStore.usdotDetails && <Section size={12}><USDOTInfoDetails info={tenantNewStore.usdotDetails} /></Section>}
            {tenantNewStore.usdotDetails && <SectionSeparator/>}
            <Grid item xs={12}>
                { tenantNewStore.usdotDetails && !tenantNewStore.usdotSearchForm.$.usdot.hasError && <Grid item xs={12}>
                    <BoxCenter>
                        <LoadingButton loading={tenantNewStore.isLoading} variant="contained" color={"success"} onClick={tenantNewStore.save}>
                            Create Company
                        </LoadingButton>
                    </BoxCenter>
                </Grid>}
            </Grid>
        </ContainerDetails>
    </React.Fragment>
})
