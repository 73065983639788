import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfOrderCompleteListFilters,
    OrderCompleteListFilters,
    OrderCompleteType,
    OrderModelListView
} from "app/modules/company/api/clients.api";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import React from "react";
import {ModalOrderDetails} from "app/modules/company/components/orders/details/modal.order.details";
import {DialogInvoiceNew} from "app/modules/company/components/accounting/orders/open/dialog.invoice.new";

export class OrdersStore extends BaseListStore<OrderModelListView>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState(''),
        driverIds: new FieldState([]),
        carriersIds: new FieldState([]),
        vehicleIds: new FieldState([]),
        type: new FieldState<OrderCompleteType>(OrderCompleteType.NotInvoiced)
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action,
        });
    }

    applySpecificFilters() {
    }

    createdInvoice = (orderId: number, customerId: number) => {
        const key = "new_invoice_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogInvoiceNew closeModal={() => this.dialogStore.closeDialog(key)} invoiceCreated={async () => {
                this.dialogStore.closeDialog(key);
                await this.loadData();
            }} orderId={orderId} customerId={customerId}/>
        });
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            await this.filters.validate();

            if(this.filters.hasError) {
                return;
            }

            let filters = new OrderCompleteListFilters({
                search: this.filters.$.search.value,
                driversIds: this.filters.$.driverIds.value,
                vehicleIds: this.filters.$.vehicleIds.value,
                carrierIds: this.filters.$.carriersIds.value,
                type: this.filters.$.type.value
            });

            const [result] = await Promise.all([
                this.companyApiClients.orderClient.getOpenOrdersList(new DataListGetParamsOfOrderCompleteListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    openOrder = (orderId: number) => {
        const key = "order_details_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalOrderDetails orderId={orderId} closeOrderModal={() => this.dialogStore.closeDialog(key)} /></div>
        });
    }

    async init() {
        await this.loadData()

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
