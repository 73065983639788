import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Avatar, Button, Grid, InputBase} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {DriversStore} from "app/modules/company/components/drivers/DriversStore";
import {Link} from "react-router-dom";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {DriverListModel, DutyStatus, TenantClaims} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {Paths} from "app/modules/company/stores/paths";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {MainStore} from "app/modules/company/stores/MainStore";

const getColor = (input: Date) => {
    const date = new Date(input);
    const now = new Date();
    const nowPlus30 = DateTimeHelper.addDays(new Date(), 30);

    if(date < now) {
        return "#f60000";
    } else if(date > nowPlus30) {
        return "#49d535";
    } else {
        return "#a7a742";
    }
}

const StatusAvatar = (prop: {status: string, color: string}) =>
    <Avatar sx={{ bgcolor: prop.color, width: "20px", height: "20px", fontSize: "10px", fontWeight: "bold" }}>{prop.status}</Avatar>

export const MapStatus: Map<DutyStatus, React.ReactNode> = new Map<DutyStatus, React.ReactNode>([
    [DutyStatus.Driving, <StatusAvatar color="rgb(40, 166, 72)" status="D"/>],
    [DutyStatus.OffDuty, <StatusAvatar color="rgb(40, 166, 72)" status="OFF"/>],
    [DutyStatus.OnDuty, <StatusAvatar color="rgb(40, 166, 72)" status="ON"/>],
    [DutyStatus.PersonalConveyance, <StatusAvatar color="rgb(40, 166, 72)" status="PC"/>],
    [DutyStatus.SleeperBed, <StatusAvatar color="rgb(40, 166, 72)" status="SB"/>],
    [DutyStatus.YardMove, <StatusAvatar color="rgb(40, 166, 72)" status="YM"/>]
]);

const columns = [
    {
        field: 'fullName',
        headerName: 'Name',
        minWidth: 250,
        flex: 4,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            <Link to={Paths.EditDriver.nav(params.row.id)}>{params.row.fullName}</Link>
        ),
    },
    {
        field: 'note',
        headerName: 'Note',
        minWidth: 250,
        flex: 4,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            StringHelper.formatNull(params.row.note)
        ),
    },
    {
        field: 'email',
        headerName: 'Email',
        minWidth: 200,
        flex: 2
    },
    {
        field: 'phone',
        headerName: 'Phone',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            StringHelper.formatPhoneNumber(params.row.phone)
        ),
    },
    {
        field: 'carrierName',
        headerName: 'Carrier',
        minWidth: 250,
        flex: 2,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            StringHelper.formatNull(params.row.carrierName)
        ),
    },
    {
        field: 'vehicle',
        headerName: 'Vehicle',
        minWidth: 150,
        flex: 2,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            StringHelper.formatNull(params.row.vehicle)
        ),
    },
    {
        field: 'driverLicense',
        headerName: 'Driver License',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            `${params.row.driverLicenseState}-${params.row.driverLicense}`
        ),
    },
    {
        field: 'medicalExpiration',
        headerName: 'Med.Exp',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            <div style={params.row.medicalExpiration && {color: getColor(params.row.medicalExpiration)}}>{DateTimeHelper.formatMomentDate(params.row.medicalExpiration)}</div>
        ),
    },
    {
        field: 'driverLicenseExpiration',
        headerName: 'DL.Exp',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            <div style={params.row.driverLicenseExpiration && {color: getColor(params.row.driverLicenseExpiration)}}>{DateTimeHelper.formatMomentDate(params.row.driverLicenseExpiration)}</div>
        ),
    },
    {
        field: 'hasIntegrations',
        headerName: 'Integrations',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            StringHelper.formatYesNo(params.row.hasIntegrations)
        ),
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.createdAt)
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<DriverListModel>) => (
            StringHelper.formatActive(params.row.active)
        )
    }
];

@provider(DriversStore)
@observer
export default class Index extends Component<any, any> {
    @inject driversStore: DriversStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.driversStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.DriverEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.driversStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.DriverEdit) && <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewDriver.nav} Text="New Driver"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.driversStore.filters.$.search.value}
                            onChange={(e)=>this.driversStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.driversStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.driversStore.items}
                    columns={columns}
                    pageSize={this.driversStore.pageSize}
                    rowsPerPageOptions={this.driversStore.pageSizeOptions}
                    rowCount={this.driversStore.totalRows}
                    page={this.driversStore.skipRows/this.driversStore.pageSize}
                    loading={this.driversStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.driversStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.driversStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.driversStore.init();
    }
}

