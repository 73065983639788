import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {requiredDate, requiredNumber} from "app/modules/common/form/validators";
import {OrderStopEditModel, OrderStopType} from "app/modules/company/api/clients.api";
import {ModalOrderStopParams} from "app/modules/company/components/orders/details/modal.stop";
import * as moment from "moment";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogSelectAddress} from "app/modules/company/components/orders/details/dialog.address";
import {action, makeObservable, observable, runInAction} from "mobx";

export class OrderStopStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    params: ModalOrderStopParams = null;
    stopName: string = null;
    stopFullAddress: string = null;

    formState = new FormState({
        type: new FieldState(OrderStopType.Shipper).validators(requiredNumber),
        addressId: new FieldState(null),
        date: new FieldState<moment.Moment>(moment(new Date())).validators(requiredDate),
        isDirectOrASAP: new FieldState(false),
        description: new FieldState(''),
        pOCName: new FieldState(''),
        pOCPhone: new FieldState(''),
        arrived: new FieldState<moment.Moment>(null),
        departed: new FieldState<moment.Moment>(null)
    });

    constructor() {
        super();

        makeObservable(this, {
            stopName: observable,
            stopFullAddress: observable,

            clearStopInfo: action
        });

        this.isInitLoading = false;
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.orderClient.upsertOrderStop(this.params.orderId, new OrderStopEditModel({
                id: this.params.item?.id || 0,
                type: this.formState.$.type.value,
                orderStopAddressId: this.formState.$.addressId.value,
                date: this.formState.$.date.value,
                isDirectOrASAP: this.formState.$.isDirectOrASAP.value,
                description: this.formState.$.description.value,
                pOCName: this.formState.$.pOCName.value,
                pOCPhone: this.formState.$.pOCPhone.value,
                arrived: this.formState.$.arrived.value,
                departed: this.formState.$.departed.value
            }));

            this.params.refreshOrderStops();
            this.params.closeOrderStopModal();
        });
    }

    clearStopInfo = () => {
        this.stopName = null;
        this.stopFullAddress = null;
        this.formState.$.addressId.onChange(null);
    }

    changeAddress = () => {
        const key = "stop_address_" + this.params.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSelectAddress closeModal={() => this.dialogStore.closeDialog(key)} orderStopType={this.formState.$.type.value} setAddress={async (addressId: number, fullAddress: string, addressName: string) => {
            await this.wrapLoadingApiCall(async () => {
                this.formState.$.addressId.onChange(addressId);

                runInAction(() => {
                    this.stopFullAddress = fullAddress;
                    this.stopName = addressName;
                });

                this.dialogStore.closeDialog(key);
            });
        }} title="Update Address"/>
    });
    }

    init = (params: ModalOrderStopParams) => {
        this.params = params;

        if(this.params.item) {
            this.stopName = this.params.item.name;
            this.stopFullAddress = this.params.item.fullAddress;

            this.formState.$.type.value = this.params.item.type;
            this.formState.$.addressId.value = this.params.item.orderStopAddressId;
            this.formState.$.date.value = this.params.item.date;
            this.formState.$.isDirectOrASAP.value = this.params.item.isDirectOrASAP;
            this.formState.$.description.value = this.params.item.description;
            this.formState.$.pOCName.value = this.params.item.pOCName;
            this.formState.$.pOCPhone.value = this.params.item.pOCPhone;
            this.formState.$.arrived.value = this.params.item.arrived;
            this.formState.$.departed.value = this.params.item.departed;
        }
    }
}
