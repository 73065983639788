import {Box, Grid, IconButton, Stack} from "@mui/material";
import React from "react";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #0000008a',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px"
};

export const ModalContent = (props) => <Box sx={style}>
    <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
    >
        {props.children}
    </Grid>
</Box>

const styleFull = (color: string = null) => ({
    position: 'absolute',
    width: "100vw",
    height: "100vh",
    top: '0',
    left: '0',
    bgcolor: color == undefined ? 'background.paper' : color,
    border: '2px solid #0000008a'
});

export const ModalFullContent = (props: {children: React.ReactNode, color?: string}) => <Box sx={styleFull(props.color)}>
    <Grid
        container
        spacing={0}
        sx={{p: 4}}
    >
        {props.children}
    </Grid>
</Box>

export const ModalFullContentDetails = (props: { children: React.ReactNode, closeModal: () => void, title: string, color?: string }) => <Box sx={styleFull(props.color)}>
    <Grid
        container
        spacing={0}
        sx={{p: 4}}
    >
        <PageTitleDetails Title={props.title} Size={8}/>
        <Grid item xs={4}>
            <Stack direction={"row"} display="flex" justifyContent={"right"} alignItems={"flex-start"}>
                <IconButton color={"secondary"} size={"large"} onClick={props.closeModal}>
                    <CloseIcon color={"disabled"}/>
                </IconButton>
            </Stack>
        </Grid>
        <Grid item xs={12} sx={{overflowY: "auto", height: "calc(100vh - 160px)"}}>
            {props.children}
        </Grid>
    </Grid>
</Box>
