import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    TelegramChannelListModel,
    DataListGetParamsOfTelegramChannelListFilters,
    TelegramChannelListFilters
} from "app/modules/company/api/clients.api";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogInfo} from "app/modules/common/dialog/dialog.info";
import {Button} from "@mui/material";
import {DialogChannel} from "app/modules/company/components/settings/telegram/link/dialog.channel";

export class TelegramChannelStore extends BaseListStore<TelegramChannelListModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    update = async (channelId: number) => {
        const key = "update_channel";
        this.dialogStore.addDialog({
            key: key,
            component: <DialogChannel closeModal={() => this.dialogStore.closeDialog(key)} channelId={channelId} onSave={() => {
                this.dialogStore.closeDialog(key);
                this.loadData();
            }}/>
        });
    }

    generateAuthCode = async () => {
        await this.wrapLoadingApiCall(async () => {
            const code = await this.companyApiClients.telegramClient.generateAuthCode();

            const key = code;
            this.dialogStore.addDialog({
                key: key,
                component: <DialogInfo closeModal={() => this.dialogStore.closeDialog(key)} title="Auth Code" text={<div>
                    <div>Please use this code: <Button onClick={() => navigator.clipboard.writeText(code)}>{code}</Button> to authorize a new channel to be used.</div>
                    <div>This code is valid for 3 mins.</div>
                </div>}/>
            });
        });
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new TelegramChannelListFilters({search: this.filters.$.search.value});
            const [result] = await Promise.all([
                this.companyApiClients.telegramClient.getList(new DataListGetParamsOfTelegramChannelListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    delete = async (id: number) => {
        this.dialogStore.addConfirmDialog({
            okColor: "error",
            title: "Delete Link",
            description: "Are you sure, you want to delete this telegram link?",
            cancelText: "Close",
            okText: "Delete",
            okAction: async () => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.telegramClient.deleteTelegramChannelLink(id);
                    await this.loadData();
                });
            }
        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
