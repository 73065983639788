import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Box, Button, Grid, IconButton, InputBase, Stack} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {VehiclesStore} from "app/modules/company/components/vehicles/VehiclesStore";
import {Link} from "react-router-dom";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {VehicleListModel, TenantClaims} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {Paths} from "app/modules/company/stores/paths";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {MainStore} from "app/modules/company/stores/MainStore";
import {LocationLink} from "app/modules/common/details/location-link";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import {SectionTextField} from "app/modules/common/details/text-field";
import {VehicleTypesMap} from "app/modules/company/components/vehicles/details/VehicleDetailsStore";
import {CarrierRating} from "app/modules/company/components/carriers/carrier-rating";

const getColor = (input: Date) => {
    const date = new Date(input);
    const nowPlus30 = DateTimeHelper.addDays(new Date(), 30);
    const nowPlus7 = DateTimeHelper.addDays(new Date(), 7);

    if(date < nowPlus7) {
        return "#f60000";
    } else if(date > nowPlus30) {
        return "#49d535";
    } else {
        return "#a7a742";
    }
}

const columns = [
    {
        field: 'displayName',
        headerName: 'Name',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            <Link to={Paths.EditVehicle.nav(params.row.id)}>{params.row.displayName}</Link>
        ),
    },
    {
        field: 'number',
        headerName: 'Number',
        minWidth: 70,
        flex: 1,
    },
    {
        field: 'vIN',
        headerName: 'VIN',
        minWidth: 200,
        flex: 1
    },
    {
        field: 'spec',
        headerName: 'SPECS',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            <Button variant={"text"} onClick={() => navigator.clipboard.writeText(params.row.specs)}>SPECS</Button>
        ),
    },
    {
        field: 'rating',
        headerName: 'Rating',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => <CarrierRating
                vehicleRating={params.row.rating}
                vehicleCustomPeriodRating={params.row.customPeriodRating}
                carrierRating={params.row.carrierRating}
                carrierCustomPeriodRating={params.row.carrierCustomPeriodRating}
            />
        ,
    },
    {
        field: 'type',
        headerName: 'Type',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            VehicleTypesMap.get(params.row.type)
        ),
    },
    {
        field: 'carrierName',
        headerName: 'Carrier',
        minWidth: 250,
        flex: 2,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            StringHelper.formatNull(params.row.carrierName)
        ),
    },
    {
        field: 'license',
        headerName: 'Plate',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            params.row.licenseNumber + (params.row.licenseState?.length > 0 ? ` (${params.row.licenseState})` : '')
        ),
    },
    {
        field: 'maxLoad',
        headerName: 'Max Load',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            params.row.maxLoad
        ),
    },
    {
        field: 'location',
        headerName: 'Location',
        minWidth: 450,
        flex: 2,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => {
            const store = useInstance(VehiclesStore);
            return <div>
                <Stack direction={"row"} alignItems={"center"}>
                    {params.row.distance != null && <div><b>{params.row.distance}</b>mi</div>}
                    <IconButton color="primary" onClick={() => store.openVehicleHistory(params.row.id)}><TravelExploreIcon/></IconButton>
                    {params.row.location && <Button variant={"text"} onClick={() => navigator.clipboard.writeText(params.row.location)}>COPY</Button>}
                    <LocationLink location={params.row.location} locationDisplay={params.row.displayLocation}/>
                </Stack>

            </div>;
        },
    },
    {
        field: 'locatedAt',
        headerName: 'LocatedAt',
        minWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => {
            return <Stack direction={"column"}>
                <div>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(params.row.locatedAt)}</div>
                {params.row.locatedAt && <div>{params.row.locatedAt?.fromNow()}</div>}
            </Stack>;
        },
    },
    {
        field: 'registrationExpirationDate',
        headerName: 'Registration Exp',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            <div style={params.row.registrationExpirationDate && {color: getColor(params.row.registrationExpirationDate)}}>{DateTimeHelper.formatMomentDate(params.row.registrationExpirationDate)}</div>
        ),
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.createdAt)
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<VehicleListModel>) => (
            StringHelper.formatActive(params.row.active)
        )
    }
];

@provider(VehiclesStore)
@observer
export default class Index extends Component<any, any> {
    @inject vehiclesStore: VehiclesStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.vehiclesStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.VehicleEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.vehiclesStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.VehicleEdit) && <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewVehicle.nav} Text="New Vehicle"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.vehiclesStore.filters.$.search.value}
                            onChange={(e)=>this.vehiclesStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.vehiclesStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <Grid item xs={12}>
                <Box sx={{ mb: 1, width: "100%", backgroundColor: "#fcfcfc", p: 2 }}>
                    <Stack direction={"row"} spacing={2} sx={{ width: "100%" }} alignItems="center" justifyContent="center">
                        <SectionTextField
                            label={"From Zip"}
                            field={this.vehiclesStore.filters.$.zip}
                            type={"number"}
                            width={"100px"}
                        />
                        <SectionTextField
                            label={"From Radius"}
                            field={this.vehiclesStore.filters.$.radius}
                            type={"number"}
                            width={"100px"}
                        />
                    </Stack>
                </Box>
            </Grid>
            <DataGridBody openFilter={true}>
                <DataGridPro
                    disableVirtualization
                    rows={this.vehiclesStore.items}
                    columns={columns}
                    pageSize={this.vehiclesStore.pageSize}
                    rowsPerPageOptions={this.vehiclesStore.pageSizeOptions}
                    rowCount={this.vehiclesStore.totalRows}
                    page={this.vehiclesStore.skipRows/this.vehiclesStore.pageSize}
                    loading={this.vehiclesStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.vehiclesStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.vehiclesStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.vehiclesStore.init();
    }
}

