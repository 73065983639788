import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {
    Box,
    Button,
    Grid,
    InputBase, Stack,
    Typography
} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {OrdersHoldStore} from "app/modules/company/components/orders/hold/OrdersStore";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {
    OrderModelListView, OrderNotesList,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {LoadingButton} from "@mui/lab";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {
    SectionDatePickerField,
    SectionTextField
} from "app/modules/common/details/text-field";
import {DriverFilter} from "app/modules/company/components/filters/drivers";
import {VehicleFilter} from "app/modules/company/components/filters/vehicle";
import {orderStatusColorMap} from "app/modules/company/components/orders/all";
import {TrackingDetails} from "app/modules/company/components/vehicles/components/tracking-details";

const columns = [
    {
        field: 'status',
        headerName: '',
        maxWidth: 30,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <Box sx={{ width: "5px", height: "100%", backgroundColor: orderStatusColorMap.get(params.row.status) }} />
        ),
    },
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => {
            const store = useInstance(OrdersHoldStore);
            return <Button variant={"text"} onClick={() => store.openOrder(params.row.id)}>{params.row.id}</Button>;
        },
    },
    {
        field: 'po',
        headerName: 'PO',
        width: 100,
        valueGetter: (params: GridRenderCellParams<OrderModelListView>) =>
            `${StringHelper.formatNull(params.row.orderNumber)}`,
    },
    {
        field: 'pickUp',
        headerName: 'From',
        minWidth: 200,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <Stack direction={"column"}>
                <Box>{params.row.from || 'N/A'}</Box>
                <b>{DateTimeHelper.formatMomentDateShort(params.row.pickUp)}</b>
            </Stack>
        ),
    },
    {
        field: 'drop',
        headerName: 'To',
        width: 200,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <Stack direction={"column"}>
                <Box>{params.row.to || 'N/A'}</Box>
                <b>{DateTimeHelper.formatMomentDateShort(params.row.drop)}</b>
            </Stack>
        ),
    },
    {
        field: 'notes',
        headerName: 'Notes (Latest 2)',
        width: 700,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => {
            const store = useInstance(OrdersHoldStore);
            return <Stack direction={"column"}>
                {params.row.orderNotes?.length > 0 && params.row.orderNotes.slice(0, 2).map((x: OrderNotesList) =>
                    <Stack direction={"row"} spacing={1} onClick={() => store.openDialogOrderNotes(params.row.orderNotes)}
                           sx={{
                               cursor: "pointer",
                               "&:hover": {
                                   color: "#1976d2",
                                   textDecoration: "underline"
                               }
                           }}>
                        <i><b>{x.notes}</b></i>
                        <span>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(x.createdAt)} - {x.createdBy}</span></Stack>) || "N/A"}
                    </Stack>;
        },
    },
    {
        field: 'vehicle',
        headerName: 'Vehicle',
        minWidth: 250,
        flex: 2,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <div>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <TrackingDetails vehicleId={params.row.vehicleId}><b>{params.row.vehicle}</b></TrackingDetails> {params.row.carrier?.length > 0 && <Typography variant="caption" display="block"> - {params.row.carrier}</Typography>}
                </Stack>
                <div>
                    {params.row.drivers?.join(", ")}
                </div>
                {!params.row.drivers?.length && !params.row.vehicle && "N/A"}
            </div>
        ),
    },
    {
        field: 'customer',
        headerName: 'Customer',
        minWidth: 300,
        flex: 2,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <div>
                <div>{params.row.customer || 'N/A'}</div>
                {params.row.contact?.length > 0 && <Typography variant="caption" display="block">
                    {params.row.contact}
                </Typography>}
            </div>
        ),
    }
];

@provider(OrdersHoldStore)
@observer
export default class Index extends Component<any, any> {
    @inject ordersStore: OrdersHoldStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.ordersStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.OrderEdit) ? 8 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.ordersStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.OrderNew) && <Grid item xs={4} textAlign={"right"}>
                <LoadingButton loading={this.ordersStore.isLoading} variant={"contained"} onClick={this.ordersStore.newBlankOrder}>New Blank Order</LoadingButton>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <Stack direction={"row"}>
                        <SearchPaper width={400}>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.ordersStore.filters.$.search.value}
                                onChange={(e)=>this.ordersStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.ordersStore.applyFilters}>Search</Button>
                        </SearchPaper>
                        <Button variant={"text"} size={"small"} sx={{ ml: 2 }} onClick={this.ordersStore.showHideAdvanceStore}>Advance Filters</Button>
                    </Stack>
                </BoxCenter>
            </DataGridHeader>
            {this.ordersStore.isAdvanceFilterOpen && <Box sx={{ mb: 1, width: "100%", backgroundColor: "#fcfcfc", p: 2 }}>
                <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
                    <SectionDatePickerField
                        field={this.ordersStore.filters.$.from}
                        label="From"
                        width={"150px"}
                    />
                    <SectionDatePickerField
                        field={this.ordersStore.filters.$.to}
                        label="To"
                        width={"150px"}
                    />
                    <SectionTextField
                        label={"From Zip"}
                        field={this.ordersStore.filters.$.fromZip}
                        type={"number"}
                        width={"100px"}
                    />
                    <SectionTextField
                        label={"From Radius"}
                        field={this.ordersStore.filters.$.fromRadius}
                        type={"number"}
                        width={"100px"}
                    />
                    <SectionTextField
                        label={"To Zip"}
                        field={this.ordersStore.filters.$.toZip}
                        type={"number"}
                        width={"100px"}
                    />
                    <SectionTextField
                        label={"To Radius"}
                        field={this.ordersStore.filters.$.toRadius}
                        type={"number"}
                        width={"100px"}
                    />
                    <DriverFilter value={this.ordersStore.filters.$.driverIds} width={"20%"} />
                    <VehicleFilter value={this.ordersStore.filters.$.vehicleIds} width={"20%"} />
                </Stack>
            </Box>}
            <DataGridBody openFilter={this.ordersStore.isAdvanceFilterOpen}>
                <DataGridPro
                    disableVirtualization
                    rows={this.ordersStore.items}
                    columns={columns}
                    pageSize={this.ordersStore.pageSize}
                    rowsPerPageOptions={this.ordersStore.pageSizeOptions}
                    rowCount={this.ordersStore.totalRows}
                    page={this.ordersStore.skipRows/this.ordersStore.pageSize}
                    loading={this.ordersStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.ordersStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.ordersStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.ordersStore.init();
    }
}
