import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {
    DataListGetParamsOfPaymentListFilters,
    PaymentListFilters, PaymentListModel
} from "app/modules/company/api/clients.api";
import React from "react";
import {DialogPaymentNew} from "app/modules/company/components/accounting/payments/dialog.payment.new";

export class PaymentStore extends BaseListStore<PaymentListModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action,
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        await this.wrapLoadingApiCall(async () => {
            await this.filters.validate();

            if(this.filters.hasError) {
                return;
            }

            let filters = new PaymentListFilters({
                search: this.filters.$.search.value
            });

            const [result] = await Promise.all([
                this.companyApiClients.paymentClient.getPaymentList(new DataListGetParamsOfPaymentListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    createNewPayment = async () => {
        const key = "new_payment_new";
        this.dialogStore.addDialog({
            key: key,
            component: <DialogPaymentNew closeModal={() => this.dialogStore.closeDialog(key)} paymentCreated={async () => {
                this.dialogStore.closeDialog(key);
                await this.loadData();
            }}/>
        });
    }

    delete = async (id: number) => {
        this.dialogStore.addConfirmDialog({
            okColor: "error",
            title: "Delete Payment",
            description: "Are you sure, you want to delete this payment?",
            cancelText: "Close",
            okText: "Delete",
            okAction: async () => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.paymentClient.removePayment(id);
                    await this.loadData();
                });
            }
        });
    }

    openPayment = (paymentId: number) => {

    }

    async init() {
        await this.loadData()

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
