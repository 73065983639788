import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {email, required} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {ContactEditModel, NamedValueLong, TenantClaims} from "app/modules/company/api/clients.api";
import {DialogSelectCustomer} from "app/modules/company/components/orders/details/dialog.customer";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";

export class ContactDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject dialogStore: DialogStore;

    title: string = 'New Contact';
    contactId?: number = null;
    customers?: NamedValueLong[] = null;

    formState = new FormState({
        fullName: new FieldState('').validators(required),
        phone: new FieldState(''),
        extension: new FieldState(''),
        email: new FieldState('').validators(required, email),
        title: new FieldState(''),
        customerIds: new FieldState<number[]>([])
    });

    isReadOnlyMode: boolean = false;

    constructor() {
        super();

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.ContactEdit);

        makeObservable(this, {
            title: observable,
            customers: observable,

            init: action,
            removeCustomer: action,
            save: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.contactClient.contactUpsert(new ContactEditModel({
                id: this.contactId,
                fullName: this.formState.$.fullName.value,
                phone: this.formState.$.phone.value,
                extension: this.formState.$.extension.value,
                email: this.formState.$.email.value,
                title: this.formState.$.title.value,
                customerIds: this.formState.$.customerIds.value
            }));

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }

    removeCustomer = (customerId: number) => {
        this.formState.$.customerIds.value = this.formState.$.customerIds.value.filter(x => x != customerId);
        this.customers = this.customers.filter(x => x.id != customerId);
    }

    addCustomer = () => {
        const key = "customer";
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSelectCustomer closeModal={() => this.dialogStore.closeDialog(key)} setCustomer={async (customerId, customerName: string) => {
            await this.wrapLoadingApiCall(async () => {
                this.customers.push(new NamedValueLong({ id: customerId, name: customerName }));
                this.formState.$.customerIds.value.push(customerId);

                this.dialogStore.closeDialog(key);
            });
        }} title="Update Customer"/>
    });
    }

    init = async (id: number) => {
        this.contactId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(this.contactId > 0) {
                const result = await this.companyApiClients.contactClient.getContactDetails(this.contactId);

                this.title = result.fullName;

                this.formState.$.fullName.value = result.fullName;
                this.formState.$.phone.value = result.phone;
                this.formState.$.extension.value = result.extension;
                this.formState.$.email.value = result.email;
                this.formState.$.title.value = result.title;
                this.formState.$.customerIds.value = result.customerIds;

                this.customers = result.customers;
            }
        });
    }
}
