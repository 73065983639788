import { FormState, FieldState } from 'formstate';
import {email, required} from "app/modules/common/form/validators";
import {action, makeObservable, runInAction} from "mobx";
import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {AdminApiClients} from "app/modules/admin/services/AxiosBaseClient";
import {OrganizationNewModel} from "app/modules/admin/api/clients.api";

export class OrganizationNewStore extends BaseLoadingStore {
    @inject adminApiClients: AdminApiClients;

    constructor() {
        super();

        makeObservable(this, {
            save: action
        });
    }

    formState = new FormState({
        companyName: new FieldState('').validators(required),
        ownerName: new FieldState('').validators(required),
        ownerEmail: new FieldState('').validators(required, email)
    });

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        this.wrapLoadingApiCall(async () => {
            await this.adminApiClients.tenantManagementClient.createOrganization(new OrganizationNewModel({
                name: this.formState.$.companyName.value,
                ownerName: this.formState.$.ownerName.value,
                ownerEmail: this.formState.$.ownerEmail.value
            }))

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }
}
