import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Button, Grid, InputBase} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {ContactListModel, TenantClaims} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {Paths} from "app/modules/company/stores/paths";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {MainStore} from "app/modules/company/stores/MainStore";
import {ContactStore} from "app/modules/company/components/contact/contacts/ContactStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";

const columns = [
    {
        field: 'name',
        headerName: 'Full Name',
        minWidth: 250,
        flex: 4,
        renderCell: (params: GridRenderCellParams<ContactListModel>) => (
            <Link to={Paths.EditContact.nav(params.row.id)}>
                {params.row.fullName}
            </Link>
        ),
    },
    {
        field: 'phone',
        headerName: 'Phone',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<ContactListModel>) => (
            <span>{StringHelper.formatNull(params.row.phone)} {params.row.extension}</span>
        )
    },
    {
        field: 'email',
        headerName: 'Email',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<ContactListModel>) => (
            StringHelper.formatNull(params.row.email)
        )
    },
    {
        field: 'title',
        headerName: 'Title',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<ContactListModel>) => (
            StringHelper.formatNull(params.row.title)
        )
    }
];

@provider(ContactStore)
@observer
export default class Index extends Component<any, any> {
    @inject contactStore: ContactStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.contactStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={this.mainStore.checkClaim(TenantClaims.CarrierEdit) ? 6 : 12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.contactStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.ContactEdit) && <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewContact.nav} Text="New Contact"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.contactStore.filters.$.search.value}
                            onChange={(e)=>this.contactStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.contactStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.contactStore.items}
                    columns={columns}
                    pageSize={this.contactStore.pageSize}
                    rowsPerPageOptions={this.contactStore.pageSizeOptions}
                    rowCount={this.contactStore.totalRows}
                    page={this.contactStore.skipRows/this.contactStore.pageSize}
                    loading={this.contactStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.contactStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.contactStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.contactStore.init();
    }
}

