import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {int, requiredNumber} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {
    BidModelView,
    ExternalOrderBidStatus,
    PostedRouteSummary, SendBidEmailModel,
    VehicleShortListModel
} from "app/modules/company/api/clients.api";
import queryString from "query-string";
import moment from "moment";
import duration from "moment-duration-format";
import {CacheService} from "app/modules/company/services/CacheService";

export class BidDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject cacheService: CacheService;

    externalOrderId: number = null;
    externalOrderBidId: number = null;

    title: string = 'New Bid';
    bidData: BidModelView = null;
    vehicles: VehicleShortListModel[] = [];
    route: PostedRouteSummary = null;
    preSelectedVehicleIds: number[] = [];
    bidCreated: () => void;

    formState = new FormState({
        vehicleId: new FieldState(null).validators(requiredNumber),
        customMessage: new FieldState(''),
        rate: new FieldState(null).validators(requiredNumber, int),
        ratePerMile: new FieldState(1).validators(requiredNumber, int),
        milesOut: new FieldState(null).validators(requiredNumber, int),
        miles: new FieldState(null),
        driverTime: new FieldState(0)
    });

    constructor() {
        super();

        makeObservable(this, {
            title: observable,
            route: observable,
            formState: observable,

            init: action,
            post: action,
            addMessage: action
        });
    }

    post = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            let bidModel = new SendBidEmailModel({
                externalOrderBidId: this.bidData.bidId,
                orderId: this.bidData.postedOrderId,
                vehicleId: this.formState.$.vehicleId.value,
                customMessage: this.formState.$.customMessage.value,
                rate: this.formState.$.rate.value,
                miles: this.formState.$.miles.value ?? 0,
                milesOut: this.formState.$.milesOut.value
            });

            if(this.bidData.status == ExternalOrderBidStatus.Requested && this.bidData.bidId > 0) {
                await this.companyApiClients.bidsClient.sendBidEmail(bidModel);
            } else if(this.bidData.status == ExternalOrderBidStatus.Rejected && this.bidData.bidId > 0) {
                await this.companyApiClients.bidsClient.sendBidEmailForce(bidModel);
            } else {
                await this.companyApiClients.bidsClient.createBid(bidModel);
            }

            runInAction(() => {
                this.bidCreated ? this.bidCreated() : (this.isSavedSuccessfully = true);
            });
        });
    }

    recalculateRoute = async () => {
        await this.wrapLoadingApiCall(async () => {
            this.route = await this.companyApiClients.bidsClient.recalculateRoute(this.bidData.postedOrderId, this.formState.$.vehicleId.value);

            runInAction(() => {
                this.formState.$.milesOut.onChange(this.route?.milesOut);
                this.formState.$.miles.onChange(this.route?.routeMiles);
            });
        });
    }

    getGoogleMapParams = () => {
        return queryString.stringify({
            key: 'AIzaSyD2jTzFsa8WordLMSc9J_dGuDRPz5f7GjM',
            origin: `${this.vehicles.find(x => x.id == this.formState.$.vehicleId.value).location}`,
            waypoints: [this.bidData.pickUpAddress],
            destination: `${this.bidData.deliveryAddress}`,
            avoid: 'tolls',
            mode: 'driving'
        });
    }

    formatTime = (seconds: number) => {
        duration.format;
        // @ts-ignore
        return moment.duration(seconds, 'seconds').format("h [hrs], m [min]");
    }

    getMilesOutText = () => "Miles Out:" + this.route?.milesOut;
    getPUTimeText = () => "PU ETA:" + this.formatTime(this.route?.pUTime + (this.formState.$.driverTime.value * 60));
    getTransitionTimeSoloText = () => "Transit Solo:" + this.formatTime(this.route?.transitionTimeSolo + (this.formState.$.driverTime.value * 60));
    getTransitionTimeTeamText = () => "Transit Team:" + this.formatTime(this.route?.transitionTimeTeam + (this.formState.$.driverTime.value * 60));

    addMessage = (value: string) => {
        this.formState.$.customMessage.onChange(this.formState.$.customMessage.value + (this.formState.$.customMessage.value?.length > 0 ? '\n' : '') + value);
    }

    init = async (externalOrderId: number, externalOrderBidId: number, vehiclesNumbers: number[], bidCreated: () => void) => {
        this.externalOrderId = externalOrderId;
        this.externalOrderBidId = externalOrderBidId;
        this.bidCreated = bidCreated;

        await this.wrapInitLoadingApiCall(async () => {
            if(this.externalOrderId != null || this.externalOrderBidId != null) {
                [this.bidData, this.vehicles] = [
                    this.externalOrderId > 0 ?
                        await this.companyApiClients.bidsClient.getNewBidInfo(this.externalOrderId) :
                        await this.companyApiClients.bidsClient.getBidInfo(this.externalOrderBidId),
                    await this.cacheService.getVehicles(),
                    this.externalOrderId > 0 && await this.companyApiClients.bidsClient.setAsViewed(this.externalOrderId)
                ];

                this.formState.$.rate.onChange(this.bidData.rate);

                if(this.bidData.vehicleId > 0) {
                    this.formState.$.vehicleId.onChange(this.bidData.vehicleId);
                    await this.recalculateRoute();
                } else {
                    let number = null;

                    if(Array.isArray(vehiclesNumbers) && vehiclesNumbers?.length == 1) {
                        number = vehiclesNumbers[0];
                    } else if(vehiclesNumbers?.length > 0 && !Array.isArray(vehiclesNumbers)) {
                        number = vehiclesNumbers;
                    }

                    if(number != null) {
                        runInAction(() => {
                            const found = this.vehicles.find(x => x.number == number);
                            if(found != null) {
                                this.formState.$.vehicleId.onChange(found.id);
                                this.recalculateRoute();
                            }
                        });
                    }
                }
            }
        });
    }
}
