import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {TenantClaims} from "app/modules/organization/api/clients.api";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {MainStore} from "app/modules/organization/stores/MainStore";
import {ApiClients} from "app/modules/organization/services/AxiosBaseClient";
import moment from "moment";
import {FieldState, FormState} from "formstate";
import {email, required} from "app/modules/common/form/validators";
import {
    NamedValue, NamedValueLong,
    OrganizationUserEditEmailModel,
    OrganizationUserEditFullNameModel, OrganizationUserEditPhoneModel, UserTenantRoles
} from "app/modules/organization/api/clients.api";

export class UserDetailsStore extends BaseLoadingStore {
    @inject mainStore: MainStore;
    @inject apiClients: ApiClients;

    title: string = 'New User';
    isReadOnlyMode: boolean = false;
    userId: string;
    isActive: boolean = true;
    tenantRoles: Map<number, Map<string, boolean>> = new Map<number, Map<string, boolean>>();

    roles: NamedValue[] = [];
    tenants: NamedValueLong[] = [];

    formFullNameState = new FormState({
        fullName: new FieldState('').validators(required),
    });

    formEmailState = new FormState({
        email: new FieldState('').validators(email, required),
    });

    formPhoneState = new FormState({
        phone: new FieldState('').validators(required),
    });

    constructor() {
        super();

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.UsersEdit);

        makeObservable(this, {
            title: observable,
            isActive: observable,
            tenantRoles: observable,

            init: action,
            activate: action,
            deactivate: action,
            changeTenantRoleState: action
        });

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.UsersEdit);
    }

    activate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.apiClients.userClient.activateOrganizationUser(this.userId);
            runInAction(() => {
                this.isActive = true;
            });
        });
    }

    deactivate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.apiClients.userClient.deactivateOrganizationUser(this.userId);
            runInAction(() => {
                this.isActive = false;
            });
        });
    }

    saveFullName = async () => {
        await this.formFullNameState.validate();

        if(this.formFullNameState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.userClient.updateOrganizationUserFullName(new OrganizationUserEditFullNameModel({
                userId: this.userId,
                fullName: this.formFullNameState.$.fullName.value
            }));
        });
    }

    saveEmail = async () => {
        await this.formEmailState.validate();

        if(this.formEmailState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.userClient.updateOrganizationUserEmail(new OrganizationUserEditEmailModel({
                userId: this.userId,
                email: this.formEmailState.$.email.value
            }));
        });
    }

    savePhone = async () => {
        await this.formPhoneState.validate();

        if(this.formPhoneState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.userClient.updateOrganizationUserPhone(new OrganizationUserEditPhoneModel({
                userId: this.userId,
                phone: this.formPhoneState.$.phone.value
            }));
        });
    }

    private setTenantRoles = (userTenantRoles: UserTenantRoles[]) => {
        for(let i = 0; i < this.tenants.length; i++) {
            for (let j = 0; j < this.roles.length; j++) {
                this.tenantRoles.get(this.tenants[i].id).set(this.roles[j].id, false);
            }
        }

        for(let i = 0; i < userTenantRoles.length; i++) {
            for (let j = 0; j < userTenantRoles[i].roleIds.length; j++) {
                this.tenantRoles.get(userTenantRoles[i].tenantId).set(userTenantRoles[i].roleIds[j], true);
            }
        }
    }

    changeTenantRoleState = (tenantId, roleId) => {
        this.tenantRoles.get(tenantId).set(roleId, !this.tenantRoles.get(tenantId).get(roleId));
    }

    saveTenantRoles = async () => {
        const updateRoles : UserTenantRoles[] = [];

        this.tenantRoles.forEach((roles, tenantId) => {
            let roleIds: string[] = [];
            roles.forEach((value, role) => {
                if(value) {
                    roleIds.push(role);
                }
            });

            if(roleIds.length > 0) {
                updateRoles.push(new UserTenantRoles({
                    tenantId: tenantId,
                    roleIds: roleIds
                }))
            }
        })

        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.userClient.updateUserRolesPerTenant(this.userId, updateRoles);
        });
    }

    init = async (id: string) => {
        this.userId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(!!this.userId) {
                const [result, userRoles, roles, tenants] = [
                    await this.apiClients.userClient.getOrganizationUser(this.userId),
                    await this.apiClients.userClient.getUserRolesPerTenant(this.userId),
                    await this.apiClients.userClient.getOrganizationRoles(),
                    await this.apiClients.tenantClient.getTenantsShortList(),
                ];

                this.roles = roles;
                this.tenants = tenants;

                for(let i = 0; i < this.tenants.length; i++) {
                    this.tenantRoles.set(this.tenants[i].id, new Map<string, boolean>());

                    for (let j = 0; j < this.roles.length; j++) {
                        this.tenantRoles.get(this.tenants[i].id).set(this.roles[j].id, false);
                    }
                }

                this.setTenantRoles(userRoles);

                this.title = result.fullName;
                this.isActive = result.lockoutEnd < (moment(new Date()));

                this.formFullNameState.$.fullName.value = result.fullName;
                this.formEmailState.$.email.value = result.email;
                this.formPhoneState.$.phone.value = result.phone;
            }
        });
    }
}
