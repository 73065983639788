import {observer} from "mobx-react";
import React, {Component} from "react";
import {inject, provider} from "react-ioc";
import {useParams} from "react-router-dom";
import {Loading} from "app/modules/common/layout/components/loading";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {Section, SectionBlank, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {Box, Card, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {SectionTextField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/organization/stores/MainStore";
import {RoleDetailsStore} from "app/modules/organization/components/roles/details/RoleDetailsStore";
import {TenantClaims} from "app/modules/company/api/clients.api";

const PermissionGroup = (props: any) => {
    return <Card sx={{ padding: "15px 25px" }}>
        <Stack direction="column" key={props.permission} spacing={0}>
            <Typography variant="subtitle1" component="span" textAlign={"left"} sx={{ paddingBottom: 1, fontWeight: "bold" }}>{props.title}</Typography>
            {props.children}
        </Stack>
    </Card>
}

const Permission = observer((props: { permission, title, description, roleDetailsStore }) => {
    let permissionStringName = 'T.' + TenantClaims[props.permission];
    return <Stack direction="column" key={permissionStringName} spacing={0}>
            <FormControlLabel sx={{ width: 300, height: 35 }} control={<Checkbox onChange={(e) => !props.roleDetailsStore.isReadOnlyMode && props.roleDetailsStore.changeTenantRoleState(permissionStringName)} checked={props.roleDetailsStore.rolePermissions.indexOf(permissionStringName) >= 0} />} label={props.title} />
            <Typography sx={{ paddingLeft: 4, fontStyle: 'italic' }} color={"#a4a4a4"} variant="subtitle2" component="span" textAlign={"left"}>{props.description}</Typography>
        </Stack>

})

@provider(RoleDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject roleDetailsStore: RoleDetailsStore;
    @inject mainStore: MainStore;

    isReadOnlyMode = true;

    render() {
        if(this.roleDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        return <Container>
            <PageTitleDetails Title={this.roleDetailsStore.title} Size={!this.roleDetailsStore.isReadOnlyMode ? 8 : 12}/>
            <ContainerDetails>
                <SectionName title="Info" description="Role Info" />
                <Section>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Name"}
                            field={this.roleDetailsStore.formNameState.$.name}
                            isReadOnlyMode={this.roleDetailsStore.isReadOnlyMode}
                            fullWidth/>
                        {!this.roleDetailsStore.isReadOnlyMode && <LoadingButton loading={this.roleDetailsStore.isLoading} disabled={this.roleDetailsStore.formNameState.hasError} variant="outlined" onClick={this.roleDetailsStore.saveName}>
                            Update
                        </LoadingButton>}
                    </Stack>
                </Section>
                <SectionSeparator/>
                <SectionName title="Permissions" description="Role Permissions" />
                <SectionBlank>
                    <Stack direction="column">
                        <PermissionGroup title={"Admin Access"}>
                            <Permission permission={TenantClaims.FullAccess} title={"Full Access"} description={"Provides full access to all features."} roleDetailsStore={this.roleDetailsStore}/>
                        </PermissionGroup>
                        <br/>
                        <br/>
                        <Typography variant="h5" component="span" textAlign={"left"} sx={{ paddingBottom: 2 }}>Organization</Typography>
                        <PermissionGroup title={"General"}>
                            <Permission permission={TenantClaims.OrganizationAdmin} title={"Admin"} description={"Can access the organization portal."} roleDetailsStore={this.roleDetailsStore}/>
                            <Permission permission={TenantClaims.UsersListView} title={"View Users"} description={"Can see list of users and user details."} roleDetailsStore={this.roleDetailsStore}/>
                            <Permission permission={TenantClaims.UsersEdit} title={"Edit Users"} description={"Can manage users, update user names, assign roles, etc..."} roleDetailsStore={this.roleDetailsStore}/>
                            <Permission permission={TenantClaims.TenantListView} title={"View Companies"} description={"See all companies for the organization."} roleDetailsStore={this.roleDetailsStore}/>
                            <Permission permission={TenantClaims.TenantNew} title={"New Company"} description={"Can create a new company."} roleDetailsStore={this.roleDetailsStore}/>
                        </PermissionGroup>
                    </Stack>
                    <br/>
                    <br/>
                    <Stack direction="column">
                        <Typography variant="h5" component="span" textAlign={"left"} sx={{ paddingBottom: 2 }}>Company</Typography>
                        <Stack direction="row" spacing={2}>
                        <Stack direction="column" spacing={1}>
                            <PermissionGroup title={"General"}>
                                <Permission permission={TenantClaims.CompanyUser} title={"Company Access"} description={"Can access a company."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Drivers"}>
                                <Permission permission={TenantClaims.DriverListView} title={"View"} description={"Can see list of drivers and driver details."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.DriverEdit} title={"Edit"} description={"Can manage drivers."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Carriers"}>
                                <Permission permission={TenantClaims.CarrierListView} title={"View"} description={"Can see list of carriers and carrier details."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.CarrierEdit} title={"Edit"} description={"Can manage carriers."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Vehicles"}>
                                <Permission permission={TenantClaims.VehicleListView} title={"View"} description={"Can see list of vehicles and vehicle details."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.VehicleEdit} title={"Edit"} description={"Can manage vehicles."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Customers"}>
                                <Permission permission={TenantClaims.CustomerEdit} title={"Edit Customers"} description={"Can manage customers."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.ContactEdit} title={"Edit Contacts"} description={"Can manage contacts."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Integration"}>
                                <Permission permission={TenantClaims.IntegrationListView} title={"View Integrations"} description={"Can see list of integrations and integration details."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.IntegrationEdit} title={"Edit Integrations"} description={"Can manage integrations."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Radar"}>
                                <Permission permission={TenantClaims.RadarView} title={"View"} description={"Can see the radar."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.EditVehicleAvailability} title={"Update vehicle availability"} description={"Can manage vehicle availability on the radar."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Files"}>
                                <Permission permission={TenantClaims.DeleteFile} title={"Delete"} description={"Can delete files."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                        </Stack>
                        <Stack direction="column" spacing={1}>
                            <PermissionGroup title={"Order"}>
                                <Permission permission={TenantClaims.OrderListView} title={"View"} description={"Can see list of orders and order details."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderEdit} title={"Edit"} description={"Can manage orders."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderNew} title={"New"} description={"Can create orders."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderNotification} title={"Notifications"} description={"Can send order notifications."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderVehicle} title={"Edit Vehicles"} description={"Can update order vehicles."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderDrivers} title={"Edit Drivers"} description={"Can update order drivers."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderDraft} title={"Draft"} description={"Change am Order Status to Draft."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderReady} title={"Ready"} description={"Change am Order Status to Ready."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderProgress} title={"Progress"} description={"Change am Order Status to Progress."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderCancel} title={"Cancel"} description={"Change am Order Status to Cancel."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderOnHold} title={"OnHold"} description={"Change am Order Status to OnHold."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderComplete} title={"Complete"} description={"Change am Order Status to Complete."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderClose} title={"Close"} description={"Change am Order Status to Close."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderTaskView} title={"View Tasks"} description={"Can see list of order tasks."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderTaskEdit} title={"Edit Tasks"} description={"Can manage order tasks."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderQuoteListView} title={"View Quotes"} description={"Can see list of order quotes."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OrderQuoteEdit} title={"Edit Quotes"} description={"Can manage order quotes."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                        </Stack>
                        <Stack direction="column" spacing={1}>
                            <PermissionGroup title={"Bids"}>
                                <Permission permission={TenantClaims.BidListView} title={"View"} description={"Can see bids."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.BidCreate} title={"New"} description={"Create a new bid."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.BidWon} title={"Won"} description={"Mark bids as won."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.BidForceWon} title={"Force Won"} description={"Mark bids as won after a rejections."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.BidReject} title={"Reject"} description={"Reject bids."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Settings"}>
                                <Permission permission={TenantClaims.SettingsEditView} title={"Setting"} description={"Can update company settings."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.TelegramChannelEdit} title={"Telegram Channels"} description={"Manage telegram channels."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.TrackingDeviceEdit} title={"Tracking Device"} description={"Manage telegram channels (Driver Mobile App)."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OwnerAccountListView} title={"Owner Accounts View"} description={"Can see list of owner accounts (Owner Mobile App)."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.OwnerAccountEdit} title={"Owner Accounts Edit"} description={"Can manage owner accounts."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                            <PermissionGroup title={"Accounting"}>
                                <Permission permission={TenantClaims.AccountingInvoicing} title={"Invoicing"} description={"Can create invoices."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.AccountingPayments} title={"Payments"} description={"Can record payments."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.AccountingAdmin} title={"Delete Payment/Open Invoice"} description={"Can delete payments and open invoices."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.AccountingSummary} title={"Summary"} description={"Can see order summary."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.AccountPayout} title={"Payout"} description={"Can manage payouts."} roleDetailsStore={this.roleDetailsStore}/>
                                <Permission permission={TenantClaims.AccountPayoutAdmin} title={"PayoutAdmin"} description={"Can remove payouts."} roleDetailsStore={this.roleDetailsStore}/>
                            </PermissionGroup>
                        </Stack>
                    </Stack>
                    </Stack>
                    <br/>
                    <br/>
                    <Box>
                        {!this.roleDetailsStore.isReadOnlyMode && <LoadingButton sx={{ width: "100%" }} loading={this.roleDetailsStore.isLoading} variant="contained" onClick={this.roleDetailsStore.saveRolePermissions}>
                            Update Permissions
                        </LoadingButton>}
                    </Box>
                </SectionBlank>
            </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.roleDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
