import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfTelegramChannelSettingsListFilters,
    TelegramChannelListFilters, TelegramChannelSettingsListModel
} from "app/modules/company/api/clients.api";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogChannel} from "app/modules/company/components/settings/telegram/types/dialog.channel";

export class TelegramChannelStore extends BaseListStore<TelegramChannelSettingsListModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    upsert = async (channelId?: number) => {
        const key = "update_channel";
        this.dialogStore.addDialog({
            key: key,
            component: <DialogChannel closeModal={() => this.dialogStore.closeDialog(key)} channelId={channelId} onSave={() => {
                this.dialogStore.closeDialog(key);
                this.loadData();
            }}/>
        });
    }

    delete = async (id: number) => {
        this.dialogStore.addConfirmDialog({
            okColor: "error",
            title: "Delete Settings",
            description: "Are you sure, you want to delete this telegram settings?",
            cancelText: "Close",
            okText: "Delete",
            okAction: async () => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.telegramClient.deleteTelegramChannelSettings(id);
                    await this.loadData();
                });
            }
        });
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new TelegramChannelListFilters({search: this.filters.$.search.value});
            const [result] = await Promise.all([
                this.companyApiClients.telegramClient.getSettingsList(new DataListGetParamsOfTelegramChannelSettingsListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
