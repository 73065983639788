import {observer} from "mobx-react";
import React from "react";
import {Modal} from "@mui/material";
import  IndexDetails from "app/modules/company/components/load-boards/bids/details/index";
import {ModalFullContent, ModalFullContentDetails} from "app/modules/common/layout/modal/modal-content";

export interface ModalBidDetailsParams {
    externalOrderBidId: number;
    closeOrderModal: () => void;
}

export const ModalBidDetails = observer((props: ModalBidDetailsParams) => {
    return <Modal
        open={true}
        onClose={() => { props.closeOrderModal(); }}
    >
        <div>
            <ModalFullContent>
                <ModalFullContentDetails closeModal={props.closeOrderModal} title="Bid Details" color={"#f0f0f0"}>
                    <IndexDetails externalOrderBidId={props.externalOrderBidId} externalOrderId={null} vehiclesNumbers={[]} bidCreated={props.closeOrderModal} />
                </ModalFullContentDetails>
            </ModalFullContent>
        </div>
    </Modal>;
})
