import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {FieldState, FormState} from "formstate";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {CacheService} from "app/modules/company/services/CacheService";
import {action, makeObservable, observable, runInAction} from "mobx";
import moment from "moment";
import {int, required, requiredNumber} from "app/modules/common/form/validators";
import {
    InvoiceShortModel,
    PaymentEditModel,
    PaymentInvoice,
    PaymentMethodType,
    RTSImportRecordResponseOfRTSPurchaseResult
} from "app/modules/company/api/clients.api";
import {DialogSelectCustomer} from "app/modules/company/components/orders/details/dialog.customer";
import React from "react";

export const PaymentTypes = [
    { id: PaymentMethodType.ACH, name: "ACH" },
    { id: PaymentMethodType.CreditCard, name: "Credit Card" },
    { id: PaymentMethodType.Check, name: "Check" },
    { id: PaymentMethodType.Cash, name: "Cash" }
];

export class PaymentDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject dialogStore: DialogStore;
    @inject cacheService: CacheService;

    title: string = 'Record Payment';
    paymentId: number;

    customerName: string = null;

    formState = new FormState({
        PaymentDate: new FieldState<moment.Moment>(null).validators(required),
        Reference: new FieldState(null),
        CheckNumber: new FieldState(null),
        Type: new FieldState<PaymentMethodType>(PaymentMethodType.ACH).validators(required),
        PaymentInvoices: new FormState([]),
        CustomerId: new FieldState(null).validators(requiredNumber, int)
    });

    invoiceState = new FormState({
        InvoiceNumber: new FieldState(null).validators(int)
    });

    invoiceDetails = new Map<number, InvoiceShortModel>([]);

    constructor() {
        super();

        makeObservable(this, {
            title: observable,
            customerName: observable,

            init: action,
            save: action,
            deleteInvoice: action,
            addInvoice: action,
            uploadRTSPurchaseFileCallback: action
        });
    }

    deleteInvoice = async (invoiceId: number) => {
        if(this.invoiceDetails.has(invoiceId)) {
            this.invoiceDetails.delete(invoiceId)
        }

        let index = this.formState.$.PaymentInvoices.$.findIndex(x => x.$.InvoiceId.value == invoiceId);
        if(index >=0 ) {
            this.formState.$.PaymentInvoices.$.splice(index, 1);
        }
    }

    uploadRTSPurchaseFileCallback = async (callbackData: object[]) => {
        let data = callbackData.map(x => {
            let invoice = new RTSImportRecordResponseOfRTSPurchaseResult();
            invoice.init(x);

            return invoice;
        });

        this.invoiceDetails.clear();
        this.formState.$.PaymentInvoices.$.length = 0;

        for(let i = 0; i < data.length; i++) {
            let item = data[i];
            this.invoiceDetails.set(item.invoice.id, item.invoice);

            this.formState.$.PaymentInvoices.$.push(new FormState({
                Amount: new FieldState(item.record.fundedAmount).validators(requiredNumber, int),
                InvoiceId: new FieldState(item.invoice.id).validators(requiredNumber, int),
                CloseInvoice: new FieldState<boolean>(true)
            }));
        }
    }

    addInvoice = async () => {
        let value = Number(this.invoiceState.$.InvoiceNumber.value);

        await this.invoiceState.validate();
        if(isNaN(value) || this.invoiceState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            let invoiceDetails = await this.companyApiClients.invoiceClient.getShortInvoiceInfo([value]);

            this.invoiceState.$.InvoiceNumber.onChange('');

            for(let i = 0; i < invoiceDetails.length; i++) {
                let item = invoiceDetails[i];

                if(!this.invoiceDetails.has(item.id)) {
                    runInAction(() => {
                        this.formState.$.PaymentInvoices.$.push(new FormState({
                            Amount: new FieldState(item.amount).validators(requiredNumber, int),
                            InvoiceId: new FieldState(item.id).validators(requiredNumber, int),
                            CloseInvoice: new FieldState<boolean>(true)
                        }));

                        this.invoiceDetails.set(item.id, item);
                    });
                }
            }
        });
    }

    save = async () => {
        if(this.paymentId > 0) {
            return;
        }

        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.paymentClient.createPayment(new PaymentEditModel ({
                id: this.paymentId,
                type: this.formState.$.Type.value,
                checkNumber: this.formState.$.CheckNumber.value,
                reference: this.formState.$.Reference.value,
                paymentDate: this.formState.$.PaymentDate.value,
                customerId: this.formState.$.CustomerId.value,
                paymentInvoices: this.formState.$.PaymentInvoices.$.map(x => {
                    return new PaymentInvoice({
                        amount: x.$.Amount.value,
                        closeInvoice: x.$.CloseInvoice.value,
                        invoiceId: x.$.InvoiceId.value
                    })
                })
            }));

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }

    changeCustomer = () => {
        const key = "select_customer_payment";
        let dialogStore = this.dialogStore;

        dialogStore.addDialog({
                key: key,
                component: <DialogSelectCustomer closeModal={() => dialogStore.closeDialog(key)} setCustomer={async (customerId, name) => {
                    this.customerName = name;
                    this.formState.$.CustomerId.onChange(customerId);

                dialogStore.closeDialog(key);
            }} title="Update Payer"/>
        });
    }

    init = async (id: number) => {
        this.paymentId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if (this.paymentId > 0) {
            }
        });
    }
}
