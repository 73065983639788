import {action, makeObservable, observable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfGetOrdersSearchParams,
    GetOrdersSearchParams, RadarModel
} from "app/modules/company/api/clients.api";
import {Paths} from "app/modules/company/stores/paths";
import * as React from "react";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";

export class ExternalOrdersStore extends BaseListStore<RadarModel>{
    @inject companyApiClients: ApiClients;

    gridApi: React.MutableRefObject<GridApiPro> = null;
    expandedRows: number[] = [];

    filters = new FormState({
        fromZip: new FieldState(null),
        fromRadius: new FieldState(null),
        toZip: new FieldState(null),
        toRadius: new FieldState(null),
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action,
            expandedRows: observable,
            hideBid: action
        });
    }

    hideBid = async (orderId: number) => {
        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.bidsClient.hideBid(orderId);

            this.removeOrder(orderId);
        })
    }

    bet = async (orderId: number) => {
        window.open(Paths.OrderNewBidDetails.nav(orderId), "_blank");
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new GetOrdersSearchParams({
                pickUpZip: (this.filters.$.fromZip.value || '').toString(),
                pickUpRadius: this.filters.$.fromRadius.value,
                deliveryZip: (this.filters.$.toZip.value || '').toString(),
                deliveryRadius: this.filters.$.toRadius.value,
            });

            const [result] = await Promise.all([
                this.companyApiClients.radarClient.getOrders(new DataListGetParamsOfGetOrdersSearchParams({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
            this.expandedRows = this.items.filter(x => x.note?.length > 0).map(x => x.id);
        });
    }

    removeOrder = (orderId) => {
        this.items = this.items.filter(x => x.id != orderId);
        this.expandedRows = this.items.filter(x => x.note?.length > 0).map(x => x.id);

        this.gridApi.current.updateRows([{id: orderId, _action: "delete"}]);
    }

    async init(gridApi: React.MutableRefObject<GridApiPro>) {
        await this.loadData();

        this.gridApi = gridApi;

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
