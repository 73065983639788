import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable} from "mobx";
import {inject} from "react-ioc";
import {AdminApiClients} from "app/modules/admin/services/AxiosBaseClient";

export class TenantDetailsStore extends BaseLoadingStore {
    @inject adminApiClients: AdminApiClients;

    title: string = '';

    TabDetails: string = "Details";
    TabUsers: string = "Users";
    selectedTab: string = this.TabDetails;
    tenantId : number = null;

    constructor() {
        super();

        makeObservable(this, {
            title: observable,
            selectedTab: observable,
            tenantId: observable,

            init: action,
            selectTab: action,
            resetJobs: action,
            upgradeTenant: action
        });
    }

    resetJobs = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.adminApiClients.tenantManagementClient.setReccurentJobs(this.tenantId);
        })
    }

    sylectusSync = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.adminApiClients.tenantManagementClient.runSylectusSync(this.tenantId);
        })
    }

    upgradeTenant = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.adminApiClients.tenantManagementClient.upgradeTenant(this.tenantId);
        })
    }

    init = async (id: number) => {
        await this.wrapInitLoadingApiCall(() => {
            this.tenantId = id;
            this.title = "First Easy Delivery, Inc";
            this.isLoading = false;
        })
    }

    selectTab = (tab: string) => this.selectedTab = tab;
}
