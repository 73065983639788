import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Stack} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {SectionTextField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {TelegramChannelModel} from "app/modules/company/api/clients.api";
import {required} from "app/modules/common/form/validators";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogChannelProps {
    closeModal: () => void;
    onSave: () => void;
    channelId: number;
}

export const DialogChannel = observer((props: DialogChannelProps) => {
    const [formState, _] = useState(new FormState({
        driverIds: new FieldState([]),
        vehicleIds: new FieldState([]),
        title: new FieldState("").validators(required),
        active: new FieldState(false)
    }));
    const [isLoading, setLoading] = useState(true);

    const store = useInstance(ApiClients);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const [details] = [
                await store.telegramClient.getDetails(props.channelId)
            ];

            formState.$.title.value = details.title;
            setLoading(false);
        };

        fetchData();
    }, []);

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);

            await store.telegramClient.update(new TelegramChannelModel({
                id: props.channelId,
                title: formState.$.title.value
            }));

            props.onSave();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    }

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Update Telegram Channel
        </DialogTitle>
        <DialogContent>
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                {!isLoading ? <Stack direction={"column"} spacing={2}>
                    <SectionTextField
                        label={"Title"}
                        field={formState.$.title}
                        fullWidth
                    />
                </Stack> : <LinearProgress/> }
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
