import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack, TextField
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {required} from "app/modules/common/form/validators";
import {SectionDatePickerField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {
    NamedValueLong,
    PaymentEditModel, PaymentInvoice,
    PaymentMethodType
} from "app/modules/company/api/clients.api";
import {DialogSelectCustomer} from "app/modules/company/components/orders/details/dialog.customer";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {Loading} from "app/modules/common/layout/components/loading";
import moment from "moment/moment";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogPaymentNewProps {
    closeModal: () => void;
    paymentCreated: () => void;
}

const paymentMethodTypes = [
    new NamedValueLong({ id: PaymentMethodType.ACH, name: "ACH" }),
    new NamedValueLong({ id: PaymentMethodType.Cash, name: "Cash" }),
    new NamedValueLong({ id: PaymentMethodType.Check, name: "Check" }),
    new NamedValueLong({ id: PaymentMethodType.CreditCard, name: "CreditCard" })
];

export const DialogPaymentNew = observer((props: DialogPaymentNewProps) => {
    const [formState, _] = useState(new FormState({
        customerId: new FieldState(null).validators(required),
        amount: new FieldState(0).validators(required),
        paymentDate: new FieldState(moment()).validators(required),
        reference: new FieldState(""),
        checkNumber: new FieldState(""),
        type: new FieldState(PaymentMethodType.ACH).validators(required),
        paymentInvoices: new FieldState<PaymentInvoice[]>([]).validators(required),
    }));

    const [isLoading, setLoading] = useState(false);
    const [customerName, setCustomerName] = useState("");

    const store = useInstance(ApiClients);
    const dialogStore = useInstance(DialogStore);

    const changeCustomer = () => {
        const key = "new_customer_payment";
        dialogStore.addDialog({
            key: key,
            component: <DialogSelectCustomer closeModal={() => dialogStore.closeDialog(key)} setCustomer={async (customerId, name) => {
                formState.$.customerId.onChange(customerId);
                setCustomerName(name);
                dialogStore.closeDialog(key);
            }} title="Select Payer"/>
        });
    }

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);
            await store.paymentClient.createPayment(new PaymentEditModel({
                customerId: formState.$.customerId.value,
                paymentDate: formState.$.paymentDate.value,
                reference: formState.$.reference.value,
                checkNumber: formState.$.checkNumber.value,
                type: formState.$.type.value
            }));

            props.paymentCreated();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Create Invoice
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                {isLoading ? <Loading /> : <Stack spacing={2} direction={"column"}>
                    <Box sx={{ cursor: "pointer !important", width: "100%" }}>
                        <TextField
                            id="outlined-disabled"
                            label="Payer"
                            fullWidth
                            onClick={() => changeCustomer()}
                            value={customerName || "Set Default Payer"}
                        />
                    </Box>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Amount"}
                            field={formState.$.amount}
                            type={"number"}
                            width={"50%"}
                        />
                        <SectionDatePickerField
                            field={formState.$.paymentDate}
                            label="Payment Date"
                            width={"50%"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Reference"}
                            field={formState.$.reference}
                            width={"50%"}
                        />
                        <SectionTextField
                            label={"CheckNumber"}
                            field={formState.$.checkNumber}
                            width={"50%"}
                        />
                    </Stack>
                    <SectionSelectField
                        field={formState.$.type}
                        width={"100%"}
                        label={"Net Term"}
                        none
                        items={() => paymentMethodTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />
                </Stack>}
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Create
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
