import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {AdminApiClients} from "app/modules/admin/services/AxiosBaseClient";
import {
    DataListGetParamsOfOrganizationListFilters, OrganizationListFilters,
    OrganizationListModel
} from "app/modules/admin/api/clients.api";
import {FieldState, FormState} from "formstate";

export class OrganizationsStore extends BaseListStore<OrganizationListModel> {
    @inject adminApiClients: AdminApiClients;

    filters = new FormState({
        name: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    impersonateOrganization = async (id) => {
        await this.adminApiClients.usersClient.impersonateOrganization(id);

        window.open("/org", "_blank")
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new OrganizationListFilters({ name: this.filters.$.name.value });
            const [result] = await Promise.all([
                this.adminApiClients.tenantManagementClient.getOrganizations(new DataListGetParamsOfOrganizationListFilters({ offset: this.skipRows, pageSize: this.pageSize, filter: filters }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
