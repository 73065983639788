import React from "react";
import {Collapse, Divider, ListItemIcon, ListItemText, MenuItem, MenuList} from "@mui/material";
import {observer} from "mobx-react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {makeObservable, observable} from "mobx";
import {useNavigate} from "react-router-dom";

export class NavMenuItem {
    title: string;
    icon: React.ReactNode;
    path: string;
    selected: boolean;
    expanded: boolean;
    externalUrl: boolean = false;
    subItems: NavMenuItem[];

    public constructor(init?:Partial<NavMenuItem>) {
        Object.assign(this, init);

        makeObservable(this, {
            selected: observable,
            expanded: observable
        });
    }
}

export const Menu = observer((props: {menu: NavMenuItem[], onClick: (NavMenuItem) => void}) => {
    const navigate = useNavigate()
    let items = [];

    if(!props.menu?.length) {
        return <div/>;
    }

    const onClick = (x: NavMenuItem) => {
        if(x.subItems?.length > 0) {
            props.onClick(x)
        } else {
            x.externalUrl ? window.open(x.path, "_blank") : navigate(x.path)
        }
    }

    for(let i = 0; i < props.menu?.length; i++) {
        let x = props.menu[i];
        items.push(<MenuItem key={x.title + "_item"} selected={x.selected} onClick={() => onClick(x)} sx={{ pl: 2 }}>
            <ListItemIcon>
                {x.icon}
            </ListItemIcon>
            <ListItemText primary={x.title}/>
            {x.subItems && (x.expanded ? <ExpandLess /> : <ExpandMore />)}
        </MenuItem>);

        if(i != (props.menu?.length-1)) items.push(<Divider light key={x.title + "_divider"}  sx={{ my: 0.5 }} />);

        if(x.subItems?.length > 0) {
            items.push(<Collapse in={x.expanded} timeout="auto" unmountOnExit key={x.title + "_Collapse"}>
                {x.subItems?.map((xx, xIndex) => {
                    return <MenuItem key={xx.title + xIndex + "_subItem"} selected={xx.selected}
                                     onClick={() => navigate(xx.path)}>
                        <ListItemIcon sx={{pl: 2}}>
                            {xx.icon}
                        </ListItemIcon>
                        <ListItemText primary={xx.title}/>
                    </MenuItem>
                })}
            </Collapse>);
        }
    }

    return <MenuList>
        {items}
    </MenuList>;
});
