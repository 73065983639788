import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {Loading} from "app/modules/common/layout/components/loading";
import {Grid, MenuItem, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {ActivateDeactivateSection} from "app/modules/common/details/activate-section";
import {SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {CompanyIntegrationTypeMap} from "app/modules/company/components/company-integrations";
import {CompanyIntegrationDetailsStore} from "app/modules/company/components/company-integrations/details/CompanyIntegrationDetailsStore";
import {CompanyIntegrationType} from "app/modules/company/api/clients.api";

@provider(CompanyIntegrationDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject companyIntegrationDetailsStore: CompanyIntegrationDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.companyIntegrationDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.companyIntegrationDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.CompanyIntegrations.nav}/>;
        }

        if(this.companyIntegrationDetailsStore.isRedirectToNewEntity) {
            return <NavigateHook path={Paths.EditIntegration.nav(this.companyIntegrationDetailsStore.integrationId)}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.companyIntegrationDetailsStore.title} Size={!this.companyIntegrationDetailsStore.isReadOnlyMode ? 8 : 12}/>
                {!this.companyIntegrationDetailsStore.isReadOnlyMode && <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.companyIntegrationDetailsStore.isLoading} disabled={this.companyIntegrationDetailsStore.formState.hasError} variant="contained" onClick={this.companyIntegrationDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>}
                <ContainerDetails>
                    <SectionName title="Info" description="Integration Basic Info"/>
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Name"}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                    field={this.companyIntegrationDetailsStore.formState.$.name}
                                    fullWidth
                                />
                                <SectionSelectField
                                    field={this.companyIntegrationDetailsStore.formState.$.type}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode || this.companyIntegrationDetailsStore.integrationId > 0}
                                    label={"Type"}
                                    none={false}
                                    width={"100%"}
                                    items={() => Array.from(CompanyIntegrationTypeMap.entries()).map((x) => <MenuItem value={x[0]} key={x[0]}>{x[1]}</MenuItem>)}
                                />
                            </Stack>
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Credentials" description="Credentials to have an access to the API"/>
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            {this.companyIntegrationDetailsStore.formState.$.type.value == CompanyIntegrationType.GoogleApi &&
                                <SectionTextField
                                    label={"ApiKey"}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                    field={this.companyIntegrationDetailsStore.formGoogleKey.$.apiKey}
                                    type={"password"}
                                    fullWidth
                                />}
                            {this.companyIntegrationDetailsStore.formState.$.type.value == CompanyIntegrationType.SMTP &&
                                <Stack spacing={2} direction={"row"}>
                                    <SectionTextField
                                        label={"Address"}
                                        isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                        field={this.companyIntegrationDetailsStore.formSmtp.$.address}
                                        fullWidth
                                    />
                                    <SectionTextField
                                        label={"PORT"}
                                        isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                        field={this.companyIntegrationDetailsStore.formSmtp.$.port}
                                        fullWidth
                                    />
                                    <SectionTextField
                                        label={"User"}
                                        isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                        field={this.companyIntegrationDetailsStore.formSmtp.$.user}
                                        fullWidth
                                    />
                                    <SectionTextField
                                        label={"Password"}
                                        isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                        field={this.companyIntegrationDetailsStore.formSmtp.$.password}
                                        type={"password"}
                                        fullWidth
                                    />
                                </Stack>}
                            {this.companyIntegrationDetailsStore.formState.$.type.value == CompanyIntegrationType.Sylectus &&
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Corporation Id"}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                    field={this.companyIntegrationDetailsStore.formSylectus.$.corporationId}
                                    fullWidth
                                />
                                <SectionTextField
                                    label={"Corporation Password"}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                    field={this.companyIntegrationDetailsStore.formSylectus.$.corporationPassword}
                                    type={"password"}
                                    fullWidth
                                />
                                <SectionTextField
                                    label={"User Id"}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                    field={this.companyIntegrationDetailsStore.formSylectus.$.userId}
                                    fullWidth
                                />
                                <SectionTextField
                                    label={"User Password"}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                    field={this.companyIntegrationDetailsStore.formSylectus.$.userPassword}
                                    type={"password"}
                                    fullWidth
                                />
                            </Stack>}
                            {this.companyIntegrationDetailsStore.formState.$.type.value == CompanyIntegrationType.DAT &&
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Corporation Email"}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                    field={this.companyIntegrationDetailsStore.formDAT.$.corporationEmail}
                                    fullWidth
                                />
                                <SectionTextField
                                    label={"Corporation Password"}
                                    isReadOnlyMode={this.companyIntegrationDetailsStore.isReadOnlyMode}
                                    field={this.companyIntegrationDetailsStore.formDAT.$.corporationPassword}
                                    type={"password"}
                                    fullWidth
                                />
                            </Stack>}
                        </Stack>
                    </Section>
                    { !this.companyIntegrationDetailsStore.isReadOnlyMode && this.companyIntegrationDetailsStore.integrationId > 0 && <ActivateDeactivateSection isActive={this.companyIntegrationDetailsStore.isActive} isLoading={this.companyIntegrationDetailsStore.isLoading} activate={this.companyIntegrationDetailsStore.activate} deactivate={this.companyIntegrationDetailsStore.deactivate} />}
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.companyIntegrationDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} key={urlParams.id} />;
}
