import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputBase,
    LinearProgress, List, ListItem, ListItemText,
    Stack, Typography
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    DataListGetParamsOfOrderStopAddressListFilters,
    OrderStopAddressListFilters, OrderStopAddressModel, OrderStopType,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {requiredMinLength} from "app/modules/common/form/validators";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {MainStore} from "app/modules/company/stores/MainStore";
import {DialogAddressNew} from "app/modules/company/components/orders/details/dialog.address.new";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogSelectAddressProps {
    closeModal: () => void;
    setAddress: (addressId: number, fullAddress: string, addressName: string) => void;
    title: string;
    orderStopType: OrderStopType;
}

export const DialogSelectAddress = observer((props: DialogSelectAddressProps) => {
    const [formState, _] = useState(new FormState({
        addressId: new FieldState(null),
        fullAddress: new FieldState(''),
        addressName: new FieldState(''),
        search: new FieldState("").validators(requiredMinLength(2))
    }));

    const [items, setItems] = useState<OrderStopAddressModel[]>();
    const [isLoading, setLoading] = useState(false);

    const store = useInstance(ApiClients);
    const dialogStore = useInstance(DialogStore);
    const mainStore = useInstance(MainStore);

    const fetchData = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);
            const data = await store.orderClient.searchOrderStopAddresses(new DataListGetParamsOfOrderStopAddressListFilters({
                offset: 0,
                pageSize: 100,
                filter: new OrderStopAddressListFilters({ search: formState.$.search.value, type: props.orderStopType })
            }));

            setItems(data.items);
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    const createNewAddress = () => {
        const key = "new_address";
        props.closeModal();
        dialogStore.addDialog({
            key: key,
            component: <DialogAddressNew closeModal={() => dialogStore.closeDialog(key)} orderStopType={props.orderStopType} setAddress={async (addressId, fullAddress: string, addressName: string) => {
                props.setAddress(addressId, fullAddress, addressName);
                dialogStore.closeDialog(key);
            }} title="Create Address"/>
        });
    }

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
            id="alert-dialog-description" style={{ minWidth: "400px", padding: "25px" }}
        >
            <Stack spacing={2} direction={"column"}>
                <SearchPaper width={400}>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        value={formState.$.search.value}
                        onChange={(e)=> formState.$.search.onChange(e.target.value)}
                        placeholder="Search (min 2 chars)"
                        inputProps={{ 'aria-label': 'search' }}
                        onKeyPress={e => e.key === "Enter" && fetchData()}
                        error={formState.$.search.hasError}
                    />
                    <SearchVerDivider />
                    <LoadingButton loading={isLoading} variant="text" onClick={fetchData}>Search</LoadingButton>
                </SearchPaper>
                {mainStore.checkClaim(TenantClaims.OrderEdit) && <Button onClick={createNewAddress}>Create New Address</Button>}
                {isLoading ? <LinearProgress/> : <List dense={true} sx={{
                    overflow: 'auto',
                    maxHeight: 300
                }}>
                    {items?.map(x => <ListItem sx={{cursor: "pointer"}} key={x.id} selected={x.id == formState.$.addressId.value} onClick={() => {
                        formState.$.addressId.onChange(x.id);
                        formState.$.addressName.onChange(x.name);
                        formState.$.fullAddress.onChange(x.fullAddress);
                    }}>
                        <ListItemText
                            primary={x.name}
                            secondary={x.fullAddress}
                        />
                    </ListItem>)}
                    {!items?.length && <Typography sx={{ width: "100%" }} align={"center"} variant="subtitle2" display="block">No result.</Typography>}
                </List>}
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} disabled={!formState.$.addressId.value} variant="outlined" onClick={() => { props.setAddress(formState.$.addressId.value, formState.$.fullAddress.value, formState.$.addressName.value); }} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
