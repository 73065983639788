import {observer} from "mobx-react";
import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {inject, provider} from "react-ioc";
import {OrderDetailsStore} from "app/modules/company/components/orders/details/OrderDetailsStore";
import {SectionCheckBoxField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {MainStore} from "app/modules/company/stores/MainStore";
import {LoadingButton} from "@mui/lab";
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import {OrderStopType, OrderStopViewModel} from "app/modules/company/api/clients.api";
import {OrderStopStore} from "app/modules/company/components/orders/details/OrderStopStore";

export interface ModalOrderStopParams {
    orderId: number;
    item: OrderStopViewModel;
    closeOrderStopModal: () => void;
    refreshOrderStops: () => void;
}

@provider(OrderStopStore)
@observer
export class ModalOrderStop extends React.Component<ModalOrderStopParams, any> {
    @inject orderDetailsStore: OrderDetailsStore;
    @inject orderStopStore: OrderStopStore;
    @inject mainStore: MainStore;

    render () {
        return <Dialog
            open={this.orderDetailsStore.isEditStopItemModalOpen}
            onClose={() => { this.props.closeOrderStopModal(); }}
        >
            <DialogTitle id="alert-dialog-title">
                <Stack spacing={2} direction={"row"}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" width={"100%"}>
                        Order Stop
                    </Typography>
                    {this.orderDetailsStore.details.canUpdateStops && <Button color="error" onClick={this.orderDetailsStore.deleteOrderStop}>Delete</Button>}
                </Stack>
            </DialogTitle>
            <DialogContent
                sx={{ backgroundColor: "#f0f0f0" }}
                id="alert-dialog-description" style={{ minWidth: "400px", padding: "25px" }}
            >
                <Stack spacing={2} direction={"column"}>
                    <SectionSelectField
                        field={this.orderStopStore.formState.$.type}
                        label={"Type"}
                        width={"100%"}
                        onChange={this.orderStopStore.clearStopInfo}
                        items={() => [<MenuItem value={OrderStopType.Shipper} key={1}>Shipper</MenuItem>, <MenuItem value={OrderStopType.Consignee} key={2}>Consignee</MenuItem>]}
                    />
                        {this.orderStopStore.formState.$.addressId.value > 0 ?
                        <Stack direction={"column"} spacing={1} onClick={this.orderStopStore.changeAddress} sx={{ color: "#1976d2", cursor: "pointer" }}>
                            <Typography variant={"subtitle1"}>{this.orderStopStore.stopName}</Typography>
                            <Typography variant={"subtitle2"} gutterBottom>{this.orderStopStore.stopFullAddress}</Typography>
                        </Stack> :
                        <Button variant={"text"} onClick={this.orderStopStore.changeAddress}>Search/Create Address</Button>
                    }
                    <Stack spacing={2} direction={"row"}>
                        <DateTimePicker
                            label="Date"
                            renderInput={(params) => <TextField variant="standard" {...params} />}
                            value={this.orderStopStore.formState.$.date.value}
                            onChange={this.orderStopStore.formState.$.date.onChange}
                            minutesStep={15}
                        />
                        <SectionCheckBoxField
                            field={this.orderStopStore.formState.$.isDirectOrASAP}
                            label={this.orderStopStore.formState.$.type.value == OrderStopType.Shipper ? "ASAP" : "Direct"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"POC Name"}
                            field={this.orderStopStore.formState.$.pOCName}
                            width={"50%"}
                        />
                        <SectionTextField
                            label={"POC Phone"}
                            field={this.orderStopStore.formState.$.pOCPhone}
                            width={"50%"}
                        />
                    </Stack>
                    <SectionTextField
                        label={"Description"}
                        field={this.orderStopStore.formState.$.description}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={this.props.closeOrderStopModal}>Close</Button>
                <LoadingButton color="primary" loading={this.orderDetailsStore.isLoading} variant="contained" onClick={this.orderStopStore.save}>Save</LoadingButton>
            </DialogActions>
        </Dialog>;
    }

    componentDidMount() {
        this.orderStopStore.init(this.props);
    }
}
