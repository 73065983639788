import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const NavigateHook = (props: { path: string }) => {
    const navigate = useNavigate();

    useEffect( () => {
        navigate(props.path);
    });

    return <React.Fragment/>
};
