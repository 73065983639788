import {observer} from "mobx-react";
import {Component} from "react";
import {inject, provider} from "react-ioc";
import {SetPasswordStore} from "app/modules/auth/components/set-password/SetPasswordStore";
import {Alert, Avatar, Box, Container, Grid, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from "react";
import {LoadingButton} from "@mui/lab";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {centerBoxStyle} from "app/modules/auth";

@provider(SetPasswordStore)
@observer
export default class Index extends Component<any, any> {
    @inject setPasswordStore: SetPasswordStore;

    render() {

        if(this.setPasswordStore.isSuccess) {
            return <Box sx={centerBoxStyle}>
                <Alert sx={{ mt: 2, mb: 2 }} severity="success">Account password has been changed successfully, please login again.</Alert>
                <Box component="form">
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/login" Text="Login" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>;
        }

        if(this.setPasswordStore.isWrongParams) {
            return <Box sx={centerBoxStyle}>
                <Alert sx={{ mt: 2, mb: 2 }} severity="warning">Reset password ling is not valid, please try again.</Alert>
                <Box component="form">
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/login" Text="Login" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>;
        }

        return <Container component="main" maxWidth="xs">
            <Box sx={centerBoxStyle}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Set Password
                </Typography>

                {this.setPasswordStore.errorMessage && <Alert sx={{ width: "100%", mt: 2, mb: 2 }} severity="error">{this.setPasswordStore.errorMessage}</Alert>}

                <Box component="form" onSubmit={() => {}} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={this.setPasswordStore.formState.$.password.value}
                        error={this.setPasswordStore.formState.$.password.hasError}
                        helperText={this.setPasswordStore.formState.$.password.error}
                        onChange={(e) => this.setPasswordStore.formState.$.password.onChange(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="confirm-password"
                        label="Confirm Password"
                        type="password"
                        id="confirm-password"
                        autoComplete="current-password"
                        value={this.setPasswordStore.formState.$.confirmPassword.value}
                        error={this.setPasswordStore.formState.$.confirmPassword.hasError}
                        helperText={this.setPasswordStore.formState.$.confirmPassword.error}
                        onChange={(e) => this.setPasswordStore.formState.$.confirmPassword.onChange(e.target.value)}
                    />
                    <LoadingButton
                        fullWidth
                        loading={this.setPasswordStore.isLoading}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={this.setPasswordStore.submit}
                    >
                        Set Password
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <BtnLink Link="/login" Text="Login" variant="text"/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>;
    }

    componentDidMount() {
        this.setPasswordStore.init();
    }
}
