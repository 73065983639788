import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {CustomerDetailsStore} from "app/modules/company/components/contact/customers/details/CustomerDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Box, Grid, MenuItem, Stack, TextField} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {FileInput} from "app/modules/company/components/file/file-uploader";
import {FileItemType, FileType} from "app/modules/company/api/clients.api";

@provider(CustomerDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject customerDetailsStore: CustomerDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.customerDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.customerDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Customers.nav}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.customerDetailsStore.title} Size={!this.customerDetailsStore.isReadOnlyMode ? 8 : 12}/>
                {!this.customerDetailsStore.isReadOnlyMode && <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.customerDetailsStore.isLoading} disabled={this.customerDetailsStore.formState.hasError} variant="contained" onClick={this.customerDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>}
                <ContainerDetails>
                    <SectionName title="Info" description="Customer info" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <SectionTextField
                                label={"Name"}
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.name}
                                fullWidth
                            />
                            <SectionTextField
                                label={"Company Name"}
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.companyName}
                                fullWidth
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"MC Prefix"}
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.mcPrefix}
                                    width={"33%"}
                                />
                                <SectionTextField
                                    label={"MC"}
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.mcNumber}
                                    width={"33%"}
                                />
                                <SectionTextField
                                    label={"USDOT"}
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.uSDOT}
                                    width={"33%"}
                                />
                            </Stack>
                            <SectionTextField
                                label={"Address"}
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.address}
                                fullWidth
                            />
                            <SectionTextField
                                label={"Address2"}
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.address2}
                                fullWidth
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"City"}
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.city}
                                    width={"33%"}
                                />
                                <SectionTextField
                                    label={"State"}
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.state}
                                    width={"33%"}
                                />
                                <SectionTextField
                                    label={"ZIP"}
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.zip}
                                    width={"33%"}
                                />
                            </Stack>
                            <SectionTextField
                                label={"Phone"}
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.phone}
                                fullWidth
                            />
                        </Stack>
                        {this.customerDetailsStore.customerId > 0 && <FileInput fileType={FileType.Agreement} fileItemType={FileItemType.Customer} objectId={this.customerDetailsStore.customerId}/>}
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Notes" description="You can set notes to warn colleagues" />
                    <Section>
                        <Stack spacing={2} direction={"row"}>
                            <SectionSelectField
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.noteType}
                                width={"50%"}
                                label={"Note Type"}
                                items={() => this.customerDetailsStore.noteTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                            />
                            <SectionTextField
                                label={"Notes"}
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.notes}
                                width={"50%"}
                            />
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Payment Info" description="Please set a remit address and default payment term/payer if use a factoring company(created as a customer)" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                                <SectionTextField
                                label={"Remit Address"}
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.remitAddress}
                                fullWidth
                            />
                            <SectionTextField
                                label={"Remit Address2"}
                                isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                field={this.customerDetailsStore.formState.$.remitAddress2}
                                fullWidth
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Remit City"}
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.remitCity}
                                    width={"33%"}
                                />
                                <SectionSelectField
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.remitState}
                                    width={"33%"}
                                    label={"State"}
                                    items={() => this.mainStore.tenantData.states.map((x) => <MenuItem value={x.code} key={x.code}>{x.name}</MenuItem>)}
                                />
                                <SectionTextField
                                    label={"Remit ZIP"}
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.remitZip}
                                    width={"33%"}
                                />
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionSelectField
                                    isReadOnlyMode={this.customerDetailsStore.isReadOnlyMode}
                                    field={this.customerDetailsStore.formState.$.defaultPaymentNetTermId}
                                    width={"50%"}
                                    label={"Net Term"}
                                    none
                                    items={() => this.customerDetailsStore.paymentNetTerms.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                                />
                                <Box sx={{ cursor: "pointer !important", width: "50%" }}>
                                    <TextField
                                        id="outlined-disabled"
                                        label="Default Payer"
                                        fullWidth
                                        disabled={this.customerDetailsStore.isReadOnlyMode}
                                        onClick={() => !this.customerDetailsStore.isReadOnlyMode && this.customerDetailsStore.changeCustomer()}
                                        value={this.customerDetailsStore.formState.$.defaultPayer.value || "Set Default Payer"}
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                    </Section>
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.customerDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
