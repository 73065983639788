import axios, {AxiosInstance} from "axios";

import {
    ITenantDataProviderClient,
    TenantDataProviderClient,
    IDriverClient,
    DriverClient,
    IFileClient,
    FileClient,
    CarrierClient,
    ICarrierClient,
    VehicleClient,
    IVehicleClient,
    IntegrationClient,
    IIntegrationClient,
    SettingsClient,
    ISettingsClient,
    RadarClient,
    IRadarClient,
    IBidsClient,
    BidsClient,
    OrderClient,
    IOrderClient,
    INoteClient,
    NoteClient,
    IContactClient,
    ContactClient,
    ITelegramClient,
    TelegramClient,
    PaymentClient,
    IPaymentClient,
    IInvoiceClient,
    InvoiceClient,
    ITrackingDeviceClient,
    TrackingDeviceClient,
    IOrderTaskClient,
    OrderTaskClient,
    IOrderQuoteClient,
    OrderQuoteClient,
    IOwnerAccountClient,
    OwnerAccountClient,
    IMobileClient,
    MobileClient,
    ICompanyIntegrationClient,
    CompanyIntegrationClient
} from "../api/clients.api";

const hostName = window.location.hostname;
export const apiBaseUrl = hostName == 'localhost' ? 'http://localhost:3000' :'https://app.onlogixs.com';

export class ApiClients {
    tenantDataProviderClient: ITenantDataProviderClient = null;
    driverClient: IDriverClient = null;
    fileClient: IFileClient = null;
    noteClient: INoteClient = null;
    carrierClient: ICarrierClient = null;
    vehicleClient: IVehicleClient = null;
    integrationClient: IIntegrationClient = null;
    companyIntegrationClient: ICompanyIntegrationClient = null;
    settingsClient: ISettingsClient = null;
    radarClient: IRadarClient = null;
    bidsClient: IBidsClient = null;
    orderClient: IOrderClient = null;
    contactClient: IContactClient = null;
    telegramClient: ITelegramClient = null;
    paymentClient: IPaymentClient = null;
    invoiceClient: IInvoiceClient = null;
    trackingDeviceClient: ITrackingDeviceClient = null;
    orderTaskClient: IOrderTaskClient = null;
    orderQuoteClient: IOrderQuoteClient = null;
    ownerAccountClient: IOwnerAccountClient = null;
    axiosInstance: AxiosInstance = null;
    mobileClient: IMobileClient = null;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: apiBaseUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            validateStatus: (status) => {
                return status >= 200 && status < 500
            }
        });

        this.axiosInstance.defaults.withCredentials = true;

        this.tenantDataProviderClient = new TenantDataProviderClient(apiBaseUrl, this.axiosInstance);
        this.driverClient = new DriverClient(apiBaseUrl, this.axiosInstance);
        this.fileClient = new FileClient(apiBaseUrl, this.axiosInstance);
        this.noteClient = new NoteClient(apiBaseUrl, this.axiosInstance);
        this.carrierClient = new CarrierClient(apiBaseUrl, this.axiosInstance);
        this.vehicleClient = new VehicleClient(apiBaseUrl, this.axiosInstance);
        this.settingsClient = new SettingsClient(apiBaseUrl, this.axiosInstance);
        this.radarClient = new RadarClient(apiBaseUrl, this.axiosInstance);
        this.bidsClient = new BidsClient(apiBaseUrl, this.axiosInstance);
        this.integrationClient = new IntegrationClient(apiBaseUrl, this.axiosInstance);
        this.orderClient = new OrderClient(apiBaseUrl, this.axiosInstance);
        this.contactClient = new ContactClient(apiBaseUrl, this.axiosInstance);
        this.telegramClient = new TelegramClient(apiBaseUrl, this.axiosInstance);
        this.paymentClient = new PaymentClient(apiBaseUrl, this.axiosInstance);
        this.trackingDeviceClient = new TrackingDeviceClient(apiBaseUrl, this.axiosInstance);
        this.invoiceClient = new InvoiceClient(apiBaseUrl, this.axiosInstance);
        this.orderQuoteClient = new OrderQuoteClient(apiBaseUrl, this.axiosInstance);
        this.ownerAccountClient = new OwnerAccountClient(apiBaseUrl, this.axiosInstance);
        this.orderTaskClient = new OrderTaskClient(apiBaseUrl, this.axiosInstance);
        this.mobileClient = new MobileClient(apiBaseUrl, this.axiosInstance);
        this.companyIntegrationClient = new CompanyIntegrationClient(apiBaseUrl, this.axiosInstance);

        this.axiosInstance.interceptors.response.use((response) => {
            if(response.status == 400) {
                if(response.data.ErrorCode == "Maintenance" && response.data.Obj == "Tenant") {
                    window.location.href = "/maintenance";
                    return;
                }
            }

            return response;
        }, (error) => {
            if(error.response?.status == 401) {
                window.location.href = "/";
            }
        });
    }
}


