import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputBase,
    LinearProgress, List, ListItem, ListItemText,
    Stack, Tab, Typography
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton, TabContext, TabList, TabPanel} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    ContactListFilters,
    ContactEditModel,
    ContactListModel, DataListGetParamsOfContactListFilters,
    TenantClaims
} from "app/modules/company/api/clients.api";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {required, requiredMinLength} from "app/modules/common/form/validators";
import {SectionTextField} from "app/modules/common/details/text-field";
import {MainStore} from "app/modules/company/stores/MainStore";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogCustomerContactNewSearchProps {
    closeModal: () => void;
    setContact: (customerId: number) => void;
    title: string;
}

enum TabType {
    Search = 1,
    New = 2
};

export const DialogCustomerContactNewSearch = observer((props: DialogCustomerContactNewSearchProps) => {
    const [formSearchState, _S] = useState(new FormState({
        contactId: new FieldState(null),
        search: new FieldState("").validators(requiredMinLength(2))
    }));

    const [formState, _] = useState(new FormState({
        fullName: new FieldState("").validators(required),
        phone: new FieldState(""),
        extension: new FieldState(""),
        email: new FieldState("").validators(required),
        title: new FieldState("")
    }));


    const [items, setItems] = useState<ContactListModel[]>();
    const [isLoading, setLoading] = useState(false);
    const [tab, setTab] = useState(TabType.Search.toString());

    const store = useInstance(ApiClients);
    const mainStore = useInstance(MainStore);

    const fetchData = async () => {
        if(isLoading) {
            return;
        }

        await formSearchState.validate();

        if(formSearchState.hasError) {
            return;
        }

        try {
            setLoading(true);
            const data = await store.contactClient.getContactList(new DataListGetParamsOfContactListFilters({
                offset: 0,
                pageSize: 100,
                filter: new ContactListFilters({ search: formSearchState.$.search.value })
            }));
            setItems(data.items);
        } finally {
            setLoading(false);
        }
    };

    const save = async () => {
        if(tab == TabType.Search.toString()) {
            props.setContact(formSearchState.$.contactId.value);
        } else {
            if(isLoading) {
                return;
            }

            await formState.validate();

            if(formState.hasError) {
                return;
            }

            try {
                setLoading(true);
                const id = await store.contactClient.contactUpsert(new ContactEditModel({
                    fullName: formState.$.fullName.value,
                    phone: formState.$.phone.value,
                    extension: formState.$.extension.value,
                    email: formState.$.email.value,
                    title: formState.$.title.value
                }));

                props.setContact(id);
            }
            catch (ex) { ApiErrorHandler(ex); }
            finally {
                setLoading(false);
            }
        }
    }

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                <TabContext value={tab}>
                    <TabList onChange={(e, newValue) => setTab(newValue)} sx={{ width: "100%" }}>
                        <Tab value={TabType.Search.toString()} label="Search" sx={{ width: "50%", borderBottom: "1px solid rgb(25, 118, 210)" }} />
                        {mainStore.checkClaim(TenantClaims.ContactEdit) && <Tab value={TabType.New.toString()} label="New" sx={{ width: "50%", borderBottom: "1px solid rgb(25, 118, 210)" }} />}
                    </TabList>
                    <TabPanel value={TabType.Search.toString()}>
                        <Stack spacing={2} direction={"column"}>
                            <SearchPaper width={400}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    value={formSearchState.$.search.value}
                                    onChange={(e)=> formSearchState.$.search.onChange(e.target.value)}
                                    placeholder="Search by name/email/phone (min 2 chars)"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onKeyPress={e => e.key === "Enter" && fetchData()}
                                    error={formSearchState.$.search.hasError}
                                />
                                <SearchVerDivider />
                                <LoadingButton loading={isLoading} variant="text" onClick={fetchData}>Search</LoadingButton>
                            </SearchPaper>
                            {isLoading ? <LinearProgress/> : <List dense={true} sx={{
                                overflow: 'auto',
                                maxHeight: 300
                            }}>
                                {items?.map(x => <ListItem sx={{cursor: "pointer"}} key={x.id} selected={x.id == formSearchState.$.contactId.value} onClick={() => formSearchState.$.contactId.onChange(x.id)}>
                                    <ListItemText
                                        primary={x.fullName}
                                        secondary={[(x.phone && "Phone:") + x.phone, (x.email && "Email:") + x.email].filter(x => !!x).join(", ")}
                                    />
                                </ListItem>)}
                                {!items?.length && <Typography sx={{ width: "100%" }} align={"center"} variant="subtitle2" display="block">No result.</Typography>}
                            </List>}
                        </Stack>
                    </TabPanel>
                    <TabPanel value={TabType.New.toString()}>
                        <SectionTextField
                            label={"Full Name"}
                            field={formState.$.fullName}
                            fullWidth
                        />
                        <Stack spacing={2} direction={"row"}>
                            <SectionTextField
                                label={"Phone"}
                                field={formState.$.phone}
                                width={"70%"}
                            />
                            <SectionTextField
                                label={"Extension"}
                                field={formState.$.extension}
                                width={"30%"}
                            />
                        </Stack>
                        <SectionTextField
                            label={"Email"}
                            field={formState.$.email}
                            fullWidth
                        />
                        <SectionTextField
                            label={"Title"}
                            field={formState.$.title}
                            fullWidth
                        />
                    </TabPanel>
                </TabContext>
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} disabled={tab == TabType.Search.toString() && !formSearchState.$.contactId.value} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
