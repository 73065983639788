import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Box, Button, Grid, IconButton, Stack, Tooltip} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {SearchVerDivider} from "app/modules/common/layout/components/search";
import {CustomerNoteType, RadarModel, TenantClaims} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {ExternalOrdersStore} from "app/modules/company/components/load-boards/external-orders/ExternalOrdersStore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import {customerNoteTypeColorMap} from "app/modules/company/components/contact/customers";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {withHooksHOC} from "app/modules/common/grid/data-grid-hoc";
import {PusherTenantService} from "app/modules/common/pusher/PusherTenantService";
import {SectionTextField} from "app/modules/common/details/text-field";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";

const columns = [
    {
        field: 'hide',
        headerName: 'Hide',
        width: 70,
        renderCell: (params: GridRenderCellParams<RadarModel>) => {
            const externalOrdersStore = useInstance(ExternalOrdersStore);

            return <div>
                <IconButton onClick={() => externalOrdersStore.hideBid(params.row.id)}>
                    <VisibilityOffIcon sx={{ fontSize: "25px" }} color={"disabled"}/>
                </IconButton>
            </div>;
        }
    },
    {
        field: 'bid',
        headerName: 'Bid',
        width: 70,
        renderCell: (params: GridRenderCellParams<RadarModel>) => {
            const store = useInstance(MainStore);
            const externalOrdersStore = useInstance(ExternalOrdersStore);

            return <div>
                {params.row.bidIds?.length > 0 ? <b>Bid</b> : (store.checkClaim(TenantClaims.BidCreate) ? <IconButton onClick={() => externalOrdersStore.bet(params.row.id)}>
                    <EmailIcon sx={{ fontSize: "25px" }} color={params.row.isViewed && "disabled" || "primary"}/>
                </IconButton> : "No Bid")}
            </div>;
        }
    },
    {
        field: 'customer',
        headerName: 'Customer',
        width: 400,
        renderCell: (params: GridRenderCellParams<RadarModel>) => {
            let name = <span>{params.row.customer}</span>;

            if(params.row.customerNote?.length > 0) {
                name = <Tooltip title={params.row.customerNote} arrow>{name}</Tooltip>;
            }

            return <div>
                <div>{params.row.customerNoteType != CustomerNoteType.None ? <span style={{ color: customerNoteTypeColorMap.get(params.row.customerNoteType) }}>{name}</span> : name}</div>
                <div><b>{params.row.customerName?.length > 0 && <span>{params.row.customerName} </span>}{params.row.customerMc && <span>MC:{params.row.customerMc}</span>}</b></div>
            </div>;
        }
    },
    {
        field: 'from',
        headerName: 'From',
        width: 200,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div>{params.row.from + " " + params.row.fromZip}</div>
                <div><b>{params.row.pickup}</b></div>
            </div>
        ),
    },
    {
        field: 'to',
        headerName: 'To',
        width: 200,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div>{params.row.to + " " + params.row.toZip}</div>
                <div><b>{params.row.drop}</b></div>
            </div>
        ),
    },
    {
        field: 'price',
        headerName: 'Rate/OrderNum',
        width: 70,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div><b>${params.row.price && NumberHelper.formatUSDollar(params.row.price)}</b></div>
                <div><b>{params.row.orderNumber}</b></div>
            </div>
        ),
    },
    {
        field: 'miles',
        headerName: 'Miles',
        type: 'number',
        width: 70,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div><b>{params.row.miles}</b> ml</div>
            </div>
        ),
    },
    {
        field: 'weight',
        headerName: 'Weight',
        width: 110,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div><b>{params.row.weight}</b> lb</div>
                <div><b>{params.row.pieces}</b> pc</div>
            </div>
        ),
    },
    {
        field: 'vehicleType',
        headerName: 'VehicleType',
        width: 150
    },
    {
        field: 'posted',
        headerName: 'Posted',
        width: 150,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(params.row.postedFrom)}</div>
                <div>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(params.row.postedTo)}</div>
            </div>
        ),
    },
    {
        field: 'contact',
        headerName: 'Contact',
        width: 400,
        renderCell: (params: GridRenderCellParams<RadarModel>) => (
            <div>
                <div>{params.row.contact}
                    {params.row.contactEmail && <Button variant={"text"} sx={{ fontSize: "12px", minWidth: 0 }} onClick={() => navigator.clipboard.writeText(params.row.contactEmail)} title={params.row.contactEmail}>Email</Button>}
                    {params.row.contactPhone && <Button variant={"text"} sx={{ fontSize: "12px", minWidth: 0 }} onClick={() => navigator.clipboard.writeText(params.row.contactPhone)} title={params.row.contactPhone}>Phone</Button>}
                </div>
            </div>
        ),
    },
];

@provider(ExternalOrdersStore)
@observer
 class Index extends Component<any, any> {
    @inject externalOrdersStore: ExternalOrdersStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;
    @inject pusherTenantService: PusherTenantService;

    render() {
        if(this.externalOrdersStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.externalOrdersStore.totalRows}/>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ pb: 1, pt: 1, width: "100%", backgroundColor: "#fcfcfc" }}>
                    <Stack direction={"row"} spacing={2} sx={{ width: "100%" }} alignItems="center" justifyContent="center">
                        <SectionTextField
                            label={"From Zip"}
                            field={this.externalOrdersStore.filters.$.fromZip}
                            type={"number"}
                            width={"100px"}
                        />
                        <SectionTextField
                            label={"From Radius"}
                            field={this.externalOrdersStore.filters.$.fromRadius}
                            type={"number"}
                            width={"100px"}
                        />
                        <SectionTextField
                            label={"To Zip"}
                            field={this.externalOrdersStore.filters.$.toZip}
                            type={"number"}
                            width={"100px"}
                        />
                        <SectionTextField
                            label={"To Radius"}
                            field={this.externalOrdersStore.filters.$.toRadius}
                            type={"number"}
                            width={"100px"}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.externalOrdersStore.applyFilters}>Search</Button>
                    </Stack>
                </Box>
            </Grid>
            <DataGridBody openFilter={true}>
                <DataGridPro
                    className={"radar-grid"}
                    disableVirtualization
                    rows={this.externalOrdersStore.items}
                    columns={columns}
                    pageSize={this.externalOrdersStore.pageSize}
                    rowsPerPageOptions={this.externalOrdersStore.pageSizeOptions}
                    rowCount={this.externalOrdersStore.totalRows}
                    page={this.externalOrdersStore.skipRows/this.externalOrdersStore.pageSize}
                    loading={this.externalOrdersStore.isLoading}
                    detailPanelExpandedRowIds={this.externalOrdersStore.expandedRows}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.externalOrdersStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.externalOrdersStore.setPageSize(pageSize) }
                    getDetailPanelHeight={() => 35}
                    getDetailPanelContent={(params) =>
                        params.row.note?.length > 0 && <Box sx={{ p: 1, color: "#1976d2" }}><b>{params.row.note}</b></Box>}
                />
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.externalOrdersStore.init(this.props.gridApiRef);

        this.pusherTenantService.bindToTenantChannel("RadarOrderViewed", this.orderViewed);
        this.pusherTenantService.bindToTenantChannel("RadarOrderBet", this.orderBet);
        this.pusherTenantService.bindToTenantChannel("RadarOrderHide", this.hideOrder);
    }

    async componentWillUnmount() {
        this.pusherTenantService.unbindToTenantChannel("RadarOrderViewed", this.orderViewed);
        this.pusherTenantService.unbindToTenantChannel("RadarOrderBet", this.orderBet);
        this.pusherTenantService.unbindToTenantChannel("RadarOrderHide", this.hideOrder);
    }

    orderViewed = (id) => {
        const found = this.externalOrdersStore.items.find(x => x.id == id);

        if(found != null) {
            found.isViewed = true;
            this.props.gridApiRef.current.updateRows(this.externalOrdersStore.items);
        }
    }

    orderBet = (event) => {
        const found = this.externalOrdersStore.items.find(x => x.id == event.Id);

        if(found != null) {
            if(found.bidIds != null) {
                found.bidIds.push(event.BidId);
            } else {
                found.bidIds = [event.BidId];
            }

            this.props.gridApiRef.current.updateRows(this.externalOrdersStore.items);
        }
    }

    hideOrder = (id) => this.externalOrdersStore.removeOrder(id);
}

export default withHooksHOC(Index);

