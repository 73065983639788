import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfInvoiceListFilters, InvoiceListFilters,
    InvoiceListModel, InvoiceStatus
} from "app/modules/company/api/clients.api";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import React from "react";
import {ModalOrderDetails} from "app/modules/company/components/orders/details/modal.order.details";

export class InvoiceStore extends BaseListStore<InvoiceListModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState(''),
        statuses: new FieldState([InvoiceStatus.Closed]),
        overdue: new FieldState(false)
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action,
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            await this.filters.validate();

            if(this.filters.hasError) {
                return;
            }

            let filters = new InvoiceListFilters({
                search: this.filters.$.search.value,
                statuses: this.filters.$.statuses.value,
                overdue: this.filters.$.overdue.value
            });

            const [result] = await Promise.all([
                this.companyApiClients.invoiceClient.getList(new DataListGetParamsOfInvoiceListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    openOrder = (orderId: number) => {
        const key = "order_details_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalOrderDetails orderId={orderId} closeOrderModal={() => this.dialogStore.closeDialog(key)} /></div>
        });
    }

    async init() {
        await this.loadData()

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
