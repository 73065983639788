import React, {Component} from "react";
import {Button, Grid, IconButton, InputBase} from "@mui/material";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {Link} from "react-router-dom";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {OrganizationsStore} from "app/modules/admin/components/organizations/OrganizationsStore";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {NavigationStore} from "app/modules/admin/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {Paths} from "app/modules/admin/stores/paths";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {OrganizationListModel, TenantListModel, UserListModel} from "app/modules/admin/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {StringHelper} from "app/modules/common/helpers/StringHelper";

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 5,
        renderCell: (params: GridRenderCellParams<OrganizationListModel>) => (
            <Link to={Paths.EditOrganization.nav(params.row.id)}>{params.row.name}</Link>
        ),
    },{
        field: 'tenants',
        headerName: 'Tenants',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrganizationListModel>) => (
            params.row.tenants
        )
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrganizationListModel>) => (
            StringHelper.formatActive(params.row.active)
        )
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<UserListModel>) => (
            DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.createdAt)
        ),
    },
    {
        field: 'impersonate',
        headerName: 'Impersonate',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TenantListModel>) => {
            let organizationsStore = useInstance(OrganizationsStore);

            return <IconButton color="primary" onClick={() => organizationsStore.impersonateOrganization(params.row.id)}>
                <VisibilityIcon />
            </IconButton>
        },
    }
];

@provider(OrganizationsStore)
@observer
export default class Index extends Component<any, any> {
    @inject organizationsStore: OrganizationsStore;
    @inject navigationStore: NavigationStore;

    render() {
        if(this.organizationsStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.organizationsStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewOrganization.nav} Text="New Organization"/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.organizationsStore.filters.$.name.value}
                            onChange={(e)=>this.organizationsStore.filters.$.name.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.organizationsStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.organizationsStore.items}
                    columns={columns}
                    pageSize={this.organizationsStore.pageSize}
                    rowsPerPageOptions={this.organizationsStore.pageSizeOptions}
                    rowCount={this.organizationsStore.totalRows}
                    page={this.organizationsStore.skipRows/this.organizationsStore.pageSize}
                    loading={this.organizationsStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.organizationsStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.organizationsStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {

        this.organizationsStore.init();
    }
}

