import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {SectionSelectField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {VehicleShortListModel} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";

export interface DialogVehicleProps {
    closeModal: () => void;
    setVehicle: (vehicleId: number) => void;
    vehicleId?: number;
    setNone: boolean;
    title: string;
}

export const DialogVehicle = observer((props: DialogVehicleProps) => {
    const [formState, _] = useState(new FormState({
        vehicleId: new FieldState(props.vehicleId || null),
    }));

    const [items, setItems] = useState<VehicleShortListModel[]>();
    const [isLoading, setLoading] = useState(true);

    const store = useInstance(CacheService);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await store.getVehicles();
            setItems(data);
            setLoading(false);
        };

        fetchData();
    }, []);

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                {!isLoading ? <SectionSelectField
                    field={formState.$.vehicleId}
                    width={"100%"}
                    label={"Vehicle"}
                    none={props.setNone}
                    items={() => { return items.map((x) => <MenuItem value={x.id} key={x.id}><b>#{x.number}</b>{x.carrierId && <span>&nbsp;({x.carrierName})</span>}</MenuItem>); }}
                /> : <LinearProgress/> }
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={() => { props.setVehicle(formState.$.vehicleId.value); }} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
