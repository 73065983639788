import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {email, required} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {
    CarrierShortListModel,
    OwnerAccountEditModel,
    OwnerAccountNewModel,
    TenantOwnerStatus,
    VehicleShortListModel
} from "app/modules/company/api/clients.api";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {CacheService} from "app/modules/company/services/CacheService";

export class OwnerAppAccountDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject dialogStore: DialogStore;
    @inject cacheService: CacheService;

    title: string = 'New Contact';
    accountId: number;
    vehicles?: VehicleShortListModel[] = null;
    carriers?: CarrierShortListModel[] = null;

    status: TenantOwnerStatus = null;

    formState = new FormState({
        name: new FieldState('').validators(required),
        email: new FieldState('').validators(required, email),

        vehicleIds: new FieldState<number[]>([]),
        carrierId: new FieldState<number>(null)
    });

    constructor() {
        super();

        makeObservable(this, {
            title: observable,
            vehicles: observable,
            carriers: observable,
            status: observable,

            init: action,
            save: action,
            activate: action,
            deactivate: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            if(!!this.accountId) {
                await this.companyApiClients.ownerAccountClient.update(new OwnerAccountEditModel({
                    id: this.accountId,
                    name: this.formState.$.name.value,
                    vehicleIds: this.formState.$.vehicleIds.value,
                    carrierId: this.formState.$.carrierId.value
                }));
            } else {
                await this.companyApiClients.ownerAccountClient.create(new OwnerAccountNewModel({
                    name: this.formState.$.name.value,
                    email: this.formState.$.email.value,
                    vehicleIds: this.formState.$.vehicleIds.value,
                    carrierId: this.formState.$.carrierId.value
                }));
            }

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }

    activate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.ownerAccountClient.activate(this.accountId);
            runInAction(() => {
                this.status = TenantOwnerStatus.Active;
            });
        });
    }

    deactivate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.ownerAccountClient.deactivate(this.accountId);
            runInAction(() => {
                this.status = TenantOwnerStatus.Inactive;
            });
        });
    }

    init = async (id: number) => {
        this.accountId = id;

        this.vehicles = await this.cacheService.getVehicles();
        this.carriers = await this.cacheService.getCarriers();

        await this.wrapInitLoadingApiCall(async () => {
            if(this.accountId > 0) {
                const result = await this.companyApiClients.ownerAccountClient.getDetails(this.accountId);

                this.title = result.name;

                this.formState.$.name.value = result.name;
                this.formState.$.email.value = result.email;
                this.formState.$.vehicleIds.value = result.vehicleIds;
                this.formState.$.carrierId.value = result.carrierId;

                this.status = result.status;
            }
        });
    }
}
