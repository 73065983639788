import React, {useRef, useState} from "react";
import queryString from "query-string";
import Alert from '@mui/material/Alert';
import {
    Box,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import FileUploadIcon from '@mui/icons-material/FileUpload';

export interface IFileInputParams {
    params: object;
    title: string;
    url: string;
    accept: string;
    callback(object): void;
}

export const FileSingleInput = (params: IFileInputParams) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    let apiClients = useInstance(ApiClients);

    const fileInput = useRef(null)

    if(loading) {
        return <div>File is uploading...</div>;
    }

    return <Stack direction={"column"} sx={{width: "100%"}} display={"flex"} alignItems={"center"}>
        {error?.length > 0 ? <Alert severity="error">
            Error — <strong>{error}</strong>
        </Alert> : null}
        <div>
            <Box>
                <IconButton color="primary" onClick={() => fileInput.current.click()}>
                    <Typography variant="subtitle2" component="div"><b>{params.title}</b></Typography>
                    <FileUploadIcon />
                </IconButton>
            </Box>
            <input
                style={{ display: "none" }}
                type="file"
                multiple={false}
                ref={fileInput}
                accept={params.accept}
                onChange={e => {
                    setLoading(true);

                    let formData = new FormData();
                    formData.append("file", e.target.files[0]);

                    // @ts-ignore
                    let obj = queryString.stringify({ id: params.params });

                    apiClients.axiosInstance.post(params.url + "?" + obj,
                        formData, {
                            // @ts-ignore
                            headers: {
                                'Content-Type': 'application/x-www-url-formencoded'
                            }
                        }).then((value) => {
                        if(value.status == 400) {
                            setError(value?.data?.Message || "Unexpected Error");
                        } else {
                            setError('');
                        }

                        if(value.status == 200) {
                            params.callback(value.data);
                        }
                    }).catch(() => {
                        setError('File is not uploaded.')
                    }).finally(() => {
                        setLoading(false);
                    })
                }}
            />
        </div>
    </Stack>
}
