import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    CarrierShortListModel, VehicleAvailabilityModel,
    VehicleRadarAvailabilityStatus
} from "app/modules/company/api/clients.api";
import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate";
import {ModalOrderDetails} from "app/modules/company/components/orders/details/modal.order.details";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {CacheService} from "app/modules/company/services/CacheService";
import Moment from "moment";

export class FleetViewStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;
    @inject cacheService: CacheService;

    filters = new FormState({
        search: new FieldState(''),
        carrierId: new FieldState(-1)
    })

    items: VehicleAvailabilityModel[] = null;
    filteredItems: VehicleAvailabilityModel[] = null;
    carrier: CarrierShortListModel[] = [];
    selectedVehicle: number = null;

    constructor() {
        super();

        makeObservable(this, {
            filteredItems: observable,
            selectedVehicle: observable,

            init: action,
            applyFilters: action,
            setSelected: action
        });
    }

    openOrderDetails = (orderId: number) => {
        const key = "order_details_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalOrderDetails orderId={orderId} closeOrderModal={() => this.dialogStore.closeDialog(key)} /></div>
        });
    }

    applyFilters = async () => {
        this.selectedVehicle = null;

        if(this.filters.$.search.value?.length > 0) {
            this.filteredItems = this.items.filter(x => this.filters.$.search.value == x.number.toString());
        } else {
            switch (this.filters.$.carrierId.value) {
                case -1:
                    this.filteredItems = this.items;
                    break;
                case -2:
                    this.filteredItems = this.items.filter(x => x.status == VehicleRadarAvailabilityStatus.Available && x.availability.availableAt < Moment());
                    break;
                case -3:
                    this.filteredItems = this.items.filter(x => x.status == VehicleRadarAvailabilityStatus.Available);
                    break;
                case -4:
                    this.filteredItems = this.items.filter(x => x.status != VehicleRadarAvailabilityStatus.Available);
                    break;
                default:
                    this.filteredItems = this.items.filter(x => x.carrierId == (this.filters.$.carrierId.value || null));
                    break;
            }
        }
    }

    setSelected = (id: number) => {
        this.selectedVehicle = this.selectedVehicle == id ? null : id;
    }

    refreshFleet = async () => {
        const [fleet] = [
            await this.companyApiClients.vehicleClient.getVehicleAvailabilityModels()
        ];

        await this.setItems(fleet);
        await this.applyFilters();
    }

    setItems = async (fleet: VehicleAvailabilityModel[]) => {
        this.items = fleet.sort((a, b) => {
            let aSpeed = a.speed > 0 ? 0 : 1
            let bSpeed = b.speed > 0 ? 0 : 1
            return aSpeed - bSpeed;
        });

        this.filteredItems = this.items;
    }

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
            const [fleet, carriers] = [
                await this.companyApiClients.vehicleClient.getVehicleAvailabilityModels(),
                await this.cacheService.getCarriers()
            ];

            await this.setItems(fleet);

            this.carrier = [
                new CarrierShortListModel({ id: -1, displayName: "All" }),
                new CarrierShortListModel({ id: -2, displayName: "Available Now" }),
                new CarrierShortListModel({ id: -3, displayName: "Available All" }),
                new CarrierShortListModel({ id: -4, displayName: "Unavailable" }),
                new CarrierShortListModel({ id: null, displayName: "Own" })]
                .concat(carriers);
        });
    }
}
