import React, {useEffect, useState} from "react";
import Alert from '@mui/material/Alert';
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {
    INoteListItem, NewNoteModel, NoteItemType, NoteListItem,
    NoteType
} from "app/modules/company/api/clients.api";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import EditIcon from '@mui/icons-material/Edit';
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";

export interface INotesParams {
    noteType: NoteType,
    noteItemType: NoteItemType;
    objectId: number;
    notes?: NoteListItem[];
}

const FileTypeNames: Map<NoteType,string> = new Map([
    [NoteType.Order, "Notes"],
    [NoteType.OrderAccounting, "Accounting Notes"],
    [NoteType.Carrier, "Carrier's Notes"],
    [NoteType.Driver, "Driver's Notes"],
    [NoteType.Vehicle, "Vehicle's Notes"]
]);

export const Notes = (params: INotesParams) => {
    const [note, setNote] = useState("");
    const [loading, setLoading] = useState(true);
    const [addLoading, setAddLoading] = useState(false);
    const [error, setError] = useState('');
    const [notes, setNotes] = useState([]);

    let apiClients = useInstance(ApiClients);

    const loadNotes = () => apiClients.noteClient.getList(params.objectId, params.noteItemType, params.noteType)
        .then((response) => { setNotes(response)  });

    useEffect(() => {
        setError(null);

        if(params.notes != undefined) {
            setNotes(params.notes);
            setLoading(false);
        } else {
            loadNotes().finally(() => { setLoading(false); });
        }
    }, [params.objectId, params.noteItemType, params.noteType]);

    const addNote = async () => {
        try {
            setAddLoading(true);
            setError(null);
            await apiClients.noteClient.addNote(new NewNoteModel({
                note: note,
                noteItemType: params.noteItemType,
                noteType: params.noteType,
                referenceId: params.objectId
            }));
            await loadNotes();
            setNote('');
        }
        catch (ex) {
            setError("Can't add a note");
        }
        finally {
            setAddLoading(false);
        }
    }

    if(loading) {
        return <div>File is uploading...</div>;
    }

    return <Stack direction={"column"} sx={{width: "100%"}}>
        <div><b>{ FileTypeNames.get(params.noteType) }:</b></div>
        <Divider sx={{ marginTop: "15px", marginBottom: "15px" }}/>
        {Boolean(notes.length) && (
            <React.Fragment>
                <Grid container sx={{ pb: 1 }}>
                    {notes.map((f: INoteListItem) => {
                        return <Grid item xs={12} key={f.id}>
                            <Stack direction={"row"} sx={{ width: "100%", paddingBottom: "10px" }}>
                                <Typography variant="subtitle2" component="div" sx={{ width: "calc(100% - 150px)" }} gutterBottom>
                                    <div>{f.notes}</div>
                                </Typography>
                                <Typography variant="body2" component="div" sx={{ fontWeight: "bold", fontStyle: "italic" }} gutterBottom>
                                    <div>{DateTimeHelper.formatMomentDateTimeUtcToLocal(f.createdAt)}</div>
                                    <div>{f.createdBy}</div>
                                </Typography>
                            </Stack>
                        </Grid>;
                    })}
                </Grid>
            </React.Fragment>
        )}
        {error?.length > 0 ? <Alert severity="error">
            Error — <strong>{error}</strong>
        </Alert> : null}
        <Stack direction={"row"} sx={{width: "100%"}}>
            <TextField id="standard-basic" variant="standard" value={note} label="Note" fullWidth onChange={ (obj: React.ChangeEvent<HTMLInputElement>) => setNote(obj.target.value) } />
            <div>
                <Box sx={{ paddingTop: "10px" }}>
                    <IconButton color="primary" disabled={note?.length == 0 || addLoading} onClick={() => addNote()}>
                        <EditIcon />
                    </IconButton>
                </Box>
            </div>
        </Stack>
    </Stack>
}
