import {observer} from "mobx-react";
import React from "react";
import {Modal} from "@mui/material";
import { OrderDetailIndex } from "app/modules/company/components/orders/details/index";
import {ModalFullContent, ModalFullContentDetails} from "app/modules/common/layout/modal/modal-content";

export interface ModalOrderDetailsParams {
    orderId: number;
    closeOrderModal: () => void;
}

export const ModalOrderDetails = observer((props: ModalOrderDetailsParams) => {
    return <Modal
        open={true}
        onClose={() => { props.closeOrderModal(); }}
    >
        <div>
            <ModalFullContent>
                <ModalFullContentDetails closeModal={props.closeOrderModal} title="Order Details" color={"#f0f0f0"}>
                    <OrderDetailIndex orderId={props.orderId} />
                </ModalFullContentDetails>
            </ModalFullContent>
        </div>
    </Modal>;
})
