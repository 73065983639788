import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {OrganizationDetailsStore} from "app/modules/admin/components/organizations/details/OrganizationDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Box, Grid, Tabs, Tab} from "@mui/material";
import Details from "app/modules/admin/components/organizations/details/details";
import {useParams} from "react-router-dom";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/admin/stores/paths";

@provider(OrganizationDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject organizationDetailsStore: OrganizationDetailsStore;

    render() {
        if(this.organizationDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.organizationDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Organizations.nav}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.organizationDetailsStore.model.name} Size={12}/>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Tabs value={this.organizationDetailsStore.selectedTab} onChange={(even, value) =>this.organizationDetailsStore.selectTab(value)} centered>
                            <Tab value={this.organizationDetailsStore.TabDetails} label={this.organizationDetailsStore.TabDetails} />
                            <Tab value={this.organizationDetailsStore.TabUsers} label={this.organizationDetailsStore.TabUsers} />
                            <Tab value={this.organizationDetailsStore.TabTenants} label={this.organizationDetailsStore.TabTenants} />
                        </Tabs>
                    </Box>
                </Grid>

                {this.organizationDetailsStore.selectedTab == this.organizationDetailsStore.TabDetails && <Details/>}
        </Container>;
    }

    async componentDidMount() {
        const { id } = this.props.urlParams;
        await this.organizationDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
