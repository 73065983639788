import {observer} from "mobx-react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    IconContainerProps,
    LinearProgress,
    Rating, Stack, TextareaAutosize, Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { styled } from '@mui/material/styles';
import React, {useState} from "react";

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import {CarrierRatingNoteType} from "app/modules/company/api/clients.api";

export interface DialogRatingProps {
    closeModal: () => void;
    setRating: (DialogRatingValue) => void;
}

export interface DialogRatingValue {
    pickUpOnTime: boolean;
    deliverOnTime: boolean;
    communicationOnTime: boolean;
    trackingUpdate: boolean;
    failToDeliver: boolean;

    note: string;
    noteType: CarrierRatingNoteType;
}

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons: {
    [index: string]: {
        icon: React.ReactElement;
        label: string;
    };
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="large" />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentSatisfiedIcon color="warning" fontSize="large" />,
        label: 'Neutral',
    },
    3: {
        icon: <SentimentVerySatisfiedIcon color="success" fontSize="large" />,
        label: 'Very Satisfied',
    },
};

function IconNoteContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value == 2 ? 3 : 1].icon}</span>;
}

const RatingNoteControl = (props: { value: CarrierRatingNoteType, onChange: (CarrierRatingNoteType) => void }) =>
    <StyledRating
        name="highlight-selected-only"
        defaultValue={CarrierRatingNoteType.Neutral}
        max={3}
        IconContainerComponent={IconNoteContainer}
        getLabelText={(value: number) => customIcons[value].label}
        highlightSelectedOnly
        value={props.value}
        onChange={(event: React.SyntheticEvent, value: number | null) => props.onChange(value as CarrierRatingNoteType)}
    />;

const RatingControl = (props: { onChange: (bool) => void }) =>
    <StyledRating
        name="highlight-selected-only"
        max={2}
        IconContainerComponent={IconContainer}
        getLabelText={(value: number) => customIcons[value == 2 ? 3 : 1].label}
        highlightSelectedOnly
        onChange={(event: React.SyntheticEvent, value: number | null) => props.onChange(value == 2)}
    />;

const RatingItem = (props: { title: string, onChange: (bool) => void }) => <Stack direction={"column"} spacing={1} alignItems={"center"} width={"100%"} sx={{ paddingBottom: "5px" }}>
    <Typography variant="subtitle2" sx={{width: "100%"}} textAlign={"center"}>{props.title}</Typography><RatingControl onChange={props.onChange} />
</Stack>

export const DialogRating = observer((props: DialogRatingProps) => {
    const [isLoading, setLoading] = useState(true);
    const [note, setNote] = useState('');
    const [noteType, setNoteType] = useState(CarrierRatingNoteType.Neutral);
    const [pickUpOnTime, setPickUpOnTime] = useState(true);
    const [deliverOnTime, setDeliverOnTime] = useState(true);
    const [communicationOnTime, setCommunicationOnTime] = useState(true);
    const [trackingUpdate, setTrackingUpdate] = useState(true);
    const [failToDeliver, setFailToDeliver] = useState(true);

    if(isLoading) {
        setLoading(false);
    }

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
    >
        <DialogTitle id="alert-dialog-title">
            Please Set Rating
        </DialogTitle>
        <DialogContent>
            <DialogContent id="alert-dialog-description" style={{ minWidth: "200px" }}>
                {isLoading ? <LinearProgress/> : <Stack direction={"column"} spacing={1}>
                    <Stack direction={"row"} spacing={1}>
                        <RatingItem title="Communication" onChange={setCommunicationOnTime} />
                        <RatingItem title="Tracking" onChange={setTrackingUpdate} />
                        <RatingItem title="PickUp" onChange={setPickUpOnTime} />
                        <RatingItem title="Delivery" onChange={setDeliverOnTime} />
                        <RatingItem title="Fail To Deliver" onChange={(value) => setFailToDeliver(!value)} />
                    </Stack>
                    <Divider sx={{ marginTop: "15px !important", marginBottom: "15px !important" }}/>
                    <TextareaAutosize
                        minRows={10}
                        placeholder="Comment (Optional)"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        style={{ width: '100%' }}
                    />
                    <Stack alignItems={"center"} width={"100%"}>
                        <RatingNoteControl value={noteType} onChange={setNoteType} />
                    </Stack>
                </Stack> }
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={() => { props.setRating({
                pickUpOnTime: pickUpOnTime || true,
                deliverOnTime: deliverOnTime || true,
                communicationOnTime: communicationOnTime || true,
                trackingUpdate: trackingUpdate || true,
                failToDeliver: failToDeliver || true,
                note: note,
                noteType: noteType || CarrierRatingNoteType.Neutral
            } as DialogRatingValue); }} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
