import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {IUserInfo, TenantClaims, ITenantInfo} from "app/modules/company/api/clients.api";
import {PusherTenantService} from "app/modules/common/pusher/PusherTenantService";

export class MainStore extends BaseLoadingStore{
    @inject apiClients: ApiClients;
    @inject navigationStore: NavigationStore;
    @inject pusherTenantService: PusherTenantService;

    userData: IUserInfo = null;
    tenantData: ITenantInfo = null;

    isAuthenticated: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            userData: observable,
            isAuthenticated: observable,

            init: action
        });
    }

    checkClaim = (claim: TenantClaims) => (this.userData.isAdmin || this.userData.claims.find(x => x == claim) || this.userData.claims.find(x => x == TenantClaims.FullAccess)) != null;

    async init() {
        await this.wrapInitLoadingApiCall(async () => {
            [this.userData, this.tenantData] = await Promise.all([
                await this.apiClients.tenantDataProviderClient.getUserData(),
                await this.apiClients.tenantDataProviderClient.getTenantData()
            ]);

            this.navigationStore.init(this.checkClaim);
            this.pusherTenantService.init(
                this.userData.pusherSettings.appKey,
                this.userData.pusherSettings.cluster,
                this.userData.pusherSettings.pusherNamespace,
                this.userData.pusherSettings.channelAuthorization,
                this.userData.tenantId);

            this.isAuthenticated = true;
        }, null, () => {
            runInAction(() => {
                this.isInitLoading = false;
            });
        });

        /*spy((event) => {
            if (event.type === 'update') {
                console.log(event)
            }
        })*/
    }
}
