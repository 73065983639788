import {observer} from "mobx-react";
import React, { useState} from "react";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {Box, Stack, Tooltip} from "@mui/material";
import {blue, green, red} from "@mui/material/colors";

export interface TrackingDetailsProps {
    vehicleId?: number,
    children: any
}
export const TrackingDetails = observer((props: TrackingDetailsProps) => {
    const [isLoading, setLoading] = useState(false);
    const [isHovering, setHover] = useState(false);
    const [trackingData, setTrackingData] = useState(null);

    if(!props.vehicleId) {
        return props.children;
    }

    const store = useInstance(ApiClients);

    if(!isLoading && !trackingData && isHovering) {
        const fetchData = async () => {
            setLoading(true);
            const data = await store.vehicleClient.getVehicleTrackingDataById(props.vehicleId);
            setTrackingData(data);

            setLoading(false);
        };

        fetchData();
    }

    return <React.Fragment>
        <Tooltip open={isHovering} onClose={() => setHover(false)} onOpen={() => setHover(true)} title={
            <Box sx={{ fontSize: "10pt" }}>
                {
                    !isLoading ? (trackingData ? <Stack direction="column"
                                                      justifyContent="center"
                                                      alignItems="center">
                        { (trackingData?.deviceData || trackingData?.eLDIntegration) && <React.Fragment>
                            {trackingData?.deviceData && !trackingData?.eLDIntegration && <div>Tracking Type: {trackingData?.deviceData && "Mobile"}</div>}
                            {trackingData?.eLDIntegration && <div>Tracking Type: {trackingData?.eLDIntegration && ("ELD Integration (" + trackingData.eLDIntegration + ")")}</div>}
                            {<div>{trackingData.isDeviceTrackingEnabled ? <Box component={"span"} sx={{ color: green["400"] }}>Enabled</Box> : <Box component={"span"} sx={{ color: red["200"] }}>Disabled</Box>}</div>}

                        </React.Fragment> }
                        <div>{trackingData.description}</div>
                        <div>{trackingData.locatedAt?.fromNow()}</div>
                    </Stack> : "No Data") : "Loading..."
                }

            </Box>
        }>
            <Box sx={{ cursor: "pointer", color: blue.A700 }} >{props.children}</Box>
        </Tooltip>
    </React.Fragment>;
})
