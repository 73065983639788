import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    CarrierShortListModel,
    InProgressStopGroup,
    VehicleFleetViewModel
} from "app/modules/company/api/clients.api";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {ModalOrderDetails} from "app/modules/company/components/orders/details/modal.order.details";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {CacheService} from "app/modules/company/services/CacheService";

export class TimelineStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;
    @inject cacheService: CacheService;

    filters = new FormState({
        search: new FieldState(''),
        carrierId: new FieldState(-1)
    })

    items: VehicleFleetViewModel[] = null;
    filteredItems: VehicleFleetViewModel[] = null;
    carrier: CarrierShortListModel[] = [];
    stops: InProgressStopGroup[] = null;
    selectedVehicles: number[] = [];
    mouseFocusedVehicleId?: number = null;
    mouseFocusedOrderId?: number = null;

    constructor() {
        super();

        makeObservable(this, {
            filteredItems: observable,
            selectedVehicles: observable,
            stops: observable,
            mouseFocusedVehicleId: observable,
            mouseFocusedOrderId: observable,

            init: action,
            applyFilters: action,
            setSelected: action,
            setMouseHover: action
        });
    }

    openOrderDetails = (orderId: number) => {
        const key = "order_details_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalOrderDetails orderId={orderId} closeOrderModal={() => this.dialogStore.closeDialog(key)} /></div>
        });
    }

    setMouseHover = (vehicleId: number, orderId: number) => {
        this.mouseFocusedVehicleId = vehicleId;
        this.mouseFocusedOrderId = orderId;
    }

    applyFilters = async () => {
        this.selectedVehicles.length = 0;

        if(this.filters.$.search.value?.length > 0) {
            this.filteredItems = this.items.filter(x => this.filters.$.search.value == x.number.toString());
        } else {
            this.filteredItems = this.items.filter(x => this.filters.$.carrierId.value == -1 || (x.carrierId == (this.filters.$.carrierId.value || null)));
        }
    }

    setSelected = (id: number) => {
        let find = this.selectedVehicles.find(x => x == id);

        if(find) {
            this.selectedVehicles = this.selectedVehicles.filter(x => x != id);
        } else {
            this.selectedVehicles.push(id);
        }
    }

    refreshFleet = async () => {
        const [fleet] = [
            await this.companyApiClients.vehicleClient.getFleetViewList()
        ];

        await this.setItems(fleet);
        await this.applyFilters();
    }

    setItems = async (fleet: VehicleFleetViewModel[]) => {
        this.items = fleet
            .sort((a, b) => a.number - b.number)
            .sort((a, b) => b.orderIds?.length - a.orderIds?.length);

        this.filteredItems = this.items;
    }

    reloadData = async () => {
        await this.wrapLoadingApiCall(async () => {
            const [fleet, stops] = [
                await this.companyApiClients.vehicleClient.getFleetViewList(),
                await this.companyApiClients.orderClient.getInProgressStops()
            ];

            await runInAction(async () => {
                await this.setItems(fleet);
                this.stops = stops;
            })
        });
    }

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
            const [fleet, carriers, stops] = [
                await this.companyApiClients.vehicleClient.getFleetViewList(),
                await this.cacheService.getCarriers(),
                await this.companyApiClients.orderClient.getInProgressStops()
            ];

            await this.setItems(fleet);

            this.stops = stops;
            this.carrier = [new CarrierShortListModel({ id: -1, displayName: "All" }), new CarrierShortListModel({ id: null, displayName: "Own" })].concat(carriers);
        });
    }
}
