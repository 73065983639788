import {action, makeObservable, observable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    OrderModelListView, DataListGetParamsOfOrderListFilters,
    OrderListFilters
} from "app/modules/company/api/clients.api";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import React from "react";
import {ModalOrderDetails} from "app/modules/company/components/orders/details/modal.order.details";

export class OrdersStore extends BaseListStore<OrderModelListView>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState(''),
        from: new FieldState(null),
        to: new FieldState(null),
        fromZip: new FieldState(null),
        fromRadius: new FieldState(null),
        toZip: new FieldState(null),
        toRadius: new FieldState(null),
        driverIds: new FieldState([]),
        vehicleIds: new FieldState([]),
        statuses: new FieldState([]),
    })

    isAdvanceFilterOpen: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            isAdvanceFilterOpen: observable,

            init: action,
            newBlankOrder: action,
            showHideAdvanceStore: action
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            await this.filters.validate();

            if(this.filters.hasError) {
                return;
            }

            let filters = new OrderListFilters({
                search: this.filters.$.search.value,
                from: this.filters.$.from.value,
                to: this.filters.$.to.value,
                fromZip: this.filters.$.fromZip.value,
                fromRadius: this.filters.$.fromRadius.value,
                toZip: this.filters.$.toZip.value,
                toRadius: this.filters.$.toRadius.value,
                driversIds: this.filters.$.driverIds.value,
                vehicleIds: this.filters.$.vehicleIds.value,
                statuses: this.filters.$.statuses.value
            });
            const [result] = await Promise.all([
                this.companyApiClients.orderClient.getList(new DataListGetParamsOfOrderListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    newBlankOrder = async () => {
        await this.wrapLoadingApiCall(async () => {
            const orderId = await this.companyApiClients.orderClient.createNewBlankOrder();
            this.openOrder(orderId);
            await this.loadData()
        })
    }

    openOrder = (orderId: number) => {
        const key = "order_details_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalOrderDetails orderId={orderId} closeOrderModal={() => this.dialogStore.closeDialog(key)} /></div>
        });
    }

    showHideAdvanceStore = () => {
        this.isAdvanceFilterOpen = !this.isAdvanceFilterOpen;
    }

    async init() {
        await this.loadData()

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
