import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    VehicleListModel,
    DataListGetParamsOfVehicleListFilters,
    VehicleListFilters
} from "app/modules/company/api/clients.api";
import {DialogVehicleTracking} from "app/modules/company/components/vehicles/details/dialog.vehicle.tracking";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";

export class VehiclesStore extends BaseListStore<VehicleListModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState(''),
        zip: new FieldState(null),
        radius: new FieldState(null),
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new VehicleListFilters({
                search: this.filters.$.search.value,
                zip: (this.filters.$.zip.value || '')?.toString(),
                radius: this.filters.$.radius.value,
            });
            const [result] = await Promise.all([
                this.companyApiClients.vehicleClient.getList(new DataListGetParamsOfVehicleListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    openVehicleHistory = (vehicleId: number) => {
        const key = "vehicle_" + vehicleId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogVehicleTracking vehicleId={vehicleId} closeModal={() => this.dialogStore.closeDialog(key)}/>
        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
