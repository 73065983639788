import {Box, Grid, Paper} from "@mui/material";
import React from "react";

export const Container = (props) => {
    return <Grid container spacing={props.spacing ?? 0} sx={{ p: 2 }}>{props.children}</Grid>;
}

export const ContainerDetails = (props) => {
    return <Grid item xs={12}>
        <Box sx={{ pl: { lg: 20, md: 10, sx: 4 }, pr: { lg: 20, md: 10, sx: 4 }, pt: 4, pb: 10, boxShadow: "none"}}>
            <Grid container>
                {props.children}
            </Grid>
        </Box>
    </Grid>;
}

export const BoxCenter = (props) => {
    return <Box display="flex"
                justifyContent="center"
                alignItems="center">{props.children}</Box>;
}

export const FullCenteredContainerCenter = (props) => {
    return <Box sx={{height: "100vh", width: "100vw", backgroundColor: "#f0f0f0"}}>
        <BoxCenter>{props.children}</BoxCenter>
    </Box>;
}

export const LeftVehicleFilterPanel = (props) => <Box sx={{position: "absolute", width: 350, height: "120px", borderRadius: "0px", paddingTop: "10px", paddingBottom: "10px", display: props.isHidden && "none"}}>{props.children}</Box>
export const LeftVehiclePanel = (props) => <Paper sx={{position: "absolute", width: 350, height: "calc(100vh - 184px)", top: "184px", borderRadius: "0px", overflowY: "auto", display: props.isHidden && "none"}}>{props.children}</Paper>
export const LeftVehicleContentPanel = (props) => <Box sx={{marginLeft: props.hideLeftPanel ? "0" : "350px", height: "calc(100vh - 64px)", overflow: "auto"}}>{props.children}</Box>
