import {
    OrderNotesList,
    OrderStatus
} from "app/modules/company/api/clients.api";
import {OrdersStore} from "app/modules/company/components/orders/all/OrdersStore";
import React from "react";
import {DialogOrderNotes} from "app/modules/company/components/orders/hold/dialog.notes";

export class OrdersHoldStore extends OrdersStore {
    constructor() {
        super();

        this.filters.$.statuses.value = [OrderStatus.OnHold];
    }

    openDialogOrderNotes = (notes: OrderNotesList[]) => {
        const key = "order_note_" + notes[0].createdAt.valueOf();
        this.dialogStore.addDialog({
            key: key,
            component: <DialogOrderNotes notes={notes} closeModal={() => this.dialogStore.closeDialog(key)}/>
        });
    }
}
