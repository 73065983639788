import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/organization/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfRoleListFilters,
    RoleListFilters,
    RoleListModel
} from "app/modules/organization/api/clients.api";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogRoleNew} from "app/modules/organization/components/roles/dialog.role.new";

export class RolesStore extends BaseListStore<RoleListModel>{
    @inject apiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new RoleListFilters({search: this.filters.$.search.value});
            const [result] = await Promise.all([
                this.apiClients.userClient.getRoles(new DataListGetParamsOfRoleListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    createNewRole = () => {
        const key = "new_role";
        this.dialogStore.addDialog({
            key: key,
            component: <DialogRoleNew closeModal={() => this.dialogStore.closeDialog(key)} onAdded={async () => {
            this.dialogStore.closeDialog(key);
            await this.loadData();
        }} title="Create Role"/>
    });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
