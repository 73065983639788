import {observer} from "mobx-react";
import React, {Component} from "react";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {inject, provider} from "react-ioc";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {MainView} from "app/modules/common/layout/main.view";
import {RouteChangeHandlerComponent} from "app/modules/common/layout/hooks/route-change-handler";
import {Menu} from "app/modules/common/menu/menu";
import {Route, Routes} from "react-router-dom";
import {Paths} from "app/modules/company/stores/paths";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import DriversIndex from 'app/modules/company/components/drivers/index';
import DriversDetails from 'app/modules/company/components/drivers/details/index';
import CarriersIndex from 'app/modules/company/components/carriers/index';
import CarriersDetails from 'app/modules/company/components/carriers/details/index';
import VehiclesIndex from 'app/modules/company/components/vehicles/index';
import VehiclesDetails from 'app/modules/company/components/vehicles/details/index';
import IntegrationsIndex from 'app/modules/company/components/integrations/index';
import IntegrationsDetails from 'app/modules/company/components/integrations/details/index';
import CompanyIntegrationsIndex from 'app/modules/company/components/company-integrations/index';
import CompanyIntegrationsDetails from 'app/modules/company/components/company-integrations/details/index';
import SettingsDetails from 'app/modules/company/components/settings/general';
import EmailSettingsDetails from 'app/modules/company/components/settings/bid-email';
import TrackingDevicesIndex from 'app/modules/company/components/settings/tracking/index';
import MobileLogsIndex from 'app/modules/company/components/settings/mobile-log/index';
import OwnerAppAccountsIndex from 'app/modules/company/components/settings/owner-account/index';
import OwnerAppAccountDetails from 'app/modules/company/components/settings/owner-account/details/index';
import FleetViewIndex from 'app/modules/company/components/fleet-view/index';
import TimelineIndex from 'app/modules/company/components/timeline/index';
import RadarIndex from 'app/modules/company/components/load-boards/radar/index';
import ExternalOrdersIndex from 'app/modules/company/components/load-boards/external-orders/index';
import BidsIndex from 'app/modules/company/components/load-boards/bids/index';
import QuotesIndex from 'app/modules/company/components/quotes/all/index';
import QuotesIndexDetails from 'app/modules/company/components/quotes/details/index';
import { IndexDetails as IndexBidsDetails} from 'app/modules/company/components/load-boards/bids/details/index';
import { IndexNewBidDetails as IndexNewBidDetails} from 'app/modules/company/components/load-boards/bids/details/index';
import OrdersIndex from 'app/modules/company/components/orders/all';
import OrdersProgressIndex from 'app/modules/company/components/orders/progress';
import OrdersReadyIndex from 'app/modules/company/components/orders/ready';
import OrdersHoldIndex from 'app/modules/company/components/orders/hold';
import OrdersTasksIndex from 'app/modules/company/components/orders/tasks';
import { IndexDetails } from 'app/modules/company/components/orders/details/index';
import {DialogContainer} from "app/modules/common/dialog/dialog.container";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import CustomersIndex from 'app/modules/company/components/contact/customers/index';
import ContactsIndex from 'app/modules/company/components/contact/contacts/index';
import ContactIndexDetails from 'app/modules/company/components/contact/contacts/details/index';
import CustomerIndexDetails from 'app/modules/company/components/contact/customers/details/index';
import TelegramChannelLinkIndex from 'app/modules/company/components/settings/telegram/link';
import TelegramChannelIndex from 'app/modules/company/components/settings/telegram/types';
import {PusherTenantService} from "app/modules/common/pusher/PusherTenantService";
import {CacheService} from "app/modules/company/services/CacheService";
import AccountingOpenIndex from 'app/modules/company/components/accounting/orders/open/index';
import AccountingNetTermsIndex from 'app/modules/company/components/accounting/net-term/index';
import AccountingInvoicesIndex from 'app/modules/company/components/accounting/invoices/open/index';
import AccountingInvoicesClosedIndex from 'app/modules/company/components/accounting/invoices/closed/index';
import AccountingPaymentsIndex from 'app/modules/company/components/accounting/payments/index';
import AccountingPaymentsEdit from 'app/modules/company/components/accounting/payments/details/index';
import AccountingDashboardIndex from 'app/modules/company/components/accounting/dashboard/index';
import Events from "app/modules/common/static/EventNames";
import {VehicleAvailabilityModel, VehicleAvailabilityStatus} from "app/modules/company/api/clients.api";
import PubSub from 'pubsub-js';
import {LoadScript} from "@react-google-maps/api";
import moment from "moment";

@provider(MainStore, NavigationStore, ApiClients, DialogStore, PusherTenantService, CacheService)
@observer
export class IndexCompany extends Component<any, any> {
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;
    @inject cacheService: CacheService;
    @inject pusherTenantService: PusherTenantService;

    render() {
        if(this.mainStore.isInitLoading) {
            return <Loading/>;
        }

        if(!this.mainStore.isInitLoading && !this.mainStore.isAuthenticated) {
            return <NavigateHook path={"/login"} />;
        }

        return <React.Fragment>
                <LoadScript googleMapsApiKey="AIzaSyD2jTzFsa8WordLMSc9J_dGuDRPz5f7GjM" />
                <MainView
                    Menu={<Menu menu={this.navigationStore.menuItems} onClick={this.navigationStore.menuItemOnClick} />}
                    Title={<div>{this.mainStore.tenantData.name}</div>}
                    Content={<Routes>
                        <Route path={Paths.Dashboard.router} element={<div/>}/>

                        <Route path={Paths.Drivers.router} element={<DriversIndex/>}/>
                        <Route path={Paths.NewDriver.router} element={<DriversDetails/>}/>
                        <Route path={Paths.EditDriver.router} element={<DriversDetails/>}/>

                        <Route path={Paths.Carriers.router} element={<CarriersIndex/>}/>
                        <Route path={Paths.NewCarrier.router} element={<CarriersDetails/>}/>
                        <Route path={Paths.EditCarrier.router} element={<CarriersDetails/>}/>

                        <Route path={Paths.Vehicles.router} element={<VehiclesIndex/>}/>
                        <Route path={Paths.NewVehicle.router} element={<VehiclesDetails/>}/>
                        <Route path={Paths.EditVehicle.router} element={<VehiclesDetails/>}/>

                        <Route path={Paths.Integrations.router} element={<IntegrationsIndex/>}/>
                        <Route path={Paths.NewIntegration.router} element={<IntegrationsDetails/>}/>
                        <Route path={Paths.EditIntegration.router} element={<IntegrationsDetails/>}/>

                        <Route path={Paths.CompanyIntegrations.router} element={<CompanyIntegrationsIndex/>}/>
                        <Route path={Paths.NewCompanyIntegration.router} element={<CompanyIntegrationsDetails/>}/>
                        <Route path={Paths.EditCompanyIntegration.router} element={<CompanyIntegrationsDetails/>}/>

                        <Route path={Paths.Settings.router} element={<SettingsDetails/>}/>
                        <Route path={Paths.EmailSettings.router} element={<EmailSettingsDetails/>}/>
                        <Route path={Paths.TrackingDevices.router} element={<TrackingDevicesIndex/>}/>

                        <Route path={Paths.OwnerAppAccounts.router} element={<OwnerAppAccountsIndex/>}/>
                        <Route path={Paths.NewOwnerAppAccount.router} element={<OwnerAppAccountDetails/>}/>
                        <Route path={Paths.EditOwnerAppAccount.router} element={<OwnerAppAccountDetails/>}/>

                        <Route path={Paths.FleetView.router} element={<FleetViewIndex/>}/>
                        <Route path={Paths.Timeline.router} element={<TimelineIndex/>}/>
                        <Route path={Paths.Radar.router} element={<RadarIndex/>}/>
                        <Route path={Paths.ExternalOrders.router} element={<ExternalOrdersIndex/>}/>

                        <Route path={Paths.OrderBids.router} element={<BidsIndex/>}/>
                        <Route path={Paths.OrderBidDetails.router} element={<IndexBidsDetails/>}/>
                        <Route path={Paths.OrderNewBidDetails.router} element={<IndexNewBidDetails key={"details"}/>}/>

                        <Route path={Paths.Quotes.router} element={<QuotesIndex/>}/>
                        <Route path={Paths.NewQuote.router} element={<QuotesIndexDetails/>}/>
                        <Route path={Paths.EditQuote.router} element={<QuotesIndexDetails/>}/>

                        <Route path={Paths.Orders.router} element={<OrdersIndex/>}/>
                        <Route path={Paths.OrdersInProgress.router} element={<OrdersProgressIndex/>}/>
                        <Route path={Paths.OrdersReady.router} element={<OrdersReadyIndex/>}/>
                        <Route path={Paths.OrdersHold.router} element={<OrdersHoldIndex/>}/>
                        <Route path={Paths.EditOrder.router} element={<IndexDetails/>}/>
                        <Route path={Paths.OrdersTasks.router} element={<OrdersTasksIndex/>}/>

                        <Route path={Paths.Customers.router} element={<CustomersIndex/>}/>
                        <Route path={Paths.NewCustomer.router} element={<CustomerIndexDetails/>}/>
                        <Route path={Paths.EditCustomer.router} element={<CustomerIndexDetails/>}/>

                        <Route path={Paths.Contacts.router} element={<ContactsIndex/>}/>
                        <Route path={Paths.NewContact.router} element={<ContactIndexDetails/>}/>
                        <Route path={Paths.EditContact.router} element={<ContactIndexDetails/>}/>

                        <Route path={Paths.TelegramChannelLinks.router} element={<TelegramChannelLinkIndex/>}/>
                        <Route path={Paths.TelegramChannels.router} element={<TelegramChannelIndex/>}/>

                        <Route path={Paths.MobileLogs.router} element={<MobileLogsIndex/>}/>

                        <Route path={Paths.AccountingInvoiceDashboard.router} element={<AccountingDashboardIndex/>}/>
                        <Route path={Paths.AccountingOpen.router} element={<AccountingOpenIndex/>}/>
                        <Route path={Paths.AccountingNetTerms.router} element={<AccountingNetTermsIndex/>}/>
                        <Route path={Paths.AccountingInvoices.router} element={<AccountingInvoicesIndex/>}/>
                        <Route path={Paths.AccountingInvoicesClosed.router} element={<AccountingInvoicesClosedIndex/>}/>

                        <Route path={Paths.AccountingPayments.router} element={<AccountingPaymentsIndex/>}/>
                        <Route path={Paths.EditAccountingPayment.router} element={<AccountingPaymentsEdit/>}/>
                        <Route path={Paths.NewAccountingPayment.router} element={<AccountingPaymentsEdit/>}/>
                    </Routes>}/>
                <RouteChangeHandlerComponent handler={this.navigationStore.changeLocation}/>
                <DialogContainer/>
        </React.Fragment>;
    }

    async componentDidMount() {
        await this.mainStore.init();
        await this.cacheService.init();

        this.pusherTenantService.bindToTenantChannel("VehiclesUpdateVehicleAvailabilityByOwner", this.vehiclesUpdateVehicleAvailability);
        this.pusherTenantService.bindToTenantChannel("RadarOrderBet", this.messageHandler);
        this.pusherTenantService.bindToTenantChannel("TaskReminder", this.taskReminderHandler);
        this.pusherTenantService.bindToTenantChannel("ForceReload", this.refreshApp);
    }

    messageHandler(event: any) {
        PubSub.publish(Events.Notification, event.Message);
    }

    vehiclesUpdateVehicleAvailability(data: VehicleAvailabilityModel) {
        let formattedData = VehicleAvailabilityModel.fromJS(data);
        PubSub.publish(Events.Notification, `#${formattedData.number} became ${formattedData.availability.status != VehicleAvailabilityStatus.Available ? "Unavailable" : "Available"}`);
    }

    taskReminderHandler(data: { Id: number, OrderId: number, TaskNote: string, TaskDue: string }) {
        PubSub.publish(Events.OrderTaskNotification, `Task for Order #${data.OrderId} - ${data.TaskNote} (Due: ${moment(data.TaskDue).fromNow()})`);
    }

    refreshApp() {
        location.reload();
    }

    async componentWillUnmount() {
        this.pusherTenantService.unbindToTenantChannel("VehiclesUpdateVehicleAvailabilityByOwner", this.vehiclesUpdateVehicleAvailability);
        this.pusherTenantService.unbindToTenantChannel("RadarOrderBet", this.messageHandler);
        this.pusherTenantService.unbindToTenantChannel("TaskReminder", this.taskReminderHandler);
        this.pusherTenantService.unbindToTenantChannel("ForceReload", this.refreshApp);
    }
}
