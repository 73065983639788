import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {
    DataListGetParamsOfOrderTaskListFilters, OrderTaskFinishRequest,
    OrderTaskListFilters, OrderTaskModel,
    OrderTaskStatus
} from "app/modules/company/api/clients.api";
import {ModalOrderDetails} from "app/modules/company/components/orders/details/modal.order.details";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogOrderTask} from "app/modules/company/components/orders/tasks/dialog.task.edit";

export class TaskStore extends BaseListStore<OrderTaskModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        await this.wrapLoadingApiCall(async () => {
            let filters = new OrderTaskListFilters({search: this.filters.$.search.value, assignedTo: [], orderIds: [], statuses: [OrderTaskStatus.Open, OrderTaskStatus.OpenNotified], types: []});
            const [result] = await Promise.all([
                this.companyApiClients.orderTaskClient.getList(new DataListGetParamsOfOrderTaskListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    finishTask = (taskId: number) => {
        this.dialogStore.addConfirmDialog({
            okColor: "success",
            title: "Complete this task",
            description: "Are you sure, you want to complete this task?",
            cancelText: "Close",
            okText: "Complete",
            okAction: async () => {
                await this.companyApiClients.orderTaskClient.finish(new OrderTaskFinishRequest({ id: taskId }));
                this.loadData();
            }
        });
    }

    openTask = (taskId: number) => {
        const key = "vehicle_" + taskId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogOrderTask taskId={taskId} closeModal={() => {
                    this.dialogStore.closeDialog(key);
                    this.loadData();
                }
            }/>
        });
    }

    openOrder = (orderId: number) => {
        const key = "order_details_" + orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <div><ModalOrderDetails orderId={orderId} closeOrderModal={() => this.dialogStore.closeDialog(key)} /></div>
        });
    }

    async init() {
        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
