import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {
    Button,
    Grid,
    InputBase, Stack
} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {NetTermsStore} from "app/modules/company/components/accounting/net-term/NetTermsStore";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {PaymentNetTermListModel} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentNetTermListModel>) => {
            const store = useInstance(NetTermsStore);
            return <Button variant={"text"} onClick={() => store.openNetTerm(params.row.id)}>{params.row.id}</Button>;
        },
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        flex: 2,
        valueGetter: (params: GridRenderCellParams<PaymentNetTermListModel>) =>
            `${StringHelper.formatNull(params.row.name)}`,
    },
    {
        field: 'netTerm',
        headerName: 'Net Term (Days)',
        maxWidth: 120,
        type: 'number',
        flex: 2,
    },
    {
        field: 'commission',
        headerName: 'Commission (%)',
        maxWidth: 120,
        type: 'number',
        flex: 2,
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentNetTermListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.createdAt)
        ),
    },
];

@provider(NetTermsStore)
@observer
export default class Index extends Component<any, any> {
    @inject netTermsStore: NetTermsStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.netTermsStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.netTermsStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <Button variant={"contained"} onClick={() => this.netTermsStore.openNetTerm()}>New Net Term</Button>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <Stack direction={"row"}>
                        <SearchPaper width={400}>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.netTermsStore.filters.$.search.value}
                                onChange={(e)=>this.netTermsStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.netTermsStore.applyFilters}>Search</Button>
                        </SearchPaper>
                    </Stack>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.netTermsStore.items}
                    columns={columns}
                    pageSize={this.netTermsStore.pageSize}
                    rowsPerPageOptions={this.netTermsStore.pageSizeOptions}
                    rowCount={this.netTermsStore.totalRows}
                    page={this.netTermsStore.skipRows/this.netTermsStore.pageSize}
                    loading={this.netTermsStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.netTermsStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.netTermsStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.netTermsStore.init();
    }
}

