import {IUSDOTInfo} from "app/modules/organization/api/clients.api";
import React from "react";
import {Divider, Stack, TextField, Typography} from "@mui/material";
import {BoxCenter} from "app/modules/common/layout/components/containers";

export const USDOTInfoDetails = (props: { info: IUSDOTInfo }) => {
    return <div>
        <BoxCenter>
            <Typography variant="h6" component="div" textAlign={"center"}>
                {props.info.carrier.legalName} {props.info.carrier.dbaName && <span>({props.info.carrier.dbaName})</span>}
            </Typography>
        </BoxCenter>
        <Divider sx={{ pt: 2, mb: 4 }} light />
        <Stack spacing={4} direction={"column"}>
            <Stack spacing={2} direction={"row"}>
                <TextField
                    sx={{ width: "25%" }}
                    label="Street"
                    value={props.info.carrier.phyStreet}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <TextField
                    sx={{ width: "25%" }}
                    label="City"
                    value={props.info.carrier.phyCity}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <TextField
                    sx={{ width: "25%" }}
                    label="State"
                    value={props.info.carrier.phyState}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <TextField
                    sx={{ width: "25%" }}
                    label="ZIP"
                    value={props.info.carrier.phyZipcode}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <TextField
                    sx={{ width: "25%" }}
                    label="Country"
                    value={props.info.carrier.phyCountry}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
            </Stack>
            <Stack spacing={2} direction={"row"}>
                <TextField
                    sx={{ width: "25%" }}
                    label="Ein"
                    value={props.info.carrier.ein}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <TextField
                    sx={{ width: "25%" }}
                    label="Allowed To Operate"
                    value={props.info.carrier.allowedToOperate}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <TextField
                    sx={{ width: "25%" }}
                    label="Status Code"
                    value={props.info.carrier.statusCode}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <TextField
                    sx={{ width: "25%" }}
                    label="Drivers"
                    value={props.info.carrier.totalDrivers}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <TextField
                    sx={{ width: "25%" }}
                    label="Units"
                    value={props.info.carrier.totalPowerUnits}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
            </Stack>
        </Stack>
    </div>;
}
