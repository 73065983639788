import {Rating} from "@mui/lab";
import {Box, Grid, Popover, Stack, Typography} from "@mui/material";
import React from "react";
import {RatingModel} from "app/modules/company/api/clients.api";

const colorMap = (rating: number) => {
    if(rating > 75) return "#4caf50";
    if(rating > 50) return "#eaba02";
    return "#ff1744";
}

export const CarrierRating = (params: {carrierRating?: RatingModel, carrierCustomPeriodRating?: RatingModel, vehicleRating?: RatingModel, vehicleCustomPeriodRating?: RatingModel}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <React.Fragment>
        <Stack
            direction={"row"}
            spacing={1}
            ria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Rating name="half-rating-read" defaultValue={(params.vehicleCustomPeriodRating?.rating ?? params.carrierCustomPeriodRating?.rating)*10/2} precision={0.1} readOnly sx={{ fontSize: "14px" }} />
            </Stack>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none'
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ p:2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={1}>Rating</Grid>
                        <Grid item xs={1}>Orders</Grid>
                        <Grid item xs={1}>PickUp</Grid>
                        <Grid item xs={1}>Delivery</Grid>
                        <Grid item xs={2}>Communication</Grid>
                        <Grid item xs={1}>Tracking</Grid>
                        <Grid item xs={2}>Failed Deliver</Grid>
                        <Grid item xs={12} sx={{ height: "2px", borderBottom: "1px solid #c4c6c7" }}></Grid>
                        {params.vehicleCustomPeriodRating && <RatingElementFull title={"Vehicle Period"} rating={params.vehicleCustomPeriodRating}/>}
                        {params.vehicleRating && <RatingElementFull title={"Vehicle"} rating={params.vehicleRating}/>}
                        {params.carrierCustomPeriodRating && <RatingElementFull title={"Carrier Period"} rating={params.carrierCustomPeriodRating}/>}
                        {params.carrierRating && <RatingElementFull title={"Carrier"} rating={params.carrierRating}/>}
                    </Grid>
                </Box>
            </Popover>
        </Stack>
    </React.Fragment>;
}

const RatingElement = (params: {rating: number}) => <Typography variant={"body2"} sx={{ color: colorMap(params.rating) }}>{params.rating}%</Typography>

const RatingElementFull = (params: {title: string, rating: RatingModel}) => <React.Fragment>
    <Grid item xs={3}>{params.title}</Grid>
    <Grid item xs={1}><RatingElement rating={params.rating?.rating*100}/></Grid>
    <Grid item xs={1}><Typography variant={"body2"}>{params.rating?.ratingOrders}</Typography></Grid>
    <Grid item xs={1}><RatingElement rating={params.rating?.pickUpOnTime*100}/></Grid>
    <Grid item xs={1}><RatingElement rating={params.rating?.deliverOnTime*100}/></Grid>
    <Grid item xs={2}><RatingElement rating={params.rating?.communicationOnTime*100}/></Grid>
    <Grid item xs={1}><RatingElement rating={params.rating?.trackingUpdate*100}/></Grid>
    <Grid item xs={2}><RatingElement rating={params.rating?.failToDeliver*100}/></Grid>
</React.Fragment>
