import {inject} from "react-ioc";
import {OrganizationDetailsStore} from "app/modules/admin/components/organizations/details/OrganizationDetailsStore";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Stack, TextField} from "@mui/material";
import {ContainerDetails} from "app/modules/common/layout/components/containers";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {LoadingButton} from "@mui/lab";

@observer
export default class Details extends Component<any, any> {
    @inject organizationDetailsStore: OrganizationDetailsStore;
    render() {
        return <ContainerDetails>
            <SectionName title="Basic Info" description="Organization Basic Info" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <div>
                        <TextField
                            fullWidth
                            label="Name"
                            value={this.organizationDetailsStore.model.name}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                        />
                    </div>
                    {this.organizationDetailsStore.owners?.map((x) => {
                        return <Stack spacing={2} direction={"row"} key={x.id}>
                            <TextField
                                sx={{ width: "50%" }}
                                label="Owner Name"
                                value={x.fullName}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                            />
                            <TextField
                                sx={{ width: "50%" }}
                                label="Owner Email"
                                value={x.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                            />
                        </Stack>
                    })}
                    <Stack spacing={2} direction={"row"}>
                        <TextField
                            sx={{ width: "50%" }}
                            label="Active"
                            value={StringHelper.formatYesNo(this.organizationDetailsStore.model.active)}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                        />
                        <TextField
                            sx={{ width: "50%" }}
                            label="CreatedAt"
                            value={DateTimeHelper.formatMomentDateTimeUtcToLocal(this.organizationDetailsStore.model.createdAt)}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="standard"
                        />
                    </Stack>
                </Stack>
            </Section>
            <SectionSeparator/>
            <SectionName title="Actions" description="Actions to Activate/Deactivate/Delete" />
            <Section>
                <Stack spacing={2} direction={"row"}>
                    {!this.organizationDetailsStore.model.active && <LoadingButton loading={this.organizationDetailsStore.isLoading} variant="outlined" color="primary" onClick={this.organizationDetailsStore.activate}>
                        Activate
                    </LoadingButton>}
                    {this.organizationDetailsStore.model.active && <LoadingButton loading={this.organizationDetailsStore.isLoading} variant="outlined" color="error" onClick={this.organizationDetailsStore.deactivate}>
                        Deactivate
                    </LoadingButton>}
                    <LoadingButton loading={this.organizationDetailsStore.isLoading} disabled={this.organizationDetailsStore.model.tenants > 0} variant="contained" color="error" onClick={this.organizationDetailsStore.delete}>
                        Delete
                    </LoadingButton>
                </Stack>
            </Section>
        </ContainerDetails>;
    }
}
