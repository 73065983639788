import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";

import owner from '/assets/owner.png';
import driver from '/assets/driver.png';

const BreakElement = () => <div>
    <br/>
    <br/>
</div>

export default () => {
    return <Grid container sx={{ width: "100%", paddingTop: "20px" }}>
        <Grid item xs={12}>
            <Typography variant="h3" component="div" align={"center"} justifyContent={"center"} gutterBottom>Mobile Apps</Typography>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} sx={{paddingLeft: "10px", paddingRight: "10px", paddingTop: 10}}>
            <Typography variant="h4" component="div" align={"center"} justifyContent={"center"} gutterBottom>iOS</Typography>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} sx={{paddingLeft: "10px", paddingRight: "10px"}}>
            <Box sx={{ width: "100%" }} display="flex" justifyContent="center" alignItems="center">
                <Stack spacing={4} direction={"row"}>
                    <img src={owner} style={{ maxWidth: "100px", width: "100%", borderRadius: "20px", cursor: "pointer" }} onClick={() => window.open("https://apps.apple.com/us/app/onlogixs-owner/id6450876728", "_blank")}/>
                    <img src={driver} style={{ maxWidth: "100px", width: "100%", borderRadius: "20px", cursor: "pointer" }} onClick={() => window.open("https://apps.apple.com/us/app/onlogixs-tracking/id1639478449", "_blank")}/>
                </Stack>
            </Box>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} sx={{paddingLeft: "10px", paddingRight: "10px", paddingTop: 10}}>
            <Typography variant="h4" component="div" align={"center"} justifyContent={"center"} gutterBottom>Android</Typography>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={8} sx={{paddingLeft: "10px", paddingRight: "10px"}}>
            <Box sx={{ width: "100%" }} display="flex" justifyContent="center" alignItems="center">
                <Stack spacing={4} direction={"row"}>
                    <img src={owner} style={{ maxWidth: "100px", width: "100%", borderRadius: "20px", cursor: "pointer" }} onClick={() => window.open("https://github.com/spopov/mobile-apps/releases/download/android_driver_v3/owner.apk", "_blank")}/>
                    <img src={driver} style={{ maxWidth: "100px", width: "100%", borderRadius: "20px", cursor: "pointer" }} onClick={() => window.open("https://github.com/spopov/mobile-apps/releases/download/android_driver_v3/driver.apk", "_blank")}/>
                </Stack>
            </Box>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} textAlign={"center"} sx={{ paddingTop: 4 }}>
            Ⓒ {new Date().getFullYear()} OnLogixs
            <BreakElement/>
        </Grid>
    </Grid>;
}
