import {observer} from "mobx-react";
import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {SectionTextAreaField, SectionTextField} from "app/modules/common/details/text-field";

export interface DialogSetTextProps {
    closeModal: () => void;
    setText: (text: string) => void;
    text: string;
    title: string;
    textarea?: boolean
}

export const DialogSetText = observer((props: DialogSetTextProps) => {
    const [formState, _] = useState(new FormState({
        text: new FieldState<string>(props.text || ''),
    }));

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
            { props.textarea ? <SectionTextAreaField
                label={"Text"}
                field={formState.$.text}
                fullWidth
            /> : <SectionTextField
                label={"Text"}
                field={formState.$.text}
                fullWidth
            />}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeModal}>Close</Button>
            <Button variant="outlined" onClick={() => { props.setText(formState.$.text.value); }} color="primary" autoFocus>
                Save
            </Button>
        </DialogActions>
    </Dialog>;
})
