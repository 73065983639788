import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {Loading} from "app/modules/common/layout/components/loading";
import {Grid, MenuItem, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {SectionSelectChipsField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {OwnerAppAccountDetailsStore} from "app/modules/company/components/settings/owner-account/details/OwnerAppAccountDetailsStore";
import {ActivateDeactivateSection} from "app/modules/common/details/activate-section";
import {TenantOwnerStatus} from "app/modules/company/api/clients.api";

@provider(OwnerAppAccountDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject ownerAppAccountDetailsStore: OwnerAppAccountDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.ownerAppAccountDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.ownerAppAccountDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.OwnerAppAccounts.nav}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.ownerAppAccountDetailsStore.title} Size={8}/>
                <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.ownerAppAccountDetailsStore.isLoading} disabled={this.ownerAppAccountDetailsStore.formState.hasError} variant="contained" onClick={this.ownerAppAccountDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>
                <ContainerDetails>
                    <SectionName title="Info" description="Contact info" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <SectionTextField
                                label={"Name"}
                                field={this.ownerAppAccountDetailsStore.formState.$.name}
                                fullWidth
                            />
                            <SectionTextField
                                label={"Email"}
                                field={this.ownerAppAccountDetailsStore.formState.$.email}
                                fullWidth
                                isReadOnlyMode={!!this.ownerAppAccountDetailsStore.accountId}
                            />
                            <SectionSelectChipsField
                                field={this.ownerAppAccountDetailsStore.formState.$.vehicleIds}
                                width={"100%"}
                                label={"Vehicles"}
                                none={true}
                                items={this.ownerAppAccountDetailsStore.vehicles.map((x) => { return { id: x.id, name: x.displayName }; })}
                            />
                            <SectionSelectField
                                field={this.ownerAppAccountDetailsStore.formState.$.carrierId}
                                label={"Carrier"}
                                none={true}
                                width={"100%"}
                                items={() => this.ownerAppAccountDetailsStore.carriers.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                            />
                        </Stack>
                    </Section>
                    {this.ownerAppAccountDetailsStore.accountId > 0 && <ActivateDeactivateSection isActive={this.ownerAppAccountDetailsStore.status == TenantOwnerStatus.Active} isLoading={this.ownerAppAccountDetailsStore.isLoading} activate={this.ownerAppAccountDetailsStore.activate} deactivate={this.ownerAppAccountDetailsStore.deactivate} />}
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.ownerAppAccountDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
