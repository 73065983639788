import {observer} from "mobx-react";
import React, {Component} from "react";
import {useParams} from "react-router-dom";
import {OrderLinkStore} from "app/modules/external/order-link/OrderLinkStore";
import {inject, provider} from "react-ioc";
import {Loading} from "app/modules/common/layout/components/loading";
import {AppBar, Box, Card, Divider, Grid, Stack, Toolbar, Typography} from "@mui/material";
import {InfoElement} from "app/modules/common/details/info-element";
import {
    Timeline,
    TimelineConnector, TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {OrderStopType} from "app/modules/company/api/clients.api";
import ArchiveIcon from "@mui/icons-material/Archive";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

@provider(OrderLinkStore)
@observer
class Index extends Component<any, any> {
    @inject orderLinkStore: OrderLinkStore;

    render() {
        if(this.orderLinkStore.isInitLoading) {
            return <Loading/>;
        }

        return <Box>
            <AppBar component="nav">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Order #: {this.orderLinkStore.details.orderNo}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container sx={{ pt: 10 }} spacing={2}>
                <Grid item md={12} lg={7}>
                    <Card sx={{p: 2}}>
                        <Stack spacing={2} direction={"column"}>
                            <Stack direction={"column"} spacing={1} sx={{pb: 2}}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <InfoElement name="Vehicle" value={this.orderLinkStore.details.vehicle || "N/A"}/>
                                </Stack>
                                <InfoElement name="Drivers" value={this.orderLinkStore.details.drivers.map(x => `${x.fullName} - ${x.phone} ${x.carrier?.length > 0 ? `(${x.carrier})` : ''}`)?.join(", ") || "N/A"}/>
                            </Stack>
                            <Typography variant="h5" display="block" gutterBottom>
                                Stops
                            </Typography>
                            <Divider/>
                            <Stack direction={"row"} sx={{width: "100%"}}>
                                <Timeline>
                                    {this.orderLinkStore.details.stops?.length > 0 && this.orderLinkStore.details.stops.map((x, index) => {
                                        let hours, minutes;

                                        if(x.arrived) {
                                            [hours, minutes] = DateTimeHelper.formatDiff(x.arrived, x.departed);
                                        }

                                        return <TimelineItem key={x.id} position={"right"}>
                                            <TimelineOppositeContent
                                                align="right"
                                                variant="body2"
                                                sx={{width: "250px", flex: "none", pb: "40px"}}
                                            >
                                                <Box sx={{
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    pt: "20px"
                                                }}>
                                                    <Stack direction={"column"} sx={{width: "100%"}}>
                                                        <Typography variant={"caption"} gutterBottom><b>Scheduled: </b>{DateTimeHelper.formatMomentDate(x.date)} <b>{DateTimeHelper.formatMomentTimeShortUtcToLocal(x.date)}</b></Typography>
                                                        {(index == 0 || this.orderLinkStore.details.stops[index-1].departed) && <Divider sx={{ pt: 1, mb: 1 }}/>}
                                                        {((index == 0 || this.orderLinkStore.details.stops[index-1].departed) || x.arrived) &&
                                                        <div><b>Arrived: </b><Typography variant={"caption"} gutterBottom>
                                                            {x.arrived ? <span>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(x.arrived)}</span> : "Not Arrived Yet" }
                                                        </Typography></div>}
                                                        {x.arrived &&
                                                        <div><b>Departed: </b><Typography variant={"caption"} gutterBottom>
                                                            {x.departed ? <span>{DateTimeHelper.formatMomentDateTimeShortUtcToLocal(x.departed)}</span> : "Not Departed Yet" }
                                                        </Typography></div>}

                                                        {x.arrived && <Divider sx={{ pt: 1, mb: 1 }}/>}
                                                        {x.arrived && <div><b>Time Spent:</b> <Typography variant={"subtitle2"} component="span" gutterBottom color={hours >= 2 && "error"}><b>{hours}</b> hrs and <b>{minutes}</b> mins</Typography></div>}
                                                    </Stack>
                                                </Box>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                                <TimelineDot
                                                    sx={{ cursor: "pointer" }}
                                                    color={ x.departed ? ( x.type == OrderStopType.Shipper ? 'primary' : 'secondary') : 'grey'}
                                                >
                                                    {x.type == OrderStopType.Shipper ? <ArchiveIcon/> : <LocalShippingIcon/>}
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px', px: 2, pb: "40px" }}>
                                                <Box sx={{
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    pt: "20px"
                                                }}>
                                                    {x.name && <Typography variant="subtitle2" component="span">
                                                        {x.name || "N/A"}
                                                    </Typography>}
                                                    {x.address?.length > 0 && <Typography variant="subtitle2">
                                                        {x.address}
                                                    </Typography>}
                                                    {x.address?.length > 0 && <Typography variant={"h6"}>
                                                        {x.city}, {x.state} {x.zipCode}
                                                    </Typography>}

                                                    {(x.pOCName?.length > 0 || x.description?.length > 0) && <Divider sx={{ pt: 1, mb: 1, width: "200px"}}/>}
                                                    {x.pOCName?.length > 0 || x.pOCPhone?.length > 0 && <Typography variant="caption">
                                                        POC: <b>{(x.pOCName || "" + " " + x.pOCPhone || "").trim()}</b>
                                                    </Typography>}
                                                    {x.description?.length > 0 && <Typography variant="caption">
                                                        <div>
                                                            {x.description}
                                                        </div>
                                                    </Typography>}
                                                </Box>
                                            </TimelineContent>
                                        </TimelineItem>;
                                    })}
                                </Timeline>
                            </Stack>
                        </Stack>
                    </Card>
                </Grid>
                <Grid item md={12} lg={5}>
                    {this.orderLinkStore.details.stops?.length > 1 && <iframe width="100%" height="500px" style={{border: 0}} loading="lazy" allowFullScreen
                                                                         src={`https://www.google.com/maps/embed/v1/directions?${this.orderLinkStore.getGoogleMapParams()}`}></iframe>}
                </Grid>
            </Grid>
        </Box>;
    }

    componentDidMount() {
        const { link } = this.props.urlParams;
        this.orderLinkStore.init(link);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
