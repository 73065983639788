import React, {Component} from "react";
import {inject, provider} from "react-ioc";
import {TimelineStore} from "app/modules/company/components/timeline/TimelineStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {observer} from "mobx-react";
import {Avatar, Box, Button, Divider, InputBase, MenuItem, Stack, Typography} from "@mui/material";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {
    BoxCenter,
    LeftVehicleContentPanel,
    LeftVehicleFilterPanel,
    LeftVehiclePanel
} from "app/modules/common/layout/components/containers";
import {SectionSelectField} from "app/modules/common/details/text-field";
import {LocationLink} from "app/modules/common/details/location-link";
import {
    LoadingButton,
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {OrderStatus, OrderStopType} from "app/modules/company/api/clients.api";
import Moment from "moment";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import Colors from "app/modules/common/static/colors";
import {orderStatusColorMap} from "app/modules/company/components/orders/all";

const StatusAvatar = (prop: {number: number, orders: any[]}) =>
    <Avatar sx={{
        bgcolor: prop.orders?.length > 0 ? "#eaba02" : "#606060",
        width: "35px",
        height: "35px",
        fontSize: "12px",
        fontWeight: "bold" }}>{prop.number}</Avatar>

const TimeLineStatusAvatar = (prop: {number: string, status: OrderStatus, selected?:boolean }) =>
    <Avatar sx={{
        bgcolor: orderStatusColorMap.get(prop.status),
        border: prop.selected ? "3px #1976d2 solid" : "3px transparent solid",
        boxShadow: prop.selected ? "inset 0 0 3px #1976d2" : undefined,
        width: "35px",
        height: "35px",
        fontSize: "12px",
        fontWeight: "bold" }}>{prop.number}</Avatar>

@provider(TimelineStore)
@observer
export default class Index extends Component<any, any> {
    @inject timelineStore: TimelineStore;
    interval = null;

    render() {
        if(this.timelineStore.isInitLoading) {
            return <Loading/>;
        }

        let yesterday = Moment().startOf('day').add(-1, 'day');
        let today = Moment().startOf('day');
        let tomorrow = Moment().startOf('day').add(1, 'day');

        let now = Moment().valueOf();

        return (
            <Box>
                <LeftVehicleFilterPanel>
                    <BoxCenter>
                        <SearchPaper>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.timelineStore.filters.$.search.value}
                                onChange={(e)=>this.timelineStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.timelineStore.applyFilters}>Search</Button>
                        </SearchPaper>
                    </BoxCenter>
                    <Box sx={{paddingTop: "12px"}}>
                        <SectionSelectField
                            variant={"filled"}
                            field={this.timelineStore.filters.$.carrierId}
                            width={"100%"}
                            label={"Carrier"}
                            onChange={() => this.timelineStore.applyFilters()}
                            items={() => this.timelineStore.carrier.map((x) => <MenuItem value={x.id} key={x.id}>{x.displayName}</MenuItem>)}
                        />
                    </Box>
                </LeftVehicleFilterPanel>
                <LeftVehiclePanel>
                    {this.timelineStore.filteredItems.map(x => {
                        return <Box sx={{ width: "100%", backgroundColor: this.timelineStore.selectedVehicles.indexOf(x.id) >= 0 ? Colors.LeftMenuSelectedColor : null, cursor: "pointer" }} key={x.id} onClick={() => this.timelineStore.setSelected(x.id)}>
                                <Stack direction={"row"} sx={{ width: "100%", pt: 1, pb: 1 }}>
                                    <Box sx={{ width: "40px", pl: 1, pr: 1 }}>
                                        <StatusAvatar number={x.number} orders={x.orderIds}/>
                                    </Box>
                                    <Box sx={{ width: "310px" }}>
                                        <Stack direction={"column"}>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {x.make} {x.year}
                                            </Typography>
                                            {x.speed > 0 && <Typography variant="caption" display="block" gutterBottom>
                                                <b>Driver name</b>
                                            </Typography>}
                                            {x.carrierName?.length > 0 && <Typography variant="caption" display="block" gutterBottom sx={{ fontSize: "9px" }}>
                                                <i>{x.carrierName}</i>
                                            </Typography>}
                                            <Typography variant="caption" display="block" gutterBottom>
                                                <LocationLink location={x.location} locationDisplay={x.displayLocation}/> {x.locatedAt && "-"} <b>{x.locatedAt?.fromNow()}</b>
                                            </Typography>
                                            {x.orderIds?.length > 0 && <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                                <Typography variant="body2" display="block" component={"div"}><b>Orders:</b></Typography>
                                                {x.orderIds.map(o => <Button key={o.orderId} style={{fontSize: "7pt"}} onClick={(event) => {
                                                    this.timelineStore.openOrderDetails(o.orderId);
                                                    event.stopPropagation();
                                                }}>{o.from} - {o.to} ({o.orderId})</Button>)}
                                            </Stack>}
                                        </Stack>
                                    </Box>
                                </Stack>
                            <Divider/>
                        </Box>
                    })}
                </LeftVehiclePanel>
                <LeftVehicleContentPanel>
                    <Stack direction={"row"} sx={{p: 2}}>
                        <PageTitleChip Title={"Timeline"}/>
                        <LoadingButton
                            variant={"outlined"}
                            sx={{ ml:4, height: "35px", mt: "12px" }}
                            onClick={() => this.timelineStore.reloadData()}
                            loading={this.timelineStore.isLoading}
                        >Refresh</LoadingButton>
                    </Stack>
                    <Stack direction={"row"} sx={{p: 2, width: "100%"}}>
                        <Box sx={{width: "50%", textAlign: "center"}}>
                            <Typography variant={"h4"}>PU</Typography>
                        </Box>
                        <Box sx={{width: "50%", textAlign: "center"}}>
                            <Typography variant={"h4"}>DROP</Typography>
                        </Box>
                    </Stack>
                    {this.timelineStore.isLoading && <Loading/>}
                    {!this.timelineStore.isLoading && this.timelineStore?.stops.map((g, index) => {
                            let groupDate = Moment(Moment.utc(g.date).format("MM/DD/YYYY")).startOf('day');

                            let vehicleIds = this.timelineStore.filteredItems.map(x => x.id);
                            let stops = g.items.filter(i => vehicleIds.indexOf(i.vehicleId) >= 0 || !i.vehicleId);

                            stops = this.timelineStore.selectedVehicles.length > 0 ? stops.filter(i => this.timelineStore.selectedVehicles.indexOf( i.vehicleId) >= 0) : stops;

                            if(stops.length == 0) {
                                return <React.Fragment key={g.date.valueOf()}/>;
                            }

                            return <div key={g.date.valueOf()}>
                                <Typography variant="h5" align={"center"} style={{ fontWeight: "bolder" }}>
                                    {groupDate.valueOf() == yesterday.valueOf() ? "Yesterday" : (groupDate.valueOf() == tomorrow.valueOf() ? "Tomorrow" : (groupDate.valueOf() == today.valueOf() ? "Today" : groupDate.format("dd DD MMM")))}
                                </Typography>
                                <Timeline sx={{ mt: 0, mb: 0 }}>

                                {stops.map((x, stopIndex) => {
                                        let hours, minutes;

                                        if(x.arrived) {
                                            [hours, minutes] = DateTimeHelper.formatDiff(x.arrived, x.departed);
                                        }

                                        let color = x.departed ? '#3ed1043b' : (x.arrived ? "#3ed1043b" : "none");

                                        let timeBox = <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            width: "350px"
                                        }}>
                                            {x.arrived && <Typography variant={"body2"}><b>Arrived:</b> {x.arrived && DateTimeHelper.formatMomentDateTimeShortUtcToLocal(x.arrived) || "N/A"}</Typography>}
                                            {x.departed && <Typography variant={"body2"}><b>Departed:</b> {x.departed && DateTimeHelper.formatMomentDateTimeShortUtcToLocal(x.departed) || "N/A"}</Typography>}
                                            {x.arrived && <Divider sx={{ pt: "2px", mb: "2px" }}/>}
                                            {x.arrived && <Typography variant={"body2"}><b>Time Spent:</b> <Typography variant={"subtitle2"} component="span" gutterBottom color={hours >= 2 && "error"}><b>{hours}</b> hrs and <b>{minutes}</b> mins</Typography></Typography>}
                                        </Box>;

                                        return <TimelineItem
                                            key={x.id} position={x.type != OrderStopType.Shipper ? "right" : "left"}
                                            sx={{
                                                backgroundColor: color,
                                                marginBottom: stops.length-1 != stopIndex ? "3px" : undefined,
                                                border: this.timelineStore.mouseFocusedOrderId == x.orderId ? "2px #1976d266 solid" : "2px #f0f0f0 solid",
                                                boxShadow: this.timelineStore.mouseFocusedOrderId == x.orderId ? "inset 0 0 8px #1976d2" : "inset 0 0 8px #f0f0f0",
                                            }}
                                            onMouseEnter={() => this.timelineStore.setMouseHover(x.vehicleId, x.orderId)}
                                            onMouseLeave={() => this.timelineStore.setMouseHover(null, null)}
                                        >
                                                <TimelineOppositeContent
                                                    align="right"
                                                    variant="body2"
                                                    sx={{pb: "5px"}}
                                                >
                                                    <Box sx={{
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                    }}>
                                                        <Stack direction={"column"} sx={{width: "100%"}}>
                                                            <Typography
                                                                variant={"h4"}>
                                                                {DateTimeHelper.formatMomentTimeShortUtcToLocal(x.date)}
                                                            </Typography>
                                                            {!x.arrived && x.date && x.date.valueOf() < now && <span><Typography variant={"subtitle1"} component="span"><b>Should Arrive:</b></Typography> <Typography variant={"subtitle1"} component="span" color={"error"}><b>{x.date.fromNow()}</b></Typography></span>}
                                                        </Stack>
                                                    </Box>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator
                                                    sx={{
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={(event) => { this.timelineStore.openOrderDetails(x.orderId); }}>
                                                    <TimelineConnector />
                                                    <TimeLineStatusAvatar number={x.vehicleNumber?.toString() || "N/A"} status={x.departed ? OrderStatus.Completed : x.status} selected={this.timelineStore.mouseFocusedVehicleId == x.vehicleId}/>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ py: '12px', px: 2, pb: "5px" }}>
                                                    <Box sx={{
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                    }}>
                                                        <Stack spacing={5} direction={"row"} justifyContent={x.type == OrderStopType.Shipper ? "right" : "left"}>
                                                            {x.type == OrderStopType.Shipper && timeBox}
                                                            <Box sx={{ width: "100%" }}>
                                                                {x.drivers?.length > 0 && <Typography variant="caption" display="block" color={"primary"}><b>{x.drivers.join(", ")}</b></Typography>}
                                                                {x.drivers?.length > 0 && <Divider />}
                                                                <Typography variant={"subtitle1"}>{x.address}</Typography>
                                                                <Typography variant={"h6"}>{x.cityStateZip}</Typography>
                                                            </Box>
                                                            {x.type == OrderStopType.Consignee && timeBox}
                                                        </Stack>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                    })}
                                </Timeline>
                            </div>})}
                </LeftVehicleContentPanel>
            </Box>
        )
    }

    async componentDidMount() {
        await this.timelineStore.init();
    }
}
