import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Box, Button, Grid, InputBase, Stack} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {BidModelListView, OrderModelListView, OrderTaskModel, OrderTaskType} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {TaskStore} from "app/modules/company/components/orders/tasks/TaskStore";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";

const columns = [
    {
        field: 'order',
        headerName: 'OrderId',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => {
            const store = useInstance(TaskStore);
            return <Button variant={"text"} onClick={() => store.openOrder(params.row.orderId)}>{params.row.orderId}</Button>;
        },
    },
    {
        field: 'taskNote',
        headerName: 'Note',
        minWidth: 250,
        flex: 4,
        renderCell: (params: GridRenderCellParams<OrderTaskModel>) => (
            params.row.taskNote
        ),
    },
    {
        field: 'taskDue',
        headerName: 'Due',
        maxWidth: 160,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderTaskModel>) => (
            <Box sx={{ color: colorMap(params.row.taskDue) }}>{DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.taskDue)}</Box>
        )
    },
    {
        field: 'assignedTo',
        headerName: 'Assigned To',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderTaskModel>) => (
            StringHelper.formatNull(params.row.assignedTo)
        )
    },
    {
        field: 'type',
        headerName: 'Type',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderTaskModel>) => (
            params.row.type == OrderTaskType.Accounting ? "Accounting" : "General"
        )
    },
    {
        field: 'assignedAt',
        headerName: 'Assigned By',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderTaskModel>) => {
            return params.row.assignedAt ? <div>
                <div>{DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.assignedAt)}</div>
                <b>{params.row.assignedBy}</b>
            </div> : <div>N/A</div>;
        },
    },
    {
        field: 'createdAt',
        headerName: 'Created By',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderTaskModel>) => (
            <div>
                <div>{DateTimeHelper.formatMomentDateTimeUtcToLocal(params.row.createdAt)}</div>
                <b>{params.row.createdBy}</b>
            </div>
        ),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<BidModelListView>) => {
            const store = useInstance(TaskStore);
            return <Stack direction={"row"} spacing={2}>
                <Button variant={"outlined"} onClick={() => store.finishTask(params.row.id)}>Complete</Button>
                <Button variant={"text"} onClick={() => store.openTask(params.row.id)}>Edit</Button>
            </Stack>
        }
    }
];

const colorMap = (date: Date) => {
    let now = new Date();
    if(date > now) return "#4caf50";
    return "#ff1744";
}

@provider(TaskStore)
@observer
export default class Index extends Component<any, any> {
    @inject taskStore: TaskStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.taskStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.taskStore.totalRows}/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.taskStore.filters.$.search.value}
                            onChange={(e)=>this.taskStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.taskStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.taskStore.items}
                    columns={columns}
                    pageSize={this.taskStore.pageSize}
                    rowsPerPageOptions={this.taskStore.pageSizeOptions}
                    rowCount={this.taskStore.totalRows}
                    page={this.taskStore.skipRows/this.taskStore.pageSize}
                    loading={this.taskStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.taskStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.taskStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.taskStore.init();
    }
}

