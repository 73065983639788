import {Stack, Typography} from "@mui/material";
import React from "react";

export const InfoElement = (props: {name: string, width?: string, value: string, hasError?: boolean,  error?: string, onClick?: (() => void) | boolean}) => <Stack direction={"column"} style={{ width: props.width }}>
    <Typography variant="subtitle2" display="block">
        <Stack direction={"row"} spacing={1} alignContent={"center"}>
            <Typography component={"div"}>{props.name}:</Typography> <b onClick={() => props.onClick != undefined && props.onClick != false && props.onClick != true && props.onClick()} style={props.onClick && LinkPointerStyle || {}}>{props.value}</b>
        </Stack>
    </Typography>
    {props.hasError && <Typography color="error" style={{ fontSize: "0.75rem" }} component={"div"}>{props.error || "Error"}</Typography>}
</Stack>;

export const LinkPointerStyle = { textDecoration: "underline", color: "#1976d2", cursor: "pointer" };
