import {SectionSelectChipsField} from "app/modules/common/details/text-field";
import React, {useEffect, useState} from "react";
import {FieldState} from "formstate";
import {NamedValueLong} from "app/modules/company/api/clients.api";
import {ExternalOrderBidStatusNameMap} from "app/modules/company/components/load-boards/bids";

export const BidStatusFilter = (props: {value: FieldState<any[]>, width?: string }) => {
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setStatuses(Array.from(ExternalOrderBidStatusNameMap.keys()).map(x => new NamedValueLong({ id: x, name: ExternalOrderBidStatusNameMap.get(x) })))
        };

        fetchData();
    },[]);

    return <SectionSelectChipsField
                field={props.value}
                label={"Status"}
                width={props.width}
                none={true}
                items={statuses.map((x) => { return { id: x.id, name: x.name }; })}
            />;
}
