import {SectionSelectChipsField} from "app/modules/common/details/text-field";
import React, {useEffect, useState} from "react";
import {FieldState} from "formstate";
import {useInstance} from "react-ioc";
import {CacheService} from "app/modules/company/services/CacheService";

export const DriverFilter = (props: {value: FieldState<any[]>, width?: string }) => {
    const [drivers, setDriver] = useState([]);
    let cacheService = useInstance(CacheService);

    useEffect(() => {
        const fetchData = async () => {
            setDriver(await cacheService.getDrivers())
        };

        fetchData();
    },[]);

    return <SectionSelectChipsField
                field={props.value}
                label={"Drivers"}
                width={props.width}
                none={true}
                items={drivers.map((x) => { return { id: x.id, name: x.fullName + ( x.carrierId ?  " - " + x.carrier : "") }; })}
            />;
}
