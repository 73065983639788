export const Paths = {
    Tenants: { nav: "/org/tenants", router: "/tenants" },
    NewTenant: { nav: "/org/tenants/new", router: "/tenants/new" },
    EditTenant: { nav: (id, page = '') => `/org/tenants/${id}/${page}`, router: "/tenants/:id/*" },

    Users: { nav: "/org/users", router: "/users" },
    EditUsers: { nav: (id) => `/org/users/${id}`, router: "/users/:id" },
    NewUser: { nav: "/org/users/new", router: "/users/new" },

    Roles: { nav: "/org/roles", router: "/roles" },
    EditRoles: { nav: (id) => `/org/roles/${id}`, router: "/roles/:id" },
    NewRole: { nav: "/org/roles/new", router: "/roles/new" },

    Dashboard: { nav: "/org", router: "/" },
}
