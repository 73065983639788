import {BaseStore} from "app/modules/auth/services/BaseStore";
import {FieldState, FormState} from "formstate";
import {email, required} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/auth/services/AxiosBaseClient";
import {action, makeObservable, observable, runInAction} from "mobx";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export class ResetPasswordStore extends BaseStore {
    @inject apiClients: ApiClients;

    isLoading: boolean = false;
    isSuccess: boolean = false;
    errorMessage: string = null;

    formState = new FormState({
        email: new FieldState('').validators(required, email)
    });

    constructor() {
        super();

        makeObservable(this, {
            isLoading: observable,
            isSuccess: observable,
            errorMessage: observable,

            submit: action
        });
    }

    submit = async () => {
        this.errorMessage = null;

        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        runInAction(() => { this.isLoading = true; })

        this.executeRecaptcha(async (token) => {
            try {
                await this.apiClients.authClient.resetPassword(this.formState.$.email.value, token);

                runInAction(() => { this.isSuccess = true; });
            } catch(ex) {
                if(!ex.isCodedExceptionApi) {
                    runInAction(() => {
                        this.errorMessage = "Unexpected error";
                    });
                }

                ApiErrorHandler(ex);
            }
            finally {
                runInAction(() => { this.isLoading = false; })
            }
        });
    }
}
