import {action, makeObservable, observable, runInAction} from "mobx";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export abstract class BaseLoadingStore {
    isInitLoading: boolean = true;
    isLoading: boolean = false;
    isSavedSuccessfully: boolean = false;

    protected constructor() {
        makeObservable(this, {
            isLoading: observable,
            isInitLoading: observable,
            isSavedSuccessfully: observable,

            wrapLoadingApiCall: action,
            wrapInitLoadingApiCall: action,
            resetSavedSuccessfully: action
        });
    }

    resetSavedSuccessfully = () => this.isSavedSuccessfully = false;

    wrapLoadingApiCall = async (action: () => void) => {
        try{
            this.isLoading = true;

            await action();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            runInAction(() => {
                this.isLoading = false;
            })
        }
    }

    wrapInitLoadingApiCall = async (action: () => void, error?: (ex) => void, finallyAction?: () => void) => {
        try{
            this.isInitLoading = true;

            await action();

            runInAction(() => {
                this.isInitLoading = false;
            });
        }
        catch (ex) {
            error ? error(ex) : ApiErrorHandler(ex);
        }
        finally {
            finallyAction && finallyAction();
        }
    }
}
