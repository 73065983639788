import {observer} from "mobx-react";
import React from "react";
import {Button, Grid, MenuItem, Modal, Stack, Typography} from "@mui/material";
import {inject, provider} from "react-ioc";
import {OrderDetailsStore} from "app/modules/company/components/orders/details/OrderDetailsStore";
import {ModalContent} from "app/modules/common/layout/modal/modal-content";
import {SectionCheckBoxField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {OrderItemStore} from "app/modules/company/components/orders/details/OrderItemStore";
import {OrderItemViewModel} from "app/modules/company/api/clients.api";
import {MainStore} from "app/modules/company/stores/MainStore";
import {LoadingButton} from "@mui/lab";

export interface ModalOrderItemParams {
    orderId: number;
    item: OrderItemViewModel;
    closeOrderItemModal: () => void;
    refreshOrderItems: () => void;
}

@provider(OrderItemStore)
@observer
export class ModalOrderItem extends React.Component<ModalOrderItemParams, any> {
    @inject orderDetailsStore: OrderDetailsStore;
    @inject orderItemStore: OrderItemStore;
    @inject mainStore: MainStore;

    render () {
        return <Modal
            open={this.orderDetailsStore.isEditOrderItemModalOpen}
            onClose={() => { this.props.closeOrderItemModal(); }}
        >
            <div>
                <ModalContent>
                    <Grid item xs={12}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Order Item
                        </Typography>
                        <Grid item xs={12} sx={{ pt: 4, pb: 4 }}>
                            <Stack spacing={2} direction={"column"}>
                                <SectionTextField
                                    label={"Description"}
                                    field={this.orderItemStore.formState.$.description}
                                    fullWidth
                                />
                                <SectionSelectField
                                    field={this.orderItemStore.formState.$.cargoType}
                                    label={"Cargo Type"}
                                    width={"100%"}
                                    items={() => this.mainStore.tenantData.cargoTypes.map((x) => <MenuItem value={parseInt(x.id)} key={x.id}>{x.name}</MenuItem>)}
                                />
                                <Stack spacing={2} direction={"row"}>
                                    <SectionTextField
                                        label={"Packing"}
                                        field={this.orderItemStore.formState.$.packing}
                                        width={"50%"}
                                    />
                                    <SectionCheckBoxField
                                        field={this.orderItemStore.formState.$.hazardous}
                                        label="Hazardous"
                                    />
                                </Stack>
                                <Stack spacing={2} direction={"row"}>
                                    <SectionTextField
                                        label={"Piece Units"}
                                        field={this.orderItemStore.formState.$.pieceUnits}
                                        width={"50%"}
                                        type={"number"}
                                    />
                                    <SectionTextField
                                        label={"Weight"}
                                        field={this.orderItemStore.formState.$.weight}
                                        width={"50%"}
                                        type={"number"}
                                    />
                                </Stack>

                                <Stack spacing={2} direction={"row"}>
                                    <SectionTextField
                                        label={"Length"}
                                        field={this.orderItemStore.formState.$.length}
                                        width={"33%"}
                                        type={"number"}
                                    />
                                    <SectionTextField
                                        label={"Width"}
                                        field={this.orderItemStore.formState.$.width}
                                        width={"33%"}
                                        type={"number"}
                                    />
                                    <SectionTextField
                                        label={"Height"}
                                        field={this.orderItemStore.formState.$.height}
                                        width={"33%"}
                                        type={"number"}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="text" onClick={this.props.closeOrderItemModal}>Close</Button>
                        <LoadingButton color="primary" loading={this.orderDetailsStore.isLoading} variant="contained" onClick={this.orderItemStore.save}>Save</LoadingButton>
                    </Grid>
                </ModalContent>
            </div>
        </Modal>;
    }

    componentDidMount() {
        this.orderItemStore.init(this.props);
    }
}
