import React, {Component} from "react";
import {MainView} from "app/modules/common/layout/main.view";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "./stores/NavigationStore"
import {Menu} from "app/modules/common/menu/menu";
import {Route, Routes} from "react-router-dom";
import {Paths} from "app/modules/admin/stores/paths";
import TenantsIndex from "app/modules/admin/components/tenants/index";
import TenantNew from "app/modules/admin/components/tenants/new/tenant-new";
import TenantDetailsIndex from "app/modules/admin/components/tenants/details/index";
import UsersIndex from "app/modules/admin/components/users/index";
import OrganizationsIndex from "app/modules/admin/components/organizations/index";
import OrganizationNew from "app/modules/admin/components/organizations/new/organization-new";
import OrganizationDetailsIndex from "app/modules/admin/components/organizations/details/index";
import { RouteChangeHandlerComponent } from "../common/layout/hooks/route-change-handler";
import {MainStore} from "app/modules/admin/stores/MainStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {AdminApiClients} from "app/modules/admin/services/AxiosBaseClient";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";

@provider(NavigationStore, MainStore, AdminApiClients)
@observer
export class IndexAdmin extends Component<any, any> {
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.mainStore.isInitLoading) {
            return <Loading/>;
        }

        if(!this.mainStore.isLoading && !this.mainStore.isAuthenticated) {
            return <NavigateHook path={"/admin/accessDenied"} />;
        }

        return <React.Fragment>
            <MainView
                Menu={<Menu menu={this.navigationStore.menuItems} onClick={this.navigationStore.menuItemOnClick} />}
                Title={<div>{this.navigationStore.title}</div>}
                Content={<Routes>
                    <Route path="/firstLogin" element={<div>FirstLogin</div>} />
                    <Route path={Paths.Tenants.router} element={<TenantsIndex/>}/>
                    <Route path={Paths.NewTenant.router} element={<TenantNew/>}/>
                    <Route path={Paths.EditTenant.router} element={<TenantDetailsIndex/>}/>
                    <Route path={Paths.Users.router} element={<UsersIndex/>} />
                    <Route path={Paths.Organizations.router} element={<OrganizationsIndex/>} />
                    <Route path={Paths.NewOrganization.router} element={<OrganizationNew/>}/>
                    <Route path={Paths.EditOrganization.router} element={<OrganizationDetailsIndex/>}/>
                </Routes>}/>
                <RouteChangeHandlerComponent handler={this.navigationStore.changeLocation}/>
        </React.Fragment>;
    }

    async componentDidMount() {
        await this.mainStore.init();
    }
}


