import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {email, required} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {
    CarrierShortListModel,
    DriverEditModel,
    TenantClaims,
    VehicleShortListModel
} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";

export class DriverDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject cacheService: CacheService;

    title: string = 'New Driver';
    driverId: number;
    isActive: boolean = true;
    formState = new FormState({
        fullName: new FieldState('').validators(required),
        phone: new FieldState('').validators(required),
        email: new FieldState('').validators(required, email),
        carrierId: new FieldState(null),
        vehicleId: new FieldState(null),
        driverLicenseExpiration: new FieldState(null),
        medicalExpiration: new FieldState(null),
        driverLicenseState: new FieldState('').validators(required),
        driverLicense: new FieldState(null).validators(required),
        note: new FieldState(''),
    });

    isReadOnlyMode: boolean = false;
    vehicles: VehicleShortListModel[] = [];
    carrier: CarrierShortListModel[] = [];

    constructor() {
        super();

        this.formState.$.driverLicenseState.value = this.mainStore.tenantData.states[0].code;
        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.DriverEdit);

        makeObservable(this, {
            title: observable,
            isActive: observable,

            init: action,
            activate: action,
            deactivate: action,
            save: action
        });
    }

    activate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.driverClient.activate(this.driverId);
            runInAction(() => {
                this.isActive = true;
            });
        });
    }

    deactivate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.driverClient.deactivate(this.driverId);
            runInAction(() => {
                this.isActive = false;
            });
        });
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.driverClient.upsert(new DriverEditModel({
                id: this.driverId,
                fullName: this.formState.$.fullName.value,
                phone: this.formState.$.phone.value?.toString(),
                email: this.formState.$.email.value,
                carrierId: this.formState.$.carrierId.value || null,
                vehicleId: this.formState.$.vehicleId.value || null,
                driverLicenseExpiration: this.formState.$.driverLicenseExpiration.value,
                medicalExpiration: this.formState.$.medicalExpiration.value,
                driverLicenseState: this.formState.$.driverLicenseState.value || null,
                driverLicense: this.formState.$.driverLicense.value,
                note: this.formState.$.note.value
            }));

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }

    init = async (id: number) => {
        this.driverId = id;

        await this.wrapInitLoadingApiCall(async () => {
            const [carriers, vehicles] = [
                await this.cacheService.getCarriers(),
                await this.cacheService.getVehicles()
            ];

            this.carrier = carriers;
            this.vehicles = vehicles;

            if(this.driverId > 0) {
                const [driver] = [
                    await this.companyApiClients.driverClient.getById(this.driverId),
                ];

                this.title = driver.fullName;
                this.isActive = driver.active;

                this.formState.$.fullName.value = driver.fullName;
                this.formState.$.phone.value = driver.phone;
                this.formState.$.email.value = driver.email;
                this.formState.$.carrierId.value = driver.carrierId;
                this.formState.$.vehicleId.value = driver.vehicleId;
                this.formState.$.driverLicenseExpiration.value = driver.driverLicenseExpiration;
                this.formState.$.medicalExpiration.value = driver.medicalExpiration;
                this.formState.$.driverLicenseState.value = driver.driverLicenseState;
                this.formState.$.driverLicense.value = driver.driverLicense;
                this.formState.$.note.value = driver.note;
            }
        });
    }
}
