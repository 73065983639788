import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {TenantNewStore} from "app/modules/admin/components/tenants/new/TenantNewStore";
import {Divider, Grid, Stack, TextField, Typography} from "@mui/material";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {LoadingButton} from "@mui/lab";

@provider(TenantNewStore)
@observer
export default class Index extends Component<any, any> {
    @inject tenantNewStore: TenantNewStore;

    render() {
        return <Container>
            <PageTitleDetails Title={"New Tenant"} Size={8}/>
            <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                <LoadingButton loading={false} variant="contained">
                    Save
                </LoadingButton>
            </Grid>
            <ContainerDetails>
                <Grid item xs={4} textAlign={"right"}>
                    <Typography variant="h5" component="div">
                        Basic Info
                    </Typography>
                    <Divider light />
                </Grid>
                <Grid item xs={8} sx={{ pl: 4 }}>
                    <Stack spacing={2} direction={"column"}>
                        <div>
                            <TextField
                                fullWidth
                                value={this.tenantNewStore.formState.$.companyName.value || ''}
                                error={this.tenantNewStore.formState.$.companyName.hasError}
                                helperText={this.tenantNewStore.formState.$.companyName.error}
                                onChange={(e)=>this.tenantNewStore.formState.$.companyName.onChange(e.target.value)}
                                label="Company Name"
                                variant="standard"
                            />
                        </div>
                        <div>
                            <Stack spacing={2} direction={"row"}>
                                <TextField
                                    sx={{ width: "50%" }}
                                    value={this.tenantNewStore.formState.$.ownerName.value || ''}
                                    error={this.tenantNewStore.formState.$.ownerName.hasError}
                                    helperText={this.tenantNewStore.formState.$.ownerName.error}
                                    onChange={(e)=>this.tenantNewStore.formState.$.ownerName.onChange(e.target.value)}
                                    label="Owner Name"
                                    variant="standard"
                                />
                                <TextField
                                    sx={{ width: "50%" }}
                                    value={this.tenantNewStore.formState.$.ownerEmail.value || ''}
                                    error={this.tenantNewStore.formState.$.ownerEmail.hasError}
                                    helperText={this.tenantNewStore.formState.$.ownerEmail.error}
                                    onChange={(e)=>this.tenantNewStore.formState.$.ownerEmail.onChange(e.target.value)}
                                    label="Owner Email"
                                    variant="standard"
                                />
                            </Stack>
                        </div>
                    </Stack>
                </Grid>
            </ContainerDetails>
        </Container>;
    }
}
