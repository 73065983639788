import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {int, requiredNumber} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import queryString from "query-string";
import React from "react";
import {
    RatingEditModel, CarrierRatingNoteEditModel, DateRequest,
    FileListItem,
    NoteListItem,
    OrderItemViewModel,
    OrderStatus,
    OrderStopViewModel,
    OrderViewModel
} from "app/modules/company/api/clients.api";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {DialogSetDate} from "app/modules/common/dialog/dialog.date";
import {DialogSetText} from "app/modules/common/dialog/dialog.text";
import {DialogVehicle} from "app/modules/company/components/orders/details/dialog.vehicle";
import {DialogDrivers} from "app/modules/company/components/orders/details/dialog.drivers";
import {DialogSelectCustomer} from "app/modules/company/components/orders/details/dialog.customer";
import {DialogSelectCustomerContact} from "app/modules/company/components/orders/details/dialog.customer.contacts";
import {DialogSetNumber} from "app/modules/common/dialog/dialog.number";
import {DialogRating, DialogRatingValue} from "app/modules/company/components/orders/details/dialog.rating";
import {DialogVehicleTracking} from "app/modules/company/components/vehicles/details/dialog.vehicle.tracking";
import {DialogOrderTask} from "app/modules/company/components/orders/tasks/dialog.task.edit";

export class OrderDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;
    @inject dialogStore: DialogStore;

    title: string = 'New Order';
    orderId: number = null;

    details: OrderViewModel = null;
    stops: OrderStopViewModel[] = [];
    items: OrderItemViewModel[] = []

    initFileRateConfirmations: FileListItem[] = null;
    initFileBillsOfLanding: FileListItem[] = null;
    initFileProofsOfDelivery: FileListItem[] = null;
    initFileOrderCargo: FileListItem[] = null;

    initNotes: NoteListItem[] = null;
    initNoteAccounting: NoteListItem[] = null;

    isEditOrderItemModalOpen: boolean = false;
    selectedEditOrderItem: OrderItemViewModel = null;

    isEditStopItemModalOpen: boolean = false;
    selectedEditStopItem: OrderStopViewModel = null;

    formState = new FormState({
        vehicleId: new FieldState(null).validators(requiredNumber),
        customMessage: new FieldState(''),
        rate: new FieldState(null).validators(requiredNumber, int),
        ratePerMile: new FieldState(null).validators(requiredNumber, int),
        milesOut: new FieldState(null).validators(requiredNumber, int),
        driverTime: new FieldState(0)
    });

    constructor() {
        super();

        makeObservable(this, {
            title: observable,
            details: observable,
            stops: observable,
            items: observable,

            isEditOrderItemModalOpen: observable,
            selectedEditOrderItem: observable,
            isEditStopItemModalOpen: observable,
            selectedEditStopItem: observable,

            init: action,
            closeEditOrderItemModal: action,
            openEditOrderItemModal: action,
            openDeleteOrderItemDialog: action,
            closeEditStopItemModal: action,
            openEditStopItemModal: action,
            refreshOrderItems: action,
            refreshOrderStops: action,
            changeNotes: action
        });
    }

    closeEditOrderItemModal = () => this.isEditOrderItemModalOpen = false;
    openEditOrderItemModal = (item: OrderItemViewModel) => {
        this.selectedEditOrderItem = item;
        this.isEditOrderItemModalOpen = true;
    }

    openDeleteOrderItemDialog = (item: OrderItemViewModel) => {
        this.dialogStore.addConfirmDialog({
           okColor: "error",
           title: "Delete this order item",
           description: "Are you sure, you want to delete this order item?",
           cancelText: "Close",
           okText: "Delete",
           okAction: async () => {
               await this.companyApiClients.orderClient.deleteOrderItem(this.orderId, item.id);
               await this.refreshOrderItems();
           }
        });
    }

    sendDriverInfo = async () => {
        this.dialogStore.addConfirmDialog({
            okColor: "primary",
            title: "Send Driver Info",
            description: "Are you sure, you want to send the order info to driver(s)",
            cancelText: "Close",
            okText: "Send",
            okAction: async () => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.sendOrderNotification(this.orderId);
                });
            }
        });
    }

    refreshOrderItems = async () => {
        await this.wrapLoadingApiCall(async () => {
            const result = await this.companyApiClients.orderClient.getOrderItems(this.orderId);

            runInAction(() => {
                this.items = result;
            });
        });
    }

    closeEditStopItemModal = () => this.isEditStopItemModalOpen = false;
    openEditStopItemModal = (item: OrderStopViewModel) => {
        this.selectedEditStopItem = item;
        this.isEditStopItemModalOpen = true;
    }

    refreshOrderStops = async () => {
        await this.wrapLoadingApiCall(async () => {
            const result = await this.companyApiClients.orderClient.getOrderStops(this.orderId);

            runInAction(() => {
                this.stops = result;
            });
        });
    }

    deleteOrderStop = async () => {
        this.dialogStore.addConfirmDialog({
            okColor: "error",
            title: "Delete this order stop",
            description: "Are you sure, you want to delete this order stop?",
            cancelText: "Close",
            okText: "Delete",
            okAction: async () => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.deleteOrderStop(this.orderId, this.selectedEditStopItem.id);
                    this.closeEditStopItemModal();
                    await this.refreshOrderStops();
                });
            }
        });
    }

    setArrivalTime = (item: OrderStopViewModel) => {
        const key = "stop_arrived_" + item.id;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSetDate date={item.arrived} closeModal={() => this.dialogStore.closeDialog(key)} setDate={async (date) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setArrivalDate(this.orderId, item.id, new DateRequest({ date: date }));
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderStops();
                });
            }}/>
        });
    }

    setDepartTime = (item: OrderStopViewModel) => {
        const key = "stop_departed_" + item.id;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSetDate date={item.departed} closeModal={() => this.dialogStore.closeDialog(key)} setDate={async (date) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setDepartDate(this.orderId, item.id, new DateRequest({ date: date }));
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderStops();
                });
            }}/>
        });
    }

    changeNotes = () => {
        const key = "notes_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSetText text={this.details.driverSharedNotes} textarea closeModal={() => this.dialogStore.closeDialog(key)} setText={async (value) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setSharedNotes(this.orderId, value);
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderDetails();
                });
            }} title="Update Driver's Shared Notes"/>
        });
    }

    changeOrderNumber = () => {
        const key = "number_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSetText text={this.details.orderNo} closeModal={() => this.dialogStore.closeDialog(key)} setText={async (value) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setOrderNumber(this.orderId, value);
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderDetails();
                });
            }} title="Update Order Number"/>
        });
    }

    changeRate= () => {
        const key = "number_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSetNumber number={this.details.rate} closeModal={() => this.dialogStore.closeDialog(key)} setNumber={async (value) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setRate(this.orderId, value);
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderDetails();
                });
            }} title="Update Rate"/>
        });
    }

    changeCarrierRate = () => {
        const key = "number_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSetNumber number={this.details.carrierRate} closeModal={() => this.dialogStore.closeDialog(key)} setNumber={async (value) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setCarrierRate(this.orderId, value);
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderDetails();
                });
            }} title="Update Carrier Rate"/>
        });
    }

    markAsDraft = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.orderClient.markAsDraft(this.orderId);
            await this.refreshOrderDetails();
        });
    }

    markAsReady = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.orderClient.markAsReady(this.orderId);
            await this.refreshOrderDetails();
        });
    }

    markAsInProgress = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.orderClient.markAsInProgress(this.orderId);
            await this.refreshOrderDetails();
        });
    }

    markAsOnHold = async () => {
        this.dialogStore.addConfirmDialog({
            okColor: "warning",
            title: "put this order on hold",
            description: "Are you sure, you want to put this order on hold?",
            cancelText: "Close",
            okText: "On Hold",
            okAction: async () => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.markAsOnHold(this.orderId);
                    await this.refreshOrderDetails();
                });
            }
        });
    }

    markAsCanceled = async () => {
        this.dialogStore.addConfirmDialog({
            okColor: "error",
            title: "Cancel this order",
            description: "Are you sure, you want to cancel this order?",
            cancelText: "Close",
            okText: "Cancel Order",
            okAction: async () => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.markAsCanceled(this.orderId);
                    await this.refreshOrderDetails();
                });
            }
        });
    }

    markAsCompleted = async () => {
        let action = async () => {
            await this.wrapLoadingApiCall(async () => {
                await this.companyApiClients.orderClient.markAsCompleted(this.orderId);
            });
        };

        if(!this.details.hasCarrierReview && this.details.carrierId > 0) {
            await this.setRating(action);
            return;
        }

        this.dialogStore.addConfirmDialog({
            okColor: "success",
            title: "Complete this order",
            description: "Are you sure, you want to complete this order?",
            cancelText: "Close",
            okText: "Complete Order",
            okAction: async () => { await action(); await this.refreshOrderDetails();}
        });
    }

    setRating = async (action) => {
        const key = "vehicle_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogRating closeModal={() => this.dialogStore.closeDialog(key)} setRating={async (value: DialogRatingValue) => {
                await this.companyApiClients.carrierClient.addOrderRating(new RatingEditModel({
                    carrierId: this.details.carrierId,
                    orderId: this.details.id,
                    communicationOnTime: value.communicationOnTime,
                    deliverOnTime: value.deliverOnTime,
                    failToDeliver: value.failToDeliver,
                    pickUpOnTime: value.pickUpOnTime,
                    trackingUpdate: value.trackingUpdate
                }));

                if(value.note?.length > 0) {
                    await this.wrapLoadingApiCall(async () => {
                        await this.companyApiClients.carrierClient.addOrderRatingNote(new CarrierRatingNoteEditModel({
                            carrierId: this.details.carrierId,
                            orderId: this.details.id,
                            note: value.note,
                            type: value.noteType
                        }));
                    });
                }

                try {
                    await action();
                }
                finally {
                    this.dialogStore.closeDialog(key)
                    await this.refreshOrderDetails();
                }
            }}/>
        });
    }

    markAsClosed = async () => {
        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.orderClient.markAsClosed(this.orderId);
            await this.refreshOrderDetails();
        });
    }

    openVehicleHistory = () => {
        const key = "vehicle_" + this.details.vehicleId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogVehicleTracking vehicleId={this.details.vehicleId} closeModal={() => this.dialogStore.closeDialog(key)}/>
        });
    }

    changeVehicle = () => {
        const key = "vehicle_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogVehicle vehicleId={this.details.vehicleId} setNone={this.details.status == OrderStatus.Draft} closeModal={() => this.dialogStore.closeDialog(key)} setVehicle={async (value) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setVehicle(this.orderId, value || null);
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderDetails();
                });
            }} title="Update Vehicle"/>
        });
    }

    openTask = () => {
        const key = "order_task_" + new Date().valueOf();
        this.dialogStore.addDialog({
            key: key,
            component: <DialogOrderTask orderId={this.details.id} closeModal={() => this.dialogStore.closeDialog(key)}/>
        });
    }

    changeCustomer = () => {
        const key = "vehicle_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSelectCustomer closeModal={() => this.dialogStore.closeDialog(key)} setCustomer={async (customerId) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setCustomer(this.orderId, customerId, null);
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderDetails();
                });
            }} title="Update Customer"/>
        });
    }

    changeContact = () => {
        const key = "vehicle_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogSelectCustomerContact closeModal={() => this.dialogStore.closeDialog(key)} customerId={this.details.customerId} setContact={async (contactId) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setCustomer(this.orderId, this.details.customerId, contactId);
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderDetails();
                });
            }} title="Update Contact"/>
        });
    }

    changeDrivers = () => {
        const key = "vehicle_" + this.orderId;
        this.dialogStore.addDialog({
            key: key,
            component: <DialogDrivers driverIds={this.details.driverIds} closeModal={() => this.dialogStore.closeDialog(key)} setVehicle={async (value) => {
                await this.wrapLoadingApiCall(async () => {
                    await this.companyApiClients.orderClient.setDrivers(this.orderId, value);
                    this.dialogStore.closeDialog(key);
                    await this.refreshOrderDetails();
                });
            }} title="Update Drivers"/>
        });
    }

    openOrGenerateExternalLink = async () => {
        await this.wrapLoadingApiCall(async () => {
            let link = await this.companyApiClients.orderClient.generateCustomerExternalLink(this.details.id)

            runInAction(() => {
                this.details.externalLink = link;
            })
        });

        window.open("/external/order-link/" + this.details.externalLink, "_blank");
    }

    getGoogleMapParams = () => {
        let origin;
        let waypoints = [];
        let destination = this.stops[this.stops.length - 1].fullAddress


        if(this.details.status != OrderStatus.InProgress || !this.details.vehicleLocation) {
            origin = this.stops[0].fullAddress;

            for(let i = 1; i < this.stops.length - 1; i++) {
                let stop = this.stops[i];
                waypoints.push(stop.fullAddress);
            }
        } else {
            origin = this.details.vehicleLocation;

            for(let i = 0; i < this.stops.length - 1; i++) {
                let stop = this.stops[i];
                if(!stop.departed) {
                    waypoints.push(stop.fullAddress);
                }
            }
        }

        return queryString.stringify({
            key: 'AIzaSyD2jTzFsa8WordLMSc9J_dGuDRPz5f7GjM',
            origin: origin,
            waypoints: waypoints,
            destination: destination,
            avoid: 'tolls',
            mode: 'driving'
        });
    }

    getFrom = () => this.stops?.length > 0 && this.stops[0].address?.length > 0 && `${this.stops[0].city}, ${this.stops[0].state} ${this.stops[0].zipCode}`;
    getTo = () => this.stops?.length > 0 && this.stops[this.stops.length - 1].address?.length > 0 && `${this.stops[this.stops.length - 1].city}, ${this.stops[this.stops.length - 1].state} ${this.stops[this.stops.length - 1].zipCode}`;

    refreshOrderDetails = async () => {
        await this.wrapLoadingApiCall(async () => {
            const result = await this.companyApiClients.orderClient.getById(this.orderId);

            runInAction(() => {
                this.details = result;
            });
        });
    }

    init = async (orderId: number) => {
        this.orderId = orderId;

        this.title = `Order #${this.orderId}`;

        await this.wrapInitLoadingApiCall(async () => {
            const fullOrderInfo = await this.companyApiClients.orderClient.getFullOrderInfo(this.orderId);

            this.details = fullOrderInfo.orderDetails;
            this.stops = fullOrderInfo.stops;
            this.items = fullOrderInfo.items;

            this.initFileRateConfirmations = fullOrderInfo.fileRateConfirmations;
            this.initFileBillsOfLanding = fullOrderInfo.fileBillsOfLanding;
            this.initFileProofsOfDelivery = fullOrderInfo.fileProofsOfDelivery;
            this.initFileOrderCargo = fullOrderInfo.orderCargo;

            this.initNotes = fullOrderInfo.notes;
            this.initNoteAccounting = fullOrderInfo.noteAccounting;
        });
    }
}
