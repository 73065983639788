import React from "react";
import {BoxCenter, FullCenteredContainerCenter} from "app/modules/common/layout/components/containers";
import {Box, Button, Typography} from "@mui/material";

export const AdminAccessDenied = (props) => {
    return <FullCenteredContainerCenter>
        <Box sx={{ paddingTop: "20vh" }}>
            <Typography variant="h2" gutterBottom component="div" sx={{ fontWeight: "bold" }} textAlign={"center"}>
               Access Denied
            </Typography>
            <Typography variant="h6" gutterBottom component="div">
                You don't have an access, or the current session is expired. Please click this button to login again:
            </Typography>
            <BoxCenter><Button sx={{ fontSize: "24px" }} variant="text" onClick={() => window.location.href = "/AdminAuth/AdminLogin"}>Login</Button></BoxCenter>
        </Box>
    </FullCenteredContainerCenter>;
}
