import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import {FieldState, FormState} from "formstate";
import {int, required, requiredNumber} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {CarrierEditModel, TenantClaims} from "app/modules/company/api/clients.api";
import moment from "moment";

export class CarrierDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;

    title: string = 'New Carrier';
    carrierId: number;
    isActive: boolean = true;
    formState = new FormState({
        displayName: new FieldState('').validators(required),
        name: new FieldState('').validators(required),
        dbaName: new FieldState(''),
        uSDOT: new FieldState(null).validators(requiredNumber, int),
        eIN: new FieldState(null).validators(requiredNumber, int),
        insuranceExpirationDate: new FieldState<moment.Moment>(null).validators(required),

        address: new FieldState("").validators(required),
        address2: new FieldState(""),
        city: new FieldState("").validators(required),
        state: new FieldState("").validators(required),
        zip: new FieldState(null).validators(required),
        phone: new FieldState("").validators(required),
    });

    isReadOnlyMode: boolean = false;

    constructor() {
        super();

        this.isReadOnlyMode = !this.mainStore.checkClaim(TenantClaims.CarrierEdit);

        makeObservable(this, {
            title: observable,
            isActive: observable,

            init: action,
            activate: action,
            deactivate: action,
            save: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.carrierClient.upsert(new CarrierEditModel({
                id: this.carrierId,
                displayName: this.formState.$.displayName.value,
                name: this.formState.$.name.value,
                dbaName: this.formState.$.dbaName.value,
                uSDOT: this.formState.$.uSDOT.value,
                eIN: this.formState.$.eIN.value,
                insuranceExpirationDate: this.formState.$.insuranceExpirationDate.value,

                address: this.formState.$.address.value,
                address2: this.formState.$.address2.value,
                city: this.formState.$.city.value,
                state: this.formState.$.state.value,
                zIP: this.formState.$.zip.value,

                phone: this.formState.$.phone.value,
            }));

            runInAction(() => {
                this.isSavedSuccessfully = true;
            })
        });
    }

    activate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.carrierClient.activate(this.carrierId);
            runInAction(() => {
                this.isActive = true;
            });
        });
    }

    deactivate = async () => {
        this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.carrierClient.deactivate(this.carrierId);
            runInAction(() => {
                this.isActive = false;
            });
        });
    }

    init = async (id: number) => {
        this.carrierId = id;

        await this.wrapInitLoadingApiCall(async () => {
            if(this.carrierId > 0) {
                const result = await this.companyApiClients.carrierClient.getById(this.carrierId);

                this.title = result.displayName;
                this.isActive = result.active;

                this.formState.$.displayName.value = result.displayName;
                this.formState.$.name.value = result.name;
                this.formState.$.dbaName.value = result.dbaName;
                this.formState.$.uSDOT.value = result.uSDOT;
                this.formState.$.eIN.value = result.eIN;

                this.formState.$.address.value = result.address;
                this.formState.$.address2.value = result.address2;
                this.formState.$.city.value = result.city;
                this.formState.$.state.value = result.state;
                this.formState.$.zip.value = result.zIP;
                this.formState.$.phone.value = result.phone;

                this.formState.$.insuranceExpirationDate.value = result.insuranceExpirationDate;
            }
        });
    }
}
