import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    MenuItem,
    Stack,
    TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {FieldState, FormState} from "formstate";
import {required} from "app/modules/common/form/validators";
import {NamedValue, OrderTaskModel, OrderTaskType} from "app/modules/company/api/clients.api";
import Moment from "moment";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {
    SectionSelectField,
    SectionTextField
} from "app/modules/common/details/text-field";
import {CacheService} from "app/modules/company/services/CacheService";
import {DateTimePicker} from "@mui/x-date-pickers-pro";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export class DialogOrderTaskProps {
    closeModal: () => void;
    taskId?: number;
    orderId?: number;
}

const types = [{id: OrderTaskType.General, name: "General"}, {id: OrderTaskType.Accounting, name: "Accounting"}];

export const DialogOrderTask = observer((props: DialogOrderTaskProps) => {
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState<NamedValue[]>();
    const [formState, _] = useState(new FormState({
        type: new FieldState<OrderTaskType>(OrderTaskType.General).validators(required),
        assignedTo: new FieldState<string>(null),
        taskNote: new FieldState("").validators(required),
        taskDue: new FieldState<Moment.Moment>(null).validators(required)
    }));

    const store = useInstance(ApiClients);
    const cacheService = useInstance(CacheService);

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);

            await store.orderTaskClient.upsert(new OrderTaskModel({
                id: props.taskId,
                orderId: props.orderId,
                type: formState.$.type.value,
                assignedTo: formState.$.assignedTo.value,
                taskNote: formState.$.taskNote.value,
                taskDue: formState.$.taskDue.value
            }));

            props.closeModal();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            setUsers(await cacheService.getUsers());

            if(props.taskId != null) {
                let task = await store.orderTaskClient.getById(props.taskId);

                formState.$.type.value = task.type;
                formState.$.assignedTo.value = task.assignedTo;
                formState.$.taskNote.value = task.taskNote;
                formState.$.taskDue.value = task.taskDue;
            }

            setLoading(false);
        };

        fetchData();
    }, []);

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xl"}
    >
        <DialogTitle id="alert-dialog-title">
            Task Details
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                {!isLoading ? <Stack spacing={2} direction={"column"}>

                    <SectionTextField
                        label={"Note"}
                        field={formState.$.taskNote}
                        fullWidth
                    />
                    <Stack spacing={2} direction={"row"}>
                        <DateTimePicker
                            label="Task Due"
                            renderInput={(params) => <TextField variant="standard" {...params} />}
                            value={formState.$.taskDue.value}
                            onChange={formState.$.taskDue.onChange}
                            minutesStep={1}
                        />
                        <SectionSelectField
                            field={formState.$.type}
                            width={"50%"}
                            label={"State"}
                            items={() => types.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                        />
                    </Stack>
                    <SectionSelectField
                        field={formState.$.assignedTo}
                        width={"100%"}
                        label={"Assigned To"}
                        none={true}
                        items={() => users.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />
                </Stack> : <LinearProgress/> }
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton color="primary" loading={isLoading} variant="contained" onClick={save}>Save</LoadingButton>
        </DialogActions>
    </Dialog>;
});
