import {observer} from "mobx-react";
import React, {Component} from "react";
import {inject, provider} from "react-ioc";
import {UserDetailsStore} from "app/modules/organization/components/users/details/UserDetailsStore";
import {useParams} from "react-router-dom";
import {Loading} from "app/modules/common/layout/components/loading";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {SectionTextField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {ActivateDeactivateSection} from "app/modules/common/details/activate-section";
import {MainStore} from "app/modules/organization/stores/MainStore";

@provider(UserDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject userDetailsStore: UserDetailsStore;
    @inject mainStore: MainStore;

    isReadOnlyMode = true;

    render() {
        if(this.userDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        return <Container>
            <PageTitleDetails Title={this.userDetailsStore.title} Size={!this.userDetailsStore.isReadOnlyMode ? 8 : 12}/>
            <ContainerDetails>
                <SectionName title="Info" description="User Info" />
                <Section>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Full Name"}
                            field={this.userDetailsStore.formFullNameState.$.fullName}
                            isReadOnlyMode={this.userDetailsStore.isReadOnlyMode}
                            fullWidth/>
                        {!this.userDetailsStore.isReadOnlyMode && <LoadingButton loading={this.userDetailsStore.isLoading} disabled={this.userDetailsStore.formFullNameState.hasError} variant="outlined" onClick={this.userDetailsStore.saveFullName}>
                            Update
                        </LoadingButton>}
                    </Stack>
                    <br/>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Email"}
                            field={this.userDetailsStore.formEmailState.$.email}
                            isReadOnlyMode={this.userDetailsStore.isReadOnlyMode}
                            fullWidth/>
                        {!this.userDetailsStore.isReadOnlyMode && <LoadingButton loading={this.userDetailsStore.isLoading} disabled={this.userDetailsStore.formEmailState.hasError} variant="outlined" onClick={this.userDetailsStore.saveEmail}>
                            Update
                        </LoadingButton>}
                    </Stack>
                    <br/>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Phone"}
                            field={this.userDetailsStore.formPhoneState.$.phone}
                            isReadOnlyMode={this.userDetailsStore.isReadOnlyMode}
                            fullWidth/>
                        {!this.userDetailsStore.isReadOnlyMode && <LoadingButton loading={this.userDetailsStore.isLoading} disabled={this.userDetailsStore.formPhoneState.hasError} variant="outlined" onClick={this.userDetailsStore.savePhone}>
                            Update
                        </LoadingButton>}
                    </Stack>
                </Section>
                <SectionSeparator/>
                {!this.userDetailsStore.isReadOnlyMode && !!this.userDetailsStore.userId && <ActivateDeactivateSection isActive={this.userDetailsStore.isActive} isLoading={this.userDetailsStore.isLoading} activate={this.userDetailsStore.activate} deactivate={this.userDetailsStore.deactivate} />}
                <SectionSeparator/>
                <SectionName title="Roles" description="User Roles" />
                <Section>
                    <Stack spacing={1} direction={"column"}>
                        {
                            this.userDetailsStore.tenants.map(x => {
                                return <Stack key={x.id} spacing={2} direction={"column"}>
                                    <Typography variant="h6" component="div">
                                        {x.name}
                                    </Typography>
                                    <Stack direction={"column"}>
                                        {this.userDetailsStore.roles.map(r => {
                                            return <div key={r.id}>
                                                <FormControlLabel key={r.id} control={<Checkbox onChange={(e) => !this.userDetailsStore.isReadOnlyMode && this.userDetailsStore.changeTenantRoleState(x.id, r.id)} checked={this.userDetailsStore.tenantRoles.get(x.id).get(r.id)} />} label={r.name} />
                                            </div>
                                        })}
                                    </Stack>
                                </Stack>
                            })
                        }
                    </Stack>
                    <br/>
                    <br/>
                    {!this.userDetailsStore.isReadOnlyMode && <LoadingButton loading={this.userDetailsStore.isLoading} variant="outlined" onClick={this.userDetailsStore.saveTenantRoles}>
                        Update
                    </LoadingButton>}
                </Section>

            </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.userDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
