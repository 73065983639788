import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/organization/services/AxiosBaseClient";
import {required} from "app/modules/common/form/validators";
import {SectionTextField} from "app/modules/common/details/text-field";
import {OrganizationTenantRoleNewModel} from "app/modules/organization/api/clients.api";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogCustomerNewProps {
    closeModal: () => void;
    onAdded: () => void;
    title: string;
}

export const DialogRoleNew = observer((props: DialogCustomerNewProps) => {
    const [formState, _] = useState(new FormState({
        name: new FieldState("").validators(required)
    }));

    const [isLoading, setLoading] = useState(false);

    const store = useInstance(ApiClients);

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);
            await store.userClient.createOrganizationRole(new OrganizationTenantRoleNewModel({
                roleName: formState.$.name.value
            }));
            props.onAdded();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                <Stack spacing={2} direction={"column"}>
                    <SectionTextField
                        label={"Name"}
                        field={formState.$.name}
                        fullWidth
                    />
                </Stack>
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Save
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
