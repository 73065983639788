import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {
    Button,
    Grid,
    InputBase, Stack,
    Typography
} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {OrdersStore} from "app/modules/company/components/accounting/orders/open/OrdersStore";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {OrderModelListView, PaymentListModel} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => {
            const store = useInstance(OrdersStore);
            return <Button variant={"text"} onClick={() => store.openOrder(params.row.id)}>{params.row.id}</Button>;
        },
    },
    {
        field: 'dropdate',
        headerName: 'Drop',
        width: 90,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <div>
                <div>{DateTimeHelper.formatMomentDateShort(params.row.drop)}</div>
            </div>
        ),
    },
    {
        field: 'customer',
        headerName: 'Customer',
        minWidth: 300,
        flex: 2,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <div>
                <div>{params.row.customer || 'N/A'}</div>
                {params.row.contact?.length > 0 && <Typography variant="caption" display="block">
                    {params.row.contact}
                </Typography>}
            </div>
        ),
    },
    {
        field: 'po',
        headerName: 'PO',
        width: 100,
        valueGetter: (params: GridRenderCellParams<OrderModelListView>) =>
            `${StringHelper.formatNull(params.row.orderNumber)}`,
    },
    {
        field: 'rate',
        headerName: 'Rate',
        maxWidth: 110,
        type: 'number',
        flex: 1,
        valueGetter: (params: GridRenderCellParams<PaymentListModel>) =>
            `${NumberHelper.formatUSDollar(params.row.rate)}`,
    },
    {
        field: 'miles',
        headerName: 'Miles',
        maxWidth: 90,
        type: 'number',
        flex: 1
    },
    {
        field: 'route',
        headerName: 'Route',
        minWidth: 200,
        flex: 2,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) =>
            <div>
                <div>{params.row.from || 'N/A'}</div>
                <div>{params.row.to || 'N/A'}</div>
            </div>
    },
    {
        field: 'drivers',
        headerName: 'Drivers',
        minWidth: 150,
        flex: 2,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <div>
                {params.row.drivers?.join(", ") || 'N/A'}
            </div>
        ),
    },
    {
        field: 'vehicle',
        headerName: 'Vehicle',
        minWidth: 250,
        flex: 2,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => (
            <div>
                <div>
                    <b>{params.row.vehicle || "N/A"}</b>
                </div>
                {params.row.carrier?.length > 0 && <Typography variant="caption" display="block">
                    {params.row.carrier}
                </Typography>}
            </div>
        ),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<OrderModelListView>) => {
            const store = useInstance(OrdersStore);
            return <Button variant="outlined" size="small" onClick={() => store.createdInvoice(params.row.id, params.row.customerId)}>Create Invoice</Button>;
        },
    },
];

@provider(OrdersStore)
@observer
export default class Index extends Component<any, any> {
    @inject ordersStore: OrdersStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.ordersStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.ordersStore.totalRows}/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <Stack direction={"row"}>
                        <SearchPaper width={400}>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.ordersStore.filters.$.search.value}
                                onChange={(e)=>this.ordersStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.ordersStore.applyFilters}>Search</Button>
                        </SearchPaper>
                    </Stack>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.ordersStore.items}
                    columns={columns}
                    pageSize={this.ordersStore.pageSize}
                    rowsPerPageOptions={this.ordersStore.pageSizeOptions}
                    rowCount={this.ordersStore.totalRows}
                    page={this.ordersStore.skipRows/this.ordersStore.pageSize}
                    loading={this.ordersStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.ordersStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.ordersStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.ordersStore.init();
    }
}

