import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack, TextField
} from "@mui/material";
import {FieldState, FormState} from "formstate";
import {LoadingButton} from "@mui/lab";
import {useInstance} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {long, required} from "app/modules/common/form/validators";
import {SectionDatePickerField, SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {MainStore} from "app/modules/company/stores/MainStore";
import {CustomerEditModel, NamedValueLong, NewInvoiceBasedOnOrderEditModel} from "app/modules/company/api/clients.api";
import {CacheService} from "app/modules/company/services/CacheService";
import {DialogSelectCustomer} from "app/modules/company/components/orders/details/dialog.customer";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {Loading} from "app/modules/common/layout/components/loading";
import moment from "moment/moment";
import {ApiErrorHandler} from "app/modules/common/static/ErrrorHandler";

export interface DialogInvoiceNewProps {
    closeModal: () => void;
    invoiceCreated: () => void;
    orderId: number;
    customerId: number;
}

export const DialogInvoiceNew = observer((props: DialogInvoiceNewProps) => {
    const [formState, _] = useState(new FormState({
        address: new FieldState("").validators(required),
        address2: new FieldState(""),
        city: new FieldState("").validators(required),
        state: new FieldState("").validators(required),
        zip: new FieldState(null).validators(required),
        invoiceNumber: new FieldState(null).validators(required, long),
        payerId: new FieldState(null).validators(required),
        paymentNetTermId: new FieldState(null).validators(required),
        reference: new FieldState(""),
        invoiceDate: new FieldState(moment()),
    }));

    const [isLoading, setLoading] = useState(true);
    const [customerDetails, setCustomerDetails] = useState<CustomerEditModel>(null);
    const [paymentNetTerms, setPaymentNetTerms] = useState<NamedValueLong[]>(null);
    const [payerName, setPayerName] = useState("");

    const store = useInstance(ApiClients);
    const mainStore = useInstance(MainStore);
    const cacheService = useInstance(CacheService);
    const dialogStore = useInstance(DialogStore);

    useEffect(() => {
        const fetchData = async () => {
            const [details, resultPayments, invoiceNumber] = [
                await store.contactClient.getCustomerDetails(props.customerId),
                await cacheService.getPaymentNetTerms(),
                await store.invoiceClient.getLatestInvoiceNumber(),
            ];

            setCustomerDetails(details);
            setPaymentNetTerms(resultPayments);

            if(details.defaultPayerId > 0) {
                const payerDetails = await store.contactClient.getCustomerDetails(details.defaultPayerId);

                formState.$.payerId.value = details.defaultPayerId;
                setPayerName(details.defaultPayer);

                formState.$.address.value = payerDetails.remitAddress;
                formState.$.address2.value = payerDetails.remitAddress2;
                formState.$.city.value = payerDetails.remitCity;
                formState.$.state.value = payerDetails.remitState;
                formState.$.zip.value = payerDetails.remitZIP;
                formState.$.paymentNetTermId.value = payerDetails.defaultPaymentNetTermId;
            } else {
                formState.$.payerId.value = details.id;
                setPayerName(details.companyName);

                formState.$.address.value = details.remitAddress;
                formState.$.address2.value = details.remitAddress2;
                formState.$.city.value = details.remitCity;
                formState.$.state.value = details.remitState;
                formState.$.zip.value = details.remitZIP;
                formState.$.paymentNetTermId.value = details.defaultPaymentNetTermId;
            }

            formState.$.invoiceNumber.value = invoiceNumber + 1;

            setLoading(false);
        };

        fetchData();
    }, [])

    const changeCustomer = () => {
        const key = "new_customer_invoice_" + props.orderId;
        dialogStore.addDialog({
            key: key,
            component: <DialogSelectCustomer closeModal={() => dialogStore.closeDialog(key)} setCustomer={async (customerId, name) => {
                const selectedDetails = await store.contactClient.getCustomerDetails(customerId);
                formState.$.payerId.onChange(customerId);
                setPayerName(name);

                formState.$.address.onChange(selectedDetails.remitAddress);
                formState.$.address2.onChange(selectedDetails.remitAddress2);
                formState.$.city.onChange(selectedDetails.remitCity);
                formState.$.state.onChange(selectedDetails.remitState);
                formState.$.zip.onChange(selectedDetails.remitZIP);
                formState.$.paymentNetTermId.onChange(selectedDetails.defaultPaymentNetTermId);

                dialogStore.closeDialog(key);
            }} title="Update Payer"/>
        });
    }

    const save = async () => {
        if(isLoading) {
            return;
        }

        await formState.validate();

        if(formState.hasError) {
            return;
        }

        try {
            setLoading(true);
            await store.invoiceClient.createInvoiceBasedOnOrder(new NewInvoiceBasedOnOrderEditModel({
                address: formState.$.address.value,
                address2: formState.$.address2.value,
                city: formState.$.city.value,
                state: formState.$.state.value,
                zIP: formState.$.zip.value,
                country: "",
                number: formState.$.invoiceNumber.value,
                orderId: props.orderId,
                payerId: formState.$.payerId.value,
                paymentNetTermId: formState.$.paymentNetTermId.value,
                reference: formState.$.reference.value,
                invoiceDate: formState.$.invoiceDate.value
            }));

            props.invoiceCreated();
        }
        catch (ex) { ApiErrorHandler(ex); }
        finally {
            setLoading(false);
        }
    };

    return <Dialog
        open={true}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Create Invoice
        </DialogTitle>
        <DialogContent
            sx={{ backgroundColor: "#f0f0f0" }}
        >
            <DialogContent id="alert-dialog-description" style={{ minWidth: "400px" }}>
                {isLoading ? <Loading /> : <Stack spacing={2} direction={"column"}>
                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="Customer"
                        defaultValue={customerDetails.companyName}
                    />
                    <SectionTextField
                        label={"Remit Address"}
                        field={formState.$.address}
                        fullWidth
                    />
                    <SectionTextField
                        label={"Remit Address 2"}
                        field={formState.$.address2}
                        fullWidth
                    />
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Remit City"}
                            field={formState.$.city}
                            width={"33%"}
                        />
                        <SectionSelectField
                            label={"Remit State"}
                            field={formState.$.state}
                            width={"33%"}
                            items={() => mainStore.tenantData.states.map((x) => <MenuItem value={x.code} key={x.code}>{x.name}</MenuItem>)}
                        />
                        <SectionTextField
                            label={"Remit ZIP"}
                            field={formState.$.zip}
                            width={"33%"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Reference"}
                            field={formState.$.reference}
                            width={"50%"}
                        />
                        <SectionTextField
                            label={"Invoice Number"}
                            field={formState.$.invoiceNumber}
                            width={"50%"}
                            type={"number"}
                        />
                    </Stack>
                    <SectionSelectField
                        field={formState.$.paymentNetTermId}
                        width={"100%"}
                        label={"Net Term"}
                        none
                        items={() => paymentNetTerms.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />
                    <SectionDatePickerField
                        field={formState.$.invoiceDate}
                        label="Invoice Date"
                        width={"100%"}
                        minDate={new Date()}
                    />
                    <Box sx={{ cursor: "pointer !important", width: "100%" }}>
                        <TextField
                            id="outlined-disabled"
                            label="Payer"
                            fullWidth
                            onClick={() => changeCustomer()}
                            value={payerName || "Set Default Payer"}
                        />
                    </Box>
                </Stack>}
            </DialogContent>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={props.closeModal}>Close</LoadingButton>
            <LoadingButton loading={isLoading} variant="outlined" onClick={save} color="primary" autoFocus>
                Create
            </LoadingButton>
        </DialogActions>
    </Dialog>;
})
