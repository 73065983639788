import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {ContactDetailsStore} from "app/modules/company/components/contact/contacts/details/ContactDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {Chip, Grid, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {Section, SectionName} from "app/modules/common/layout/components/details";
import {LoadingButton} from "@mui/lab";
import {MainStore} from "app/modules/company/stores/MainStore";
import {NavigateHook} from "app/modules/common/layout/hooks/navigate-hook";
import {Paths} from "app/modules/company/stores/paths";
import {SectionTextField} from "app/modules/common/details/text-field";

@provider(ContactDetailsStore)
@observer
class Index extends Component<any, any> {
    @inject contactDetailsStore: ContactDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.contactDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        if(this.contactDetailsStore.isSavedSuccessfully) {
            return <NavigateHook path={Paths.Contacts.nav}/>;
        }

        return <Container>
                <PageTitleDetails Title={this.contactDetailsStore.title} Size={!this.contactDetailsStore.isReadOnlyMode ? 8 : 12}/>
                {!this.contactDetailsStore.isReadOnlyMode && <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
                    <LoadingButton loading={this.contactDetailsStore.isLoading} disabled={this.contactDetailsStore.formState.hasError} variant="contained" onClick={this.contactDetailsStore.save}>
                        Save
                    </LoadingButton>
                </Grid>}
                <ContainerDetails>
                    <SectionName title="Info" description="Contact info" />
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <SectionTextField
                                label={"Full Name"}
                                isReadOnlyMode={this.contactDetailsStore.isReadOnlyMode}
                                field={this.contactDetailsStore.formState.$.fullName}
                                fullWidth
                            />
                            <SectionTextField
                                label={"Title"}
                                isReadOnlyMode={this.contactDetailsStore.isReadOnlyMode}
                                field={this.contactDetailsStore.formState.$.title}
                                fullWidth
                            />
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Phone"}
                                    isReadOnlyMode={this.contactDetailsStore.isReadOnlyMode}
                                    field={this.contactDetailsStore.formState.$.phone}
                                    width={"70%"}
                                />
                                <SectionTextField
                                    label={"Extension"}
                                    isReadOnlyMode={this.contactDetailsStore.isReadOnlyMode}
                                    field={this.contactDetailsStore.formState.$.extension}
                                    width={"30%"}
                                />
                            </Stack>
                            <SectionTextField
                                label={"Email"}
                                isReadOnlyMode={this.contactDetailsStore.isReadOnlyMode}
                                field={this.contactDetailsStore.formState.$.email}
                                fullWidth
                            />
                            <Stack direction="row" spacing={1}>
                                {this.contactDetailsStore.customers?.map(x => {
                                    return <Chip
                                        key={x.id}
                                        label={x.name}
                                        onDelete={!this.contactDetailsStore.isReadOnlyMode && (() => this.contactDetailsStore.removeCustomer(x.id))}
                                    />;
                                })}
                                {!this.contactDetailsStore.isReadOnlyMode && <Chip label="Link Customer" color="primary" variant="filled" onClick={this.contactDetailsStore.addCustomer} />}
                            </Stack>
                        </Stack>
                    </Section>
                </ContainerDetails>
        </Container>;
    }

    componentDidMount() {
        const { id } = this.props.urlParams;
        this.contactDetailsStore.init(id);
    }
}

export default (props) => {
    const urlParams = useParams();
    return <Index {...props} urlParams={urlParams} />;
}
