import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {Button, Grid, InputBase} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {
    TrackingDeviceModel
} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {MobileLogStore} from "app/modules/company/components/settings/mobile-log/TrackingDeviceStore";

const columns = [
    {
        field: 'sid',
        headerName: 'Sid',
        minWidth: 150,
        flex: 4
    },
    {
        field: 'number',
        headerName: 'Number',
        minWidth: 200,
        flex: 1
    },
    {
        field: 'message',
        headerName: 'Message',
        minWidth: 400,
        flex: 2
    },
    {
        field: 'errorMessage',
        headerName: 'ErrorMessage',
        minWidth: 250,
        flex: 1
    },
    {
        field: 'created',
        headerName: 'Created',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TrackingDeviceModel>) => (
            <div>
                <div>{params.row.created?.fromNow() || "N/A"}</div>
            </div>
        ),
    }
];

@provider(MobileLogStore)
@observer
export default class Index extends Component<any, any> {
    @inject mobileLogStore: MobileLogStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.mobileLogStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={12}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.mobileLogStore.totalRows}/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.mobileLogStore.filters.$.search.value}
                            onChange={(e)=>this.mobileLogStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.mobileLogStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    getRowId={(row) => row.sid}
                    disableVirtualization
                    rows={this.mobileLogStore.items}
                    columns={columns}
                    pageSize={this.mobileLogStore.pageSize}
                    rowsPerPageOptions={this.mobileLogStore.pageSizeOptions}
                    rowCount={this.mobileLogStore.totalRows}
                    page={this.mobileLogStore.skipRows/this.mobileLogStore.pageSize}
                    loading={this.mobileLogStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.mobileLogStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.mobileLogStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.mobileLogStore.init();
    }
}

