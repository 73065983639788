import React, {Component} from "react";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {
    Button,
    Grid,
    InputBase, Stack
} from "@mui/material";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";
import {inject, provider, useInstance} from "react-ioc";
import {observer} from "mobx-react";
import {PaymentStore} from "app/modules/company/components/accounting/payments/PaymentStore";
import {NavigationStore} from "app/modules/company/stores/NavigationStore";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import {InvoiceListModel, PaymentListModel, TenantClaims} from "app/modules/company/api/clients.api";
import {Loading} from "app/modules/common/layout/components/loading";
import {MainStore} from "app/modules/company/stores/MainStore";
import {StringHelper} from "app/modules/common/helpers/StringHelper";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {Paths} from "app/modules/company/stores/paths";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentListModel>) => {
            const store = useInstance(PaymentStore);
            return <Button variant={"text"} onClick={() => store.openPayment(params.row.id)}>{params.row.id}</Button>;
        },
    },
    {
        field: 'customer',
        headerName: 'Customer',
        width: 300,
        flex: 2,
        valueGetter: (params: GridRenderCellParams<PaymentListModel>) =>
            `${StringHelper.formatNull(params.row.customer)}`,
    },
    {
        field: 'amount',
        headerName: 'Amount',
        maxWidth: 120,
        type: 'number',
        flex: 2,
        valueGetter: (params: GridRenderCellParams<PaymentListModel>) =>
            `${NumberHelper.formatUSDollar(params.row.amount)}`,
    },
    {
        field: 'type',
        headerName: 'Type',
        maxWidth: 120,
        flex: 2,
    },
    {
        field: 'checkNumber',
        headerName: 'CheckNumber',
        maxWidth: 120,
        flex: 2,
    },
    {
        field: 'reference',
        headerName: 'Reference',
        maxWidth: 120,
        flex: 2,
    },
    {
        field: 'paymentDate',
        headerName: 'PaymentDate',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.paymentDate)
        ),
    },
    {
        field: 'createdAt',
        headerName: 'CreatedAt',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentListModel>) => (
            DateTimeHelper.formatMomentDate(params.row.createdAt)
        ),
    },
    {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InvoiceListModel>) => {
            const store = useInstance(MainStore);
            const paymentStore = useInstance(PaymentStore);

            return <Stack direction={"row"} spacing={2}>
                {store.checkClaim(TenantClaims.AccountingAdmin) ? <Button variant="outlined" size="small" color={"error"} onClick={() => paymentStore.delete(params.row.id)}>Remove</Button> : "-"}
            </Stack>;
        },
    },
];

@provider(PaymentStore)
@observer
export default class Index extends Component<any, any> {
    @inject paymentStore: PaymentStore;
    @inject navigationStore: NavigationStore;
    @inject mainStore: MainStore;

    render() {
        if(this.paymentStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={this.navigationStore.title} TotalRows={this.paymentStore.totalRows}/>
            </Grid>
            {this.mainStore.checkClaim(TenantClaims.AccountingPayments) && <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={Paths.NewAccountingPayment.nav} Text="Receive Payment"/>
            </Grid>}
            <DataGridHeader>
                <BoxCenter>
                    <Stack direction={"row"}>
                        <SearchPaper width={400}>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={this.paymentStore.filters.$.search.value}
                                onChange={(e)=>this.paymentStore.filters.$.search.onChange(e.target.value)}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            <SearchVerDivider />
                            <Button variant="text" onClick={this.paymentStore.applyFilters}>Search</Button>
                        </SearchPaper>
                    </Stack>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.paymentStore.items}
                    columns={columns}
                    pageSize={this.paymentStore.pageSize}
                    rowsPerPageOptions={this.paymentStore.pageSizeOptions}
                    rowCount={this.paymentStore.totalRows}
                    page={this.paymentStore.skipRows/this.paymentStore.pageSize}
                    loading={this.paymentStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    checkboxSelection={false}
                    onPageChange={(page) => this.paymentStore.setPage(page)}
                    onPageSizeChange={(pageSize) => this.paymentStore.setPageSize(pageSize) }/>
            </DataGridBody>
        </DataGridContainer>;
    }

    componentDidMount() {
        this.paymentStore.init();
    }
}

