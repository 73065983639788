import axios from "axios";

import {
    IOrganizationClient,
    OrganizationClient,
    IDataProviderClient,
    DataProviderClient,
    UserClient,
    IUserClient,
    ITenantClient,
    TenantClient
} from "../api/clients.api";

const hostName = window.location.hostname;
export const apiBaseUrl = hostName == 'localhost' ? 'http://localhost:5000' :'https://app.onlogixs.com';

export class ApiClients {
    organizationClient: IOrganizationClient = null;
    dataProviderClient: IDataProviderClient = null;
    userClient: IUserClient = null;
    tenantClient: ITenantClient = null;

    constructor() {
        let axiosInstance = axios.create({
            baseURL: apiBaseUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            validateStatus: (status) => {
                return status >= 200 && status < 500
            }
        });

        axiosInstance.defaults.withCredentials = true;

        this.organizationClient = new OrganizationClient(apiBaseUrl, axiosInstance);
        this.dataProviderClient = new DataProviderClient(apiBaseUrl, axiosInstance);
        this.userClient = new UserClient(apiBaseUrl, axiosInstance);
        this.tenantClient = new TenantClient(apiBaseUrl, axiosInstance);

        axiosInstance.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(error.response?.status == 401) {
                window.location.href = "/login";
            }
        });
    }
}


