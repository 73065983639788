import {useGridApiRef} from "@mui/x-data-grid-pro";
import React from "react";


export const withHooksHOC = (Component: any) => {
    return (props: any) => {
        const gridApiRef = useGridApiRef();

        return <Component gridApiRef={gridApiRef} {...props} />;
    };
};
