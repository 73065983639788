import {action, makeObservable, runInAction} from "mobx";
import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import React from "react";
import {DialogNetTermEdit} from "app/modules/company/components/accounting/net-term/dialog.net-term.edit";
import {
    DataListGetParamsOfPaymentNetTermListFilters, PaymentNetTermListFilters,
    PaymentNetTermListModel
} from "app/modules/company/api/clients.api";

export class NetTermsStore extends BaseListStore<PaymentNetTermListModel>{
    @inject companyApiClients: ApiClients;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action,
        });
    }

    applySpecificFilters() {
    }

    async loadData() {
        await this.wrapLoadingApiCall(async () => {
            await this.filters.validate();

            if(this.filters.hasError) {
                return;
            }

            let filters = new PaymentNetTermListFilters({
                search: this.filters.$.search.value
            });

            const [result] = await Promise.all([
                this.companyApiClients.paymentClient.getPaymentNetTermList(new DataListGetParamsOfPaymentNetTermListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    openNetTerm = (netTermId: number = null) => {
        const key = "NetTerm_details_" + (netTermId || 0);
        this.dialogStore.addDialog({
            key: key,
            component: <div><DialogNetTermEdit netTermId={netTermId} closeModal={() => this.dialogStore.closeDialog(key)} updated={async () => {
                this.dialogStore.closeDialog(key);
                await this.loadData();
            }} /></div>
        });
    }

    async init() {
        await this.loadData()

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
