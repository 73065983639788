import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate";
import {email, long, required, requiredMaxLength} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {MainStore} from "app/modules/company/stores/MainStore";
import {
    BidSmtpSettings,
    CompanyIntegrationType,
    CompanySettings,
    GoogleApiRoutingSettings,
    LogoSettings, NamedValueLong,
} from "app/modules/company/api/clients.api";

export class SettingDetailsStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;
    @inject mainStore: MainStore;

    smptIntegrations: NamedValueLong[] = null;
    googleApiIntegrations: NamedValueLong[] = null;

    title: string = 'Setting';

    formGoogleApiState = new FormState({
        googleApiIntegrationId: new FieldState(null).validators(long)
    });

    formBidSmtpState = new FormState({
        smtpIntegrationId: new FieldState(null).validators(long)
    });

    formCompanyState = new FormState({
        officePhone: new FieldState('').validators(required),
        officeEmail: new FieldState('').validators(required, email),
        dispatchPhone: new FieldState('').validators(required),
        dispatchEmail: new FieldState(null).validators(required, email),
        mcNumber: new FieldState(null).validators(),
    });

    formLogoState = new FormState({
        logoBase64: new FieldState('').validators(requiredMaxLength(100000, "100kb as MAX")),
    });

    constructor() {
        super();

        makeObservable(this, {
            title: observable,
            smptIntegrations: observable,
            googleApiIntegrations: observable,

            init: action,
            saveBidSmtp: action,
            saveGoogleApi: action,
            saveSmtp: action
        });
    }

    saveGoogleApi = async () => {
        await this.formGoogleApiState.validate();

        if(this.formGoogleApiState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.settingsClient.setGoogleApiRoutingSettings(new GoogleApiRoutingSettings({
                googleApiIntegrationId: this.formGoogleApiState.$.googleApiIntegrationId.value
            }));
        });
    }

    saveBidSmtp = async () => {
        await this.formBidSmtpState.validate();

        if(this.formBidSmtpState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.settingsClient.setBidSmtpSettings(new BidSmtpSettings({
                smtpIntegrationId: this.formBidSmtpState.$.smtpIntegrationId.value
            }));
        });
    }

    saveLogo = async () => {
        await this.formLogoState.validate();

        if(this.formLogoState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.settingsClient.setLogoSettings(new LogoSettings({
                base64: this.formLogoState.$.logoBase64.value
            }));
        });
    }

    saveSmtp = async () => {
        await this.formBidSmtpState.validate();

        if(this.formBidSmtpState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.settingsClient.setBidSmtpSettings(new BidSmtpSettings({
                smtpIntegrationId: this.formBidSmtpState.$.smtpIntegrationId.value
            }));
        });
    }

    saveCompany= async () => {
        await this.formCompanyState.validate();

        if(this.formCompanyState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.companyApiClients.settingsClient.setCompanySettings(new CompanySettings({
                officePhone: this.formCompanyState.$.officePhone.value,
                officeEmail: this.formCompanyState.$.officeEmail.value,
                dispatchPhone: this.formCompanyState.$.dispatchPhone.value,
                dispatchEmail: this.formCompanyState.$.dispatchEmail.value,
                mcNumber: this.formCompanyState.$.mcNumber.value
            }));
        });
    }

    init = async () => {
        await this.wrapInitLoadingApiCall(async () => {
            const [smtp, google, company, logo, smptIntegrations, googleApiIntegrations] = [
                await this.companyApiClients.settingsClient.getBidSmtpSettings(),
                await this.companyApiClients.settingsClient.getGoogleApiRoutingSettings(),
                await this.companyApiClients.settingsClient.getCompanySettings(),
                await this.companyApiClients.settingsClient.getLogoSettings(),
                await this.companyApiClients.companyIntegrationClient.getShortListByType(CompanyIntegrationType.SMTP),
                await this.companyApiClients.companyIntegrationClient.getShortListByType(CompanyIntegrationType.GoogleApi),
            ];

            this.smptIntegrations = smptIntegrations;
            this.googleApiIntegrations = googleApiIntegrations;

            this.formGoogleApiState.$.googleApiIntegrationId.value = google?.googleApiIntegrationId;

            this.formLogoState.$.logoBase64.value = logo?.base64;

            this.formBidSmtpState.$.smtpIntegrationId.value = smtp?.smtpIntegrationId;

            this.formCompanyState.$.officePhone.value = company?.officePhone;
            this.formCompanyState.$.officeEmail.value = company?.officeEmail;
            this.formCompanyState.$.dispatchPhone.value = company?.dispatchPhone;
            this.formCompanyState.$.dispatchEmail.value = company?.dispatchEmail;
            this.formCompanyState.$.mcNumber.value = company?.mcNumber;
        });
    }
}
