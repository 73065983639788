import {inject, provider} from "react-ioc";
import {observer} from "mobx-react";
import React, {Component} from "react";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {SettingDetailsStore} from "app/modules/company/components/settings/general/SettingDetailsStore";
import {Loading} from "app/modules/common/layout/components/loading";
import {MenuItem, Stack} from "@mui/material";
import {Section, SectionName, SectionSeparator} from "app/modules/common/layout/components/details";
import {MainStore} from "app/modules/company/stores/MainStore";
import {SectionSelectField, SectionTextField} from "app/modules/common/details/text-field";
import {LoadingButton} from "@mui/lab";
import {FileBase64Input} from "app/modules/common/details/file-base64";

@provider(SettingDetailsStore)
@observer
export default class Index extends Component<any, any> {
    @inject settingDetailsStore: SettingDetailsStore;
    @inject mainStore: MainStore;

    render() {
        if(this.settingDetailsStore.isInitLoading) {
            return <Loading/>;
        }

        return <Container>
                <PageTitleDetails Title={this.settingDetailsStore.title} Size={12}/>
                <ContainerDetails>
                    <SectionName title="Company Info" description="General Company Info."/>
                    <Section>
                        <Stack spacing={2} direction={"column"}>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Office Phone"}
                                    field={this.settingDetailsStore.formCompanyState.$.officePhone}
                                    fullWidth/>
                                <SectionTextField
                                    label={"Office Email"}
                                    field={this.settingDetailsStore.formCompanyState.$.officeEmail}
                                    fullWidth/>
                                <SectionTextField
                                    label={"Dispatch Phone"}
                                    field={this.settingDetailsStore.formCompanyState.$.dispatchPhone}
                                    fullWidth/>
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <SectionTextField
                                    label={"Dispatch Email"}
                                    field={this.settingDetailsStore.formCompanyState.$.dispatchEmail}
                                    fullWidth/>
                                <SectionTextField
                                    label={"Override MC Number"}
                                    field={this.settingDetailsStore.formCompanyState.$.mcNumber}
                                    fullWidth/>
                            </Stack>
                            <LoadingButton loading={this.settingDetailsStore.isLoading} disabled={this.settingDetailsStore.formCompanyState.hasError} variant="contained" onClick={this.settingDetailsStore.saveCompany}>
                                Save
                            </LoadingButton>
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Google API" description="Google API ket that has an access to: Geocoding API, Distance Matrix API, Maps JavaScript API (Google charges will apply)" />
                    <Section>
                        <Stack spacing={2} direction={"row"}>
                            <SectionSelectField
                                field={this.settingDetailsStore.formGoogleApiState.$.googleApiIntegrationId}
                                width={"100%"}
                                label={"Google ApiKey Integration (Geocoding API, Distance Matrix API, Maps JavaScript API)"}
                                items={() => this.settingDetailsStore.googleApiIntegrations.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                            />
                            <LoadingButton loading={this.settingDetailsStore.isLoading} disabled={this.settingDetailsStore.formGoogleApiState.hasError} variant="contained" onClick={this.settingDetailsStore.saveGoogleApi}>
                                Save
                            </LoadingButton>
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Email SMTP" description="SMTP Setting that is used for sending/reading emails. Please provide en email that receives all emails."/>
                    <Section>
                        <Stack spacing={2} direction={"row"}>
                            <SectionSelectField
                                field={this.settingDetailsStore.formBidSmtpState.$.smtpIntegrationId}
                                width={"100%"}
                                label={"SMTP Integration"}
                                items={() => this.settingDetailsStore.smptIntegrations.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                            />
                            <LoadingButton loading={this.settingDetailsStore.isLoading} disabled={this.settingDetailsStore.formBidSmtpState.hasError} variant="contained" onClick={this.settingDetailsStore.saveSmtp}>
                                Save
                            </LoadingButton>
                        </Stack>
                    </Section>
                    <SectionSeparator/>
                    <SectionName title="Logo" description="Please upload your logo, that will be user in all Emails/Documents" />
                    <Section>
                        <Stack spacing={2} direction={"row"}>
                            <FileBase64Input multiple={false} label={"Logo Image"} onDone={(file) => { this.settingDetailsStore.formLogoState.$.logoBase64.onChange(file.base64) }}/>
                            <img src={this.settingDetailsStore.formLogoState.$.logoBase64.value}/>
                            <LoadingButton loading={this.settingDetailsStore.isLoading} disabled={this.settingDetailsStore.formLogoState.hasError} variant="contained" onClick={this.settingDetailsStore.saveLogo}>
                                Save
                            </LoadingButton>
                        </Stack>
                    </Section>
                </ContainerDetails>
        </Container>;
    }

    async componentDidMount() {
        await this.settingDetailsStore.init();
    }
}
