import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {ModalOrderItemParams} from "app/modules/company/components/orders/details/modal.item";
import {FieldState, FormState} from "formstate";
import {int, required, requiredNumber} from "app/modules/common/form/validators";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/company/services/AxiosBaseClient";
import {OrderItemViewModel} from "app/modules/company/api/clients.api";

export class OrderItemStore extends BaseLoadingStore {
    @inject companyApiClients: ApiClients;

    params: ModalOrderItemParams = null;

    formState = new FormState({
        description: new FieldState('').validators(required),
        cargoType: new FieldState(1).validators(requiredNumber),
        packing: new FieldState('Pallet').validators(required),
        hazardous: new FieldState(false),
        pieceUnits: new FieldState(1).validators(requiredNumber),
        weight: new FieldState(0).validators(requiredNumber),
        height: new FieldState(0).validators(int),
        width: new FieldState(0).validators(int),
        length: new FieldState(0).validators(int),
    });

    constructor() {
        super();

        this.isInitLoading = false;
    }

    save = async () => {
        await this.formState.validate();

        if(this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
           await this.companyApiClients.orderClient.upsertOrderItem(this.params.orderId, new OrderItemViewModel({
               id: this.params.item?.id || 0,
               description: this.formState.$.description.value,
               cargoType: this.formState.$.cargoType.value,
               packing: this.formState.$.packing.value,
               hazardous: this.formState.$.hazardous.value,
               pieceUnits: this.formState.$.pieceUnits.value,
               weight: this.formState.$.weight.value,
               height: this.formState.$.height.value || null,
               width: this.formState.$.width.value || null,
               length: this.formState.$.length.value || null,
           }));

           this.params.refreshOrderItems();
           this.params.closeOrderItemModal();
        });
    }

    init = (params: ModalOrderItemParams) => {
        this.params = params;

        if(this.params.item) {
            this.formState.$.description.value = this.params.item.description;
            this.formState.$.cargoType.value = this.params.item.cargoType;
            this.formState.$.packing.value = this.params.item.packing;
            this.formState.$.hazardous.value = this.params.item.hazardous;
            this.formState.$.pieceUnits.value = this.params.item.pieceUnits;
            this.formState.$.weight.value = this.params.item.weight;
            this.formState.$.height.value = this.params.item.height;
            this.formState.$.width.value = this.params.item.width;
            this.formState.$.length.value = this.params.item.length;
        }
    }
}
