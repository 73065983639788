import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {ApiClients} from "app/modules/external/services/AxiosBaseClient";
import {inject} from "react-ioc";
import {OrderExternalViewModel} from "app/modules/external/api/clients.api";
import {action, makeObservable, observable} from "mobx";
import {OrderStatus} from "app/modules/company/api/clients.api";
import queryString from "query-string";

export class OrderLinkStore extends BaseLoadingStore {
    @inject apiClients : ApiClients;
    details: OrderExternalViewModel = null;

    constructor() {
        super();

        makeObservable(this, {
            details: observable,

            init: action,
        });
    }

    init = async (link: string) => {
        await this.wrapInitLoadingApiCall(async () => {
            this.details = await this.apiClients.externalClient.getOrderInfo(link);
        });
    }

    getGoogleMapParams = () => {
        let origin;
        let waypoints = [];
        let destination = this.details.stops[this.details.stops.length - 1].fullAddress


        if(this.details.status != OrderStatus.InProgress || !this.details.vehicleLocation) {
            origin = this.details.stops[0].fullAddress;

            for(let i = 1; i < this.details.stops.length - 1; i++) {
                let stop = this.details.stops[i];
                waypoints.push(stop.fullAddress);
            }
        } else {
            origin = this.details.vehicleLocation;

            for(let i = 0; i < this.details.stops.length - 1; i++) {
                let stop = this.details.stops[i];
                if(!stop.departed) {
                    waypoints.push(stop.fullAddress);
                }
            }
        }

        return queryString.stringify({
            key: 'AIzaSyD2jTzFsa8WordLMSc9J_dGuDRPz5f7GjM',
            origin: origin,
            waypoints: waypoints,
            destination: destination,
            avoid: 'tolls',
            mode: 'driving'
        });
    }
}
